import { useColorsContext } from '@utils/styles';


const ExamPrepIcon = ({
    size = 20,
    style,
}) => {

    const { text } = useColorsContext();
    return (
        <svg width={size} height={size * (25 / 21)} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 23.5287H2.25C1.91848 23.5287 1.60054 23.4037 1.36612 23.1814C1.1317 22.959 1 22.6574 1 22.343V2.18574C1 1.87127 1.1317 1.56968 1.36612 1.34731C1.60054 1.12495 1.91848 1.00003 2.25 1.00003H13.5287C14.6026 0.996684 15.6488 1.32277 16.5113 1.92963C17.3649 2.54138 18.1251 3.26244 18.77 4.07222C19.4098 4.89033 19.7535 5.88273 19.75 6.90135V22.343C19.75 22.6574 19.6183 22.959 19.3839 23.1814C19.1495 23.4037 18.8315 23.5287 18.5 23.5287Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.75 3.37146V5.7429H17.25C17.913 5.7429 18.5489 5.99274 19.0178 6.43747C19.4866 6.8822 19.75 7.48539 19.75 8.11433V9.30005" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.75 17.6C4.75 17.6 7.25 11.6714 7.25 8.11426L11 16.4143" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 15.2286C7.91984 15.0564 9.70283 14.2107 11 12.8572" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.9175 11.9062L14.5812 15.3057" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.4575 13.9244C13.6883 13.9945 14.9186 13.7784 16.0413 13.2948" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ExamPrepIcon;