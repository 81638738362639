import { ExpandMoreRounded } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Rating,
    styled,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { FilterType } from 'dataTypes'
import React, { FC } from 'react'
import { StyleSheet } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import FilterIcon from '@icons/FilterIcon'
import useGetFilterItemIcon from './useGetFilterItemIcon'
import TutredSwitch from '@components/TutredSwitch'
import TutredSlider from '@components/TutredSlider'
import useGetFilterChip from './useGetFilterChip'
import useGetFilterOptions from './useGetFilterOptions'
import GroupHeader from './GroupHeader'
import GroupItems from './GroupItems'
import StarIcon from '@icons/StarIcon'
import QuestionMarkIcon from '@icons/QuestionMarkIcon'

interface Props {
    filters: FilterType[]
    setFilters: React.Dispatch<React.SetStateAction<FilterType[]>>
    drawerOpen: boolean
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
    resetFilters: () => void
}

const TutorListFilters: FC<Props> = ({
    filters,
    setFilters,
    resetFilters,
    drawerOpen,
    setDrawerOpen,
}) => {
    const { primaryLight, background, text } = useColorsContext()
    const defaultStyles = useStyles()
    const getFilterChip = useGetFilterChip()
    const filterIcon = useGetFilterItemIcon()
    const filterOptions = useGetFilterOptions()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const activeFilters = Object.keys(filters).filter((key) => {
        if (filters[key].type === 'boolean') {
            return filters[key].value
        } else if (filters[key].type === 'range') {
            if (filters[key].label === 'Price') {
                return (
                    filters[key].value[0] !== filters[key].minValue ||
                    filters[key].value[1] !== filters[key].maxValue
                )
            } else {
                return filters[key].value[1] !== 0
            }
        } else if (filters[key].type === 'array') {
            return filters[key].value?.length > 0
        }
    })

    return (
        <Box
            sx={{
                marginTop: { xs: 0, md: 2 },
                backgroundColor: primaryLight,
                p: 2,
                mb: { xs: 7, md: 0 },
                ml: { xs: 0, md: 2 },
                borderRadius: { xs: 0, md: 3 },
                border: '3px solid #D9D9D9',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <FilterIcon size={24} />
                    <Typography
                        variant="h6"
                        fontFamily="Inter_700Bold"
                        sx={{ ml: 1 }}
                    >
                        Filters
                    </Typography>
                </Box>
                <Typography variant="caption" fontFamily="Poppins_500Medium">
                    Use search filters to find your right match!
                </Typography>
                <Divider sx={{ mt: 1 }} flexItem variant="middle" />
            </Box>
            {activeFilters.length > 0 && (
                <Box>
                    <Typography
                        variant="body2"
                        fontFamily="Poppins_600SemiBold"
                        sx={{ mt: 2, textAlign: 'center', cursor: 'pointer' }}
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </Typography>
                </Box>
            )}
            <>
                {Object.keys(filters).map((key) => {
                    const filter = filters[key]

                    if (filter.type === 'boolean') {
                        return (
                            <ListItem disablePadding sx={{ minHeight: 24 }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 40,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {filterIcon(filter.label)}
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography={true}
                                    id={`switch-list-label-${key}`}
                                    sx={{
                                        display: 'flex',
                                        lexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textTransform: 'capitalize',
                                            fontFamily: defaultStyles.titleFont,
                                        }}
                                    >
                                        {filter.label}
                                    </Typography>
                                    {filter.tip && (
                                        <Tooltip
                                            title={filter.tip}
                                            placement="right"
                                            sx={{ ml: 1 }}
                                        >
                                            <IconButton>
                                                <QuestionMarkIcon
                                                    color={
                                                        theme.palette.grey[500]
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </ListItemText>
                                <TutredSwitch
                                    onChange={() => {
                                        setFilters((prev) => {
                                            return {
                                                ...prev,
                                                [key]: {
                                                    ...prev[key],
                                                    value: !filters[key].value,
                                                },
                                            }
                                        })
                                    }}
                                    checked={filters[key].value}
                                    inputProps={{
                                        'aria-labelledby': `switch-list-label-${key}`,
                                    }}
                                />
                            </ListItem>
                        )
                    } else if (filter.type === 'range') {
                        return (
                            <ListItem disablePadding>
                                <Accordion
                                    square
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        width: '100%',
                                        boxShadow: 'none',
                                        backgroundColor: 'inherit',
                                    }}
                                    disableGutters
                                    defaultExpanded
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreRounded />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{
                                            height: 36,
                                            minHeight: 36,
                                            padding: 0,
                                            '& .MuiAccordionSummary-content': {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 40,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {filterIcon(filter.label)}
                                        </ListItemIcon>
                                        <ListItemText
                                            disableTypography={true}
                                            id={`switch-list-label-${key}`}
                                            sx={{
                                                display: 'flex',
                                                lexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    fontFamily:
                                                        defaultStyles.titleFont,
                                                }}
                                            >
                                                {filter.label}
                                            </Typography>
                                        </ListItemText>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: 0 }}>
                                        {filter.label === 'Price' && (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled
                                                    slotProps={{
                                                        input: {
                                                            style: {
                                                                backgroundColor:
                                                                    background,
                                                                color: 'white',
                                                                textAlign:
                                                                    'center',
                                                                fontFamily:
                                                                    'Inter_600SemiBold',
                                                                WebkitTextFillColor:
                                                                    'black',
                                                                fontSize: 16,
                                                            },
                                                        },
                                                        htmlInput: {
                                                            style: {
                                                                textAlign:
                                                                    'center',
                                                                WebkitTextFillColor:
                                                                    'black',
                                                            },
                                                        },
                                                    }}
                                                    value={`$${filter.value[0]}-$${filter.value[1]}${filter.value[1] === filter.maxValue ? '+' : ''}`}
                                                />
                                                <Box sx={{ px: 2 }}>
                                                    <TutredSlider
                                                        sx={{ mt: 0.5 }}
                                                        getAriaLabel={() =>
                                                            filter.label
                                                        }
                                                        step={5}
                                                        value={filter.value}
                                                        onChange={(e, val) => {
                                                            setFilters(
                                                                (prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        [key]: {
                                                                            ...prev[
                                                                                key
                                                                            ],
                                                                            value: val,
                                                                        },
                                                                    }
                                                                }
                                                            )
                                                        }}
                                                        valueLabelDisplay="auto"
                                                        max={filter.maxValue}
                                                        min={filter.minValue}
                                                        marks={
                                                            filter.marks || []
                                                        }
                                                        // getAriaValueText={}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                        {filter.label === 'Rating' && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    px: 2,
                                                }}
                                            >
                                                <Rating
                                                    name="half-rating"
                                                    value={filter.value[1]}
                                                    icon={
                                                        <StarIcon
                                                            filled={true}
                                                            size={24}
                                                        />
                                                    }
                                                    emptyIcon={
                                                        <StarIcon
                                                            filled={true}
                                                            fill="#FFFFFF"
                                                            size={24}
                                                        />
                                                    }
                                                    IconContainerComponent={({
                                                        value,
                                                        ...props
                                                    }) => (
                                                        <span
                                                            {...props}
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                            }}
                                                        />
                                                    )}
                                                    precision={0.5}
                                                    onChange={(e, val) => {
                                                        setFilters((prev) => {
                                                            return {
                                                                ...prev,
                                                                [key]: {
                                                                    ...prev[
                                                                        key
                                                                    ],
                                                                    value: [
                                                                        0,
                                                                        val,
                                                                    ],
                                                                },
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        )
                    } else if (filter.type === 'array') {
                        return (
                            <ListItem disablePadding>
                                <Accordion
                                    square
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        width: '100%',
                                        boxShadow: 'none',
                                        backgroundColor: 'inherit',
                                    }}
                                    disableGutters
                                    defaultExpanded
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreRounded />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{
                                            padding: 0,
                                            height: 36,
                                            minHeight: 36,
                                            '& .MuiAccordionSummary-content': {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {' '}
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 40,
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {filterIcon(filter.label)}
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography={true}
                                                id={`switch-list-label-${key}`}
                                                sx={{
                                                    display: 'flex',
                                                    lexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontFamily:
                                                            defaultStyles.titleFont,
                                                    }}
                                                >
                                                    {filter.label}
                                                </Typography>
                                                {filter.tip && (
                                                    <Tooltip
                                                        title={filter.tip}
                                                        placement="right"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        <IconButton>
                                                            <QuestionMarkIcon
                                                                color={
                                                                    theme
                                                                        .palette
                                                                        .grey[500]
                                                                }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </ListItemText>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: 0 }}>
                                        <Autocomplete
                                            multiple
                                            id="fixed-tags-demo"
                                            value={filters[key].value}
                                            groupBy={(option) => {
                                                return option?.group
                                            }}
                                            renderGroup={(params) => (
                                                <li key={params.key}>
                                                    {params.group && (
                                                        <GroupHeader
                                                            title={params.group}
                                                        />
                                                    )}
                                                    <GroupItems
                                                        children={
                                                            params.children
                                                        }
                                                        filter={
                                                            filters[key].label
                                                        }
                                                    />
                                                </li>
                                            )}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => {
                                                if (
                                                    filter.optionType ===
                                                    'string'
                                                ) {
                                                    return option === value
                                                } else {
                                                    return (
                                                        option.id === value.id
                                                    )
                                                }
                                            }}
                                            onChange={(event, newValue) => {
                                                setFilters((prev) => {
                                                    return {
                                                        ...prev,
                                                        [key]: {
                                                            ...prev[key],
                                                            value: newValue,
                                                        },
                                                    }
                                                })
                                            }}
                                            options={filterOptions(
                                                filter.label,
                                                filter.options,
                                                []
                                            )}
                                            getOptionLabel={(option) =>
                                                filter.optionType === 'string'
                                                    ? option
                                                    : option.label
                                            }
                                            renderTags={(
                                                tagValue,
                                                getTagProps
                                            ) =>
                                                tagValue.map(
                                                    (option, index) => {
                                                        const {
                                                            key,
                                                            ...tagProps
                                                        } = getTagProps({
                                                            index,
                                                        })
                                                        return getFilterChip(
                                                            key,
                                                            filter,
                                                            tagProps,
                                                            option
                                                        )
                                                    }
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder={
                                                        filter.placeholder
                                                    }
                                                    slotProps={{
                                                        input: {
                                                            style: {
                                                                backgroundColor:
                                                                    background,
                                                                fontSize: 12,
                                                            },

                                                            ...params.InputProps,
                                                        },
                                                    }}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        )
                    } else {
                        return null
                    }
                })}
            </>
            {!isMd && (
                <Box
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    sx={{
                        p: 2,
                        backgroundColor: primaryLight,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <Typography
                        variant="body1"
                        fontFamily={defaultStyles.boldFont}
                        textAlign="center"
                    >
                        View Results
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default TutorListFilters
