// Home Screen for logged in tutor
import { A } from "@expo/html-elements";
import { AntDesign, Feather } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { BarChart } from "react-native-chart-kit";
import { useMediaQuery } from "react-responsive";
import { Header as AuthHeader } from "../../../components/AuthHeader";
import Footer from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { LoadingPage } from "../../../components/LoadingPage";
import { StripeAccountModal } from "../../../components/StripeAccountModal";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

// Firebase


const hostname = process.env.HOSTNAME

const hexArr = [
	"#ABBFF8",
	"#9EB5F5",
	"#92AAF3",
	"#86A0F0",
	"#7A95ED",
	"#6D8BEB",
	"#6181E8",
	"#5577E5",
	"#486CE3",
	"#3C62E0",
	"#3058DD",
	"#234EDB",
];

// Account Screen
export const PaymentsNew = ({ navigation }) => {
	const { user, name, URL, isTutor, accountId, showStripeOnboarding } =
		useUserContext();
	const { db } = useFirebaseContext();
	const customAlert = useAlert();

	// User Context
	const auth = getAuth();
	const [view, setView] = useState("wallet");
	const [accountData, setAccountData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [requestPayout, setRequestPayout] = useState(true);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [instantPayout, setInstantPayout] = useState(false);
	const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
	const [chartParentWidth, setChartParentWidth] = useState(0);
	const [dataset, setDataset] = useState(null);
	const [bankConnected, setBankConnected] = useState(false);
	const [totalEarnings, setTotalEarnings] = useState(0);

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		toggleDarkMode,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
			padding: isSm ? 45 : 15,
			paddingTop: 30,
		},
		title: {
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		subtitle: {
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		label: {
			fontSize: 19,
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
		},
		balance: {
			fontSize: 19,
			color: text,
			fontFamily: defaultStyles.boldFont,
		},
		description: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
		},
		infoTitle: {
			textAlign: "left",
			fontSize: 22,
			fontFamily: defaultStyles.boldFont,
			color: text,
		},
		infoSubtitle: {
			fontSize: 19,
			fontFamily: defaultStyles.boldFont,
			color: textVariant,
		},
		infoDescription: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
			marginTop: 5,
			display: "flex",
			flex: 1,
		},
		infoLink: {
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			color: primary,
			marginTop: 10,
		},
	});

	useEffect(() => {
		if (accountId) {
			getAccountData();
			getAnalytics();
		} else {
			setLoading(false);
		}
	}, [accountId, showStripeOnboarding]);

	const getAnalytics = async () => {
		fetch(`${hostname}/api/analytics`, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + user.accessToken,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					let payments = data.payments;
					// Create the datasets for the chart

					// We need an array of colors for each month
					let months = Object.keys(payments);
					let colors = []; // 3 colors for each (4 months)
					let datapoints = [];
					let labels = [];
					var total = 0;
					// Flip the months array so it's in order
					months = months.reverse();
					// Create the datasets
					months.forEach((month, index) => {
						total += payments[month];
						// if (index > 2) {
						//     colors.push(
						//         (opacity = 0.5) => primaryVariant
						//     );
						// }

						// if (index > 6) {
						//     colors.push(
						//         (opacity = 0.5) => primary
						//     );
						// }
						colors.push((opacity = 0.5) => hexArr[index]);

						// Label is the month shortened

						labels.push(month);

						datapoints.push(payments[month].toFixed(2));
					});

					var lDataset = {
						labels: labels,
						datasets: [
							{
								data: datapoints,
								colors: colors,
							},
						],
					};
					setDataset(lDataset);
					setTotalEarnings(total);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const getAccountData = async () => {
		// Pull data from API
		fetch(`${hostname}/api/account-details`, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + user.accessToken,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setAccountData(data.account);

				if (
					data.account.settings.payouts.schedule.interval === "manual"
				) {
					setRequestPayout(true);
				} else {
					setRequestPayout(false);
				}

				if (data.account.external_accounts.data.length > 0) {
					setBankConnected(true);
				}

				data.account.external_accounts.data.forEach((account) => {
					if (account.default_for_currency) {
						setPaymentMethod(account);
						if (
							account.available_payout_methods.includes("instant")
						) {
							setInstantPayout(true);
						}
					}
				});

				// Add payouts and payments to transactions
				let lTransactions = [];
				let payouts = data.account.payouts.data;
				let charges = data.account.charges.data;

				payouts.forEach((payout) => {
					payout.type = "payout";
					lTransactions.push(payout);
				});

				charges.forEach((charge) => {
					charge.type = "charge";
					lTransactions.push(charge);
				});

				setTransactions(lTransactions);

				setLoading(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<SafeAreaView style={styles.container}>
			{/* Main Content */}
			{loading ? (
				<LoadingPage />
			) : (
				<ScrollView
					showsVerticalScrollIndicator={false}
					style={{ backgroundColor: background }}
				>
					<StripeAccountModal
						display={showPaymentMethodModal}
						setDisplay={setShowPaymentMethodModal}
						pullData={getAccountData}
					/>
					<View style={styles.maxWidth}>
						<View style={[styles.mainSection]}>
							{/* Title */}
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									marginBottom: 5,
								}}
							>
								<Link
									// onMouseEnter={handlePressIn}
									// onMouseLeave={handlePressOut}
									to={"/settings"}
								>
									<Text>Settings</Text>
								</Link>{" "}
								<Text
									style={{
										fontFamily: defaultStyles.boldFont,
										color: primary,
									}}
								>
									<Feather
										name="chevron-right"
										size={15}
										color={text}
									/>{" "}
									Payments
								</Text>
							</Text>

							<Text style={styles.title}>Payments</Text>

							{/* Top tab navigation */}
							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									marginTop: 20,
									marginBottom: 20,
									gap: 30,
									borderBottomColor: border,
									borderBottomWidth: 1,
									paddingBottom: 15,
									flexDirection: isMd ? "row" : "column",
									alignItems: isMd ? "center" : "flex-start",
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setView("wallet");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "wallet"
														? primary
														: textVariant,
												fontFamily:
													view === "wallet"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Wallet
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("payout");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "payout"
														? primary
														: textVariant,
												fontFamily:
													view === "payout"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Payout details
									</Text>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setView("transactions");
									}}
								>
									<Text
										style={[
											styles.subtitle,
											{
												color:
													view === "transactions"
														? primary
														: textVariant,
												fontFamily:
													view === "transactions"
														? defaultStyles.boldFont
														: defaultStyles.regularFont,
											},
										]}
									>
										Transactions
									</Text>
								</TouchableOpacity>
							</View>

							<View
								style={{
									display: "flex",
									flexDirection: isLg ? "row" : "column",
									gap: 30,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										paddingRight: 30,
									}}
								>
									{/* Put main Content here  */}
									{view === "wallet" && (
										<>
											{/* BIG Card */}
											<View
												style={{
													padding: 20,
													border:
														"1px solid" + border,
													borderRadius:
														defaultStyles.radius,
													display: "flex",
													justifyContent: "center",
													//  alignItems:"center",
													height: 250,
												}}
											>
												<View
													style={{
														flex: 1,
														flexDirection: "row",
														alignItems: "center",
														justifyContent:
															"center",
													}}
												>
													<Text
														style={{
															fontSize: 42,
															color: text,
															fontFamily:
																defaultStyles.titleFont,
														}}
													>
														$
														{accountData &&
															accountData.balance &&
															accountData.balance
																.available[0] &&
															accountData.balance
																.pending[0]
															? (
																(accountData
																	.balance
																	?.available[0]
																	.amount +
																	accountData
																		.balance
																		?.pending[0]
																		.amount) /
																100
															).toFixed(2)
															: 0.0}
													</Text>
												</View>
											</View>

											{/* Balance view 2 columns */}
											<View
												style={{
													padding: 20,
													border:
														"1px solid" + border,
													borderRadius:
														defaultStyles.radius,
													marginTop: 30,
													display: "flex",
													flexDirection: "row",
													flexWrap: "wrap",
													alignItems: "center",
													spaceX: 15,
													spaceY: 15,
												}}
											>
												<View
													style={{
														flex: 1,
														flexDirection: "column",
													}}
												>
													<View>
														<Text
															style={styles.label}
														>
															Available to
															withdraw
														</Text>
														<Text
															style={
																styles.description
															}
														>
															Money available to
															withdraw to your
															bank account.
														</Text>
													</View>
													<Text
														style={styles.balance}
													>
														$
														{accountData &&
															accountData.balance &&
															accountData.balance
																.available[0]
															? (
																accountData
																	.balance
																	?.available[0]
																	.amount /
																100
															).toFixed(2)
															: 0.0}
													</Text>
												</View>

												<View
													style={{
														flex: 1,
														flexDirection: "column",
													}}
												>
													<View>
														<Text
															style={styles.label}
														>
															Pending
														</Text>
														<Text
															style={
																styles.description
															}
														>
															Money that is being
															processed.
														</Text>
													</View>
													<Text
														style={styles.balance}
													>
														$
														{accountData &&
															accountData.balance &&
															accountData.balance
																.pending[0]
															? (
																accountData
																	.balance
																	?.pending[0]
																	.amount /
																100
															).toFixed(2)
															: 0.0}
													</Text>
												</View>
											</View>
											<View
												onLayout={({ nativeEvent }) =>
													setChartParentWidth(
														nativeEvent.layout.width
													)
												}
												style={{
													// padding: 15,
													border:
														"1px solid" + border,
													borderRadius:
														defaultStyles.radius,
													marginTop: 30,
													display: "flex",
													flexDirection: "column",
													padding: 20,
												}}
											>
												<View style={{}}>
													<Text
														style={{
															fontSize: 22,
															color: text,
															fontFamily:
																defaultStyles.titleFont,
														}}
													>
														$
														{totalEarnings.toFixed(
															2
														)}{" "}
														earned in 2023.
													</Text>
												</View>
												{dataset && (
													<BarChart
														withCustomBarColorFromData={
															true
														}
														flatColor={true}
														showBarTops={false}
														showValuesOnTopOfBars={
															false
														}
														data={dataset}
														height={350}
														width={
															chartParentWidth -
															45
														}
														yAxisLabel="$"
														yAxisInterval={1} // optional, defaults to 1
														bezier
														chartConfig={{
															backgroundColor:
																"transparent",
															backgroundGradientTo:
																"white",
															backgroundGradientFromOpacity: 0,
															backgroundGradientFrom:
																"white",
															backgroundGradientToOpacity: 0,
															decimalPlaces: 0, // optional, defaults to 2dp
															color: () =>
																primary,
															labelColor: () =>
																textVariant,
															style: {
																borderRadius: 5,
															},
															propsForLabels: {
																fontFamily:
																	defaultStyles.regularFont,
															},
															propsForVerticalLabels:
															{
																fontFamily:
																	defaultStyles.regularFont,
																fontSize: 14,
																color: textVariant,
															},
															propsForHorizontalLabels:
															{
																fontFamily:
																	defaultStyles.regularFont,
																fontSize: 14,
																color: textVariant,
																fromZero: true,
															},
															propsForBackgroundLines:
															{
																strokeDasharray:
																	"", // solid background lines with no dashes
																strokeWidth: 0.5,
																stroke: border,
																display:
																	"none",
															},
														}}
														style={{
															marginTop: 15,
															borderRadius: 5,
														}}
													/>
												)}

												{/* Legend with each month and line progress */}
												<View
													style={{
														display: "flex",
														flexWrap: "wrap",
														flexDirection: "row",
														flex: 1,
														gap: 30,
													}}
												>
													{dataset &&
														dataset.labels &&
														dataset.labels.map(
															(label, index) => (
																<View
																	key={`legend-${index}`}
																	style={{
																		display:
																			"flex",
																		flexDirection:
																			"row",
																		alignItems:
																			"center",
																	}}
																>
																	<View
																		style={{
																			width: 25,
																			height: 25,
																			borderRadius: 5,
																			backgroundColor:
																				hexArr[
																				index
																				],
																			marginRight: 10,
																		}}
																	></View>
																	<Text
																		style={{
																			fontSize: 16,
																			color: text,
																			fontFamily:
																				defaultStyles.boldFont,
																			marginRight: 5,
																		}}
																	>
																		$
																		{
																			dataset
																				.datasets[0]
																				.data[
																			index
																			]
																		}
																	</Text>
																	<Text
																		style={{
																			fontSize: 16,
																			color: text,
																			fontFamily:
																				defaultStyles.regularFont,
																		}}
																	>
																		{label}
																	</Text>
																</View>
															)
														)}
												</View>
											</View>
										</>
									)}

									{/* Payouts view */}
									{view === "payout" && !paymentMethod && (
										<View
											style={{
												borderBottomColor: border,
												borderBottomWidth: 1,
												paddingBottom: 15,
												marginBottom: 15,
												display: "flex",
												flexDirection: isMd
													? "row"
													: "column",
												justifyContent: "space-between",
												alignItems: isMd
													? "center"
													: "flex-start",
											}}
										>
											<View
												style={{
													maxWidth: "75%",
												}}
											>
												<Text style={styles.subtitle}>
													No account connected
												</Text>
												<Text
													style={styles.description}
												>
													Please connect your bank to
													receive payouts.
												</Text>
											</View>

											<TouchableOpacity
												onPress={() => {
													setShowPaymentMethodModal(
														true
													);
												}}
												style={{
													marginTop: 15,
												}}
											>
												<Text
													style={{
														color: primary,
														fontSize: 16,
														fontFamily:
															defaultStyles.regularFont,
														textAlign: "center",
													}}
												>
													Connect a bank account
												</Text>
											</TouchableOpacity>
										</View>
									)}

									{view === "payout" && paymentMethod && (
										<>
											{/* Name and update */}
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													display: "flex",
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View
													style={{
														maxWidth: "75%",
													}}
												>
													<Text
														style={styles.subtitle}
													>
														Account:{" "}
														{paymentMethod.object ===
															"bank_account"
															? paymentMethod.bank_name
															: paymentMethod.brand}
														****
														{paymentMethod.last4}
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Your payment method
														connected to your
														account.
													</Text>
												</View>

												<TouchableOpacity
													onPress={() => {
														setShowPaymentMethodModal(
															true
														);
													}}
													style={{
														marginTop: 15,
													}}
												>
													<Text
														style={{
															color: primary,
															fontSize: 16,
															fontFamily:
																defaultStyles.regularFont,
															textAlign: "center",
														}}
													>
														Update account
													</Text>
												</TouchableOpacity>
											</View>

											{/* Type */}
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													display: "flex",
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View
													style={{
														maxWidth: "75%",
													}}
												>
													<Text
														style={styles.subtitle}
													>
														Type:{" "}
														{paymentMethod.object ===
															"bank_account"
															? "Bank"
															: "Debit"}
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Type of payment method
														connected to your
														account.
													</Text>
												</View>
											</View>

											{/* // Payout schedule */}
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													display: "flex",
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View
													style={{
														maxWidth: "75%",
													}}
												>
													<Text
														style={styles.subtitle}
													>
														Schedule:{" "}
														{instantPayout
															? "Instant payout"
															: "2-5 business days"}
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Payout schedule for your
														account.
													</Text>
												</View>
											</View>

											{/* // Auto widthdraw / request payout */}
											<View
												style={{
													borderBottomColor: border,
													borderBottomWidth: 1,
													paddingBottom: 15,
													marginBottom: 15,
													display: "flex",
													flexDirection: isMd
														? "row"
														: "column",
													justifyContent:
														"space-between",
													alignItems: isMd
														? "center"
														: "flex-start",
												}}
											>
												<View
													style={{
														maxWidth: "75%",
													}}
												>
													<Text
														style={styles.subtitle}
													>
														Auto withdraw:{" "}
														{requestPayout
															? "Off"
															: "On"}
													</Text>
													<Text
														style={
															styles.description
														}
													>
														Your withdrawal schedule
														for your account. Set to
														auto-withdraw to
														automatically request
														your funds once they are
														available.
													</Text>
												</View>

												<View
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "flex-end",
													}}
												>
													{requestPayout ? (
														<>
															<TouchableOpacity
																onPress={() => {
																	fetch(
																		`${hostname}/api/payout-settings`,
																		{
																			method: "POST",
																			headers:
																			{
																				Authorization:
																					"Bearer " +
																					user.accessToken,
																				"Content-Type":
																					"application/json",
																			},
																			body: JSON.stringify(
																				{
																					mode: "daily",
																				}
																			),
																		}
																	)
																		.then(
																			(
																				response
																			) =>
																				response.json()
																		)
																		.then(
																			(
																				data
																			) => {
																				if (
																					data.success
																				) {
																					customAlert.success(
																						"[Payout Updated] You have successfully updated your payout settings."
																					);
																					getAccountData();
																				} else {
																					customAlert.error(
																						data.error
																					);
																				}
																			}
																		)
																		.catch(
																			(
																				error
																			) => {
																				console.error(
																					"Error:",
																					error
																				);
																				customAlert.error(
																					"[Error Updating] There was an error, please try again later."
																				);
																			}
																		);
																}}
															>
																<Text
																	style={{
																		color: primary,
																		fontSize: 16,
																		fontFamily:
																			defaultStyles.regularFont,
																		textAlign:
																			"center",
																	}}
																>
																	Turn on
																	auto-withdraw
																</Text>
															</TouchableOpacity>

															<TouchableOpacity
																onPress={() => {
																	fetch(
																		`${hostname}/api/payout`,
																		{
																			method: "POST",
																			headers:
																			{
																				Authorization:
																					"Bearer " +
																					user.accessToken,
																				"Content-Type":
																					"application/json",
																			},
																		}
																	)
																		.then(
																			(
																				response
																			) =>
																				response.json()
																		)
																		.then(
																			(
																				data
																			) => {
																				if (
																					data.success
																				) {
																					getAccountData();
																					customAlert.success(
																						"[Payout Request Received!] You have successfully requested a payout, please allow 3-5 business days for the funds to be deposited into your bank account refer back to this page to see the status of your payout."
																					);
																				} else {
																					customAlert.error(
																						data.error
																					);
																				}
																			}
																		)
																		.catch(
																			(
																				error
																			) => {
																				console.error(
																					"Error:",
																					error
																				);
																				customAlert.error(
																					"[Payout Error] There was an error requesting a payout, please try again later."
																				);
																			}
																		);
																}}
															>
																<Text
																	style={{
																		color: primary,
																		fontSize: 16,
																		fontFamily:
																			defaultStyles.regularFont,
																		textAlign:
																			"center",
																	}}
																>
																	Request
																	payout
																</Text>
															</TouchableOpacity>
														</>
													) : (
														// Turn off
														<TouchableOpacity
															onPress={() => {
																fetch(
																	`${hostname}/api/payout-settings`,
																	{
																		method: "POST",
																		headers:
																		{
																			Authorization:
																				"Bearer " +
																				user.accessToken,
																			"Content-Type":
																				"application/json",
																		},
																		body: JSON.stringify(
																			{
																				mode: "manual",
																			}
																		),
																	}
																)
																	.then(
																		(
																			response
																		) =>
																			response.json()
																	)
																	.then(
																		(
																			data
																		) => {
																			if (
																				data.success
																			) {
																				getAccountData();
																				customAlert.success(
																					"[Payout Updated] You have successfully updated your payout settings."
																				);
																			} else {
																				customAlert.error(
																					data.error
																				);
																			}
																		}
																	)
																	.catch(
																		(
																			error
																		) => {
																			console.error(
																				"Error:",
																				error
																			);
																			customAlert.error(
																				"[Payout Error] There was an error requesting a payout, please try again later."
																			);
																		}
																	);
															}}
														>
															<Text
																style={{
																	color: "#FF0000",
																	fontSize: 16,
																	fontFamily:
																		defaultStyles.regularFont,
																	textAlign:
																		"center",
																}}
															>
																Turn off
																auto-withdraw
															</Text>
														</TouchableOpacity>
													)}
												</View>
											</View>
										</>
									)}

									{/* Transactions */}
									{view === "transactions" && (
										<View
											style={{
												padding: 20,
												border: "1px solid" + border,
												borderRadius:
													defaultStyles.radius,
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Text
												style={{
													fontSize: 19,
													color: textVariant,
													fontFamily:
														defaultStyles.boldFont,
												}}
											>
												Transaction History
											</Text>
											{transactions.map(
												(transaction, index) => {
													if (
														transaction.type ==
														"charge"
													) {
														return (
															<View
																style={{
																	marginTop: 15,
																	flexDirection:
																		"column",
																}}
															>
																<View
																	style={{
																		flexDirection:
																			"row",
																		flexWrap:
																			"wrap",
																	}}
																>
																	<View
																		style={{
																			marginRight: 30,
																			width: 150,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Type:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			Payment
																			deposit
																		</Text>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																			width: 90,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Amount:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			{transaction.amount_refunded >
																				0
																				? `-$${(
																					transaction.amount_refunded /
																					100
																				).toFixed(
																					2
																				)}`
																				: `$${(
																					transaction.amount /
																					100
																				).toFixed(
																					2
																				)}`}
																		</Text>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																			width: 150,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Status:
																		</Text>

																		<View
																			style={{
																				backgroundColor:
																					transaction.status ===
																						"succeeded"
																						? green
																						: textVariant,
																				borderRadius: 60,
																				display:
																					"flex",
																				alignItems:
																					"center",
																				justifyContent:
																					"center",
																				width: 120,
																			}}
																		>
																			<Text
																				style={{
																					fontSize: 16,
																					color: "#fff",
																					fontFamily:
																						defaultStyles.boldFont,
																				}}
																			>
																				{
																					transaction.status
																				}
																			</Text>
																		</View>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Date:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			{new Date(
																				transaction.created *
																				1000
																			).toLocaleDateString()}{" "}
																			{new Date(
																				transaction.created *
																				1000
																			).toLocaleTimeString()}
																		</Text>
																	</View>
																</View>
															</View>
														);
													} else {
														return (
															<View
																style={{
																	marginTop: 15,
																	flexDirection:
																		"column",
																}}
															>
																<View
																	style={{
																		flexDirection:
																			"row",
																		flexWrap:
																			"wrap",
																	}}
																>
																	<View
																		style={{
																			marginRight: 30,
																			width: 150,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Type:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			Payout
																			withdraw
																		</Text>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																			width: 90,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Amount:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			$
																			{(
																				transaction.amount /
																				100
																			).toFixed(
																				2
																			)}
																		</Text>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																			width: 150,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Status:
																		</Text>

																		<View
																			style={{
																				backgroundColor:
																					transaction.status ===
																						"paid"
																						? green
																						: textVariant,
																				borderRadius: 60,
																				display:
																					"flex",
																				alignItems:
																					"center",
																				justifyContent:
																					"center",
																				width: 120,
																			}}
																		>
																			<Text
																				style={{
																					fontSize: 16,
																					color: "#fff",
																					fontFamily:
																						defaultStyles.boldFont,
																				}}
																			>
																				{
																					transaction.status
																				}
																			</Text>
																		</View>
																	</View>

																	<View
																		style={{
																			marginRight: 30,
																		}}
																	>
																		<Text
																			style={{
																				fontSize: 16,
																				color: textVariant,
																				fontFamily:
																					defaultStyles.regularFont,
																				marginRight: 5,
																			}}
																		>
																			Date:
																		</Text>

																		<Text
																			style={{
																				fontSize: 16,
																				color: text,
																				fontFamily:
																					defaultStyles.boldFont,
																			}}
																		>
																			{new Date(
																				transaction.arrival_date *
																				1000
																			).toLocaleDateString()}
																		</Text>
																	</View>
																</View>
															</View>
														);
													}
												}
											)}
										</View>
									)}
								</View>

								{/* Right side */}
								<View
									style={{
										display: "flex",
										flexDirection: !isLg ? "row" : "column",
										width: isLg ? 330 : "100%",
									}}
								>
									<View
										style={{
											border: "1px solid " + border,
											borderRadius: defaultStyles.radius,
											padding: 15,
											width: isLg ? 330 : "100%",
										}}
									>
										{/* Icon  */}
										<AntDesign
											name="creditcard"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											How do payouts work?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											Once you have connected a bank or
											debit card, you can request a payout
											or turn on the auto-withdraw
											feature. After completing a session
											and collecting payment which you can
											confirm from the transactions tab,
											you can expect your funds to be
											available for withdrawal within 2-5
											business days.
										</Text>
										{/* Divider  */}
										<View
											style={{
												width: "100%",
												height: 1,
												backgroundColor: border,
												marginTop: 15,
												marginBottom: 15,
											}}
										></View>

										{/* Icon  */}
										<AntDesign
											name="Safety"
											size={32}
											color={text}
											style={{ marginBottom: 10 }}
										/>

										{/* Title */}
										<Text style={styles.infoSubtitle}>
											Where is my information stored?
										</Text>
										{/* Description */}
										<Text style={styles.infoDescription}>
											We use Stripe to process all
											payments and store your information.
											Stripe is a secure and trusted
											payment processor that is PCI
											compliant and uses the latest
											encryption technology to protect
											your information.
										</Text>

										{/* Link to learn about super tutor program */}
										<A
											href="https://stripe.com"
											style={styles.infoLink}
										>
											Learn more
										</A>
									</View>
									{isMd && <View style={{ flex: 1 }}></View>}
								</View>
							</View>
						</View>
						{/* Last updated in bottom left corner */}
						{/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
					</View>
					{/* Footer  */}
					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			)}
		</SafeAreaView>
	);
};
