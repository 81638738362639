
export default function DebriefIcon({ size = 20 }) {

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1067_6)">
                <path d="M3.66667 15.5001C4.72521 15.5001 5.58333 14.642 5.58333 13.5834C5.58333 12.5249 4.72521 11.6667 3.66667 11.6667C2.60812 11.6667 1.75 12.5249 1.75 13.5834C1.75 14.642 2.60812 15.5001 3.66667 15.5001Z" fill="#010101" />
                <path d="M5.41667 16.75C4.33333 17.3333 3.08333 17.3333 2 16.75C0.833333 17.3333 0 18.5833 0 20H7.41667C7.41667 18.5833 6.58333 17.3333 5.41667 16.75Z" fill="#010101" />
                <path d="M17.9999 5.08325C16.9166 5.66659 15.6666 5.66659 14.5833 5.08325C13.4166 5.66659 12.5833 6.91659 12.5833 8.33325H19.9999C19.9999 6.91659 19.1666 5.66659 17.9999 5.08325Z" fill="#010101" />
                <path d="M16.3334 3.83333C17.392 3.83333 18.2501 2.97521 18.2501 1.91667C18.2501 0.858121 17.392 0 16.3334 0C15.2749 0 14.4167 0.858121 14.4167 1.91667C14.4167 2.97521 15.2749 3.83333 16.3334 3.83333Z" fill="#010101" />
                <path d="M2.49997 9.16675C2.5833 9.16675 2.5833 9.16675 2.66663 9.16675C3.0833 9.16675 3.41663 8.91675 3.49997 8.50008C4.16663 5.50008 6.8333 3.33341 9.99997 3.33341C10.5 3.33341 10.8333 3.00008 10.8333 2.50008C10.8333 2.00008 10.5 1.66675 9.99997 1.66675C6.0833 1.66675 2.74997 4.33341 1.91663 8.16675C1.74997 8.58341 2.0833 9.08341 2.49997 9.16675Z" fill="#010101" />
                <path d="M17.5001 10.8334C17.0834 10.7501 16.5834 11.0001 16.5001 11.5001C15.8334 14.5001 13.1667 16.6668 10.0001 16.6668C9.50008 16.6668 9.16675 17.0001 9.16675 17.5001C9.16675 18.0001 9.50008 18.3334 10.0001 18.3334C13.9167 18.3334 17.2501 15.6668 18.0834 11.8334C18.2501 11.4168 17.9167 10.9168 17.5001 10.8334Z" fill="#010101" />
            </g>
        </svg>
    );

}