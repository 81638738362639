import {
    Box,
    Card,
    Fade,
    Link,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { OnboardingStep } from '..'
import OnboardingProgressItem from './OnboardingProgressItem'
import AntDesign from '@expo/vector-icons/AntDesign'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useColorsContext } from '@utils/styles'
import TakePhotoIcon from '@icons/TakePhotoIcon'
import YourDetailsIcon from '@icons/YourDetailsIcon'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import BackgroundIcon from '@icons/BackgroundIcon'
import AboutMeIcon from '@icons/AboutMeIcon'
import { Image, useWindowDimensions } from 'react-native'

import {} from '@mui/material'
import SubjectIcon from '@icons/SubjectIcon'

interface OnboardingProgressProps {
    step: OnboardingStep
    setVisible: (visible: boolean) => void
}

const OnboardingProgress: React.FC<OnboardingProgressProps> = ({
    step,
    setVisible,
}) => {
    const { text, primaryLight, textInverse } = useColorsContext()
    const { height } = useWindowDimensions()
    const theme = useTheme()
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
    return (
        <Card
            sx={{
                height: height * 0.8,
                padding: 1,
                borderRadius: 4,
                flexDirection: 'column',
                display: 'flex',
                backgroundColor: primaryLight,
                minHeight: 600,
            }}
            elevation={0}
        >
            {/* <Image
                style={{
                    width: 120,
                    height: 65,
                    alignSelf: 'center',
                    resizeMode: 'contain',
                }}
                source={{ uri: tutredLogo }}
            /> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    mb: 2,
                }}
            >
                <img
                    src={require('@assets/images/logo.svg')}
                    alt="logo"
                    height="40"
                    width="auto"
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <OnboardingProgressItem
                        icon={<SubjectIcon size={16} />}
                        title="Subjects"
                        subtitle="Select what you want to teach"
                        selectedStep={step}
                        step="photo"
                        index={1}
                    />
                    <OnboardingProgressItem
                        icon={<BackgroundIcon size={16} />}
                        title="Background"
                        subtitle="Tell students your qualifications"
                        selectedStep={step}
                        step="subjects"
                        index={2}
                    />
                    <OnboardingProgressItem
                        icon={<Feather name="user" size={16} color={text} />}
                        title="Profile"
                        subtitle="Enter your bio, prices and more"
                        selectedStep={step}
                        step="availability"
                        index={3}
                    />
                    <OnboardingProgressItem
                        icon={<YourDetailsIcon size={16} />}
                        title="Your Details"
                        subtitle="Provide your address details"
                        selectedStep={step}
                        step="welcome"
                        index={4}
                    />
                    <OnboardingProgressItem
                        icon={<TakePhotoIcon size={16} />}
                        title="Profile photo"
                        subtitle="Upload a photo of yourself"
                        selectedStep={step}
                        step="details"
                        index={5}
                    />
                    <OnboardingProgressItem
                        icon={
                            <AntDesign name="calendar" size={16} color={text} />
                        }
                        title="Availability"
                        subtitle="Choose your schedule"
                        selectedStep={step}
                        step="background"
                        index={6}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    <img
                        src={require(
                            `@assets/images/onboarding/step-${step}.svg`
                        )}
                        style={{
                            maxHeight: 150,
                            animation: 'fadeIn 1s ease-in-out',
                            marginBottom: isXl ? 32 : 16,
                        }}
                        alt="progress"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            cursor: 'pointer',
                            mt: 1,
                        }}
                        onClick={() => setVisible(false)}
                    >
                        <MaterialIcons
                            name="arrow-back"
                            size={20}
                            color={text}
                            style={{ marginRight: 8 }}
                        />
                        <Typography variant="subtitle2" color={text}>
                            Back to Home
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default OnboardingProgress
