import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { useColorsContext, useStyles } from '@utils/styles'
import { exams as examData } from '@config/exams'

interface Props {
    exams: string[]
}

const ExamPrep: React.FC<Props> = ({ exams }) => {
    const defaultStyles = useStyles()
    const { primaryVariant, text } = useColorsContext()

    const existingExams = useMemo(() => {
        let existing = []
        if (exams?.length) {
            exams?.forEach((userExam) => {
                const exam = examData.find((exam) => userExam === exam.value)
                if (exam) {
                    existing.push(exam.label)
                }
            })
        }
        return existing
    }, [exams])

    if (!existingExams?.length) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    This tutor hasnt added any exams to their profile yet.
                </Typography>
            </Box>
        )
    }
    return (
        <Box sx={{ marginTop: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    rowGap: 8,
                    columnGap: 2,
                }}
            >
                <Typography variant="h5" sx={{ color: text }}>
                    Tests I can help with:{' '}
                </Typography>
                {existingExams.map((exam, index) => {
                    return (
                        <View
                            key={exam}
                            style={{
                                paddingVertical: 6,
                                paddingHorizontal: 16,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: primaryVariant,
                                borderRadius: defaultStyles.buttonRadius,
                            }}
                        >
                            <Text
                                key={`tutor-subject-${index}`}
                                style={{
                                    color: '#fff',
                                    fontSize: 16,
                                    fontFamily: defaultStyles.regularFont,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {exam}
                            </Text>
                        </View>
                    )
                })}
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 20,
    },
})

export default ExamPrep
