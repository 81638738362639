//Package imports
import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Modal,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";

// // File imports
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";


const hostname = process.env.HOSTNAME

export const ContactModal = ({ contactModal, setContactModal }) => {
	// options
	const reasons = [
		{ label: "General Inquiry", value: "General Inquiry" },
		{ label: "Account Issue", value: "Account Issue" },
		{ label: "Billing Issue", value: "Billing Issue" },
		{ label: "Session Issue", value: "Session Issue" },
		{ label: "Other", value: "Other" },
	];

	// State
	const [options, setOptions] = useState(reasons[0].label);
	const [sessionId, setSessionId] = useState("");
	const [description, setDescription] = useState("");

	// Init alert
	const customAlert = useAlert();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	const [error, setError] = useState(null);

	const { user } = useUserContext();

	// handle submit
	const handleSubmit = () => {
		if (options === "Session Issue" && sessionId === "") {
			// customAlert.error('Please enter a session ID');
			setError("Please enter a session ID");
			return;
		} else if (description === "") {
			// customAlert.error('Please enter a description');
			setError("Please enter a description");
			return;
		} else {
			fetch(`${hostname}/api/contact`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: user.email,
					message: description,
					reason: options,
					sessionId: sessionId,
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.json();
				})
				.then((data) => {
					// Dismiss
					setContactModal(false);

					customAlert.success(
						"Message sent successfully! We will get back to you shortly."
					);
				})
				.catch((error) => {
					// customAlert.error('Something went wrong, please try again later');
					setError("Something went wrong, please try again later");
				});

			setContactModal(false);
		}
	};

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "40%"
				: isLg
					? "50%"
					: isMd
						? "60%"
						: isSm
							? "70%"
							: "90%",
			height: isMd ? "70%" : "95%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
			marginBottom: 5,
		},
		closeButton: {
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 16,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			padding: 30,
		},

		button: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.buttonRadius,
			padding: 15,
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		descriptionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 275,
		},
		sessionInput: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			marginTop: 15,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 50,
		},
		pickerInfoText: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
			marginBottom: 1,
		},
		picker: {
			marginTop: 10,
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingVertical: 10,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={contactModal}
				onRequestClose={() => {
					setContactModal(!contactModal);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<View style={{ flexDirection: "row" }}>
								{/* Close filters button */}
								<TouchableOpacity
									style={styles.closeButton}
									onPress={() => {
										setContactModal(!contactModal);
									}}
								>
									<CloseOutlined
										style={{ fontSize: 22, color: text }}
									></CloseOutlined>
								</TouchableOpacity>

								{/* Title */}
								<Text style={styles.modalTitle}>
									Contact Us
								</Text>
							</View>
							{!isMd ? null : (
								<TouchableOpacity
									style={{
										backgroundColor: primary,
										padding: 10,
										borderRadius:
											defaultStyles.buttonRadius,
									}}
									onPress={() => {
										handleSubmit();
										setOptions(reasons[0].label);
										setDescription("");
										setSessionId("");
									}}
								>
									<Text
										style={{
											color: "#fff",
											fontSize: 14,
											fontFamily: defaultStyles.boldFont,
										}}
									>
										Submit Ticket
									</Text>
								</TouchableOpacity>
							)}
						</View>
						<View style={styles.mainContainer}>
							<Text style={styles.title}>
								Please provide as much information as possible
								to help us resolve your issue. We will get back
								to you as soon as possible.
							</Text>
							<Text
								style={{
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									color: text,
									marginVertical: 10,
								}}
							>
								Please allow up to 24 hours for a response.
							</Text>

							{/* Reason  */}
							<Picker
								style={styles.picker}
								selectedValue={options}
								onValueChange={(itemValue, itemIndex) =>
									setOptions(itemValue)
								}
							>
								{reasons.map((item, i) => (
									<Picker.Item
										key={`contact-picker-${i}`}
										label={reasons[i].label}
										value={reasons[i].value}
									/>
								))}
							</Picker>

							{options == "Session Issue" ? (
								<>
									<TextInput
										style={styles.sessionInput}
										placeholderTextColor="#999999"
										placeholder="5ARCOcv6DbMCHRUccl9W"
										onChange={(e) => {
											setSessionId(e.target.value);
										}}
									/>
									<Text
										style={{
											color: primary,
											fontFamily: defaultStyles.boldFont,
											fontSize: 12,
											padding: 5,
										}}
									>
										Session ID can be found by clicking on a
										session in your session dashboard
									</Text>
								</>
							) : null}

							<TextInput
								placeholderTextColor="#999999"
								style={styles.descriptionInput}
								placeholder="I am having an issue with...."
								multiline={true}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								maxLength={300}
							></TextInput>
							<Text style={[styles.details, { padding: 5 }]}>
								{description.length} / 300
							</Text>
						</View>

						{error && (
							<Text
								style={{
									color: "#FF0000",
									fontFamily: defaultStyles.boldFont,
									fontSize: 14,
									textAlign: "center",
									marginBottom: 30,
								}}
							>
								{error}
							</Text>
						)}

						{isMd ? null : (
							<TouchableOpacity
								style={{
									backgroundColor: primary,
									padding: 10,
									borderRadius: defaultStyles.radius,
									alignContent: "center",
									justifyContent: "center",
									alignItems: "center",
								}}
								onPress={() => {
									handleSubmit();
									setOptions(reasons[0].label);
									setDescription("");
									setSessionId("");
								}}
							>
								<Text
									style={{
										color: "#fff",
										fontSize: 14,
										fontFamily: defaultStyles.boldFont,
									}}
								>
									Submit Ticket
								</Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</Modal>
		</View>
	);
};
