import React from 'react';
import { useColorsContext } from '@utils/styles';

const ProfileIcon = ({ size = 24 }) => {
    const { text, primaryLight } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 0.317184C10.0421 0.317184 0.317139 10.0437 0.317139 22C0.317139 33.9562 10.0421 43.6828 22 43.6828C33.9578 43.6828 43.6828 33.9562 43.6828 22C43.6828 10.0437 33.9562 0.317184 22 0.317184ZM22 9.35C25.9281 9.35 29.1109 12.8422 29.1109 17.1531C29.1109 21.4609 25.9281 24.9547 22 24.9547C18.0734 24.9547 14.8906 21.4609 14.8906 17.1531C14.8906 12.8422 18.0734 9.35 22 9.35ZM22 40.1297C16.564 40.1297 11.6906 37.7109 8.36401 33.9078C10.0843 29.9687 12.4812 28.0234 15.6796 28.0234H28.3265C31.5265 28.0234 33.9203 29.9656 35.6421 33.9031C32.314 37.7094 27.439 40.1297 22 40.1297Z" fill={text} />
        </svg>

    );
}

export default ProfileIcon;
