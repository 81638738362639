import { useColorsContext } from '@utils/styles';
import React from 'react';


const SessionFeeIcon = ({ size }) => {

    const { text } = useColorsContext()
    return (
        <svg width={size} height={size * (30 / 19)} viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0V18.75H30V0H0ZM27.5 16.25H9.375V13.125H6.875V16.25H2.5V2.5H6.875V5.625H9.375V2.5H27.5V16.25Z" fill={text} />
            <path d="M9.375 7.5H6.875V11.25H9.375V7.5Z" fill={text} />
            <path d="M19.3125 8.56939C18.5481 8.22064 18.0794 7.98064 17.9075 7.84876C17.735 7.71751 17.65 7.56751 17.65 7.40126C17.65 7.24626 17.7169 7.11626 17.8531 7.01188C17.9888 6.90751 18.205 6.85501 18.5044 6.85501C19.0819 6.85501 19.7675 7.03751 20.5619 7.40126L21.2656 5.62813C20.5556 5.31251 19.8569 5.12251 19.1688 5.05189V3.76001H17.3131V5.16751C16.8919 5.27688 16.5269 5.44251 16.2256 5.67501C15.6556 6.11251 15.3706 6.72314 15.3706 7.50626C15.3706 7.92501 15.4369 8.28751 15.57 8.59314C15.7044 8.89939 15.9094 9.17064 16.1863 9.40689C16.4631 9.64376 16.88 9.88751 17.4344 10.1394C18.0463 10.4144 18.4225 10.5963 18.5644 10.6856C18.7056 10.7756 18.8088 10.8631 18.8725 10.95C18.9363 11.0375 18.9688 11.1394 18.9688 11.2556C18.9688 11.4413 18.8894 11.5931 18.7306 11.7113C18.5713 11.8294 18.3219 11.8888 17.9806 11.8888C17.585 11.8888 17.1513 11.825 16.6788 11.6994C16.2056 11.5738 15.7506 11.3981 15.3119 11.1731V13.2194C15.7275 13.4169 16.1263 13.5556 16.51 13.635C16.7413 13.6825 17.0163 13.7113 17.3131 13.73V14.9894H19.1688V13.6C19.3569 13.5494 19.5394 13.4913 19.7075 13.415C20.2113 13.1888 20.5969 12.87 20.8644 12.4619C21.1325 12.0531 21.2663 11.585 21.2663 11.0581C21.2663 10.4844 21.1231 10.0081 20.8375 9.63064C20.5525 9.25251 20.0444 8.89876 19.3125 8.56939Z" fill={text} />
        </svg>
    )
}

export default SessionFeeIcon;