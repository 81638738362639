import React, { useMemo } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { BookSessionForm } from '../..'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { examIcons, exams } from '@config/exams'
import { format } from 'date-fns'
import { useColorsContext, useStyles } from '@utils/styles'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import NoteIcon from '@icons/NoteIcon'
import ExamPrepIcon from '@icons/ExamPrepIcon'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
import SubjectChip from '@components/Chips/SubjectChip'
import { specialtyIcons, subjectIcons } from '@config/subjects'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import ExamChip from '@components/Chips/ExamChip'

interface Props {
    handleNext: () => void
    control: Control<BookSessionForm>
    tutor: any
    errors: FieldErrors<BookSessionForm>
    setView: (view: number) => void
    view: number
    selectedTime: Date
}

const AddDetails: React.FC<Props> = ({
    control,
    tutor,
    errors,
    handleNext,
    setView,
    view,
    selectedTime,
}) => {
    const { background, text, darkMode } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const examOptions = useMemo(() => {
        let existing = []
        if (tutor?.examTutor) {
            tutor?.examTutor.forEach((userExam) => {
                const exam = exams.find((exam) => userExam === exam.value)
                if (exam) {
                    existing.push(exam)
                }
            })
        }
        return existing
    }, [tutor?.examTutor])

    return (
        <>
            <DialogContent
                sx={{
                    minWidth: { xs: '100%', md: 600 },

                    display: view === 1 ? 'block' : 'none',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{ textAlign: 'center', marginBottom: 2 }}
                >
                    {format(selectedTime, 'PPPPp')}
                </Typography>

                <Box>
                    <Box sx={styles.inputContainer}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <MaterialCommunityIcons
                                name="book-outline"
                                size={20}
                                color={text}
                            />
                            <Typography
                                variant="subtitle1"
                                sx={{ marginLeft: 1 }}
                            >
                                Subjects
                            </Typography>
                        </Box>
                        <Controller
                            name="subjects"
                            control={control}
                            rules={{
                                required: 'Please choose at least one subject',
                            }}
                            defaultValue={[]}
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    multiple
                                    // value={Object.keys(user?.languages).map(
                                    //     (key) => key
                                    // )}
                                    // disablePortal={true}
                                    options={Object.keys(tutor?.subjects).map(
                                        (key) => key
                                    )}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <SubjectChip
                                                    key={key}
                                                    size={'medium'}
                                                    item={option}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                key={option}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    p: 1,
                                                }}
                                                {...props}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 28,
                                                        height: 28,
                                                        backgroundColor:
                                                            darkMode
                                                                ? theme.palette
                                                                      .grey[400]
                                                                : theme.palette
                                                                      .grey[800],
                                                        WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                                        mask: `url(${subjectIcons[option]}) no-repeat center `,
                                                        maskSize: 'contain',
                                                        WebkitMaskSize:
                                                            'contain',
                                                        mr: 2,
                                                    }}
                                                />
                                                <Typography>
                                                    {option}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            hiddenLabel
                                            inputRef={ref}
                                            {...params}
                                            error={!!errors?.subjects}
                                            placeholder="Select Subjects"
                                            helperText="Required*"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={styles.inputContainer}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <SpecialtyIcon size={18} />
                            <Typography
                                variant="subtitle1"
                                sx={{ marginLeft: 1 }}
                            >
                                Specialties
                            </Typography>
                        </Box>

                        <Controller
                            name="specialties"
                            control={control}
                            defaultValue={[]}
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    multiple
                                    // value={Object.keys(user?.languages).map(
                                    //     (key) => key
                                    // )}
                                    // disablePortal={true}
                                    options={tutor?.skills}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <SpecialtyChip
                                                    size="medium"
                                                    key={key}
                                                    item={option}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                key={option}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    p: 1,
                                                }}
                                                {...props}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 28,
                                                        height: 28,
                                                        backgroundColor:
                                                            darkMode
                                                                ? theme.palette
                                                                      .grey[400]
                                                                : theme.palette
                                                                      .grey[800],
                                                        WebkitMask: `url(${specialtyIcons[option]}) no-repeat center `,
                                                        mask: `url(${specialtyIcons[option]}) no-repeat center `,
                                                        maskSize: 'contain',
                                                        WebkitMaskSize:
                                                            'contain',
                                                        mr: 2,
                                                    }}
                                                />
                                                <Typography>
                                                    {option}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            {...params}
                                            error={!!errors?.specialties}
                                            placeholder="Select Specialties"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={styles.inputContainer}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ExamPrepIcon />
                            <Typography
                                variant="subtitle1"
                                sx={{ marginLeft: 1 }}
                            >
                                Exam Prep
                            </Typography>
                        </Box>
                        <Controller
                            name="exams"
                            control={control}
                            defaultValue={[]}
                            // rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => (
                                <Autocomplete
                                    multiple
                                    // value={Object.keys(user?.languages).map(
                                    //     (key) => key
                                    // )}
                                    // disablePortal={true}
                                    options={tutor?.examTutor}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                            const { key, ...tagProps } =
                                                getTagProps({
                                                    index,
                                                })
                                            return (
                                                <ExamChip
                                                    key={key}
                                                    item={option}
                                                    {...tagProps}
                                                />
                                            )
                                        })
                                    }
                                    sx={{
                                        '& .Mui-Autocomplete-root': {
                                            zIndex: 9999,
                                        },
                                    }}
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value)
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                key={option}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    p: 1,
                                                }}
                                                {...props}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 28,
                                                        height: 28,
                                                        backgroundColor:
                                                            darkMode
                                                                ? theme.palette
                                                                      .grey[400]
                                                                : theme.palette
                                                                      .grey[800],
                                                        WebkitMask: `url(${examIcons[option]}) no-repeat center `,
                                                        mask: `url(${examIcons[option]}) no-repeat center `,
                                                        maskSize: 'contain',
                                                        WebkitMaskSize:
                                                            'contain',
                                                        mr: 2,
                                                    }}
                                                />
                                                <Typography>
                                                    {option}
                                                </Typography>
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={ref}
                                            {...params}
                                            error={!!errors?.exams}
                                            placeholder="Exam Prep"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={styles.inputContainer}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <NoteIcon />
                            <Typography
                                variant="subtitle1"
                                sx={{ marginLeft: 1 }}
                            >
                                Note
                            </Typography>
                        </Box>
                        <Controller
                            defaultValue=""
                            name="note"
                            control={control}
                            rules={{ maxLength: 300 }}
                            render={({ field: { ref, ...field } }) => (
                                <TextField
                                    inputRef={ref}
                                    sx={{
                                        '& .MuiInputBase-multiline': {
                                            backgroundColor: text,
                                        },
                                        ...styles.input,
                                    }}
                                    minRows={3}
                                    {...field}
                                    multiline
                                    inputProps={{ maxLength: 300 }}
                                    error={!!errors?.note}
                                    placeholder="I need help with..."
                                    helperText={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {field?.value?.length}
                                                /300
                                            </Typography>
                                        </Box>
                                    }
                                />
                            )}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: view === 1 ? 'flex' : 'none' }}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => setView(0)}
                >
                    Back
                </Button>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 1,
    },
    inputContainer: {
        marginBottom: 2,
    },
    input: {
        width: '100%',
    },
    button: {
        width: 100,
        height: 40,
        marginLeft: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
export default AddDetails
