import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Slider,
    Typography,
    useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'
import AntDesign from '@expo/vector-icons/AntDesign'
import * as ImagePicker from 'expo-image-picker'
import Cropper from 'react-easy-crop'
import getCroppedImg from '@helpers/getCroppedImage'
import useUpdateProfilePicture from '@screens/loggedIn/Profile/useUpdateProfilePicture'
import { useAlert } from 'react-alert'
import Entypo from '@expo/vector-icons/Entypo'

interface UploadPhotoDialogProps {
    visible: boolean
    setVisible: (visible: boolean) => void
}

const UploadPhotoDialog: React.FC<UploadPhotoDialogProps> = ({
    visible,
    setVisible,
}) => {
    const { width, height } = useWindowDimensions()
    const { extendedUser } = useUserContext()
    const { primary, primaryLight } = useColorsContext()
    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const updateProfilePicture = useUpdateProfilePicture()
    const [dragOver, setDragOver] = useState(false)
    const alert = useAlert()
    const defaultStyles = useStyles()
    const theme = useTheme()

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    useEffect(() => {
        if (!visible) {
            setImage(null)
            setCrop({ x: 0, y: 0 })
            setZoom(1)
            setCroppedAreaPixels(null)
            setDragOver(false)
        }
    }, [visible])

    return (
        <Dialog
            open={visible}
            // scroll="paper"
            onClose={() => {
                setVisible(false)
            }}
            maxWidth="md"
            sx={{ padding: 0, overflow: 'hidden' }}
        >
            <DialogTitle
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    fontFamily={defaultStyles.titleFont}
                    textAlign="center"
                    sx={{ mb: 1 }}
                >
                    {image ? 'Crop Image' : 'Upload Image'}
                </Typography>
                <Typography variant="body1" textAlign="center">
                    {image
                        ? 'Crop the image to focus on your face'
                        : 'Upload an image that aligns with our guidelines.'}
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: width * 0.6,
                    padding: 1,
                    height: height * 0.6,
                    overflow: 'hidden',
                }}
            >
                {image ? (
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: width * 0.3,
                                borderRadius: '16px',
                            }}
                        >
                            <Cropper
                                image={image}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                showGrid={false}
                                style={{
                                    cropAreaStyle: {
                                        borderRadius: 90,
                                    },
                                }}
                                cropSize={{ width: 250, height: 250 }}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {' '}
                            <Typography
                                variant="body2"
                                fontFamily="Poppins_500Medium"
                            >
                                Out
                            </Typography>
                            <Box
                                sx={{
                                    flex: 1,
                                    pl: 1,
                                    pr: 1,
                                    display: 'flex',
                                }}
                            >
                                <Slider
                                    sx={{ mt: 0 }}
                                    defaultValue={1}
                                    step={0.1}
                                    min={0.25}
                                    onChange={(e) => {
                                        setZoom(e.target.value)
                                    }}
                                    max={2.5}
                                    aria-label="Default"
                                    valueLabelDisplay="auto"
                                />
                            </Box>
                            <Typography
                                variant="body2"
                                fontFamily="Poppins_500Medium"
                            >
                                In
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            border: `2px dashed ${dragOver ? primary : theme.palette.grey[500]}`,
                            borderRadius: '16px',
                            p: 2,
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                            setDragOver(true)
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault()
                            setDragOver(false)
                        }}
                        onDrop={(e) => {
                            e.preventDefault()
                            setDragOver(false)
                            const fileType = e.dataTransfer.files[0].type
                            if (fileType.includes('image')) {
                                const resp = window.URL.createObjectURL(
                                    e.dataTransfer.files[0]
                                )
                                setImage(resp)
                            } else {
                                alert.error(
                                    '[Error] Please only upload image files.'
                                )
                            }
                        }}
                    >
                        <Entypo
                            name="upload-to-cloud"
                            size={64}
                            color="#3a8efe"
                        />

                        <Typography variant="h6">Drag and drop here</Typography>
                        <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                            - or -{' '}
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={async () => {
                                let result =
                                    await ImagePicker.launchImageLibraryAsync({
                                        mediaTypes:
                                            ImagePicker.MediaTypeOptions.Images,
                                        quality: 1,
                                    })
                                if (!result.canceled) {
                                    setImage(result.assets[0].uri)
                                }
                            }}
                        >
                            Browse
                        </Button>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        if (image) {
                            setImage(null)
                        } else {
                            setVisible(false)
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={async () => {
                        if (image) {
                            const croppedImage = await getCroppedImg(
                                image,
                                croppedAreaPixels,
                                rotation
                            )

                            await updateProfilePicture(croppedImage)
                            setVisible(false)
                        } else {
                            let result =
                                await ImagePicker.launchImageLibraryAsync({
                                    mediaTypes:
                                        ImagePicker.MediaTypeOptions.Images,
                                    quality: 1,
                                })
                            if (!result.canceled) {
                                setImage(result.assets[0].uri)
                            }
                        }
                    }}
                >
                    {image ? 'Save' : 'Upload'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadPhotoDialog
