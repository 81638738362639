// Home Screen for logged in tutor
import { AntDesign, Feather } from '@expo/vector-icons';
import { Link } from '@react-navigation/native';
import NumericTextInput from '@wwdrew/react-native-numeric-textinput';
import { getAuth } from 'firebase/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Picker, Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { Header as AuthHeader } from '../../../components/AuthHeader';
import Footer from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { LoadingPage } from '../../../components/LoadingPage';
import { useFirebaseContext } from '../../../contexts/firebaseContext';
import { useUserContext } from '../../../contexts/userContext';
import { useColorsContext, useStyles } from '../../../utils/styles';
import Animated, { FadeInDown, FadeInUp, FadeOut, FadeOutDown, FadeOutUp } from 'react-native-reanimated'
// import { CustomTimeInput } from '../../../components/CustomTimeInput';

// Firebase
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useSessionContext } from '../../../contexts/sessionContext';
import { SESSION_MINIMUM_FEE_MAX, SESSION_MINIMUM_FEE_MIN, SESSION_RATE_MAX, SESSION_RATE_MIN, SESSION_RECORDING_FEE_MAX } from '@config/config';
import { convertAvailabilityTime } from '@helpers/convertAvailabilityTime';
import TutredSwitch from '@components/TutredSwitch';
;

const times = [
    '12:00 AM', '12:30 AM',
    '1:00 AM', '1:30 AM',
    '2:00 AM', '2:30 AM',
    '3:00 AM', '3:30 AM',
    '4:00 AM', '4:30 AM',
    '5:00 AM', '5:30 AM',
    '6:00 AM', '6:30 AM',
    '7:00 AM', '7:30 AM',
    '8:00 AM', '8:30 AM',
    '9:00 AM', '9:30 AM',
    '10:00 AM', '10:30 AM',
    '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM',
    '1:00 PM', '1:30 PM',
    '2:00 PM', '2:30 PM',
    '3:00 PM', '3:30 PM',
    '4:00 PM', '4:30 PM',
    '5:00 PM', '5:30 PM',
    '6:00 PM', '6:30 PM',
    '7:00 PM', '7:30 PM',
    '8:00 PM', '8:30 PM',
    '9:00 PM', '9:30 PM',
    '10:00 PM', '10:30 PM',
    '11:00 PM', '11:30 PM'
]

const advanceNoticeOptions = [
    { label: '5 mins', value: '5 mins' },
    { label: '10 mins', value: '10 mins' },
    { label: '25 mins', value: '25 mins' },
    { label: '1 hr', value: '1 hr' },
    { label: '2 hrs', value: '2 hrs' },
];

const bufferPeriodOptions = [
    { label: '15 mins', value: '15 mins' },
    { label: '30 mins', value: '30 mins' },
    { label: '1 hr', value: '1 hr' },
    { label: '2 hrs', value: '2 hrs' },
];

const timeToMinutes = (timeString) => {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':');
    let convertedHours = parseInt(hours);
    const convertedMinutes = parseInt(minutes);

    if (period === 'PM' && convertedHours !== 12) {
        convertedHours += 12;
    }

    return convertedHours * 60 + convertedMinutes;
}


const hostname = process.env.HOSTNAME


const CustomTimeInput = ({
    time,
    setTime
}) => {
    const [fullTime, setFullTime] = React.useState(time);

    // Colors and styles
    const { darkMode, primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, border, yellow, green } = useColorsContext();
    const defaultStyles = useStyles();

    // Responsive Breakpoints
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMd = useMediaQuery({ query: '(min-width: 768px)' });
    const isSm = useMediaQuery({ query: '(min-width: 640px)' });


    useEffect(() => {
        if (fullTime) {
            saveTime();
        }
    }, [fullTime])

    const saveTime = () => {
        setTime(fullTime);
    }

    return (
        <select
            style={{
                flex: 1,
                colorScheme: darkMode ? 'dark' : 'light',
                borderRadius: defaultStyles.radius,
                fontFamily: defaultStyles.boldFont,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 15,
                paddingRight: 15,
                color: text,
                backgroundColor: backgroundVariant,
                border: '1px solid ' + border,
                fontSize: 16
            }}
            onChange={(e) => {
                setFullTime(e.target.value)
            }}
            value={fullTime}
        >
            <option value='' selected disabled>Select a time</option>
            {
                times.map((timeO, index) => {
                    return (
                        <option value={timeO}>{timeO}</option>
                    )
                })
            }

        </select>
    )
}

// Account Screen
export const SessionSettings = ({ navigation }) => {

    const { user, minlength, minfee, maxlength, rate, autoAccept, availability, alwaysAvailable, unavailable, bufferPeriod, advanceNotice, extendedUser } = useUserContext();
    const customAlert = useAlert();
    const { db } = useFirebaseContext();
    const AnimatedText = Animated.createAnimatedComponent(Text);




    // User Context
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [localRate, setLocalRate] = useState(rate);
    const [localMinimumLength, setLocalMinimumLength] = useState(minlength);
    const [localMaximumLength, setLocalMaximumLength] = useState(maxlength);
    const [localMinimumFee, setLocalMinimumFee] = useState(minfee);
    const [localBufferPeriod, setBufferPeriod] = useState(bufferPeriod);
    const [localAdvanceNotice, setAdvanceNotice] = useState(advanceNotice);
    const [allowSessionRecording, setAllowSessionRecording] = useState(extendedUser.allowSessionRecording || false);
    const [sessionRecordingFee, setSessionRecordingFee] = useState(extendedUser.sessionRecordingFee || 0);
    const [localAvailabilitySetting, setLocalAvailabilitySetting] = useState(alwaysAvailable ? 'always' : unavailable ? 'not' : 'custom');

    const [showMonday, setShowMonday] = useState(false);
    const [showTuesday, setShowTuesday] = useState(false);
    const [showWednesday, setShowWednesday] = useState(false);
    const [showThursday, setShowThursday] = useState(false);
    const [showFriday, setShowFriday] = useState(false);
    const [showSaturday, setShowSaturday] = useState(false);
    const [showSunday, setShowSunday] = useState(false);

    const [monday, setMonday] = useState({
        start: null,
        end: null,
    });

    const [tuesday, setTuesday] = useState({
        start: null,
        end: null,
    });
    const [wednesday, setWednesday] = useState({
        start: null,
        end: null,
    });
    const [thursday, setThursday] = useState({
        start: null,
        end: null,
    });
    const [friday, setFriday] = useState({
        start: null,
        end: null,
    });

    const [saturday, setSaturday] = useState({
        start: null,
        end: null,
    });
    const [sunday, setSunday] = useState({
        start: null,
        end: null,
    });


    // Colors and styles
    const { primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, border, yellow, green, toggleDarkMode, darkMode, } = useColorsContext();
    const defaultStyles = useStyles();

    // Responsive Breakpoints
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMd = useMediaQuery({ query: '(min-width: 768px)' });
    const isSm = useMediaQuery({ query: '(min-width: 640px)' });

    // Create stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            display: "flex",
            flexDirection: Platform.OS === "web" ? "row" : null,
            paddingRight: 0, // Padding right is for the cover photo to stretch
            width: "100%",
            // maxWidth: Platform.OS === "web" ? 1350 : null,
            margin: Platform.OS === "web" ? "auto" : null,
        },
        mainSection: {
            flex: 1,
            padding: isSm ? 45 : 15,
            paddingTop: 30
        },
        title: {
            fontSize: 36,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
        subtitle: {
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        description: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant
        },
        infoTitle: {
            textAlign: 'left',
            fontSize: 22,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        infoSubtitle: {
            fontSize: 19,
            fontFamily: defaultStyles.boldFont,
            color: textVariant
        },
        infoDescription: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant,
            marginTop: 5,
            display: "flex",
            flex: 1,
        },
        infoLink: {
            fontSize: 14,
            fontFamily: defaultStyles.regularFont,
            color: primary,
            marginTop: 10
        },
        picker: {
            height: 50,
            // width: 100, 
            color: text,
            borderRadius: defaultStyles.radius,
            fontFamily: defaultStyles.boldFont,
            paddingLeft: 15,
            paddingRight: 15,
            color: text,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
        },
        details: {
            fontSize: 16,
            color: text,
            fontFamily: defaultStyles.regularFont,
        },
    });


    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);


    console.log('availability', availability);
    useEffect(() => {
        if (availability) {
            if (availability.monday && availability.monday.start && availability.monday.end) {
                setMonday({
                    start: convertAvailabilityTime(availability.monday.start, 'in'),
                    end: convertAvailabilityTime(availability.monday.end, 'in')
                })
                setShowMonday(true);
            }
            if (availability.tuesday && availability.tuesday.start && availability.tuesday.end) {
                setTuesday({
                    start: convertAvailabilityTime(availability.tuesday.start, 'in'),
                    end: convertAvailabilityTime(availability.tuesday.end, 'in')
                });
                setShowTuesday(true);
            }
            if (availability.wednesday && availability.wednesday.start && availability.wednesday.end) {
                setWednesday({
                    start: convertAvailabilityTime(availability.wednesday.start, 'in'),
                    end: convertAvailabilityTime(availability.wednesday.end, 'in')
                });
                setShowWednesday(true);
            }
            if (availability.thursday && availability.thursday.start && availability.thursday.end) {
                setThursday({
                    start: convertAvailabilityTime(availability.thursday.start, 'in'),
                    end: convertAvailabilityTime(availability.thursday.end, 'in')
                });
                setShowThursday(true);
            }
            if (availability.friday && availability.friday.start && availability.friday.end) {
                setFriday({
                    start: convertAvailabilityTime(availability.friday.start, 'in'),
                    end: convertAvailabilityTime(availability.friday.end, 'in')
                });
                setShowFriday(true);
            }
            if (availability.saturday && availability.saturday.start && availability.saturday.end) {
                setSaturday({
                    start: convertAvailabilityTime(availability.saturday.start, 'in'),
                    end: convertAvailabilityTime(availability.saturday.end, 'in')
                });
                setShowSaturday(true);
            }
            if (availability.sunday && availability.sunday.start && availability.sunday.end) {
                setSunday({
                    start: convertAvailabilityTime(availability.sunday.start, 'in'),
                    end: convertAvailabilityTime(availability.sunday.end, 'in')
                });
                setShowSunday(true);
            }
        }
    }, [availability]);


    const updateSession = async () => {
        if (Object.keys(formErrors).filter((key) => formErrors[key]).length > 0) {
            customAlert.error("Please correct errors on the form before saving");
            return;
        }

        var availability = {
            monday: {
                start: null,
                end: null,
            },
            tuesday: {
                start: null,
                end: null,
            },
            wednesday: {
                start: null,
                end: null,
            },
            thursday: {
                start: null,
                end: null,
            },
            friday: {
                start: null,
                end: null,
            },
            saturday: {
                start: null,
                end: null,
            },
            sunday: {
                start: null,
                end: null,
            }
        };

        // Validate availability dates
        if (localAvailabilitySetting === 'custom') {
            if (showMonday) {
                if (!monday.start || !monday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Monday!');
                    return;
                }

                // // Check if start time is before end time
                // if (timeToMinutes(monday.start) >= timeToMinutes(monday.end)) {
                //     customAlert.error('[Edit Profile Error] Monday start time must be before end time!');
                //     return;
                // }

                availability.monday.start = convertAvailabilityTime(monday.start, 'out', 'Mon');
                availability.monday.end = convertAvailabilityTime(monday.end, 'out', 'Mon');
            }
            console.log("availability: ", availability.monday);

            if (showTuesday) {
                if (!tuesday.start || !tuesday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Tuesday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(tuesday.start) >= timeToMinutes(tuesday.end)) {
                    customAlert.error('[Edit Profile Error] Tuesday start time must be before end time!');
                    return;
                }

                availability.tuesday.start = convertAvailabilityTime(tuesday.start, 'out', 'Tue');
                availability.tuesday.end = convertAvailabilityTime(tuesday.end, 'out', 'Tue');
            }

            if (showWednesday) {
                if (!wednesday.start || !wednesday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Wednesday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(wednesday.start) >= timeToMinutes(wednesday.end)) {
                    customAlert.error('[Edit Profile Error] Wednesday start time must be before end time!');
                    return;
                }

                availability.wednesday.start = convertAvailabilityTime(wednesday.start, 'out', 'Wed');
                availability.wednesday.end = convertAvailabilityTime(wednesday.end, 'out', 'Wed');
            }

            if (showThursday) {
                if (!thursday.start || !thursday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Thursday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(thursday.start) >= timeToMinutes(thursday.end)) {
                    customAlert.error('[Edit Profile Error] Thursday start time must be before end time!');
                    return;
                }

                availability.thursday.start = convertAvailabilityTime(thursday.start, 'out', 'Thur');
                availability.thursday.end = convertAvailabilityTime(thursday.end, 'out', 'Thur');
            }

            if (showFriday) {
                if (!friday.start || !friday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Friday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(friday.start) >= timeToMinutes(friday.end)) {
                    customAlert.error('[Edit Profile Error] Friday start time must be before end time!');
                    return;
                }

                availability.friday.start = convertAvailabilityTime(friday.start, 'out', 'Fri');
                availability.friday.end = convertAvailabilityTime(friday.end, 'out', 'Fri');
            }


            if (showSaturday) {

                console.log('saturday', saturday)

                if (!saturday.start || !saturday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Saturday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(saturday.start) >= timeToMinutes(saturday.end)) {
                    customAlert.error('[Edit Profile Error] Saturday start time must be before end time!');
                    return;
                }

                availability.saturday.start = convertAvailabilityTime(saturday.start, 'out', 'Sat');
                availability.saturday.end = convertAvailabilityTime(saturday.end, 'out', 'Sat');

            }


            if (showSunday) {
                if (!sunday.start || !sunday.end) {
                    customAlert.error('[Edit Profile Error] Please enter a start and end time for Sunday!');
                    return;
                }

                // Check if start time is before end time
                if (timeToMinutes(sunday.start) >= timeToMinutes(sunday.end)) {
                    customAlert.error('[Edit Profile Error] Sunday start time must be before end time!');
                    return;
                }

                availability.sunday.start = convertAvailabilityTime(sunday.start, 'out', 'Sun');
                availability.sunday.end = convertAvailabilityTime(sunday.end, 'out', 'Sun');
            }
        }

        const userRef = collection(db, 'users');
        const q = query(userRef, where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        console.log('allowSessionRecording', allowSessionRecording);
        querySnapshot.forEach((doc) => {
            updateDoc(doc.ref, {
                rate: localRate,
                minfee: localMinimumFee,
                maxlength: localMaximumLength,
                bufferPeriod: localBufferPeriod,
                advanceNotice: localAdvanceNotice,
                minlength: localMinimumLength,
                availability: availability,
                alwaysAvailable: localAvailabilitySetting === 'always' ? true : false,
                unavailable: localAvailabilitySetting === 'not' ? true : false,
                allowSessionRecording: allowSessionRecording,
                sessionRecordingFee: allowSessionRecording ? sessionRecordingFee : 0,
            });
        });
        customAlert.success('[Profile Updated] The changes to your profile have been saved!');
    };


    const [formErrors, setFormErrors] = useState({
        sessionRecordingFee: null,
        minimumFee: null,
        sessionRate: null
    });

    useEffect(() => {
        setFormErrors({
            sessionRecordingFee: sessionRecordingFee > SESSION_RECORDING_FEE_MAX ? `Minimum fee must not be greater than $${SESSION_RECORDING_FEE_MAX.toFixed(2)}` : null,
            minimumFee: localMinimumFee > SESSION_MINIMUM_FEE_MAX ? `Minimum fee must not be greater than $${SESSION_MINIMUM_FEE_MAX.toFixed(2)}` : localMinimumFee < SESSION_MINIMUM_FEE_MIN ? `Minimum fee must not be less than $${SESSION_MINIMUM_FEE_MIN.toFixed(2)}` : null,
            sessionRate: localRate > SESSION_RATE_MAX ? `Rate must not be greater than $${SESSION_RATE_MAX.toFixed(2)}` : localRate < SESSION_RATE_MIN ? `Rate must not be less than $${SESSION_RATE_MIN.toFixed(2)}` : null
        })
    }, [sessionRecordingFee, localMinimumFee, localRate]);

    const formError = useCallback((key) => {
        if (formErrors[key]) {
            return (
                <AnimatedText entering={FadeInDown} exiting={FadeOutDown} style={{
                    color: red,
                    marginTop: 4,
                    textAlign: 'right'
                }}>
                    {formErrors[key]}
                </AnimatedText>
            )
        } else {
            return null
        }
    }, [formErrors])

    const sessionRateError = useMemo(() => {
        if (formErrors?.sessionRate) {
            return formError('sessionRate')
        } else {
            return null
        }
    }, [formErrors?.sessionRate])

    const sessionRecordingFeeError = useMemo(() => {
        if (formErrors?.sessionRecordingFee) {
            return formError('sessionRecordingFee')
        } else {
            return null
        }
    }, [formErrors?.sessionRecordingFee])

    const minimumFeeError = useMemo(() => {
        if (formErrors?.minimumFee) {
            return formError('minimumFee')
        } else {
            return null
        }
    }, [formErrors?.minimumFee])




    return (
        <SafeAreaView style={styles.container}>
            {/* Main Content */}
            {
                loading ? <LoadingPage /> : (
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ backgroundColor: background }}
                    >
                        <View style={styles.maxWidth}>
                            <View style={[styles.mainSection]}>
                                {/* Title */}
                                <Text style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.regularFont,
                                    marginBottom: 5
                                }}>
                                    <Link
                                        // onMouseEnter={handlePressIn}
                                        // onMouseLeave={handlePressOut}
                                        to={'/settings'}
                                    >
                                        <Text>Settings</Text>
                                    </Link> <Text style={{ fontFamily: defaultStyles.boldFont, color: primary }}><Feather name="chevron-right" size={15} color={text} /> Sessions</Text>
                                </Text>

                                <Text style={styles.title}>
                                    Session settings
                                </Text>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text style={{ color: text, fontSize: 16, fontFamily: defaultStyles.regularFont }}>
                                        Configure your rate, availability, and more.
                                    </Text>
                                </View>


                                <View style={{
                                    display: "flex",
                                    flexDirection: isLg ? "row" : "column",
                                    gap: 30
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                        paddingRight: 30,
                                        marginTop: 30
                                    }}>


                                        {/* Put main Content here  */}
                                        {/* Session rate input */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, }}>
                                            <View style={{ display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start' }}>
                                                <View style={{
                                                    maxWidth: "70%"
                                                }}>
                                                    <Text style={styles.subtitle}>Session rate</Text>
                                                    <Text style={styles.description}>
                                                        Your hourly rate for sessions. Students will be charged this rate multiplied by the session length.
                                                    </Text>
                                                </View>


                                                <NumericTextInput
                                                    style={[styles.picker]}
                                                    type='currency'
                                                    locale='en-Us'
                                                    currency='CAD'
                                                    decimalPlaces={2}
                                                    value={localRate}
                                                    onUpdate={(value) => {
                                                        setLocalRate(value);
                                                    }}
                                                    placeholder='$0.00'
                                                />

                                            </View>
                                            {sessionRateError}

                                        </View>


                                        {/* Auto accept */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start' }}>
                                            <View style={{
                                                maxWidth: "70%"
                                            }}>
                                                <Text style={styles.subtitle}>Auto-accept</Text>
                                                <Text style={styles.description}>
                                                    Automatically accept session requests from students.
                                                </Text>
                                            </View>
                                            <TutredSwitch

                                                onChange={() => {
                                                    // Update user in database with new instabook value
                                                    updateDoc(doc(db, 'users', user.uid,), {
                                                        autoAccept: !autoAccept
                                                    }).then(() => {
                                                    }
                                                    ).catch((error) => {
                                                        console.log(error);
                                                    });
                                                }}
                                                checked={autoAccept}
                                            />
                                        </View>

                                        {/* Minimum Fee input */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, paddingTop: 15, marginBottom: 15, }}>
                                            <View style={{ display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start' }}>
                                                <View style={{
                                                    maxWidth: "70%"
                                                }}>
                                                    <Text style={styles.subtitle}>Minimum fee</Text>
                                                    <Text style={styles.description}>
                                                        The minimum fee for sessions, regardless of the length.
                                                    </Text>
                                                </View>

                                                <NumericTextInput
                                                    style={[styles.picker]}
                                                    type='currency'
                                                    locale='en-Us'
                                                    currency='CAD'
                                                    decimalPlaces={2}
                                                    value={localMinimumFee}
                                                    onUpdate={(value) => {
                                                        setLocalMinimumFee(value);
                                                    }}
                                                    placeholder='$0.00'
                                                />
                                            </View>
                                            {minimumFeeError}

                                        </View>

                                        {/* BufferPeriod input */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start' }}>
                                            <View style={{
                                                maxWidth: "70%"
                                            }}>
                                                <Text style={styles.subtitle}>Buffer Period</Text>
                                                <Text style={styles.description}>
                                                    The buffer period
                                                </Text>
                                            </View>


                                            <Picker
                                                selectedValue={localBufferPeriod}
                                                style={styles.picker}
                                                onValueChange={(itemValue, itemIndex) => {
                                                    setBufferPeriod(itemValue);
                                                }}
                                            >
                                                <Picker.Item label="5 minutes" value={5} />
                                                <Picker.Item label="30 minutes" value={30} />
                                                <Picker.Item label="60 minutes" value={60} />
                                                {/* Add more options as needed */}
                                            </Picker>
                                        </View>

                                        {/* Advance input */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start' }}>
                                            <View style={{
                                                maxWidth: "70%"
                                            }}>
                                                <Text style={styles.subtitle}>Advance Notice Period</Text>
                                                <Text style={styles.description}>
                                                    The advance Notice time
                                                </Text>
                                            </View>

                                            <Picker
                                                selectedValue={localAdvanceNotice}
                                                style={styles.picker}
                                                onValueChange={(itemValue, itemIndex) => {
                                                    setAdvanceNotice(itemValue);
                                                }}
                                            >
                                                <Picker.Item label="5 minutes" value={5} />
                                                <Picker.Item label="30 minutes" value={30} />
                                                <Picker.Item label="60 minutes" value={60} />
                                                {/* Add more options as needed */}
                                            </Picker>
                                        </View>

                                        {/* Minimum and Maximum Length */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                                            <View style={{
                                                display: 'flex',
                                                flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'
                                            }}>
                                                <View style={{
                                                    maxWidth: "70%"
                                                }}>
                                                    <Text style={styles.subtitle}>Minimum and Maximum length</Text>
                                                    <Text style={styles.description}>
                                                        Set the minimum and maximum length for sessions.
                                                    </Text>
                                                </View>

                                                {/* Picker for Minimum Length */}
                                                <Picker
                                                    selectedValue={localMinimumLength}
                                                    style={styles.picker}
                                                    onValueChange={(itemValue, itemIndex) => {
                                                        setLocalMinimumLength(itemValue);
                                                    }}
                                                >
                                                    <Picker.Item label="30 minutes" value={30} />
                                                    <Picker.Item label="60 minutes" value={60} />
                                                    <Picker.Item label="90 minutes" value={90} />
                                                    {/* Add more options as needed */}
                                                </Picker>

                                                {/* Picker for Maximum Length */}
                                                <Picker
                                                    selectedValue={localMaximumLength}
                                                    style={styles.picker}
                                                    onValueChange={(itemValue, itemIndex) => {
                                                        setLocalMaximumLength(itemValue);
                                                    }}
                                                >
                                                    <Picker.Item label="60 minutes" value={60} />
                                                    <Picker.Item label="90 minutes" value={90} />
                                                    <Picker.Item label="120 minutes" value={120} />
                                                    {/* Add more options as needed */}
                                                </Picker>
                                            </View>
                                        </View>

                                        {/* Session Recording */}
                                        <View style={{ paddingBottom: 15, marginBottom: 15, borderBottomColor: border, borderBottomWidth: 1, }}>
                                            <View style={{
                                                display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start',

                                            }}>
                                                <View>
                                                    <Text style={styles.subtitle}>Session Recording</Text>
                                                    <Text style={styles.description}>
                                                        Allow your students to record their sessions with you.
                                                    </Text>
                                                </View>
                                                <TutredSwitch
                                                    onChange={() => {
                                                        setAllowSessionRecording(!allowSessionRecording);
                                                    }}
                                                    checked={allowSessionRecording}
                                                />
                                            </View>
                                            {allowSessionRecording && (
                                                <Animated.View entering={FadeInUp} exiting={FadeOutUp}>
                                                    {/* Session Recording Fee */}
                                                    <View style={{ display: 'flex', flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start', paddingTop: 15 }}>
                                                        <View style={{
                                                            maxWidth: "70%"
                                                        }}>
                                                            <Text style={styles.subtitle}>Session Recording Fee</Text>
                                                            <Text style={styles.description}>
                                                                Set the fee for recording a session.
                                                            </Text>
                                                        </View>

                                                        <NumericTextInput
                                                            style={[styles.picker]}
                                                            type='currency'
                                                            locale='en-Us'
                                                            currency='CAD'
                                                            decimalPlaces={2}
                                                            value={sessionRecordingFee}
                                                            onUpdate={(value) => {
                                                                setSessionRecordingFee(value);
                                                            }}
                                                            placeholder='$0.00'
                                                        />
                                                    </View>
                                                    {sessionRecordingFeeError}
                                                </Animated.View>
                                            )}
                                        </View>

                                        {/* Availability */}
                                        <View style={{ borderBottomColor: border, borderBottomWidth: 1, paddingBottom: 15, marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                                            <View style={{
                                                display: 'flex',
                                                flexDirection: isMd ? 'row' : 'column', justifyContent: 'space-between', alignItems: isMd ? 'center' : 'flex-start'
                                            }}>
                                                <View style={{
                                                    maxWidth: "70%"
                                                }}>
                                                    <Text style={styles.subtitle}>Availability</Text>
                                                    <Text style={styles.description}>
                                                        Set your availability for students to book sessions with you.
                                                    </Text>
                                                </View>

                                                {/* Picker */}
                                                <Picker
                                                    selectedValue={localAvailabilitySetting}
                                                    style={styles.picker}
                                                    onValueChange={(itemValue, itemIndex) => {
                                                        setLocalAvailabilitySetting(itemValue);
                                                    }}
                                                >
                                                    <Picker.Item label="Always available" value="always" />
                                                    <Picker.Item label="Custom availability" value="custom" />
                                                    <Picker.Item label="Not available" value="not" />
                                                </Picker>


                                            </View>


                                            {
                                                localAvailabilitySetting === 'custom' && (
                                                    <>
                                                        {/* 
                                        <View
                                            style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}
                                          >
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Monday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  monday && monday.start && monday.end ? `${monday.start} - ${monday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Tuesday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  tuesday && tuesday.start && tuesday.end ? `${tuesday.start} - ${tuesday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Wednesday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  wednesday && wednesday.start && wednesday.end ? `${wednesday.start} - ${wednesday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Thursday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  thursday && thursday.start && thursday.end ? `${thursday.start} - ${thursday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Friday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  friday && friday.start && friday.end ? `${friday.start} - ${friday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Saturday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  saturday && saturday.start && saturday.end ? `${saturday.start} - ${saturday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                  
                                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                              <Text style={styles.details}>Sunday: </Text>
                                              <Text style={styles.details}>
                                                {
                                                  sunday && sunday.start && sunday.end ? `${sunday.start} - ${sunday.end}` : 'Not set'
                                                }
                                              </Text>
                                            </View>
                                          </View> */}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showMonday
                                                                        ? primary
                                                                        : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showMonday) {
                                                                        setMonday({ start: '', end: '' });
                                                                        setShowMonday(false);
                                                                    } else {
                                                                        setShowMonday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Monday
                                                            </Text>
                                                        </View>

                                                        {showMonday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setMonday({ ...monday, start: time });
                                                                            }}
                                                                            time={monday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setMonday({ ...monday, end: time });
                                                                            }}
                                                                            time={monday.end}
                                                                        ></CustomTimeInput>


                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showTuesday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showTuesday) {
                                                                        setTuesday({ start: '', end: '' });
                                                                        setShowTuesday(false);
                                                                    } else {
                                                                        setShowTuesday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Tuesday
                                                            </Text>
                                                        </View>

                                                        {showTuesday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setTuesday({ ...tuesday, start: time });
                                                                            }}
                                                                            time={tuesday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setTuesday({ ...tuesday, end: time });
                                                                            }}
                                                                            time={tuesday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}
                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showWednesday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showWednesday) {
                                                                        setWednesday({ start: '', end: '' });
                                                                        setShowWednesday(false);
                                                                    } else {
                                                                        setShowWednesday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Wednesday
                                                            </Text>
                                                        </View>

                                                        {showWednesday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setWednesday({ ...wednesday, start: time });
                                                                            }}
                                                                            time={wednesday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setWednesday({ ...wednesday, end: time });
                                                                            }}
                                                                            time={wednesday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showThursday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showThursday) {
                                                                        setThursday({ start: '', end: '' });
                                                                        setShowThursday(false);
                                                                    } else {
                                                                        setShowThursday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Thursday
                                                            </Text>
                                                        </View>

                                                        {showThursday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setThursday({ ...thursday, start: time });
                                                                            }}
                                                                            time={thursday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setThursday({ ...thursday, end: time });
                                                                            }}
                                                                            time={thursday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showFriday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showFriday) {
                                                                        setFriday({ start: '', end: '' });
                                                                        setShowFriday(false);
                                                                    } else {
                                                                        setShowFriday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Friday
                                                            </Text>
                                                        </View>

                                                        {showFriday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setFriday({ ...friday, start: time });
                                                                            }}
                                                                            time={friday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setFriday({ ...friday, end: time });
                                                                            }}
                                                                            time={friday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showSaturday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showSaturday) {
                                                                        setSaturday({ start: '', end: '' });
                                                                        setShowSaturday(false);
                                                                    } else {
                                                                        setShowSaturday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Saturday
                                                            </Text>
                                                        </View>

                                                        {showSaturday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setSaturday({ ...saturday, start: time });
                                                                            }}
                                                                            time={saturday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setSaturday({ ...saturday, end: time });
                                                                            }}
                                                                            time={saturday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}

                                                        <View style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: showSunday ? primary : backgroundVariant,
                                                                    border: '1px solid' + border,
                                                                    borderRadius: defaultStyles.radius,
                                                                    marginRight: 10,
                                                                }}
                                                                onPress={() => {
                                                                    if (showSunday) {
                                                                        setSunday({ start: '', end: '' });
                                                                        setShowSunday(false);
                                                                    } else {
                                                                        setShowSunday(true);
                                                                    }
                                                                }}
                                                            ></TouchableOpacity>

                                                            <Text
                                                                style={{
                                                                    color: text,
                                                                    fontFamily: defaultStyles.boldFont,
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                Sunday
                                                            </Text>
                                                        </View>

                                                        {showSunday && (
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    alignContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    gap: 15,
                                                                }}
                                                            >
                                                                {Platform.OS == 'web' ? (
                                                                    <>
                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setSunday({ ...sunday, start: time });
                                                                            }}
                                                                            time={sunday.start}
                                                                        ></CustomTimeInput>

                                                                        <Text
                                                                            style={{
                                                                                color: text,
                                                                                fontFamily: defaultStyles.boldFont,
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            to
                                                                        </Text>

                                                                        <CustomTimeInput
                                                                            setTime={(time) => {
                                                                                setSunday({ ...sunday, end: time });
                                                                            }}
                                                                            time={sunday.end}
                                                                        ></CustomTimeInput>
                                                                    </>
                                                                ) : (
                                                                    <Text style={{ color: text }}>Coming soon...</Text>
                                                                )}
                                                            </View>
                                                        )}
                                                    </>
                                                )
                                            }


                                        </View>


                                        {/* Save button */}
                                        <TouchableOpacity
                                            style={{
                                                backgroundColor: primary,
                                                borderRadius: defaultStyles.buttonRadius,
                                                height: 40,
                                                marginTop: 15,
                                                width: isMd ? 200 : "100%",
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                alignSelf: isMd ? "flex-end" : null
                                            }}
                                            onPress={() => {
                                                // Update user in database with new instabook value
                                                updateSession();
                                            }}
                                        >
                                            <Text style={{
                                                color: background,
                                                fontFamily: defaultStyles.boldFont,
                                                fontSize: 16,
                                                textAlign: 'center'
                                            }}>Save</Text>
                                        </TouchableOpacity>

                                    </View>


                                    {/* Right side */}
                                    <View style={{
                                        display: "flex",
                                        flexDirection: !isLg ? 'row' : 'column',
                                        width: isLg ? 330 : "100%",
                                    }}>
                                        <View style={{
                                            border: "1px solid " + border,
                                            borderRadius: defaultStyles.radius,
                                            padding: 15,
                                            width: isLg ? 330 : "100%",
                                        }}>

                                            {/* Icon  */}
                                            <AntDesign
                                                name="videocamera"
                                                size={32}
                                                color={text}
                                                style={{ marginBottom: 10 }}
                                            />

                                            {/* Title */}
                                            <Text style={styles.infoSubtitle}>How do sessions work?</Text>
                                            {/* Description */}
                                            <Text style={styles.infoDescription}>
                                                Students can book sessions with you at your hourly rate. You can set your availability and students can book sessions with you during those times. You will be notified when a student books a session with you, if auto-accept is disabled you will have to accept session requests.
                                            </Text>
                                            {/* Divider  */}
                                            <View style={{
                                                width: "100%",
                                                height: 1,
                                                backgroundColor: border,
                                                marginTop: 15,
                                                marginBottom: 15
                                            }}></View>


                                            {/* Icon  */}


                                            <AntDesign
                                                name="creditcard"
                                                size={32}
                                                color={text}
                                                style={{ marginBottom: 10 }}
                                            />

                                            {/* Title */}
                                            <Text style={styles.infoSubtitle}>How do the rates work?</Text>
                                            {/* Description */}
                                            <Text style={styles.infoDescription}>
                                                When a student books a session with you they select a session length. The session length is multiplied by your hourly rate to calculate the total cost of the session. If the session length is extended while the session is in progress, the student will be charged for the additional time.
                                            </Text>
                                        </View>
                                        {
                                            isMd && (
                                                <View style={{ flex: 1 }}></View>
                                            )
                                        }

                                    </View>

                                </View>
                            </View>
                            {/* Last updated in bottom left corner */}
                            {/* <Text style={{color: primary, fontSize: 12, fontFamily: defaultStyles.regularFont, position: 'absolute', bottom: 0, left: 0, marginBottom: 15, padding: isSm ? 45: 15,}}>Last updated: {lastUpdated}</Text> */}
                        </View>
                        {/* Footer  */}
                        {isSm ? <Footer></Footer> : null}
                    </ScrollView>
                )
            }
        </SafeAreaView >
    )
}