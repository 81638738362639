import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Notifications } from '@components/Notifications'
const Stack = createNativeStackNavigator()

export const NotificationsStack = ({ navigation, route, params }) => {
    return (
        <SafeAreaProvider>
            <Stack.Navigator initialRouteName="Notifications">
                <Stack.Screen
                    options={{ headerShown: false }}
                    name="Notifications"
                    component={Notifications}
                />
            </Stack.Navigator>
        </SafeAreaProvider>
    )
}
