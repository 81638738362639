import Agenda from '@components/Agenda'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Typography,
    useTheme,
} from '@mui/material'
import { PricingOption } from 'dataTypes'
import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
import { useColorsContext } from '@utils/styles'

interface Props {
    setSelectedTime: React.Dispatch<React.SetStateAction<Date>>
    tutor: any
    onSelect: () => void
    setSelectedPricingIndex: React.Dispatch<React.SetStateAction<number>>
    selectedPricingIndex: number
    setView: React.Dispatch<React.SetStateAction<number>>
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    selectedTime: Date
    view: number
    pricingOptions: MultiSwitchToggleOption[]
}

const SelectSession = ({
    setSelectedTime,
    tutor,
    onSelect,
    setSelectedPricingIndex,
    selectedPricingIndex,
    setView,
    setVisible,
    selectedTime,
    view,
    pricingOptions,
}: Props) => {
    const { primaryLight } = useColorsContext()
    const [error, setError] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        if (selectedTime) {
            setError(false)
        }
    }, [selectedTime])
    return (
        <>
            <DialogContent
                sx={{
                    minWidth: { xs: '100%', md: 600 },
                    padding: 2,
                    display: view === 0 ? 'block' : 'none',
                    overflowY: 'auto',
                }}
            >
                <Agenda
                    tutor={tutor}
                    selectedTime={selectedTime}
                    pricingOptions={pricingOptions}
                    setSelectedTime={setSelectedTime}
                    setSelectedPricingIndex={setSelectedPricingIndex}
                    selectedPricingIndex={selectedPricingIndex}
                />

                {error && (
                    <Typography
                        fontFamily="Poppins_500Medium"
                        style={{
                            color: theme.palette.error.main,
                            textAlign: 'center',
                            marginTop: 10,
                        }}
                    >
                        Please select a time.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ display: view === 0 ? 'flex' : 'none' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setVisible(false)}
                >
                    Cancel
                </Button>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (!selectedTime) {
                            setError(true)
                            return
                        }
                        setError(false)
                        setView(1)
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    button: {
        width: 100,
        height: 40,
        marginLeft: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default SelectSession
