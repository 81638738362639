import React from 'react'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useColorsContext, useStyles } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'
import { useState } from 'react'
import OnboardingDialog from '@components/OnboardingDialog'
import { useDialog } from '@contexts/dialogContext'

const OnboardingWelcome: React.FC = () => {
    const defaultStyles = useStyles()
    const { primary, text } = useColorsContext()
    const [loading, setLoading] = useState(false)
    const { extendedUser } = useUserContext()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isXs = useMediaQuery(theme.breakpoints.up('xs'))
    const { openOnboardingDialog } = useDialog()

    const helloText = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                        variant={isSm ? 'h2' : 'h3'}
                        fontFamily="Poppins_400Regular"
                    >
                        Hello,
                    </Typography>
                    <Typography
                        variant={isSm ? 'h2' : 'h3'}
                        fontFamily="Poppins_600SemiBold"
                    >
                        {extendedUser?.name?.split(' ')[0]}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Typography
                        variant={isSm ? 'h3' : 'h4'}
                        fontFamily="Poppins_500Medium"
                        textAlign="center"
                    >
                        Welcome to
                        <span
                            style={{
                                color: primary,
                                fontFamily: 'Poppins_600SemiBold',
                            }}
                        >
                            {' '}
                            tutred
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} flexWrap="wrap-reverse">
                <Grid
                    size={{ xs: 12, md: 6 }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {isMd && helloText()}
                    <Button
                        variant="contained"
                        color="primary"
                        size={isXs ? 'large' : 'medium'}
                        sx={{
                            mt: isXs ? 8 : 6,
                            mb: isXs ? 8 : 4,
                            boxShadow: `0px 0px 16px 0px ${theme.palette.primary.main}`,
                        }}
                        onClick={() => openOnboardingDialog()}
                    >
                        Complete Profile
                    </Button>
                    <img
                        src={require('@assets/images/onboarding/welcome.svg')}
                        alt="tutred"
                        width={isXs ? 450 : '100%'}
                        height={isXs ? 300 : 'auto'}
                        style={{
                            objectFit: 'contain',
                            padding: isSm ? 32 : 48,
                        }}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    {!isMd && helloText()}
                    <img
                        src={require('@assets/images/onboarding/complete-profile.svg')}
                        alt="tutred"
                        width="100%"
                        height="auto"
                        style={{
                            objectFit: 'contain',
                            padding: isSm ? 32 : 16,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default OnboardingWelcome
