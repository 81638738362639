import { doc, DocumentReference, getDoc } from 'firebase/firestore'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'

// File imports
import Footer from '@components/Footer'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useUserContext } from '@contexts/userContext'
import { useColorsContext, useStyles } from '@utils/styles'

// Components
import { Header as AuthHeader } from '@components/AuthHeader'
import { BlankPage } from '@components/BlankPage'
import { Header } from '@components/Header'
import { TutorListCard } from '@components/TutorListCard'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import useNavigateToChat from '@hooks/useNavigateToChat'

export const Favourites = ({ navigation, route, params }) => {
    // Appearance

    // Colors and styles
    const {
        primary,
        primaryVariant,
        red,
        redVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
    } = useColorsContext()
    const defaultStyles = useStyles()

    // Media queries
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    // Firebase
    const { db } = useFirebaseContext()
    const { user, isTutor, favourites } = useUserContext()
    const [sortValue, setSortValue] = useState('online')
    const [tutors, setTutors] = useState([])
    const { navigateToChat } = useNavigateToChat()

    // Stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            display: 'flex',
            //   flexDirection: isMd ? 'row' : 'column',
            flexDirection: 'column',
            width: '100%',
        },
        shadow: {
            // borderBottomColor: border,
            // borderBottomWidth: 1,
            // shadowColor: '#171717',
            // shadowOffset: {width: -2, height: 4},
            // shadowOpacity: 0.2,
            // shadowRadius: 3,
        },
        title: {
            color: text,
            fontFamily: defaultStyles.boldFont,
            fontSize: 24,
            marginBottom: 10,
        },
        searchBox: {
            width: 'fit-content',
            height: 60,
            backgroundColor: backgroundVariant,
            borderRadius: defaultStyles.radius,
            height: '100%',
            padding: 30,
            flexDirection: 'row',
            alignItems: 'center',
        },
        filtersApplied: {
            position: 'absolute',
            top: -10,
            left: -10,
            borderRadius: 100,
            backgroundColor: primaryVariant,
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        filtersAppliedText: {
            color: '#fff',
            fontFamily: defaultStyles.boldFont,
            fontSize: 12,
        },
        search: {
            flex: 1,
            marginLeft: 5,
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
        },
        modalTitle: {
            fontSize: 18,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
        results: {
            fontFamily: defaultStyles.regularFont,
            fontSize: 14,
            color: textVariant,
            marginRight: 15,
        },
        picker: {
            fontSize: 16,
            borderRadius: defaultStyles.radius,
            fontFamily: defaultStyles.boldFont,
            // padding: 12,
            paddingTop: 2.5,
            paddingBottom: 2.5,
            paddingLeft: 15,
            paddingRight: 15,
            color: text,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
        },
    })

    // On filters change update tutors displayed
    useEffect(() => {
        fetchTutors()
    }, [sortValue, favourites])

    // Fetch tutors from database
    const fetchTutors = async () => {
        const favList = []
        for await (const favourite of favourites) {
            const docRef = doc(db, 'users', favourite.id)
            const fav = await getDoc(docRef)
            favList.push(fav.data())
        }
        setTutors(favList)
    }

    if (isTutor === null) {
        return <BlankPage navigation={navigation}></BlankPage>
    }

    const favouriteData = useMemo(() => {
        const favourites = [...tutors]
        if (sortValue == 'online') {
            favourites.sort((a, b) => {
                let aOnline = a.lastOnline
                let bOnline = b.lastOnline
                return bOnline - aOnline
            })
        }

        // Sort by price
        else if (sortValue == 'low-rate') {
            favourites.sort((a, b) => {
                let aRate = a.rate
                let bRate = b.rate
                return aRate - bRate
            })
        }

        // Sort by price
        else if (sortValue == 'high-rate') {
            favourites.sort((a, b) => {
                let aRate = a.rate
                let bRate = b.rate
                return bRate - aRate
            })
        }
        return favourites
    }, [sortValue, favourites, tutors])

    const renderItem = React.useCallback(
        ({ item }) => {
            return (
                <TutorListCard
                    maxRating={0}
                    minRating={0}
                    filterRating={false}
                    navigation={navigation}
                    tutor={item}
                    navigateToChat={(tutor, user, profile) => {
                        navigateToChat(tutor, user, profile)
                    }}
                />
            )
        },
        [navigation, user, navigateToChat]
    )

    const keyExtractor = React.useCallback((item) => item.id, [])

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.maxWidth}>
                <ScrollView
                    contentContainerStyle={{
                        flexGrow: 1,
                        justifyContent: 'space-between',
                    }}
                    showsVerticalScrollIndicator={false}
                    style={{ flex: 1 }}
                >
                    <View style={{ padding: !isSm ? 15 : 45, paddingTop: 25 }}>
                        {/* Sorting & Results */}
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{ flex: 1 }}>
                                <Text style={styles.title}>My Favourites</Text>
                            </View>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <FormControl
                                    sx={{ m: 1, minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel id="demo-select-small-label">
                                        Sort By
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Sort By"
                                        value={sortValue}
                                        onChange={(e) =>
                                            setSortValue(e.target.value)
                                        }
                                    >
                                        <MenuItem value="online">
                                            Online
                                        </MenuItem>
                                        <MenuItem value="low-rate">
                                            Lowest price
                                        </MenuItem>
                                        <MenuItem value="high-rate">
                                            Highest price
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </View>

                        {/* Left side filters */}
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: !isSm ? 'column' : 'row',
                            }}
                        >
                            <FlatList
                                style={{ display: 'flex' }}
                                data={favouriteData}
                                keyExtractor={keyExtractor}
                                renderItem={renderItem}
                                ListEmptyComponent={() => {
                                    return (
                                        <View
                                            style={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily:
                                                        defaultStyles.regularFont,
                                                    fontSize: 16,
                                                    color: textVariant,
                                                    marginRight: 10,
                                                }}
                                            >
                                                Favourite a tutor to see them
                                                here!
                                            </Text>
                                        </View>
                                    )
                                }}
                            />
                        </View>
                    </View>

                    {isSm ? <Footer></Footer> : null}
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}
