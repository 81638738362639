import { SignUpFormData } from '@components/SignUpDialog'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { useMutation } from '@tanstack/react-query'
import { setDoc, doc, getFirestore } from 'firebase/firestore'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useAlert } from 'react-alert'
import { trackEvent } from '@utils/trackEvent'

const useSignUp = () => {
    const auth = getAuth()
    const db = getFirestore()
    const { authenticatedAxios } = useAxiosWithAuth()
    const alert = useAlert()

    const {
        mutate: signup,
        isPending,
        isError,
        error,
        isSuccess,
    } = useMutation({
        mutationFn: ({
            email,
            password,
            accountType,
            firstName,
            lastName,
        }: SignUpFormData) => {

            return createUserWithEmailAndPassword(auth, email, password).then(
                async (userCredential) => {

                    const user = userCredential.user

                    let urlId = Math.floor(100000 + Math.random() * 900000)

                    let userData: any = {
                        created: new Date(),
                        uid: user.uid,
                        id: user.uid,
                        email: email,
                        name: firstName + ' ' + lastName,
                        lastOnline: new Date(),
                        isTutor: accountType === 'tutor',
                        URL: urlId,
                    }

                    let createCustomerData = null
                    if (accountType == 'student') {
                        createCustomerData = await authenticatedAxios.post(
                            '/api/create-customer',
                            {
                                email,
                            }
                        )
                        userData.customerId = createCustomerData?.data?.customerId
                    }

                    setDoc(doc(db, 'users', user.uid), userData)
                        .then(() => {
                            authenticatedAxios.post('/api/email/welcome', {
                                email,
                                name: firstName + ' ' + lastName,
                                isTutor: accountType === 'tutor',
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    trackEvent('Sign-up completed', {
                        distinct_id: user.uid,
                        email: email,
                        accountType: accountType,
                    })
                }
            )
        },
        onError: (error) => {
            console.log('error', error)
            if (error?.code === 'auth/email-already-in-use') {
                alert.error('[Sign up] Email already in use!')
            } else {
                alert.error('[Sign up] Something went wrong!')
            }
        },

    })

    return { signup, isPending, isError, isSuccess, error }
}

export default useSignUp
