import React from 'react'
import { Chip, Avatar, ChipProps, Typography, IconButton } from '@mui/material'
import { languageIcons } from '../../../config/languages'
import AntDesign from '@expo/vector-icons/AntDesign'
import { useTheme } from '@mui/material/styles'
import { useColorsContext } from '@utils/styles'
import TutredBaseChip from '../TutredBaseChip'

interface LanguageChipProps extends ChipProps {
    language: string
}

const LanguageChip: React.FC<LanguageChipProps> = ({
    language,
    onDelete,
    ...rest
}) => {
    const theme = useTheme()
    const { darkMode } = useColorsContext()

    return (
        <TutredBaseChip
            icon={
                <img
                    src={languageIcons[language]}
                    alt={language}
                    width={28}
                    height={28}
                    style={{ objectFit: 'contain', marginLeft: 12 }}
                />
            }
            label={language}
            onDelete={onDelete}
            // deleteIcon={
            //     <IconButton>
            //         <AntDesign
            //             name="close"
            //             size={18}
            //             color={theme.palette.grey[800]}
            //         />
            //     </IconButton>
            // }
            variant="outlined"
            {...rest}
        />
    )
}

export default LanguageChip
