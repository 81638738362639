import { useState, useEffect, createContext, useContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useColorScheme } from 'react-native';

export const useStyles = () => {
    return {
        radius: 12,
        titleFont: 'Poppins_600SemiBold',
        boldFont: 'Poppins_700Bold',
        regularFont: 'Poppins_400Regular',
        lightFont: 'Poppins_300Light',
        buttonRadius: 100
    };
}

const colorsContext = createContext();

// Export colors as a module
export const ColorsContextProvider = ({ children }) => {

    // Load dark mode from settings
    const [darkMode, setDarkMode] = useState(useColorScheme() === "dark");
    const [primary, setPrimary] = useState("");
    const [primaryLight, setPrimaryLight] = useState("");
    const [primaryExtraLight, setPrimaryExtraLight] = useState("");
    const [primaryDark, setPrimaryDark] = useState("#4039FE");
    const [primaryVariant, setPrimaryVariant] = useState("");
    const [red, setRed] = useState("");
    const [redVariant, setRedVariant] = useState("");
    const [background, setBackground] = useState("");
    const [backgroundVariant, setBackgroundVariant] = useState("");
    const [text, setText] = useState("");
    const [textInverse, setTextInverse] = useState("");
    const [textVariant, setTextVariant] = useState("");
    const [textSecondary, setTextSecondary] = useState("");
    const [border, setBorder] = useState("");
    const [yellow, setYellow] = useState("");
    const [green, setGreen] = useState("");
    const [purple, setPurple] = useState("");
    const [grey, setGrey] = useState("#63626a");
    const [disabled, setDisabled] = useState("#bfbfbf");
    useEffect(() => {
        // Load settings from local storage,  TODO:// update this to save and load the setting for individual users in firestore.
        AsyncStorage.getItem("darkMode")
            .then((localDarkMode) => {
                if (localDarkMode) {
                    setDarkMode(localDarkMode === "true");
                }
            });
    });

    useEffect(() => {
        updateColors();
    }, [darkMode]);

    const updateColors = () => {
        // Update colors based on dark mode
        setPrimary(darkMode ? '#0060DE' : '#0060DE');
        setPrimaryLight(darkMode ? '#262626' : '#d9e9ff');
        setPrimaryExtraLight(darkMode ? '#212121' : 'rgba(0, 110, 255, 0.07)');
        setPrimaryVariant(darkMode ? "#3b37fe" : "#3b37fe");
        setRed(darkMode ? "#cc0000" : "#cc0000");
        setRedVariant(darkMode ? "#090909" : "#191919");
        setBackground(darkMode ? "#000000" : "#FEFCFC");
        setBackgroundVariant(darkMode ? "#090909" : "#FFFFFF");
        setText(darkMode ? "#FFFFFF" : "#000000");
        setTextInverse(darkMode ? "#000000" : "#FFFFFF");
        setTextVariant(darkMode ? "#d6d6d6" : "#191919");
        setTextSecondary("#8c8c8c")
        setBorder(darkMode ? "#121212" : "#EEEEEE");
        setYellow(darkMode ? "#FFB347" : "#FFB347");
        setGreen(darkMode ? "#234EDB" : "#234EDB");
        setPurple(darkMode ? "#866aeb" : "#866aeb");

    }


    const toggleDarkMode = () => {
        // Save dark mode to local storage
        AsyncStorage.setItem("darkMode", !darkMode ? "true" : "false");
        setDarkMode(!darkMode);
    }

    return (
        <colorsContext.Provider
            value={{ primary, primaryVariant, red, redVariant, background, backgroundVariant, text, textVariant, textSecondary, border, yellow, green, purple, darkMode, grey, primaryLight, textInverse, toggleDarkMode, setDarkMode, disabled, primaryDark, primaryExtraLight }}
        >
            {children}
        </colorsContext.Provider>
    );

};

export function useColorsContext() {
    return useContext(colorsContext);
}