
import { useColorsContext } from '@utils/styles';
import React from 'react';

const CertifiedBadge = ({
    size = 25
}) => {

    const { text } = useColorsContext();
    return (

        <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.3278 10.8419L21.9984 8.51255V5.20597C21.9984 4.60325 21.759 4.02522 21.3328 3.59903C20.9066 3.17284 20.3286 2.93341 19.7259 2.93341H16.4306L14.1013 0.660845C13.6755 0.237578 13.0995 0 12.4991 0C11.8987 0 11.3227 0.237578 10.8969 0.660845L8.56756 2.94477H5.26098C4.65826 2.94477 4.08023 3.1842 3.65404 3.61039C3.22785 4.03658 2.98842 4.61461 2.98842 5.21733V8.51255L0.715858 10.8419C0.496299 11.0482 0.319944 11.2961 0.19705 11.5711C0.0741551 11.8462 0.0071735 12.1429 7.24621e-07 12.4441C-0.000478175 13.0442 0.236437 13.6202 0.659044 14.0462L2.99978 16.3756V19.6822C2.99978 20.2849 3.23921 20.863 3.6654 21.2891C4.09159 21.7153 4.66963 21.9548 5.27235 21.9548H8.56756L10.8969 24.2841C11.323 24.7068 11.899 24.9437 12.4991 24.9432C13.0992 24.9437 13.6752 24.7068 14.1013 24.2841L16.4306 21.9434H19.7372C20.3399 21.9434 20.918 21.704 21.3442 21.2778C21.7703 20.8516 22.0098 20.2736 22.0098 19.6708V16.3756L24.3392 14.0462C24.7624 13.6205 25 13.0445 25 12.4441C25 11.8437 24.7624 11.2677 24.3392 10.8419H24.3278ZM19.7259 15.4439V19.6708H15.4989L12.4991 22.6706L9.49932 19.6708H5.27235V15.4439L2.27256 12.4441L5.27235 9.4443V5.21733H9.49932L12.4991 2.21755L15.4989 5.21733H19.7259V9.4443L22.7256 12.4441L19.7259 15.4439Z" fill={text} />
            <path d="M11.3628 13.2736L8.76074 10.6715L7.14722 12.2736L11.3628 16.4893L17.851 10.0011L16.2375 8.39893L11.3628 13.2736Z" fill={text} />
        </svg>

    )
}

export default CertifiedBadge
