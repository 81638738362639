import {
    average,
    collection,
    count,
    getAggregateFromServer,
    query,
    where,
} from 'firebase/firestore'
import React, { ReactNode, useEffect, useState } from 'react'
import { useFirebaseContext } from '../../contexts/firebaseContext'
import { useColorsContext, useStyles } from '../../utils/styles'
import {
    Avatar,
    Box,
    Card,
    Typography,
    Grid,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import AntDesign from '@expo/vector-icons/AntDesign'
import formatName from '@helpers/formatName'
import ShowMoreText from 'react-show-more-text'

import { useUserContext } from '@contexts/userContext'
import Favourite from '@components/Favourite'
import Subjects from '@components/Subjects'
import Specialties from '@components/Specialties'
import SendIcon from '@icons/SendIcon'
import CheckIcon from '@icons/CheckIcon'
import Rating from './Rating'
import Languages from './Languages'
import Location from './Location'
import TutredChipDisplay from '@components/TutredChipDisplay'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import SubjectIcon from '@icons/SubjectIcon'
import SubjectChip from '@components/Chips/SubjectChip'
import TutredAvatar from '@components/TutredAvatar'
import { useDialog } from '@contexts/dialogContext'

interface TutorListProps {
    navigation: any
    tutor: any
    navigateToChat: any
    minRating?: number
    filterRating?: boolean
    small?: boolean
    customAvatar?: ReactNode
}

// Tutor component
export const TutorListCard: React.FC<TutorListProps> = ({
    navigation,
    tutor,
    navigateToChat,
    minRating,
    filterRating = false,
    small = false,
    customAvatar,
}) => {
    const { db } = useFirebaseContext()

    // Colors and styles
    const defaultStyles = useStyles()
    const { primary, primaryLight, backgroundVariant, text, background } =
        useColorsContext()
    // Media queries

    // States
    const [reviewCount, setReviewCount] = useState(0)
    const [rating, setRating] = useState(0)
    const { user, profile, extendedUser } = useUserContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { openLoginDialog } = useDialog()

    // Get all reviews for tutor

    const getRating = async () => {
        let reviewsRef = collection(db, 'reviews')
        let q = query(reviewsRef)
        q = query(q, where('user.uid', '==', tutor.uid))
        const snapshot = await getAggregateFromServer(q, {
            averageRating: average('rating'),
            ratingCount: count(),
        })
        const { averageRating, ratingCount } = snapshot.data()
        setRating(averageRating?.toFixed(1) || 0)
        setReviewCount(ratingCount)
    }

    useEffect(() => {
        getRating()
    }, [tutor.uid])

    if (filterRating && rating < minRating) return null

    return (
        <Card
            sx={{
                marginBottom: small || !isMd ? 1 : 2,
                padding: small || !isMd ? 1 : 2,
                borderRadius: 3,
                backgroundColor: background,
                borderColor: '#000',
                minWidth: isMd && !small ? 600 : '100%',
            }}
            variant="outlined"
        >
            <Grid container space={2}>
                {/* Name, Language, Location, Tag Line, Description, Subjects */}
                <Grid
                    item
                    md={8}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        backgroundColor: background,
                        paddingRight: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {customAvatar || (
                            <TutredAvatar
                                user={tutor}
                                size={isMd ? 'medium' : 'small'}
                                sx={{
                                    marginRight: { xs: 1, md: 2 },
                                }}
                                clickable={extendedUser?.uid !== tutor.uid}
                            />
                        )}
                        {/* Name, Language, Location */}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {/* Name & Verified */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (extendedUser?.uid === tutor.uid) return
                                    navigation.navigate('TutorProfile', {
                                        id: tutor.URL,
                                    })
                                }}
                            >
                                <Typography
                                    variant={'h6'}
                                    fontFamily={defaultStyles.boldFont}
                                    sx={{ color: text }}
                                >
                                    {formatName(tutor.name)}
                                </Typography>
                                {tutor.isVerified && (
                                    <AntDesign
                                        name="checkcircle"
                                        size={small || !isMd ? 14 : 16}
                                        color={primary}
                                        style={{ marginLeft: small ? 4 : 8 }}
                                    />
                                )}
                            </Box>
                            {!isSm && (
                                <Rating
                                    reviewCount={reviewCount}
                                    size="small"
                                    rating={rating}
                                />
                            )}
                            {isSm && (
                                <>
                                    <Languages
                                        tutorLanguages={tutor.languages}
                                        size={small ? 'small' : 'large'}
                                    />

                                    <Location
                                        province={tutor?.location?.province}
                                        small={small}
                                        textSx={{ marginLeft: 1 }}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>

                <Grid
                    item
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        backgroundColor: backgroundVariant,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {isSm && (
                            <Rating
                                reviewCount={reviewCount}
                                size="large"
                                rating={rating}
                            />
                        )}

                        <Favourite
                            tutor={tutor}
                            size={36}
                            containerStyle={{ marginLeft: small ? 12 : 24 }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container direction={isMd ? 'row' : 'column'}>
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden',
                        flexWrap: 'wrap',
                        backgroundColor: background,
                        paddingRight: 2,
                    }}
                >
                    {!isSm && (
                        <>
                            <Languages
                                tutorLanguages={tutor.languages}
                                size={small ? 'small' : 'large'}
                            />
                            <Location
                                province={tutor?.location?.province}
                                small={small}
                            />
                        </>
                    )}
                    {/* Headline & Description */}
                    <Box
                        sx={{
                            marginTop: small || !isMd ? 0 : 1,
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant={small || !isMd ? 'body2' : 'h6'}
                            sx={{
                                color: text,
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                            }}
                            fontFamily={defaultStyles.boldFont}
                        >
                            {tutor.headline}
                        </Typography>
                        <ShowMoreText
                            more={'Read More'}
                            lines={3}
                            onClick={() => {
                                if (extendedUser?.uid === tutor.uid) return
                                navigation.navigate('TutorProfile', {
                                    id: tutor.URL,
                                })
                            }}
                            expandByClick={false}
                            className="show-more-text"
                        >
                            {tutor.description}
                        </ShowMoreText>
                        <TutredChipDisplay
                            icon={<SubjectIcon size={isMd ? 20 : 16} />}
                            items={Object.keys(tutor.subjects).map(
                                (key) => key
                            )}
                            Chip={SubjectChip}
                            limitVisible
                        />
                        <TutredChipDisplay
                            icon={<SpecialtyIcon size={isMd ? 20 : 16} />}
                            items={tutor.skills}
                            Chip={SpecialtyChip}
                            limitVisible
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={4}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: isMd ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingTop: small ? 2 : 4,
                        gap: small ? 0.5 : 1,
                    }}
                >
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            if (extendedUser?.uid === tutor.uid) return
                            if (!user) {
                                openLoginDialog(
                                    'Please log in to send a message'
                                )
                            }
                            navigateToChat(tutor, extendedUser, profile)
                        }}
                        disableElevation
                        sx={{
                            backgroundColor: primaryLight,
                            borderColor: '#D0D0D0',
                            color: '#000',
                            fontFamily: defaultStyles.titleFont,
                            fontSize: '1.25rem',
                            width: { xs: 42, md: '100%' },
                            p: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            pr: 1,
                        }}
                        endIcon={isMd ? <SendIcon size={32} /> : null}
                        size={small || !isMd ? 'small' : 'medium'}
                    >
                        {isMd ? 'Message' : <SendIcon size={32} />}
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            if (extendedUser?.uid === tutor.uid) return
                            navigation.navigate('TutorProfile', {
                                id: tutor.URL,
                            })
                        }}
                        disableElevation
                        size={small || !isMd ? 'small' : 'medium'}
                        sx={{
                            backgroundColor: primary,
                            fontFamily: defaultStyles.titleFont,
                            color: '#000',
                            fontSize: '1.25rem',
                            textTransform: 'none',
                            borderWidth: 3,
                            borderColor: '#000',
                            p: 0,
                        }}
                        endIcon={<CheckIcon size={22} />}
                    >
                        Book ${tutor.sessionPricing?.[60]?.rate || tutor?.rate}
                        /hr
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}
