import LanguagesIcon from '@icons/LanaugesIcon'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { FC, useMemo } from 'react'
import { languageIcons, languages as languagesData } from '@config/languages'

interface LanguagesProps {
    size?: 'small' | 'large'
    tutorLanguages: string[]
}

const Languages: FC<LanguagesProps> = ({ size, tutorLanguages }) => {
    const languages = useMemo(() => {
        if (tutorLanguages?.length === 0) {
            return []
        } else {
            const spokenLanguages = []
            languagesData.forEach((language) => {
                if (tutorLanguages?.hasOwnProperty(language)) {
                    spokenLanguages.push(language)
                }
            })
            return spokenLanguages
        }
    }, [tutorLanguages])

    const { text } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: { xs: 0.5, md: 0 },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: 18,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LanguagesIcon size={size === 'large' ? 20 : 16} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        overflow: 'hidden',
                        marginLeft: 1,
                    }}
                >
                    <Typography
                        fontFamily="Poppins_500Medium"
                        whiteSpace="nowrap"
                        variant={size === 'small' ? 'caption' : 'body2'}
                    >
                        I Speak
                    </Typography>
                    {languages
                        .slice(0, size === 'small' ? 2 : 3)
                        .map((language) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        fontFamily={defaultStyles.boldFont}
                                        sx={{
                                            marginRight:
                                                size === 'small' ? 0.5 : 0.75,
                                            marginLeft:
                                                size === 'small' ? 0.25 : 0.5,
                                        }}
                                        variant={
                                            size === 'small'
                                                ? 'caption'
                                                : 'body2'
                                        }
                                    >
                                        {language}
                                    </Typography>
                                    <img
                                        src={languageIcons[language]}
                                        alt={language}
                                        width={size === 'small' ? 14 : 18}
                                        height={size === 'small' ? 14 : 18}
                                        style={{
                                            objectFit: 'contain',
                                            marginBottom:
                                                size === 'small' ? 1 : 2,
                                        }}
                                    />
                                </Box>
                            )
                        })}
                    {languages.length > (size === 'small' ? 2 : 3) && (
                        <Typography
                            variant={size === 'small' ? 'caption' : 'body2'}
                            sx={{
                                color: text,
                                marginLeft: size === 'small' ? 0.25 : 0.5,
                            }}
                        >
                            ...
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Languages
