import {
    Box,
    Divider,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { TouchableOpacity } from 'react-native'
import articles from '../data'
import { useNavigation } from '@react-navigation/native'
import { useStyles } from '@utils/styles'

const HelpSections: React.FC<{ type: 'students' | 'tutors' }> = ({ type }) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const defaultStyles = useStyles()
    const navigation = useNavigation()

    return (
        <Grid container space={8} sx={{ padding: isMd ? 4 : 0 }}>
            {articles[type].map((article) => (
                <Grid
                    key={article.slug}
                    md={4}
                    xs={12}
                    item
                    sx={{
                        marginBottom: 2,
                        cursor: 'pointer',
                    }}
                >
                    <Box
                        sx={{
                            marginLeft: isMd ? 2 : 0,
                            marginRight: isMd ? 2 : 0,
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('HelpArticle', {
                                    slug: article.slug,
                                })
                            }}
                        >
                            <Typography
                                fontFamily={defaultStyles.titleFont}
                                variant={'body1'}
                            >
                                {article.title}
                            </Typography>
                            <Typography sx={{ marginBottom: 2 }}>
                                {article.text}
                            </Typography>
                        </TouchableOpacity>
                        {isMd && <Divider />}
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}

export default HelpSections
