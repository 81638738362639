import { useColorsContext } from '@utils/styles';
import React from 'react';

const StarIcon = ({ size = 40, filled = true, fill = "#0470FF" }) => {
    const { text } = useColorsContext();

    return (
        <svg width={size} height={size} viewBox="0 0 40 40" fill={filled ? fill : "none"} xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3.33334L25.15 13.7667L36.6667 15.45L28.3333 23.5667L30.3 35.0333L20 29.6167L9.7 35.0333L11.6667 23.5667L3.33333 15.45L14.85 13.7667L20 3.33334Z" stroke={text} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}


export default StarIcon;
