import { useColorsContext } from '@utils/styles';
import React from 'react';

const SessionRecordingFeeIcon = ({ size = 20 }) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.38234 14.9633H8.34559C8.65012 14.9633 8.89707 14.7166 8.89707 14.4118C8.89707 14.107 8.65016 13.8603 8.34559 13.8603H3.38234C2.47004 13.8603 1.72793 13.1182 1.72793 12.2059V3.93386C1.72793 3.02155 2.47004 2.27944 3.38234 2.27944H16.0662C16.9785 2.27944 17.7206 3.02155 17.7206 3.93386V8.8971C17.7206 9.2019 17.9675 9.44858 18.2721 9.44858C18.5766 9.44858 18.8236 9.20194 18.8236 8.8971V3.93386C18.8236 2.41354 17.5865 1.17651 16.0662 1.17651H3.38234C1.86203 1.17651 0.625 2.41354 0.625 3.93386V12.2059C0.625 13.7262 1.86203 14.9633 3.38234 14.9633Z" fill={text} />
            <path d="M12.6542 8.18832C12.6542 7.80918 12.4477 7.41925 12.1041 7.16402L9.05752 5.00984C8.39994 4.52086 6.97119 4.92312 6.97119 5.90812V10.2316C6.97119 10.9236 7.67885 11.3517 8.33479 11.3517C8.61213 11.3517 8.86201 11.2752 9.0365 11.1444L12.2646 8.96758C12.5123 8.78343 12.6542 8.49961 12.6542 8.18832ZM8.31408 10.228C8.30088 10.2261 8.28689 10.2239 8.27256 10.2216C8.15811 10.203 8.07412 10.1039 8.07412 9.98789V6.15211C8.07412 6.03578 8.15861 5.9364 8.27354 5.91824C8.28682 5.91613 8.29982 5.91421 8.31221 5.91246C8.37201 5.90398 8.4326 5.91875 8.48193 5.95363L11.4562 8.05636C11.4872 8.07953 11.5111 8.10914 11.5276 8.13554L8.481 10.1901C8.43197 10.2231 8.37256 10.2367 8.31408 10.228Z" fill={text} />
            <path d="M14.9633 18.8235C17.3959 18.8235 19.375 16.8444 19.375 14.4118C19.375 11.9791 17.3959 10 14.9633 10C12.5307 10 10.5515 11.9791 10.5515 14.4118C10.5515 16.8444 12.5307 18.8235 14.9633 18.8235ZM14.9633 11.1029C16.7879 11.1029 18.2721 12.5871 18.2721 14.4118C18.2721 16.2364 16.7879 17.7206 14.9633 17.7206C13.1387 17.7206 11.6544 16.2364 11.6544 14.4118C11.6544 12.5871 13.1387 11.1029 14.9633 11.1029Z" fill={text} />
            <path d="M16.8978 14.3714C16.8978 14.0666 16.6508 13.8199 16.3463 13.8199H15.2433V12.717C15.2433 12.4122 14.9964 12.1655 14.6919 12.1655C14.3873 12.1655 14.1404 12.4122 14.1404 12.717V14.9229H16.3463C16.6508 14.9229 16.8978 14.6762 16.8978 14.3714Z" fill={text} />
        </svg>
    )
}


export default SessionRecordingFeeIcon;
