import { useColorsContext } from '@utils/styles';
import React from 'react';

const HomeIcon = ({ size = 24, fill = 'black' }) => {

    const { text } = useColorsContext();
    return (
        <svg width={size} height={size * (20 / 24)} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4592 0.660308C12.3261 0.556423 12.1621 0.5 11.9932 0.5C11.8243 0.5 11.6603 0.556423 11.5272 0.660308L0.75 9.06451L1.68203 10.2433L3 9.21563V18.5C3.00081 18.8975 3.15911 19.2786 3.44024 19.5597C3.72137 19.8409 4.10242 19.9991 4.5 20H19.5C19.8976 19.9992 20.2787 19.841 20.5599 19.5598C20.841 19.2787 20.9993 18.8976 21 18.5V9.22246L22.318 10.25L23.25 9.07111L12.4592 0.660308ZM13.5 18.5H10.5V12.5H13.5V18.5ZM15 18.5V12.5C14.9995 12.1023 14.8414 11.721 14.5602 11.4398C14.279 11.1586 13.8977 11.0004 13.5 11H10.5C10.1023 11.0004 9.721 11.1585 9.43978 11.4397C9.15856 11.721 9.0004 12.1023 9 12.5V18.5H4.5V8.04608L12 2.20358L19.5 8.05396V18.5H15Z"
                fill={text}
            />
        </svg>
    );
}

export default HomeIcon;
