import React, { useMemo } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import VisaIcon from '@icons/visa.png'
import MastercardIcon from '@icons/mastercard.png'
import AmexIcon from '@icons/amex.png'
import { Box, Typography } from '@mui/material'
import { decode } from 'html-entities'
interface PaymentMethodProps {
    brand: string
    endingIn: string
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ brand, endingIn }) => {
    const icon = useMemo(() => {
        switch (brand) {
            case 'visa':
                return VisaIcon
            case 'mastercard':
                return MastercardIcon
            case 'amex':
                return AmexIcon
            default:
                return VisaIcon
        }
    }, [brand])

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <img style={styles.icon} src={icon} />
            <Box
                sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="body1" sx={{ marginRight: 0.5 }}>
                        {decode('&#x2022')}
                        {decode('&#x2022')}
                        {decode('&#x2022')}
                        {decode('&#x2022')}
                    </Typography>
                    {brand === 'amex' ? (
                        <>
                            <Typography
                                variant="body1"
                                sx={{ marginRight: 0.5 }}
                            >
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginRight: 0.5 }}
                            >
                                {decode('&#x2022')}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="body1"
                                sx={{ marginRight: 0.5 }}
                            >
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginRight: 0.5 }}
                            >
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                                {decode('&#x2022')}
                            </Typography>
                        </>
                    )}
                </Box>
                <Typography variant="body2">{endingIn}</Typography>
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    icon: {
        width: 40.5,
        height: 27,
        resizeMode: 'contain',
    },
})

export default PaymentMethod
