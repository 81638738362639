const degreeData = {
    "Masters": [
        { value: "Anthropology (MA)", label: "Anthropology (MA)" },
        { value: "Archaeology (MA)", label: "Archaeology (MA)" },
        { value: "Architecture (MArch)", label: "Architecture (MArch)" },
        { value: "Art (MFA)", label: "Art (MFA)" },
        { value: "Biochemistry and Molecular Biology (MSc)", label: "Biochemistry and Molecular Biology (MSc)" },
        { value: "Biological Sciences (MSc)", label: "Biological Sciences (MSc)" },
        { value: "Biomedical Engineering (MEng)", label: "Biomedical Engineering (MEng)" },
        { value: "Biomedical Engineering (MSc)", label: "Biomedical Engineering (MSc)" },
        { value: "Biomedical Technology (MBT)", label: "Biomedical Technology (MBT)" },
        { value: "Cardiovascular and Respiratory Sciences (MSc)", label: "Cardiovascular and Respiratory Sciences (MSc)" },
        { value: "Chemical and Petroleum Engineering (MEng)", label: "Chemical and Petroleum Engineering (MEng)" },
        { value: "Chemical and Petroleum Engineering (MSc)", label: "Chemical and Petroleum Engineering (MSc)" },
        { value: "Chemistry (MSc)", label: "Chemistry (MSc)" },
        { value: "Civil Engineering (MEng)", label: "Civil Engineering (MEng)" },
        { value: "Civil Engineering (MSc)", label: "Civil Engineering (MSc)" },
        { value: "Clinical Psychology (MSc)", label: "Clinical Psychology (MSc)" },
        { value: "Communication and Media Studies (MA)", label: "Communication and Media Studies (MA)" },
        { value: "Community Health Sciences (MSc)", label: "Community Health Sciences (MSc)" },
        { value: "Computational Media Design (MSc)", label: "Computational Media Design (MSc)" },
        { value: "Computer Science (MSc)", label: "Computer Science (MSc)" },
        { value: "Counselling Psychology (MC)", label: "Counselling Psychology (MC)" },
        { value: "Counselling Psychology (MSc)", label: "Counselling Psychology (MSc)" },
        { value: "Cybersecurity and Threat Intelligence (MCTI)", label: "Cybersecurity and Threat Intelligence (MCTI)" },
        { value: "Criminal Justice (MCJ)", label: "Criminal Justice (MCJ)" },
        { value: "Data Science and Analytics (MDSA)", label: "Data Science and Analytics (MDSA)" },
        { value: "Disability and Community Studies (MDCS)", label: "Disability and Community Studies (MDCS)" },
        { value: "Drama (MFA)", label: "Drama (MFA)" },
        { value: "Economics (MA)", label: "Economics (MA)" },
        { value: "Educational Research (MA)", label: "Educational Research (MA)" },
        { value: "Educational Research (MEd)", label: "Educational Research (MEd)" },
        { value: "Electrical and Computer Engineering (MEng)", label: "Electrical and Computer Engineering (MEng)" },
        { value: "Electrical and Computer Engineering (MSc)", label: "Electrical and Computer Engineering (MSc)" },
        { value: "English (MA)", label: "English (MA)" },
        { value: "Environmental Design (MEDes)", label: "Environmental Design (MEDes)" },
        { value: "Environmental Engineering (MEng)", label: "Environmental Engineering (MEng)" },
        { value: "Gastrointestinal Sciences (MSc)", label: "Gastrointestinal Sciences (MSc)" },
        { value: "Geography (MA)", label: "Geography (MA)" },
        { value: "Geography (MSc)", label: "Geography (MSc)" },
        { value: "Geographic Information Systems (MGIS)", label: "Geographic Information Systems (MGIS)" },
        { value: "Geomatics Engineering (MEng)", label: "Geomatics Engineering (MEng)" },
        { value: "Geomatics Engineering (MSc)", label: "Geomatics Engineering (MSc)" },
        { value: "Geoscience (MSc)", label: "Geoscience (MSc)" },
        { value: "Greek and Roman studies (MA)", label: "Greek and Roman studies (MA)" },
        { value: "History (MA)", label: "History (MA)" },
        { value: "Human Resource Management (MHRM)", label: "Human Resource Management (MHRM)" },
        { value: "Immunology (MSc)", label: "Immunology (MSc)" },
        { value: "Information Security and Privacy (MISP)", label: "Information Security and Privacy (MISP)" },
        { value: "Kinesiology (MKin)", label: "Kinesiology (MKin)" },
        { value: "Kinesiology (MSc)", label: "Kinesiology (MSc)" },
        { value: "Landscape Architecture (MLA)", label: "Landscape Architecture (MLA)" },
        { value: "Languages, Literatures and Cultures (MA)", label: "Languages, Literatures and Cultures (MA)" },
        { value: "Law (LLM), Master of Laws (LLM)", label: "Law (LLM), Master of Laws (LLM)" },
        { value: "Linguistics (MA)", label: "Linguistics (MA)" },
        { value: "Executive Master of Business Administration (EMBA)", label: "Business Administration (EMBA)" },
        { value: "Business Administration (MBA)", label: "Business Administration (MBA)" },
        { value: "Management (MMgmt)", label: "Management (MMgmt)" },
        { value: "Physician Assistant Studies (MPAS)", label: "Physician Assistant Studies (MPAS)" },
        { value: "Math and Statistics (MSc)", label: "Math and Statistics (MSc)" },
        { value: "Mechanical and Manufacturing Engineering (MEng)", label: "Mechanical and Manufacturing Engineering (MEng)" },
        { value: "Mechanical and Manufacturing Engineering (MSc)", label: "Mechanical and Manufacturing Engineering (MSc)" },
        { value: "Medical Science (MSc)", label: "Medical Science (MSc)" },
        { value: "Microbiology and Infectious Diseases (MSc)", label: "Microbiology and Infectious Diseases (MSc)" },
        { value: "Military, Security and Strategic Studies (MSS)", label: "Military, Security and Strategic Studies (MSS)" },
        { value: "Music (MA)", label: "Music (MA)" },
        { value: "Music (MMus)", label: "Music (MMus)" },
        { value: "Neuroscience (MSc)", label: "Neuroscience (MSc)" },
        { value: "Nursing (MN)", label: "Nursing (MN)" },
        { value: "Pathologists' Assistant (MDPA)", label: "Pathologists' Assistant (MDPA)" },
        { value: "Philosophy (MA)", label: "Philosophy (MA)" },
        { value: "Physics and Astronomy- (MSc)", label: "Physics and Astronomy- (MSc)" },
        { value: "Planning (MPlan)", label: "Planning (MPlan)" },
        { value: "Political science (MA)", label: "Political science (MA)" },
        { value: "Precision Health (MPrecH)", label: "Precision Health (MPrecH)" },
        { value: "Public Policy (MPP)", label: "Public Policy (MPP)" },
        { value: "Quantum Computing (MQuaC)", label: "Quantum Computing (MQuaC)" },
        { value: "Religious Studies (MA)", label: "Religious Studies (MA)" },
        { value: "School and Applied Child Psychology (MEd)", label: "School and Applied Child Psychology (MEd)" },
        { value: "School and applied Child Psychology (MSc)", label: "School and applied Child Psychology (MSc)" },
        { value: "Social Work (MSW)", label: "Social Work (MSW)" },
        { value: "Sociology (MA)", label: "Sociology (MA)" },
        { value: "Sustainable Energy Development (MSc)", label: "Sustainable Energy Development (MSc)" },
        { value: "Supply Chain Management (MSCM)", label: "Supply Chain Management (MSCM)" },
        { value: "Veterinary Medical Sciences (MSC)", label: "Veterinary Medical Sciences (MSC)" }
    ],
    "Professional": [
        { value: 'Law (JD) Juris Doctor', label: 'Law (JD) Juris Doctor' },
        { value: 'Medicine (MD) Doctor of Medicine', label: 'Medicine (MD) Doctor of Medicine' },
        { value: 'Optometry (OD) Doctor of Optometry', label: 'Optometry (OD) Doctor of Optometry' },
        { value: 'Osteopathic medicine (DO) Doctor of Osteopathic Medicine', label: 'Osteopathic medicine (DO) Doctor of Osteopathic Medicine' },
        { value: 'Podiatry (DP) Doctor of Podiatry', label: 'Podiatry (DP) Doctor of Podiatry' },
        { value: 'Dentistry (DDS/DDM) Doctor of Dental Surgery', label: 'Dentistry (DDS/DDM) Doctor of Dental Surgery' },
        { value: 'Pharmacy (PharmD) Doctor of Pharmacy', label: 'Pharmacy (PharmD) Doctor of Pharmacy' },
        { value: 'Veterinary Medicine (DVM) Doctor of Veterinary Medicine', label: 'Veterinary Medicine (DVM) Doctor of Veterinary Medicine' },
        { value: 'Chiropractic (DC/DCM) Doctor of Chiropractic', label: 'Chiropractic (DC/DCM) Doctor of Chiropractic' },
        { value: 'Management (DBA) Doctor of Business Administration', label: 'Management (DBA) Doctor of Business Administration' },
        { value: 'Design (DDes) Doctor of Design', label: 'Design (DDes) Doctor of Design' },
        { value: 'Nursing (DN) Doctor of Nursing', label: 'Nursing (DN) Doctor of Nursing' },
        { value: 'Education (EdD) Doctor of Education', label: 'Education (EdD) Doctor of Education' }
    ],
    "Doctorate": [
        { value: "Anthropology (PhD)", label: "Anthropology (PhD)" },
        { value: "Archaeology (PhD)", label: "Archaeology (PhD)" },
        { value: "Biochemistry and Molecular Biology (PhD)", label: "Biochemistry and Molecular Biology (PhD)" },
        { value: "Biological Sciences (PhD)", label: "Biological Sciences (PhD)" },
        { value: "Biomedical Engineering (PhD)", label: "Biomedical Engineering (PhD)" },
        { value: "Cardiovascular and Respiratory Sciences (PhD)", label: "Cardiovascular and Respiratory Sciences (PhD)" },
        { value: "Chemical and Petroleum Engineering (PhD)", label: "Chemical and Petroleum Engineering (PhD)" },
        { value: "Chemistry (PhD)", label: "Chemistry (PhD)" },
        { value: "Civil Engineering (PhD)", label: "Civil Engineering (PhD)" },
        { value: "Clinical Psychology (PhD)", label: "Clinical Psychology (PhD)" },
        { value: "Communication and Media Studies (PhD)", label: "Communication and Media Studies (PhD)" },
        { value: "Community Health Sciences (PhD)", label: "Community Health Sciences (PhD)" },
        { value: "Computational Media Design (PhD)", label: "Computational Media Design (PhD)" },
        { value: "Computer Science (PhD)", label: "Computer Science (PhD)" },
        { value: "Counselling Psychology (PhD)", label: "Counselling Psychology (PhD)" },
        { value: "Economics (PhD)", label: "Economics (PhD)" },
        { value: "Educational Research (PhD)", label: "Educational Research (PhD)" },
        { value: "Electrical and Computer Engineering (PhD)", label: "Electrical and Computer Engineering (PhD)" },
        { value: "English (PhD)", label: "English (PhD)" },
        { value: "Environmental Design (PhD)", label: "Environmental Design (PhD)" },
        { value: "Gastrointestinal Sciences (PhD)", label: "Gastrointestinal Sciences (PhD)" },
        { value: "Geography (PhD)", label: "Geography (PhD)" },
        { value: "Geomatics Engineering (PhD)", label: "Geomatics Engineering (PhD)" },
        { value: "Geoscience (PhD)", label: "Geoscience (PhD)" },
        { value: "Greek and Roman Studies (PhD)", label: "Greek and Roman Studies (PhD)" },
        { value: "History (PhD)", label: "History (PhD)" },
        { value: "Immunology (PhD)", label: "Immunology (PhD)" },
        { value: "Kinesiology (PhD)", label: "Kinesiology (PhD)" },
        { value: "Languages, Literatures and Cultures (PhD)", label: "Languages, Literatures and Cultures (PhD)" },
        { value: "Law (PhD)", label: "Law (PhD)" },
        { value: "Linguistics (PhD)", label: "Linguistics (PhD)" },
        { value: "Management (PhD)", label: "Management (PhD)" },
        { value: "Math and Statistics (PhD)", label: "Math and Statistics (PhD)" },
        { value: "Mechanical and Manufacturing Engineering (PhD)", label: "Mechanical and Manufacturing Engineering (PhD)" },
        { value: "Medical Science (PhD)", label: "Medical Science (PhD)" },
        { value: "Microbiology and Infectious Diseases (PhD)", label: "Microbiology and Infectious Diseases (PhD)" },
        { value: "Military, Security and Strategic Studies (PhD)", label: "Military, Security and Strategic Studies (PhD)" },
        { value: "Music (PhD)", label: "Music (PhD)" },
        { value: "Neuroscience (PhD)", label: "Neuroscience (PhD)" },
        { value: "Nursing (PhD)", label: "Nursing (PhD)" },
        { value: "Philosophy (PhD)", label: "Philosophy (PhD)" },
        { value: "Physics and astronomy (PhD)", label: "Physics and astronomy (PhD)" },
        { value: "Political Science (PhD)", label: "Political Science (PhD)" },
        { value: "Psychology (PhD)", label: "Psychology (PhD)" },
        { value: "Religious Studies (PhD)", label: "Religious Studies (PhD)" },
        { value: "School and Applied Child Psychology (PhD)", label: "School and Applied Child Psychology (PhD)" },
        { value: "Social Work (PhD)", label: "Social Work (PhD)" },
        { value: "Sociology (PhD)", label: "Sociology (PhD)" },
        { value: "Sustainable Systems Engineering (PhD)", label: "Sustainable Systems Engineering (PhD)" },
        { value: "Veterinary Medical Sciences (PhD)", label: "Veterinary Medical Sciences (PhD)" },
    ],
    "Bachelors": [
        { value: 'Actuarial Science (BSc)', label: 'Actuarial Science (BSc)' },
        { value: 'Architectural Studies (BA)', label: 'Architectural Studies (BA)' },
        { value: 'Ancient and Medieval History (BA)', label: 'Ancient and Medieval History (BA)' },
        { value: 'Anthropology (BA)', label: 'Anthropology (BA)' },
        { value: 'Anthropology (BSc)', label: 'Anthropology (BSc)' },
        { value: 'Archaeology (BA)', label: 'Archaeology (BA)' },
        { value: 'Archaeology (BSc)', label: 'Archaeology (BSc)' },
        { value: 'Art History (BA)', label: 'Art History (BA)' },
        { value: 'Arts (BA)', label: 'Arts (BA)' },
        { value: 'Astrophysics (BSc)', label: 'Astrophysics (BSc)' },
        { value: 'Biochemistry (BSc)', label: 'Biochemistry (BSc)' },
        { value: 'Bioinformatics (BSc)', label: 'Bioinformatics (BSc)' },
        { value: 'Biological Sciences (BSc)', label: 'Biological Sciences (BSc)' },
        { value: 'Biology (BSc)', label: 'Biology (BSc)' },
        { value: 'Biomechanics (BSc)', label: 'Biomechanics (BSc)' },
        { value: 'Biomedical Sciences (BSc)', label: 'Biomedical Sciences (BSc)' },
        { value: 'Biotechnology (BTech)', label: 'Biotechnology (BTech)' },
        { value: 'Chemistry (BSc)', label: 'Chemistry (BSc)' },
        { value: 'Communication and Media Studies (BA)', label: 'Communication and Media Studies (BA)' },
        { value: 'Communication and Media Studies (BCMS)', label: 'Communication and Media Studies (BCMS)' },
        { value: 'Community Rehabilitation and Disability Studies (BA)', label: 'Community Rehabilitation and Disability Studies (BA)' },
        { value: 'Computer Science (BCS)', label: 'Computer Science (BCS)' },
        { value: 'Computer Science (BSc)', label: 'Computer Science (BSc)' },
        { value: 'Criminal Justice (BA)', label: 'Criminal Justice (BA)' },
        { value: 'Criminal Justice (BCJ)', label: 'Criminal Justice (BCJ)' },
        { value: 'Dance (BA)', label: 'Dance (BA)' },
        { value: 'Dance (BFA)', label: 'Dance (BFA)' },
        { value: 'Data Science (BCS)', label: 'Data Science (BCS)' },
        { value: 'Data Science (BSc)', label: 'Data Science (BSc)' },
        { value: 'Design in City Innovation (BDCI)', label: 'Design in City Innovation (BDCI)' },
        { value: 'Drama (BFA)', label: 'Drama (BFA)' },
        { value: 'East Asian Language Studies (BA)', label: 'East Asian Language Studies (BA)' },
        { value: 'East Asian Studies (BA)', label: 'East Asian Studies (BA)' },
        { value: 'Economics (BA)', label: 'Economics (BA)' },
        { value: 'Economics (BSc)', label: 'Economics (BSc)' },
        { value: 'Economics (BCom)', label: 'Economics (BCom)' },
        { value: 'Education (BEd)', label: 'Education (BEd)' },
        { value: 'English (BA)', label: 'English (BA)' },
        { value: 'Environmental Science (BSc)', label: 'Environmental Science (BSc)' },
        { value: 'Exercise and Health Physiology (BSc)', label: 'Exercise and Health Physiology (BSc)' },
        { value: 'Film Studies (BA)', label: 'Film Studies (BA)' },
        { value: 'Film Studies (BFS)', label: 'Film Studies (BFS)' },
        { value: 'French (BA)', label: 'French (BA)' },
        { value: 'Gender and Sexuality Studies (BA)', label: 'Gender and Sexuality Studies (BA)' },
        { value: 'Geography (BA)', label: 'Geography (BA)' },
        { value: 'Geography (BSc)', label: 'Geography (BSc)' },
        { value: 'Geology (BSc)', label: 'Geology (BSc)' },
        { value: 'Geophysics (BSc)', label: 'Geophysics (BSc)' },
        { value: 'Global Development Studies (BA)', label: 'Global Development Studies (BA)' },
        { value: 'Greek and Roman Studies (BA)', label: 'Greek and Roman Studies (BA)' },
        { value: 'Health and Society (BHSc)', label: 'Health and Society (BHSc)' },
        { value: 'History (BA)', label: 'History (BA)' },
        { value: 'International Indigenous Studies (BA)', label: 'International Indigenous Studies (BA)' },
        { value: 'International Relations (BA)', label: 'International Relations (BA)' },
        { value: 'Kinesiology (BKin)', label: 'Kinesiology (BKin)' },
        { value: 'Kinesiology (BSc)', label: 'Kinesiology (BSc)' },
        { value: 'Language and Culture (BA)', label: 'Language and Culture (BA)' },
        { value: 'Law and Society (BA)', label: 'Law and Society (BA)' },
        { value: 'Leadership and Coaching (BA)', label: 'Leadership and Coaching (BA)' },
        { value: 'Linguistics (BA)', label: 'Linguistics (BA)' },
        { value: 'Linguistics and Language (BA)', label: 'Linguistics and Language (BA)' },
        { value: 'Mathematic (BSc)', label: 'Mathematic (BSc)' },
        { value: 'Multidisciplinary Studies (BA)', label: 'Multidisciplinary Studies (BA)' },
        { value: 'Music (BA)', label: 'Music (BA)' },
        { value: 'Music (BMus)', label: 'Music (BMus)' },
        { value: 'Natural Sciences (BSc)', label: 'Natural Sciences (BSc)' },
        { value: 'Neuroscience (BSc)', label: 'Neuroscience (BSc)' },
        { value: 'Nursing (BScN)', label: 'Nursing (BScN)' },
        { value: 'Philosophy (BA)', label: 'Philosophy (BA)' },
        { value: 'Physics (BSc)', label: 'Physics (BSc)' },
        { value: 'Plant Biology (BSc)', label: 'Plant Biology (BSc)' },
        { value: 'Political Science (BA)', label: 'Political Science (BA)' },
        { value: 'Psychology (BA)', label: 'Psychology (BA)' },
        { value: 'Psychology (BSc)', label: 'Psychology (BSc)' },
        { value: 'Religious Studies (BA)', label: 'Religious Studies (BA)' },
        { value: 'Religious Studies (BTh)', label: 'Religious Studies (BTh)' },
        { value: 'Social Work (BSW)', label: 'Social Work (BSW)' },
        { value: 'Sociology (BA)', label: 'Sociology (BA)' },
        { value: 'Spanish (BA)', label: 'Spanish (BA)' },
        { value: 'Urban Studies (BA)', label: 'Urban Studies (BA)' },
        { value: 'Visual Studies (BFA)', label: 'Visual Studies (BFA)' },
        { value: 'Zoology (BSc)', label: 'Zoology (BSc)' },
        { value: 'Accounting (BCom)', label: 'Accounting (BCom)' },
        { value: 'Business (BCom)', label: 'Business (BCom)' },
        { value: 'Business Analytics (BCom)', label: 'Business Analytics (BCom)' },
        { value: 'Business Technology Management (BCom)', label: 'Business Technology Management (BCom)' },
        { value: 'Entrepreneurship & Innovation (BCom)', label: 'Entrepreneurship & Innovation (BCom)' },
        { value: 'Finance (BCom)', label: 'Finance (BCom)' },
        { value: 'International Business Strategy (BCom)', label: 'International Business Strategy (BCom)' },
        { value: 'Marketing (BCom)', label: 'Marketing (BCom)' },
        { value: 'Operations Management (BCom)', label: 'Operations Management (BCom)' },
        { value: 'Organizational Behaviour and Human Resources (BCom)', label: 'Organizational Behaviour and Human Resources (BCom)' },
        { value: 'Personal Financial Planning (BCom)', label: 'Personal Financial Planning (BCom)' },
        { value: 'Real Estate Studies (BCom)', label: 'Real Estate Studies (BCom)' },
        { value: 'Risk Management and Insurance (BCom)', label: 'Risk Management and Insurance (BCom)' },
        { value: 'Risk Management Insurance and Finance (BCom)', label: 'Risk Management Insurance and Finance (BCom)' },
        { value: 'Supply Chain Management (BCom)', label: 'Supply Chain Management (BCom)' },
        { value: 'Aerospace Engineering (BEng)', label: 'Aerospace Engineering (BEng)' },
        { value: 'Agricultural engineering (BEng)', label: 'Agricultural engineering (BEng)' },
        { value: 'Agricultural engineering (BEng)', label: 'Agricultural engineering (BEng)' },
        { value: 'Automotive engineering (BEng)', label: 'Automotive engineering (BEng)' },
        { value: 'Biomedical Engineering (BEng)', label: 'Biomedical Engineering (BEng)' },
        { value: 'Ceramics engineering (BEng)', label: 'Ceramics engineering (BEng)' },
        { value: 'Chemical Engineering (BEng)', label: 'Chemical Engineering (BEng)' },
        { value: 'Computer Engineering (BEng)', label: 'Computer Engineering (BEng)' },
        { value: 'Construction engineering (BEng)', label: 'Construction engineering (BEng)' },
        { value: 'Civil Engineering (BEng)', label: 'Civil Engineering (BEng)' },
        { value: 'Industrial Engineering (BEng)', label: 'Industrial Engineering (BEng)' },
        { value: 'Environmental engineering (BEng)', label: 'Environmental engineering (BEng)' },
        { value: 'Electrical Engineering (BEng)', label: 'Electrical Engineering (BEng)' },
        { value: 'Engineering Physics (BEng)', label: 'Engineering Physics (BEng)' },
        { value: 'Geomatics Engineering (BEng)', label: 'Geomatics Engineering (BEng)' },
        { value: 'Geological engineering (BEng)', label: 'Geological engineering (BEng)' },
        { value: 'Marine engineering (BEng)', label: 'Marine engineering (BEng)' },
        { value: 'Materials engineering (BEng)', label: 'Materials engineering (BEng)' },
        { value: 'Mechanical Engineering (BEng)', label: 'Mechanical Engineering (BEng)' },
        { value: 'Mining engineering (BEng)', label: 'Mining engineering (BEng)' },
        { value: 'Nuclear engineering (BEng)', label: 'Nuclear engineering (BEng)' },
        { value: 'Petroleum engineering (BEng)', label: 'Petroleum engineering (BEng)' },
        { value: 'Robotics Engineering (BEng)', label: 'Robotics Engineering (BEng)' },
        { value: 'Software Engineering (BEng)', label: 'Software Engineering (BEng)' },
        { value: 'Systems engineering (BEng)', label: 'Systems engineering (BEng)' },
        { value: 'Sustainable Systems Engineering (BEng)', label: 'Sustainable Systems Engineering (BEng)' },
        { value: 'Structural engineering (BEng)', label: 'Structural engineering (BEng)' },
        { value: 'Transportation engineering (BEng)', label: 'Transportation engineering (BEng)' }
    ]
}

module.exports = {
    degreeData
}






