import { useColorsContext } from '@utils/styles';
import React from 'react';



const TotalIcon = ({ size = 100 }) => {
    const { text } = useColorsContext();

    return (
        <svg width={size} height={size * 1.14} viewBox="0 0 72 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72 20.655C72 9.523 62.977 0.5 51.844 0.5C47.973 0.5 44.371 1.609 41.301 3.5H4C1.8 3.5 0 5.3 0 7.5V77.5C0 79.7 1.8 81.5 4 81.5H60C62.2 81.5 64 79.7 64 77.5L63.999 36.708C68.852 33.027 72 27.217 72 20.655ZM69 20.655C69 30.116 61.304 37.812 51.844 37.812C42.384 37.812 34.688 30.116 34.688 20.655C34.688 11.195 42.384 3.5 51.844 3.5C61.304 3.5 69 11.195 69 20.655ZM61 77.5C61 78.042 60.542 78.5 60 78.5H4C3.458 78.5 3 78.042 3 77.5V7.5C3 6.958 3.458 6.5 4 6.5H37.502C33.909 10.14 31.688 15.137 31.688 20.655C31.688 31.788 40.709 40.812 51.844 40.812C55.144 40.812 58.25 40.003 60.999 38.597L61 77.5Z" fill={text} />
            <path d="M44.306 30.644C44.578 30.853 44.9 30.955 45.219 30.955C45.669 30.955 46.114 30.754 46.409 30.369L59.649 13.147C60.154 12.491 60.03 11.548 59.374 11.043C58.718 10.539 57.775 10.66 57.271 11.318L44.031 28.54C43.526 29.196 43.649 30.139 44.306 30.644Z" fill={text} />
            <path d="M46.194 20.889C49.063 20.889 51.387 18.563 51.387 15.695C51.387 12.827 49.063 10.5 46.194 10.5C43.324 10.5 41 12.826 41 15.694C41 18.562 43.324 20.889 46.194 20.889ZM46.194 13.5C47.403 13.5 48.387 14.484 48.387 15.694C48.387 16.904 47.403 17.888 46.194 17.888C44.984 17.888 44 16.904 44 15.694C44 14.484 44.984 13.5 46.194 13.5Z" fill={text} />
            <path d="M52.362 26.082C52.362 28.951 54.687 31.276 57.557 31.276C60.425 31.276 62.75 28.951 62.75 26.082C62.75 23.214 60.425 20.889 57.557 20.889C54.687 20.889 52.362 23.214 52.362 26.082ZM59.75 26.082C59.75 27.292 58.766 28.276 57.557 28.276C56.347 28.276 55.362 27.292 55.362 26.082C55.362 24.873 56.347 23.889 57.557 23.889C58.766 23.889 59.75 24.873 59.75 26.082Z" fill={text} />
            <path d="M47.5 54.241C47.5 55.069 48.171 55.741 49 55.741C49.829 55.741 50.5 55.069 50.5 54.241C50.5 52.047 49.111 49.533 45.346 48.928V48.494C45.346 47.666 44.675 46.994 43.846 46.994C43.017 46.994 42.346 47.666 42.346 48.494V48.875C38.819 49.344 37.192 51.871 37.192 54.241C37.192 57.702 39.931 58.789 42.346 59.432V65.029C40.997 64.727 40.192 63.9 40.192 62.708C40.192 61.88 39.521 61.208 38.692 61.208C37.863 61.208 37.192 61.88 37.192 62.708C37.192 65.563 39.259 67.648 42.346 68.076V68.5C42.346 69.328 43.017 70 43.846 70C44.675 70 45.346 69.328 45.346 68.5V68.031C49.946 67.368 50.5 64.174 50.5 62.708C50.5 59.028 47.825 57.803 45.346 57.105V51.991C46.438 52.259 47.5 52.871 47.5 54.241ZM40.191 54.241C40.191 53.89 40.316 52.35 42.345 51.912V56.303C40.748 55.797 40.191 55.294 40.191 54.241ZM47.5 62.708C47.5 63.308 47.498 64.562 45.346 65.003V60.25C46.846 60.77 47.5 61.383 47.5 62.708Z" fill={text} />
            <path d="M27 53H15C14.171 53 13.5 53.672 13.5 54.5C13.5 55.328 14.171 56 15 56H27C27.829 56 28.5 55.328 28.5 54.5C28.5 53.672 27.829 53 27 53Z" fill={text} />
            <path d="M27 62H15C14.171 62 13.5 62.672 13.5 63.5C13.5 64.328 14.171 65 15 65H27C27.829 65 28.5 64.328 28.5 63.5C28.5 62.672 27.829 62 27 62Z" fill={text} />
            <path d="M27 20H15C14.171 20 13.5 20.672 13.5 21.5C13.5 22.328 14.171 23 15 23H27C27.829 23 28.5 22.328 28.5 21.5C28.5 20.672 27.829 20 27 20Z" fill={text} />
            <path d="M27 29H15C14.171 29 13.5 29.672 13.5 30.5C13.5 31.328 14.171 32 15 32H27C27.829 32 28.5 31.328 28.5 30.5C28.5 29.672 27.829 29 27 29Z" fill={text} />
        </svg>
    );
};

export default TotalIcon;