import { Box, Grid, Typography } from '@mui/material'
import {
    getDate,
    startOfWeek,
    format,
    add,
    addDays,
    getYear,
    subDays,
    isSameDay,
    isAfter,
    isPast,
} from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import FontAwesome6 from '@expo/vector-icons/FontAwesome6'
import { useColorsContext, useStyles } from '@utils/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AgendaDaysOfTheWeek from './AgendaDaysOfTheWeek'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'

interface Props {
    selectedDay: Date
    setSelectedDay: React.Dispatch<React.SetStateAction<Date>>
    earliestDate: Date
    setSelectedPricingIndex: React.Dispatch<React.SetStateAction<number>>
    pricingOptions: MultiSwitchToggleOption[]
    selectedPricingIndex: number
    tutor: any
    setSelectedTime: React.Dispatch<React.SetStateAction<Date>>
}

const AgendaHeader: React.FC<Props> = ({
    selectedDay,
    setSelectedDay,
    earliestDate,
    setSelectedPricingIndex,
    selectedPricingIndex,
    tutor,
    setSelectedTime,
    pricingOptions,
}) => {
    const [weekStart, setWeekStart] = React.useState(earliestDate)

    const { text, grey } = useColorsContext()
    const defaultStyles = useStyles()

    useEffect(() => {
        setWeekStart(startOfWeek(selectedDay))
    }, [selectedDay])

    const title = useMemo(() => {
        const month = format(weekStart, 'MMMM')
        const weekStartDay = getDate(weekStart)
        const weekEndDay = getDate(addDays(weekStart, 6))
        const endMonth = format(addDays(weekStart, 6), 'MMMM')
        const year = getYear(addDays(weekStart, 6))
        if (month === endMonth) {
            return `${month} ${weekStartDay} - ${weekEndDay}, ${year}`
        } else {
            return `${month} ${weekStartDay} - ${endMonth} ${weekEndDay}, ${year}`
        }
    }, [weekStart])

    const days = useMemo(() => {
        const dayArray = []
        for (let i = 0; i < 7; i++) {
            dayArray.push(addDays(weekStart, i))
        }
        return dayArray
    }, [weekStart])

    const canGoBack = isAfter(weekStart, earliestDate)
    const canGoForward = isAfter(addDays(earliestDate, 21), weekStart)

    return (
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 1,
                }}
            >
                <MultiSwitchToggle
                    options={pricingOptions || []}
                    selectedIndex={selectedPricingIndex}
                    setSelectedIndex={(index: number) => {
                        setSelectedPricingIndex(index)
                        setSelectedTime(null)
                    }}
                />
            </Box>
            <Grid container alignItems={'center'}>
                <Grid item xs={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <TouchableOpacity
                            onPress={() => {
                                setWeekStart(subDays(weekStart, 7))
                                setSelectedDay(subDays(selectedDay, 7))
                            }}
                            disabled={!canGoBack}
                        >
                            <ChevronLeftIcon
                                style={{
                                    padding: 4,
                                    color: canGoBack ? text : grey,
                                }}
                                fontSize="large"
                            />
                        </TouchableOpacity>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Typography
                        variant="subtitle1"
                        sx={{ textAlign: 'center', color: text }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <TouchableOpacity
                            onPress={() => {
                                setSelectedTime(null)
                                setWeekStart(addDays(weekStart, 7))
                                const monday = addDays(selectedDay, 7)
                                if (isPast(monday)) {
                                    setSelectedDay(new Date())
                                } else {
                                    setSelectedDay(monday)
                                }
                            }}
                            disabled={!canGoForward}
                        >
                            <ChevronRightIcon
                                style={{
                                    padding: 4,
                                    color: canGoForward ? text : grey,
                                }}
                                fontSize="large"
                            />
                        </TouchableOpacity>
                    </Box>
                </Grid>
            </Grid>
            <AgendaDaysOfTheWeek
                days={days}
                selectedDay={selectedDay}
                setSelectedDay={(day: Date) => {
                    setSelectedDay(day)
                    setSelectedTime(null)
                }}
            />
        </Grid>
    )
}

export default AgendaHeader
