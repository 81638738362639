import { useColorsContext } from '@utils/styles';
import React from 'react';

const SessionLengthIcon = ({ size = 24 }) => {

    const { text } = useColorsContext()
    return (
        <svg
            height={size}
            width={size}
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title />
            <desc />
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill={text} fillRule="nonzero" transform="translate(-13.000000, -13.000000)">
                    <path d="M25,14.25 C30.9370611,14.25 35.75,19.0629389 35.75,25 C35.75,30.9370611 30.9370611,35.75 25,35.75 C19.0629389,35.75 14.25,30.9370611 14.25,25 C14.25,19.0629389 19.0629389,14.25 25,14.25 Z M25,15.75 C19.8913661,15.75 15.75,19.8913661 15.75,25 C15.75,30.1086339 19.8913661,34.25 25,34.25 C30.1086339,34.25 34.25,30.1086339 34.25,25 C34.25,19.8913661 30.1086339,15.75 25,15.75 Z M25,17.8505859 C25.3796958,17.8505859 25.693491,18.1327398 25.7431534,18.4988154 L25.75,18.6005859 L25.75,24.679 L29.5228113,28.2676561 C29.7956294,28.5272058 29.8302094,28.943137 29.6197381,29.2420854 L29.5492384,29.327987 C29.2896887,29.6008052 28.8737575,29.6353852 28.5748091,29.4249139 L28.4889075,29.3544142 L24.4830481,25.543379 C24.3639994,25.4301202 24.2862778,25.2817667 24.2599512,25.1217926 L24.25,25 L24.25,18.6005859 C24.25,18.1863724 24.5857864,17.8505859 25,17.8505859 Z" />
                </g>
            </g>
        </svg>
    )
}



export default SessionLengthIcon;