import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import {
    Text,
    StyleSheet,
    TouchableOpacity,
    ViewStyle,
    ActivityIndicator,
} from 'react-native'
import { opacity } from 'react-native-reanimated/lib/typescript/Colors'

interface TutredButtonProps {
    onPress: () => void
    title: string
    textColor?: string
    outlined?: boolean
    icon?: React.ReactNode
    customStyles?: ViewStyle
    disabled?: boolean
    loading?: boolean
}

const TutredButton: React.FC<TutredButtonProps> = ({
    onPress,
    title,
    textColor,
    outlined = false,
    icon,
    customStyles,
    disabled = false,
    loading = false,
    ...rest
}) => {
    const defaultStyles = useStyles()
    const { primary } = useColorsContext()

    const styles = StyleSheet.create({
        defaultButton: {
            borderRadius: defaultStyles.buttonRadius,
            height: 45,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: 245,
            marginBottom: 8,
        },
        button: {
            backgroundColor: primary,
        },
        outlined: {
            border: '1px solid ' + primary,
            backgroundColor: 'transparent',
        },
        disabledButton: {
            opacity: 0.5,
        },
        buttonText: {
            color: '#fff',
            fontSize: 19,
            fontFamily: defaultStyles.titleFont,
        },
    })
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[
                { ...styles.defaultButton, opacity: disabled ? 0.5 : 1 },
                outlined ? styles.outlined : styles.button,

                customStyles,
            ]}
            disabled={disabled || loading}
            {...rest}
        >
            {loading ? (
                <ActivityIndicator />
            ) : (
                <>
                    <Text
                        style={[
                            { ...styles.buttonText },
                            {
                                color: textColor
                                    ? textColor
                                    : outlined
                                      ? primary
                                      : '#FFF',
                                marginRight: icon ? 10 : 0,
                            },
                        ]}
                    >
                        {title}
                    </Text>
                    {icon}
                </>
            )}
        </TouchableOpacity>
    )
}

export default TutredButton
