import { useFirebaseContext } from '@contexts/firebaseContext'
import { useUserContext } from '@contexts/userContext'
import { Q } from '@expo/html-elements'
import * as ImagePicker from 'expo-image-picker'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { useAlert } from 'react-alert'

const useUpdateProfilePicture = () => {
    const { user } = useUserContext()
    const { db } = useFirebaseContext()
    const alert = useAlert()

    return async (image) => {
        try {
            const resp = await fetch(image)
            const blob = await resp.blob()

            const storage = getStorage()
            const storageRef = ref(
                storage,
                `profile-images/${user.uid}.jpg`
            )
            uploadBytes(storageRef, blob).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    updateDoc(doc(db, 'users', user.uid), {
                        picture: url,
                        onboarding: arrayUnion('picture'),
                    })
                    alert.success('Profile picture saved!')
                    return url

                })
            })
        } catch (error) {
            alert.error('Something went wrong. Please try again.')
            console.error(error)
        }
    }
}

export default useUpdateProfilePicture
