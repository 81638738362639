import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
    DialogContent,
    DialogActions,
    TextField,
    Button,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
    Divider,
    LinearProgress,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import PasswordStrengthBar from 'react-password-strength-bar'

import Entypo from '@expo/vector-icons/Entypo'
import { Feather } from '@expo/vector-icons'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useMutation } from '@tanstack/react-query'
import { useAlert } from 'react-alert'
import { AxiosError } from 'axios'
import { ActivityIndicator } from 'react-native'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import AntDesign from '@expo/vector-icons/AntDesign'

interface FormData {
    password: string
    confirmPassword: string
}
interface Props {
    next: (email: string) => void
    onClose: () => void
    email: string
    code: string
}

const ResetPassword: React.FC<Props> = ({ next, onClose, email, code }) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>()

    const [passwordStrength, setPasswordStrength] = useState(0)
    const [showPassword, setShowPassword] = useState(false)

    const { primary, text } = useColorsContext()
    const defaultStyles = useStyles()
    const { authenticatedAxios } = useAxiosWithAuth()
    const alert = useAlert()
    const auth = getAuth()

    const { mutate: resetPassword, isPending } = useMutation({
        mutationFn: async (data: FormData) => {
            return authenticatedAxios.put(`/api/reset-password`, {
                email: email,
                code: code,
                newPassword: data.password,
            })
        },
        onSuccess: (response, variables) => {
            signInWithCustomToken(auth, response.data.token).finally(() => {
                onClose()
                alert.success('[Password Reset] Your password has been reset!')
            })
        },
        onError: (error: AxiosError) => {
            alert.error(`[Password Reset] ${error.response?.data?.message}`)
        },
    })

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const { password } = watch()

    const barColors = [
        theme.palette.grey[500],
        theme.palette.error.main,
        theme.palette.warning.light,
        theme.palette.primary.main,
        theme.palette.success.main,
    ]

    return (
        <>
            <DialogTitle>
                <Typography
                    variant={isSm ? 'h4' : 'h6'}
                    fontFamily={defaultStyles.titleFont}
                    textAlign={'center'}
                    sx={{ marginBottom: 1 }}
                >
                    Create a new password
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 2, mb: 2, maxWidth: 450 }}>
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Password is required',
                        minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters',
                        },
                        pattern: {
                            value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).*$/,
                            message:
                                'Password must contain at least one uppercase letter, one number, and one special character',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="New Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            error={!!errors.password}
                            helperText={'Password doesnt meet requirements'}
                            margin="normal"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <>
                                            {showPassword ? (
                                                <IconButton
                                                    tabIndex={-1}
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    <Feather
                                                        name="eye-off"
                                                        size={24}
                                                        color={text}
                                                    />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    tabIndex={-1}
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    <Feather
                                                        name="eye"
                                                        size={24}
                                                        color={text}
                                                    />
                                                </IconButton>
                                            )}
                                        </>
                                    ),
                                },
                            }}
                        />
                    )}
                />
                <PasswordStrengthBar
                    password={password}
                    className="password-strength-bar"
                    barColors={barColors}
                    scoreWordStyle={{
                        fontFamily: 'Poppins_500Medium',
                        color: barColors[passwordStrength],
                    }}
                    scoreWords={['Weak', 'Weak', 'Fair', 'Good', 'Strong']}
                    shortScoreWord="Too Short"
                    onChangeScore={(score) => setPasswordStrength(score)}
                />
                <List dense>
                    <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                        <ListItemIcon sx={{ marginRight: 0, minWidth: 24 }}>
                            <Entypo
                                name="dot-single"
                                size={24}
                                color={
                                    password?.length >= 8
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontFamily={'Poppins_500Medium'}
                                color={
                                    password?.length >= 8
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            >
                                Minimum 8 characters
                            </Typography>
                        </ListItemText>
                        {password?.length >= 8 && (
                            <AntDesign
                                name="check"
                                size={18}
                                color={theme.palette.success.main}
                            />
                        )}
                    </ListItem>
                    <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                        <ListItemIcon sx={{ marginRight: 0, minWidth: 24 }}>
                            <Entypo
                                name="dot-single"
                                size={24}
                                color={
                                    /[A-Z]/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontFamily={'Poppins_500Medium'}
                                color={
                                    /[A-Z]/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            >
                                One uppercase character
                            </Typography>
                        </ListItemText>
                        {/[A-Z]/.test(password) && (
                            <AntDesign
                                name="check"
                                size={18}
                                color={theme.palette.success.main}
                            />
                        )}
                    </ListItem>
                    <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                        <ListItemIcon sx={{ marginRight: 0, minWidth: 24 }}>
                            <Entypo
                                name="dot-single"
                                size={24}
                                color={
                                    /[!@#$%^&*(),.?":{}|<>]/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontFamily={'Poppins_500Medium'}
                                color={
                                    /[!@#$%^&*(),.?":{}|<>]/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            >
                                One special character
                            </Typography>
                        </ListItemText>
                        {/[!@#$%^&*(),.?":{}|<>]/.test(password) && (
                            <AntDesign
                                name="check"
                                size={18}
                                color={theme.palette.success.main}
                            />
                        )}
                    </ListItem>
                    <ListItem sx={{ p: 0, m: 0 }} alignItems="center">
                        <ListItemIcon sx={{ marginRight: 0, minWidth: 24 }}>
                            <Entypo
                                name="dot-single"
                                size={24}
                                color={
                                    /\d/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontFamily={'Poppins_500Medium'}
                                color={
                                    /\d/.test(password)
                                        ? theme.palette.success.main
                                        : errors.password
                                          ? theme.palette.error.main
                                          : theme.palette.grey[500]
                                }
                            >
                                One number
                            </Typography>
                        </ListItemText>
                        {/\d/.test(password) && (
                            <AntDesign
                                name="check"
                                size={18}
                                color={theme.palette.success.main}
                            />
                        )}
                    </ListItem>
                </List>
                <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Confirm Password is required',
                        validate: (value) =>
                            value === control._formValues.password ||
                            'Passwords do not match',
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Confirm New Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword?.message}
                            margin="normal"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <>
                                            {showPassword ? (
                                                <IconButton
                                                    tabIndex={-1}
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    <Feather
                                                        name="eye-off"
                                                        size={24}
                                                        color={text}
                                                    />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    tabIndex={-1}
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                >
                                                    <Feather
                                                        name="eye"
                                                        size={24}
                                                        color={text}
                                                    />
                                                </IconButton>
                                            )}
                                        </>
                                    ),
                                },
                            }}
                        />
                    )}
                />
            </DialogContent>
            <Divider variant="middle" sx={{ mt: isSm ? 2 : 1 }} />
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                }}
            >
                <Button
                    onClick={handleSubmit((data) => resetPassword(data))}
                    variant="contained"
                    fullWidth
                    disabled={isPending}
                >
                    {isPending ? (
                        <ActivityIndicator size="small" color={primary} />
                    ) : (
                        'Change Password'
                    )}
                </Button>
            </DialogActions>
        </>
    )
}

export default ResetPassword
