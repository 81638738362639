import {
    Box,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    addDays,
    addHours,
    addMinutes,
    format,
    isAfter,
    isBefore,
    isFuture,
    isPast,
    isSameMinute,
    parse,
    setHours,
    setMinutes,
    startOfWeek,
    subMinutes,
} from 'date-fns'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    TouchableOpacity,
} from 'react-native'
import AgendaHeader from './AgendaHeader'
import AgendaBody from './AgendaHeader/AgendaBody'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { decode } from 'html-entities'
import { useColorsContext } from '@utils/styles'
import { ElectricalServicesRounded } from '@mui/icons-material'
import { time } from '@config/theTime'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { MultiSwitchToggleOption } from '@components/MultiSwitchToggle/MutliSwitchToggleOption'
// import { get24HourFromTime } from '@helpers/convertAvailabilityTime'

interface Props {
    tutor: any
    setSelectedTime: (time: Date) => void
    onSelect?: () => void
    selectedTime: Date
    pricingOptions: MultiSwitchToggleOption[]
    selectedPricingIndex: number
    setSelectedPricingIndex: (index: number) => void
}

const Agenda: React.FC<Props> = ({
    tutor,
    setSelectedTime,
    onSelect,
    pricingOptions,
    selectedPricingIndex,
    setSelectedPricingIndex,
    selectedTime,
}) => {
    const earliestDate = startOfWeek(new Date())
    const [selectedDay, setSelectedDay] = React.useState(new Date())

    const [existingSessions, setExistingSessions] = React.useState([])

    const { text, primaryLight, primary } = useColorsContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const timeZoneOffset = new Date().getTimezoneOffset()
    const { db } = useFirebaseContext()

    useEffect(() => {
        if (selectedTime) {
            setSelectedDay(selectedTime)
        }
    }, [selectedTime])

    useEffect(() => {
        const start = setMinutes(setHours(selectedDay, 0), 0)

        const end = addDays(start, 1)

        const unsubscribe = onSnapshot(
            query(
                collection(db, 'sessions'),
                where('tutor', '==', tutor?.uid),
                where('status', 'in', ['confirmed', 'pending']),
                where('start', '>=', start.toISOString()),
                where('start', '<=', end.toISOString())
            ),
            (querySnapshot) => {
                let sessions = []
                querySnapshot.forEach((doc) => {
                    let session = doc.data()
                    sessions.push(session)
                })
                setExistingSessions(sessions)
            }
        )

        return unsubscribe
    }, [selectedDay, tutor?.uid])

    const selectedLength = pricingOptions?.[selectedPricingIndex]?.value?.length

    const timeSlots = useMemo(() => {
        const dayOfWeek = format(selectedDay, 'EEE')

        const availability = []
        if (!tutor?.availability) return []
        Object.keys(tutor?.availability).map((key) => {
            const newAvailability = {}
            if (!tutor?.availability[key]) return
            Object.keys(tutor?.availability[key]).map((key2) => {
                if (!tutor?.availability[key][key2]) return
                const time = tutor?.availability[key][key2]

                let newDate = new Date(time)
                newDate = addMinutes(newDate, -timeZoneOffset)
                const dateString = newDate.toUTCString()
                // console.log('dateString', dateString)
                if (dateString.includes(dayOfWeek)) {
                    availability.push({
                        time: dateString,
                        type: key2,
                    })
                }
            })
            if (Object.keys(newAvailability).length > 0) {
                availability.push(newAvailability)
            }
        })

        availability.sort((a, b) => a.time.localeCompare(b.time))

        const availabilityArr = []

        for (let i = 0; i < availability.length; i++) {
            // console.log('avail', availability[i], i)
            if (availability[i].type === 'end' && i === 0) {
                availabilityArr.push({
                    start: setHours(new Date(availability[i].time), 0),
                    end: new Date(availability[i].time),
                })
            } else if (
                availability[i].type === 'start' &&
                availability[i + 1].type === 'end'
            ) {
                availabilityArr.push({
                    start: new Date(availability[i].time),
                    end: new Date(availability[i + 1].time),
                })
            } else if (i === availability.length - 1) {
                if (availability[i].type === 'start') {
                    availabilityArr.push({
                        start: new Date(availability[i].time),
                        end: setHours(new Date(availability[i].time), 24),
                    })
                }
            }
        }

        let slots = []

        const advancedNotice = tutor?.advanceNotice ? tutor?.advanceNotice : 60

        //Create slot for each day with availability
        availabilityArr.forEach((availability) => {
            const startTime = availability.start
            const endTime = availability.end

            for (
                let i = startTime.getUTCHours();
                i <= endTime.getUTCHours();
                i++
            ) {
                for (let j = 0; j < 60 / 30; j++) {
                    // console.log('create time', i, j * 30)
                    let timeSlot = new Date(selectedDay).setHours(i)
                    timeSlot = new Date(timeSlot).setMinutes(j * 30)

                    let endTimeSlot = new Date(selectedDay).setHours(
                        endTime.getUTCHours()
                    )
                    endTimeSlot = new Date(endTimeSlot).setMinutes(
                        endTime.getUTCMinutes()
                    )

                    // only add sessions tha are in the future and respect the tutors advance notice
                    // only add slots that will not exceed the endTime at completion.

                    if (
                        !isAfter(
                            new Date(),
                            addMinutes(new Date(timeSlot), -advancedNotice)
                        ) &&
                        !isAfter(
                            new Date(timeSlot),
                            addMinutes(endTimeSlot, -selectedLength)
                        )
                    ) {
                        slots.push(timeSlot)
                    }
                }
            }
        })

        // filters out slots conflict with existing sessions and buffer
        slots = slots.filter((time) => {
            const buffer = tutor?.bufferPeriod
            const newTime = new Date(time)
            const shouldFilter = existingSessions.some((session) => {
                const bufferTime = Number(buffer) + Number(selectedLength)
                const startCompare = subMinutes(
                    new Date(session.start),
                    bufferTime
                )
                const endCompare = addMinutes(new Date(session.end), buffer)
                return (
                    isAfter(newTime, startCompare) &&
                    isBefore(newTime, endCompare)
                )
            })
            return !shouldFilter
        })

        return slots
    }, [
        selectedDay,
        tutor?.availability,
        earliestDate,
        existingSessions,
        selectedPricingIndex,
        selectedLength,
        selectedTime,
    ])

    const renderItem = useCallback(
        ({ item }) => {
            return (
                <Box
                    sx={{
                        width: '100%',
                        marginBottom: 2,
                        padding: 1,
                        borderRadius: 2,
                        backgroundColor:
                            selectedTime === item ? primaryLight : '',
                        border:
                            selectedTime === item
                                ? `3px solid ${primary}`
                                : '3px solid rgba(0, 0, 0, 0.23)',
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            setSelectedTime(item)
                            onSelect && onSelect()
                        }}
                    >
                        <Typography
                            variant="body1"
                            textAlign="center"
                            color={selectedTime === item ? primary : ''}
                        >
                            {format(item, 'h:mm a')}
                        </Typography>
                    </TouchableOpacity>
                </Box>
            )
        },
        [timeZoneOffset, selectedTime, setSelectedTime, onSelect]
    )

    const keyExtractor = useCallback((item) => {
        return item.toString()
    }, [])

    const header = useMemo(() => {
        return (
            <AgendaHeader
                tutor={tutor}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                earliestDate={earliestDate}
                pricingOptions={pricingOptions}
                setSelectedPricingIndex={setSelectedPricingIndex}
                selectedPricingIndex={selectedPricingIndex}
                setSelectedTime={setSelectedTime}
            />
        )
    }, [
        tutor,
        selectedDay,
        earliestDate,
        selectedPricingIndex,
        setSelectedPricingIndex,
        setSelectedDay,
    ])

    return (
        <FlatList
            key={isMd ? 'md' : 'sm'}
            style={{
                flex: 1,
                minHeight: 400,
            }}
            ListHeaderComponent={header}
            columnWrapperStyle={{ columnGap: 10 }}
            horizontal={false}
            numColumns={isMd ? 4 : 2}
            data={timeSlots}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ListEmptyComponent={
                <Box sx={{ padding: 2, color: text }}>
                    <Typography variant="body1" textAlign="center">
                        There are no sessions available for this day.
                    </Typography>
                </Box>
            }
        />
    )
}

export default Agenda
