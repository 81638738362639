import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useUserContext } from '@contexts/userContext'
import {
    Box,
    Button,
    Divider,
    Grid2,
    List,
    ListItem,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Image, TouchableOpacity } from 'react-native'
import {
    AntDesign,
    Feather,
    FontAwesome,
    FontAwesome5,
    FontAwesome6,
    MaterialIcons,
} from '@expo/vector-icons'
import useUpdateProfilePicture from '@screens/loggedIn/Profile/useUpdateProfilePicture'
import { useColorsContext, useStyles } from '@utils/styles'
import formatName from '@helpers/formatName'
import example1 from '@assets/images/onboarding/example-1.jpg'
import example2 from '@assets/images/onboarding/example-2.jpg'
import example3 from '@assets/images/onboarding/example-3.jpg'
import UploadPhotoDialog from '@components/UploadPhotoDialog'
import TakePhotoIcon from '@icons/TakePhotoIcon'
import TutorList from '@screens/TutorList'
import { TutorListCard } from '@components/TutorListCard'
import { useNavigation } from '@react-navigation/native'
import ProfilePreviewIcon from '@icons/ProfilePreviewIcon'
import PhotoGuidelinesIcon from '@icons/PhotoGuidelinesIcon'
import ClearImageIcon from '@icons/ClearImageIcon'
import RecentPhotoIcon from '@icons/RecentPhotoIcon'
import NaturalExpressionIcon from '@icons/NaturalExpressionIcon'
import CenteredIcon from '@icons/CenteredIcon'
import PlainBackgroundIcon from '@icons/PlainBackgroundIcon'
import VisibleFaceIcon from '@icons/VisibleFaceIcon'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'

const ProfilePhoto = ({ setStep }) => {
    const defaultStyles = useStyles()
    const { extendedUser } = useUserContext()
    const [loading, setLoading] = useState(false)
    const { text, primaryExtraLight } = useColorsContext()
    const [error, setError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const navigation = useNavigation()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    useEffect(() => {
        if (extendedUser?.picture) {
            setError(false)
        }
    }, [extendedUser?.picture])

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <UploadPhotoDialog visible={showModal} setVisible={setShowModal} />
            {/* header */}
            <OnboardingSectionHeader
                title="Profile Photo"
                subtitle="Say cheese! Upload a photo of yourself and some life to your profile."
                icon={<TakePhotoIcon size={42} />}
            />

            {/* Main Content */}
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            px: 2,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 2,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ProfilePreviewIcon size={32} />
                            <Typography
                                variant="h5"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ ml: 1 }}
                            >
                                Preview
                            </Typography>
                        </Box>

                        <TutorListCard
                            customAvatar={
                                !extendedUser?.picture && (
                                    <Box
                                        sx={{
                                            width: isMd ? 82 : 64,
                                            height: isMd ? 82 : 64,
                                            borderRadius: isMd ? 7 : 5,
                                            cursor: 'pointer',
                                            borderStyle: 'dashed',
                                            mr: 1,
                                            backgroundColor: '#F3F2F0',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => {
                                            setShowModal(true)
                                        }}
                                    >
                                        <Typography
                                            lineHeight={1}
                                            fontSize={isMd ? 10 : 8}
                                        >
                                            JPG or PNG
                                        </Typography>
                                        <Typography>+</Typography>
                                        <Typography
                                            lineHeight={1}
                                            fontSize={isMd ? 10 : 8}
                                        >
                                            Max 5MB
                                        </Typography>
                                    </Box>
                                )
                            }
                            navigation={navigation}
                            tutor={extendedUser}
                            navigateToChat={() => {}}
                            small={true}
                        />
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            paddingLeft: 2,
                            paddingRight: 2,
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowModal(true)}
                            >
                                Upload
                            </Button>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{ mb: 0.5 }}>
                                    <PhotoGuidelinesIcon size={28} />
                                </Box>
                                <Typography
                                    variant="h5"
                                    sx={{ ml: 1 }}
                                    fontFamily={defaultStyles.boldFont}
                                >
                                    Photo Guidelines
                                </Typography>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Divider
                                    variant="middle"
                                    color={text}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                />
                            </Box>
                            <Grid2 container spacing={3} sx={{ pb: 4, pt: 2 }}>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <VisibleFaceIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            sx={{ fontSize: 16 }}
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Visible Face
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            No hats, sunglasses, or shadows.
                                        </Typography>
                                    </Box>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <PlainBackgroundIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Plain background
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            Keep it simple and uncluttered.
                                        </Typography>
                                    </Box>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <CenteredIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Centered
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            Face should fill most of the frame.
                                        </Typography>
                                    </Box>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <ClearImageIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Clear image
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            Use a high-resolution, non-blurry
                                            photo.
                                        </Typography>
                                    </Box>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <NaturalExpressionIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Natural Expression
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            Avoid filters and exaggerated
                                            expressions.
                                        </Typography>
                                    </Box>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <RecentPhotoIcon size={28} />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            variant="body1"
                                            fontFamily={defaultStyles.titleFont}
                                        >
                                            Recent photo
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontFamily={
                                                defaultStyles.regularFont
                                            }
                                        >
                                            Must reflect your current
                                            appearance.
                                        </Typography>
                                    </Box>
                                </Grid2>
                            </Grid2>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* footer */}
            <OnboardingFooter
                step={5}
                onNext={() => {
                    if (extendedUser?.picture) setStep(6)
                    else setError(true)
                }}
                onPrev={() => {
                    setStep(4)
                }}
                loading={loading}
            />
        </Box>
    )
}

export default ProfilePhoto
