import { useColorsContext } from '@utils/styles';
import React from 'react';


const PaymentMethodIcon = ({ size = 47 }) => {
    const { text } = useColorsContext();


    return (
        <svg width={size} height={size * (38 / 47)} viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 31H4C2.3 31 1 29.7 1 28V4C1 2.3 2.3 1 4 1H40C41.7 1 43 2.3 43 4V16" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 21H14" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 21H22" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 8H43" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 12H43" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 37C41.5228 37 46 32.5228 46 27C46 21.4772 41.5228 17 36 17C30.4772 17 26 21.4772 26 27C26 32.5228 30.4772 37 36 37Z" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.7 26.9L35 30.2L40.2 24.8" stroke={text} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default PaymentMethodIcon;