const { languages } = require("./languages");


const subjectData = {
  "Accounting": {
    specialties: ["Financial Accounting", "Managerial Accounting", "Tax Accounting", "Auditing", "Cost Accounting", "Forensic Accounting", "Governmental Accounting", "Accounting Information Systems", "International Accounting", "Accounting Ethics"],
    icon: require("@assets/images/subjects/Accounting.svg")
  },
  "Actuarial Science": {
    specialties: ["Life Insurance", "Health Insurance", "Pension and Retirement Benefits", "Property and Casualty Insurance", "Risk Management", "Actuarial Mathematics", "Financial Modeling", "Actuarial Statistics", "Investment and Finance", "Probability Theory"],
    icon: require("@assets/images/subjects/Actuarial-Science.svg")
  },
  "Agriculture": {
    specialties: ["Agronomy", "Horticulture", "Animal Science", "Soil Science", "Crop Science", "Agricultural Economics", "Agricultural Engineering", "Agricultural Biotechnology", "Sustainable Agriculture", "Agricultural Business Management", "Agricultural Extension and Education"],
    icon: require("@assets/images/subjects/Agriculture.svg")
  },
  "Anthropology": {
    specialties: ["Cultural Anthropology", "Physical Anthropology", "Archaeology", "Linguistic Anthropology", "Medical Anthropology", "Forensic Anthropology", "Environmental Anthropology", "Applied Anthropology", "Visual Anthropology", "Anthropological Theory"],
    icon: require("@assets/images/subjects/Anthropology.svg")
  },
  "Architecture": {
    specialties: ["Architectural Design", "Urban Planning", "Landscape Architecture", "Interior Design", "Sustainable Architecture", "Architectural History", "Building Technology", "Architectural Engineering", "Computer-Aided Design (CAD)", "Architectural Theory"],
    icon: require("@assets/images/subjects/Architecture.svg")
  },
  "Art": {
    specialties: ["Drawing", "Painting", "Sculpture", "Printmaking", "Art History", "Ceramics", "Pottery", "Textile Design"],
    icon: require("@assets/images/subjects/Art.svg")
  },
  "Astronomy": {
    specialties: ["Astrophysics", "Planetary Science", "Cosmology", "Stellar Astronomy", "Galactic Astronomy", "Observational Astronomy", "Radio Astronomy", "Space Science", "Astrochemistry"],
    icon: require("@assets/images/subjects/Astronomy.svg")
  },
  "Biology": {
    specialties: ["Cell Biology", "Molecular Biology", "Genetics", "Ecology", "Evolutionary Biology", "Microbiology", "Zoology", "Botany", "Physiology", "Marine Biology", "Biotechnology", "Immunology", "Neuroscience", "Biochemistry", "Developmental Biology"],
    icon: require("@assets/images/subjects/Biology.svg")
  },
  "Business Administration": {
    specialties: ["Organizational Behavior", "Human Resources Management", "Operations Management", "Strategic Management", "International Business", "Business Communication", "Supply Chain Management", "Project Management"],
    icon: require("@assets/images/subjects/Business-Administration.svg")
  },
  "Chemistry": {
    specialties: ["General Chemistry", "Organic Chemistry", "Inorganic Chemistry", "Physical Chemistry", "Analytical Chemistry", "Biochemistry", "Environmental Chemistry", "Computational Chemistry", "Medicinal Chemistry", "Polymer Chemistry", "Chemical Thermodynamics", "Quantum Chemistry"],
    icon: require("@assets/images/subjects/Chemistry.svg")
  },
  "Communication & Media Studies": {
    specialties: ["Public Speaking", "Interpersonal Communication", "Mass Communication", "Corporate Communication", "Rhetoric and Persuasion", "Communication Theory", "Journalism", "Digital Communication", "Visual Communication", "Film Production", "Film Theory", "Post-Production Editing", "Digital Media Production", "Photography"],
    icon: require("@assets/images/subjects/Communication-Media-Studies.svg")
  },
  "Computer Science": {
    specialties: ["Data Structures and Algorithms", "Computer Networks", "Operating Systems", "Database Systems", "Software Engineering", "Artificial Intelligence", "Machine Learning", "Computer Graphics", "Cybersecurity", "Web Development", "Distributed Systems", "Computational Theory", "Data Mining", "Big Data Analytics", "Data Visualization", "Natural Language Processing", "Deep Learning", "Predictive Modeling", "Data Warehousing"],
    icon: require("@assets/images/subjects/Computer-Science.svg")
  },
  "Criminology": {
    specialties: ["Criminological Theory", "Law Enforcement", "Corrections", "Juvenile Justice", "Forensic Science", "Criminal Investigation", "Victimology", "Cybercrime", "Criminal Profiling"],
    icon: require("@assets/images/subjects/Criminology.svg")
  },
  "Culinary Arts": {
    specialties: ["Baking and Pastry Arts", "Food Science and Safety", "International Cuisine", "Nutrition", "Wine and Beverage Studies"],
    icon: require("@assets/images/subjects/Culinary-Arts.svg")
  },
  "Design": {
    specialties: ["Graphic Design", "Fashion Design", "Industrial Design", "Interior Design", "UX", "UI", "Design Thinking", "Web Design", "Animation and Multimedia", "Product Design", "Sound Design"],
    icon: require("@assets/images/subjects/Design.svg")
  },
  "Dentistry": {
    specialties: ["General Dentistry", "Orthodontics", "Periodontics", "Oral and Maxillofacial Surgery", "Prosthodontics", "Endodontics", "Pediatric Dentistry", "Cosmetic Dentistry", "Oral Pathology", "Public Health Dentistry"],
    icon: require("@assets/images/subjects/Dentistry.svg")
  },
  "Earth Science": {
    specialties: ["Geology", "Meteorology", "Oceanography", "Paleontology", "Geophysics", "Geochemistry", "Climatology", "Soil Science", "Hydrology", "Volcanology", "Seismology"],
    icon: require("@assets/images/subjects/Earth-Science.svg")
  },
  "Environmental Science": {
    specialties: ["Environmental Policy and Management", "Climate Change and Sustainability", "Renewable Energy", "Natural Resource Management", "Waste Management and Pollution Control", "Conservation Biology", "Sustainable Development", "Environmental Toxicology", "Ecosystem Restoration", "Urban Ecology"],
    icon: require("@assets/images/subjects/Environmental-Science.svg")
  },
  "Economics": {
    specialties: ["Microeconomics", "Macroeconomics", "Econometrics", "International Economics", "International Trade", "Development Economics", "Behavioral Economics", "Public Economics", "Labor Economics", "Financial Economics", "Environmental Economics", "Industrial Organization", "Health Economics", "Game Theory"],
    icon: require("@assets/images/subjects/Economics.svg")
  },
  "Education": {
    specialties: ["Curriculum Development", "Educational Psychology", "Special Education", "Educational Technology", "Early Childhood Education", "Adult Education", "Educational Administration", "Counseling and Guidance", "ESL Education", "Assessment and Evaluation", "Instructional Design", "Educational Policy", "Physical Education"],
    icon: require("@assets/images/subjects/Education.svg")
  },
  "Engineering": {
    specialties: ["Mechanical Engineering", "Electrical Engineering", "Civil Engineering", "Chemical Engineering", "Computer Engineering", "Aerospace Engineering", "Biomedical Engineering", "Environmental Engineering", "Industrial Engineering", "Materials Science & Engineering", "Nuclear Engineering", "Petroleum Engineering", "Mechatronics", "Systems Engineering", "Robotics"],
    icon: require("@assets/images/subjects/Engineering.svg")
  },
  "English": {
    specialties: ["Literature Analysis", "Creative Writing", "English Grammar", "Composition and Rhetoric", "ESL (English as a Second Language)", "Technical Writing", "Linguistics", "Literary Theory", "Poetry", "Comparative Literature", "American Literature", "British Literature", "World Literature"],
    icon: require("@assets/images/subjects/English.svg")
  },
  "Entrepreneurship": {
    specialties: ["Business Planning and Strategy", "Venture Capital and Funding", "Innovation and Product Development", "Social Entrepreneurship", "E-commerce", "Leadership and Team Building", "Global Entrepreneurship", "Franchise Development", "Scalability and Growth Strategy", "Startup Operations and Management"],
    icon: require("@assets/images/subjects/Entrepreneurship.svg")
  },
  "Finance": {
    specialties: ["Corporate Finance", "Asset Management", "Investment Analysis", "Financial Markets and Institutions", "Risk Management", "International Finance", "Financial Modeling", "Behavioral Finance", "Personal Finance", "Trading & Portfolio Management", "Financial Planning", "Financial Statement Analysis"],
    icon: require("@assets/images/subjects/Finance.svg")
  },
  "Geography": {
    specialties: ["Physical Geography", "Human Geography", "Geographic Information Systems (GIS)", "Cartography", "Environmental Geography", "Urban Geography", "Economic Geography", "Cultural Geography", "Climatology", "Geopolitics", "Population Geography"],
    icon: require("@assets/images/subjects/Geography.svg")
  },
  "History": {
    specialties: ["World History", "American History", "European History", "Ancient History", "Medieval History", "Modern History", "Military History", "Cultural History", "Economic History", "Social History", "Historiography", "History of Science and Technology", "Middle Eastern History", "Asian History", "Latin American History", "African History"],
    icon: require("@assets/images/subjects/History.svg")
  },
  "Information Technology": {
    specialties: ["Network Administration", "Systems Analysis and Design", "IT Project Management", "Cybersecurity", "Cloud Computing", "Database Management", "Data Integration", "Information Systems", "IT Governance", "Software Quality Assurance", "IT Service Management"],
    icon: require("@assets/images/subjects/Information-Technology.svg")
  },
  "Kinesiology": {
    specialties: ["Exercise Physiology", "Biomechanics", "Motor Control and Learning", "Sport Psychology", "Athletic Training", "Physical Therapy", "Sports Nutrition", "Rehabilitation Sciences", "Adapted Physical Activity", "Fitness Assessment and Programming"],
    icon: require("@assets/images/subjects/Kinesiology.svg")
  },
  "Law": {
    specialties: ["Constitutional Law", "Criminal Law", "Civil Law", "Corporate Law", "International Law", "Intellectual Property Law", "Environmental Law", "Family Law", "Tax Law", "Labor and Employment Law", "Contract Law", "Human Rights Law", "Administrative Law", "Tort Law", "Real Estate Law"],
    icon: require("@assets/images/subjects/Law.svg")
  },
  "Marketing": {
    specialties: ["Digital Marketing", "Marketing Research", "Consumer Behavior", "Brand Management", "International Marketing", "Marketing Strategy", "Advertising", "Public Relations", "Social Media Marketing", "Sales Management", "Product Development", "Services Marketing"],
    icon: require("@assets/images/subjects/Marketing.svg")
  },
  "Mathematics": {
    specialties: ["Algebra", "Calculus", "Geometry", "Trigonometry", "Number Theory", "Discrete Mathematics", "Differential Equations", "Linear Algebra", "Real Analysis", "Complex Analysis", "Topology", "Mathematical Logic", "Numerical Analysis"],
    icon: require("@assets/images/subjects/Mathematics.svg")
  },
  "Medicine": {
    specialties: ["Anatomy", "Physiology", "Pathology", "Pharmacology", "Internal Medicine", "General Surgery", "Pediatrics", "Obstetrics and Gynecology", "Psychiatry", "Dermatology", "Neurology", "Radiology", "Oncology", "Emergency Medicine", "Family Medicine"],
    icon: require("@assets/images/subjects/Medicine.svg")
  },
  "Music": {
    specialties: ["Music Theory", "Vocal Training", "Singing (Voice Techniques)", "Music History", "Composition", "Conducting", "Music Production", "Ethnomusicology", "Music Technology", "Music Education", "Opera"],
    icon: require("@assets/images/subjects/Music.svg")
  },
  "Musical Instruments": {
    specialties: ["Saxophone", "Piano", "Guitar", "Violin", "Ukulele", "Harp", "Drums", "Trumpet", "Trombone", "Flute", "Clarinet", "Organ", "Banjo", "Mandolin"],
    icon: require("@assets/images/subjects/Musical-Instruments.svg")
  },
  "Nursing": {
    specialties: ["Clinical Nursing", "Pediatric Nursing", "Geriatric Nursing", "Psychiatric Nursing", "Nursing Administration", "Nursing Research", "Critical Care Nursing", "Community Health Nursing", "Nursing Education"],
    icon: require("@assets/images/subjects/Nursing.svg")
  },
  "Optometry": {
    specialties: ["Ocular Disease", "Pediatric Optometry", "Geriatric Optometry", "Contact Lenses", "Low Vision", "Vision Therapy", "Sports Vision", "Binocular Vision", "Refractive Surgery", "Neuro-Optometry"],
    icon: require("@assets/images/subjects/Optometry.svg")
  },
  "Philosophy": {
    specialties: ["Ethics", "Logic", "Metaphysics", "Epistemology", "Philosophy of Mind", "Political Philosophy", "Aesthetics", "Philosophy of Science", "Philosophy of Language", "Ancient Philosophy", "Modern Philosophy", "Continental Philosophy", "Analytic Philosophy", "Philosophy of Religion"],
    icon: require("@assets/images/subjects/Philosophy.svg")
  },
  "Physics": {
    specialties: ["Classical Mechanics", "Electromagnetism", "Thermodynamics", "Quantum Mechanics", "Relativity", "Optics", "Nuclear Physics", "Particle Physics", "Astrophysics", "Condensed Matter Physics", "Statistical Mechanics", "Computational Physics", "Biophysics", "Mathematical Physics"],
    icon: require("@assets/images/subjects/Physics.svg")
  },
  "Political Science": {
    specialties: ["International Politics", "Comparative Politics", "Political Theory", "Public Administration", "Public Policy", "Political Economy", "Political Sociology", "American Politics", "Canadian Politics", "British Politics", "Security Studies"],
    icon: require("@assets/images/subjects/Political-Science.svg")
  },
  "Psychology": {
    specialties: ["Clinical Psychology", "Cognitive Psychology", "Developmental Psychology", "Social Psychology", "Industrial-Organizational Psychology", "Educational Psychology", "Neuropsychology", "Behavioral Psychology", "Forensic Psychology", "Health Psychology", "Counseling Psychology", "Personality Psychology"],
    icon: require("@assets/images/subjects/Psychology.svg")
  },
  "Public Health": {
    specialties: ["Epidemiology", "Biostatistics", "Global Health", "Health Policy and Management", "Community Health", "Environmental Health", "Occupational Health", "Health Promotion", "Public Health Nutrition", "Health Economics"],
    icon: require("@assets/images/subjects/Public-Health.svg")
  },
  "Real Estate": {
    specialties: ["Real Estate Finance", "Commercial Real Estate", "Property Management", "Real Estate Law", "Real Estate Appraisal", "Urban Planning", "Real Estate Investment", "Real Estate Marketing", "Real Estate Development", "Mortgage Banking", "Real Estate Economics"],
    icon: require("@assets/images/subjects/Real-Estate.svg")
  },
  "Religious Studies": {
    specialties: ["Comparative Religion", "Theology", "Biblical Studies", "Confucian Studies", "Eastern Religions", "Religious Philosophy", "Islamic Studies", "Judaic Studies", "Hindu Studies", "Buddhist Studies", "Taoist Studies", "Sikh Studies", "Shinto Studies"],
    icon: require("@assets/images/subjects/Religious-Studies.svg")
  },
  "Physiotherapy": {
    specialties: ["Orthopedic & Musculoskeletal Physiotherapy", "Sports & Injury Rehabilitation", "Neurological Physiotherapy", "Pediatric & Geriatric Physiotherapy", "Cardiopulmonary Physiotherapy", "Pelvic Health & Women’s Physiotherapy", "Pain Management & Manual Therapy", "Post-Surgical Rehabilitation", "Kinesiology & Exercise Therapy", "Balance & Vestibular Rehabilitation", "Pre/Post-Natal Physiotherapy", "Workplace Injury Prevention", "Acupuncture & Dry Needling", "Aquatic Therapy"],
    icon: require("@assets/images/subjects/Physiotherapy.svg")
  },
  "Programming": {
    specialties: ["Python", "Java", "C++", "JavaScript", "C#", "Ruby", "PHP", "Swift", "Kotlin", "Go", "Rust", "SQL", "R", "MATLAB", "Perl", "Objective-C", "Scala", "Haskell", "Visual Basic", "C", "HTML/CSS"],
    icon: require("@assets/images/subjects/Programming.svg")
  },
  "Social Work": {
    specialties: ["Social Welfare Policy", "Human Behavior and the Social Environment", "Clinical Social Work", "Community Organizing", "Child and Family Services", "Mental Health Counseling", "Substance Abuse Counseling", "Gerontology", "School Social Work", "Crisis Intervention"],
    icon: require("@assets/images/subjects/Social-Work.svg")
  },
  "Sociology": {
    specialties: ["Social Theory", "Social Stratification", "Urban Sociology", "Sociology of Education", "Sociology of Family", "Sociology of Religion", "Environmental Sociology", "Demography", "Postcolonial Studies", "Social Justice Studies", "Race, Ethnicity, and Social Inequality", "Gender and Sexuality Studies"],
    icon: require("@assets/images/subjects/Sociology.svg")
  },
  // "Speech Sciences": {
  //   specialties: ["Phonetics and Phonology", "Speech Production and Perception", "Speech Therapy", "Acoustic Phonetics", "Language Development and Acquisition", "Audiology and Hearing Science", "Speech-Language Pathology", "Voice and Resonance Studies", "Speech Technology"],
  //   icon: require("@assets/images/subjects/Speech-Sciences.svg")
  // },
  "Statistics": {
    specialties: ["Descriptive Statistics", "Probability Theory", "Inferential Statistics", "Regression Analysis", "Bayesian Statistics", "Multivariate Statistics", "Design of Experiments", "Time Series Analysis", "Non-parametric Statistics", "Survey Sampling", "Statistical Software and Data Analysis"],
    icon: require("@assets/images/subjects/Statistics.svg")
  },
  "Theater": {
    specialties: ["Acting", "Cinematography", "Directing", "Playwriting", "Theater History", "Stage Design", "Musical Theater", "Costume Design", "Theater Production", "Dramatic Literature", "Dance", "Screenwriting"],
    icon: require("@assets/images/subjects/Theater.svg")
  },
  "Veterinary Medicine": {
    specialties: ["Veterinary Medicine", "Animal Anatomy and Physiology", "Veterinary Pathology", "Veterinary Pharmacology", "Animal Nutrition", "Veterinary Surgery", "Veterinary Public Health", "Animal Behavior", "Wildlife Medicine", "Veterinary Epidemiology"],
    icon: require("@assets/images/subjects/Veterinary-Medicine.svg")
  },
  "Language": {
    specialties: ["ESL(English as a Second Language", "American Sign Language", "Linguistics"],
    icon: require("@assets/images/subjects/Language.svg")
  }
};


const subjectIcons = {}
Object.keys(subjectData).forEach((subject) => {
  subjectIcons[subject] = subjectData[subject].icon
})
const specialtyIcons = {}
Object.keys(subjectData).forEach((subject) => {
  subjectData[subject].specialties.forEach((specialty) => {
    specialtyIcons[specialty] = subjectData[subject].icon
  })
})
const subjects = Object.keys(subjectData)
const specialties = {}
Object.keys(subjectData).forEach((subject) => {
  specialties[subject] = subjectData[subject].specialties
})


console.log('subjectIcons', subjectIcons)

module.exports = {
  subjects,
  specialties,
  subjectIcons,
  specialtyIcons,
  subjectData,
}