// Package imports
import { AntDesign } from "@expo/vector-icons";
import { CloseOutlined } from "@mui/icons-material";
import { Picker } from "@react-native-picker/picker";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
	Modal,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js"; // Import Stripe hooks

// File imports
import { useUserContext } from "../contexts/userContext";
import { useColorsContext, useStyles } from "../utils/styles";


const hostname = process.env.HOSTNAME

export const StripeAccountModal = ({
	display,
	setDisplay,
	settings,
	pullData,
}) => {
	// Init alert
	const customAlert = useAlert();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	// Stripe hooks
	const stripe = useStripe();
	const elements = useElements();

	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	if (darkMode) {
		// Import darkmode CSS
		require("../DarkSearchInput.css");
	} else {
		// Import lightmode CSS
		require("../LightSearchInput.css");
	}

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const { user, accountId } = useUserContext();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [type, setType] = useState("");
	const [routingNumber, setRoutingNumber] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [terms, setTerms] = useState(false);

	// handle submit
	const handleSubmit = async (e) => {
		setLoading(true);

		// Validate form
		if (
			!firstName ||
			!lastName ||
			!address ||
			!city ||
			!province ||
			!postalCode ||
			!terms ||
			!country
		) {
			setError("Please fill in all fields.");
			setLoading(false);
			return;
		}

		if (type === "card") {
			const cardElement = elements.getElement(CardNumberElement);

			if (!cardElement) {
				setError("Card details are not valid.");
				setLoading(false);
				return;
			}

			const { error, token } = await stripe.createToken(cardElement, {
				currency: 'cad',
			});

			if (error) {
				setError(error.message);
				setLoading(false);
				return;
			}

			// Submit form
			const formData = {
				token: token.id,
				accountType: type,
				firstName: firstName,
				lastName: lastName,
				addressLine1: address,
				city: city,
				country: country,
				postalCode: postalCode,
				province: province,
			};

			submitForm(formData);
		} else if (type === "bank") {
			if (!routingNumber || !accountNumber) {
				setError("Please fill in all fields.");
				setLoading(false);
				return;
			}

			// Submit form
			const formData = {
				token: null, // Add logic for bank token if needed
				accountType: type,
				routingNumber: routingNumber,
				accountNumber: accountNumber,
				firstName: firstName,
				lastName: lastName,
				addressLine1: address,
				city: city,
				country: country,
				postalCode: postalCode,
				province: province,
			};

			submitForm(formData);
		}
	};

	const submitForm = (formData) => {

		// Post request
		fetch(`${hostname}/api/create-account-payment`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user.accessToken,
			},
			body: JSON.stringify(formData),
		})
			.then((res) => {
				if (!res.ok) {
					console.error("HTTP error! Status:", res.status);
				}
				return res.json();
			})
			.then((data) => {
				if (data.success) {
					// Dismiss modal
					setDisplay(false);
					customAlert.success(
						"[Payment Method Connected] You have successfully connected your payment method."
					);
					if (pullData) {
						pullData();
					}
					return;
				} else {
					// Display error in modal
					console.error("Error in response data:", data.error);
					setError(data.error);
					setLoading(false);
					return;
				}
			})
			.catch((err) => {
				// Display error in modal
				console.error("Unknown error occurred:", err);
				setLoading(false);
				setError("Unknown error occurred. Please try again later.");
				// Clear error
				setTimeout(() => {
					setError("");
				}, 5000);
			});
	};

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			paddingTop: 30,
			paddingBottom: 30,
			backgroundColor: background,
			display: "flex",
			padding: Platform.OS === "web" ? 30 : null,
			width: "100%",
			margin: Platform.OS === "web" ? "auto" : null,
		},
		header: {
			marginTop: 30,
			flexDirection: "column",
			display: "flex",
		},

		title: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		centeredView: {
			flex: 1,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			justifyContent: "center",
			alignItems: "center",
		},
		modalView: {
			backgroundColor: background,
			borderRadius: defaultStyles.radius,
			border: `1px solid ${border}`,
			width: isXl
				? "80%"
				: isLg
					? "80%"
					: isMd
						? "60%"
						: isSm
							? "70%"
							: "90%",
			height: isMd ? "80%" : "95%",
			padding: 15,
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 2,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
		},
		modalHeader: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: " space-between",
			marginBottom: 5,
		},
		closeButton: {
			marginRight: 15,
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		title: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},

		marginTop: { marginVertical: 20 },
		infoContainer: {
			flex: 1,
			display: "flex",
			flexDirection: !isMd ? "column" : "row",
			justifyContent: "space-between",
		},
		mainContainer: {
			flex: 1,
			paddingLeft: 30,
			paddingRight: 30,
			paddingTop: 10,
			paddingBottom: 10,
		},

		button: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
			borderRadius: defaultStyles.buttonRadius,
			padding: 15,
			alignItems: "center",
		},
		buttonText: {
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		inputContainer: {
			flex: 1,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			gap: 15,
		},
		input: {
			backgroundColor: backgroundVariant,
			padding: 15,
			fontSize: 16,
			borderRadius: defaultStyles.radius,
			border: "1px solid " + border,
			fontFamily: defaultStyles.regularFont,
			color: text,
			height: 45,
		},
		details: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
	});

	return (
		<View>
			<Modal
				key={3}
				animationType="fade"
				transparent={true}
				visible={display}
				onRequestClose={() => {
					setDisplay(!display);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={styles.modalHeader}>
							<View style={{ flexDirection: "row" }}>
								{/* Close filters button */}
								<TouchableOpacity
									style={styles.closeButton}
									onPress={() => {
										setDisplay(!display);
									}}
								>
									<CloseOutlined
										style={{ fontSize: 22, color: text }}
									></CloseOutlined>
								</TouchableOpacity>

								<View
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Text style={styles.modalTitle}>
										Add a payout method
									</Text>
									<Text style={styles.details}>
										Let us know where you'd like us to send
										your money.
									</Text>
								</View>
							</View>
						</View>
						<ScrollView style={styles.mainContainer}>
							{/* TYPE PICKER */}
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.boldFont,
									marginBottom: 10,
								}}
							>
								How would you like to get paid?
							</Text>
							<View
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<TouchableOpacity
									style={{
										padding: 15,
										flex: 1,
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										borderTopWidth: 1,
										borderBottomWidth:
											type != "bank" ? 1 : 0,
										borderLeftWidth: 1,
										borderRightWidth: 1,
										borderColor:
											type == "card" ? primary : border,
										borderRadius: defaultStyles.radius,
									}}
									onPress={() => {
										setType("card");
									}}
								>
									{/* Icon */}
									<AntDesign
										name="creditcard"
										size={24}
										color={text}
										style={{
											marginRight: 15,
										}}
									/>

									<View>
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.boldFont,
											}}
										>
											Debit Card
										</Text>

										<Text>Instant payouts available.</Text>
									</View>

									{/* Circle that indicates if active */}
									<View
										style={{
											borderRadius: 100,
											width: 20,
											height: 20,
											backgroundColor:
												type == "card"
													? primary
													: backgroundVariant,
											border: "1px solid " + border,
											marginLeft: "auto",
										}}
									></View>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										padding: 15,
										flex: 1,
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										borderRadius: defaultStyles.radius,
										borderTopWidth: type == "bank" ? 1 : 0,
										borderBottomWidth: 1,
										borderLeftWidth: 1,
										borderRightWidth: 1,
										borderColor:
											type == "bank" ? primary : border,
									}}
									onPress={() => {
										setType("bank");
									}}
								>
									{/* Icon */}
									<AntDesign
										name="bank"
										size={24}
										color={text}
										style={{
											marginRight: 15,
										}}
									/>

									<View>
										<Text
											style={{
												color: text,
												fontSize: 16,
												fontFamily:
													defaultStyles.boldFont,
											}}
										>
											Bank Account
										</Text>

										<Text>3 - 5 day processing time.</Text>
									</View>

									{/* Circle that indicates if active */}
									<View
										style={{
											borderRadius: 100,
											width: 20,
											height: 20,
											backgroundColor:
												type == "bank"
													? primary
													: backgroundVariant,
											border: "1px solid " + border,
											marginLeft: "auto",
										}}
									></View>
								</TouchableOpacity>
							</View>

							{type == "card" ? (
								<>
									<View
										style={[
											styles.inputContainer,
											{
												marginTop: 15,
											},
										]}
									>
										{/* First Name */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="First Name"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setFirstName(text);
											}}
											value={firstName}
										></TextInput>

										{/* Last Name */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="Last Name"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setLastName(text);
											}}
											value={lastName}
										></TextInput>
									</View>

									<View
										style={[
											styles.inputContainer,
											{ marginTop: 15 },
										]}
									>
										{/* POSTAL CODE */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder={
												country == "US"
													? "Zip Code"
													: "Postal Code"
											}
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setPostalCode(text);
											}}
											value={postalCode}
										></TextInput>
									</View>

									{/* CARD DETAILS */}
									<View style={{ marginTop: 15 }}>
										<CardNumberElement
											options={{
												style: {
													base: {
														fontSize: "16px",
														color: text,
														"::placeholder": {
															color: textVariant,
														},
													},
													invalid: {
														color: redVariant,
													},
												},
											}}
										/>
									</View>
									<View style={{ marginTop: 15 }}>
										<CardExpiryElement
											options={{
												style: {
													base: {
														fontSize: "16px",
														color: text,
														"::placeholder": {
															color: textVariant,
														},
													},
													invalid: {
														color: redVariant,
													},
												},
											}}
										/>
									</View>
									<View style={{ marginTop: 15 }}>
										<CardCvcElement
											options={{
												style: {
													base: {
														fontSize: "16px",
														color: text,
														"::placeholder": {
															color: textVariant,
														},
													},
													invalid: {
														color: redVariant,
													},
												},
											}}
										/>
									</View>
								</>
							) : null}

							{type == "bank" ? (
								<>
									<View
										style={[
											styles.inputContainer,
											{
												marginTop: 15,
											},
										]}
									>
										{/* First Name */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="First Name"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setFirstName(text);
											}}
											value={firstName}
										></TextInput>

										{/* Last Name */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="Last Name"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setLastName(text);
											}}
											value={lastName}
										></TextInput>
									</View>

									{/* ROUTING NUMBER */}
									<View
										style={[
											styles.inputContainer,
											{ marginTop: 15 },
										]}
									>
										<TextInput
											style={[styles.input, { flex: 1 }]}
											onChangeText={setRoutingNumber}
											value={routingNumber}
											maxLength={9}
											placeholder="Routing Number"
											placeholderTextColor={textVariant}
										/>

										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="Account Number"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setAccountNumber(text);
											}}
										></TextInput>
									</View>

									<View
										style={[
											styles.inputContainer,
											{ marginTop: 15 },
										]}
									>
										{/* POSTAL CODE */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder={
												country == "US"
													? "Zip Code"
													: "Postal Code"
											}
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setPostalCode(text);
											}}
											value={postalCode}
										></TextInput>
									</View>
								</>
							) : null}

							<Text
								style={{
									marginTop: 15,
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.boldFont,
									marginBottom: 10,
								}}
							>
								Billing address
							</Text>
							<View style={[styles.inputContainer]}>
								{/* Country */}
								<Picker
									style={[
										styles.input,
										{
											flex: 1,
											paddingTop: 0,
											paddingBottom: 0,
										},
									]}
									selectedValue={country}
									onValueChange={(itemValue, itemIndex) => {
										// Set country to selected value
										setCountry(itemValue);
									}}
								>
									<Picker.Item
										label="Select a country"
										value=""
										enabled={false}
									/>
									<Picker.Item label="Canada" value="CA" />
									<Picker.Item
										label="United States"
										value="US"
									/>
								</Picker>
							</View>

							{country != "" && (
								<>
									<View
										style={[
											styles.inputContainer,
											{ marginTop: 15 },
										]}
									>
										{/* Province */}
										<Picker
											style={[
												styles.input,
												{
													flex: 1,
													paddingTop: 0,
													paddingBottom: 0,
												},
											]}
											selectedValue={province}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												// Set province to selected value
												setProvince(itemValue);
											}}
										>
											<Picker.Item
												label="Select a province"
												value=""
												enabled={false}
											/>
											{country == "CA" && (
												<>
													<Picker.Item
														label="Alberta"
														value="AB"
													/>
													<Picker.Item
														label="British Columbia"
														value="BC"
													/>
													<Picker.Item
														label="Manitoba"
														value="MB"
													/>
													<Picker.Item
														label="New Brunswick"
														value="NB"
													/>
													<Picker.Item
														label="Newfoundland and Labrador"
														value="NL"
													/>
													<Picker.Item
														label="Northwest Territories"
														value="NT"
													/>
													<Picker.Item
														label="Nova Scotia"
														value="NS"
													/>
													<Picker.Item
														label="Nunavut"
														value="NU"
													/>
													<Picker.Item
														label="Ontario"
														value="ON"
													/>
													<Picker.Item
														label="Prince Edward Island"
														value="PE"
													/>
													<Picker.Item
														label="Quebec"
														value="QC"
													/>
													<Picker.Item
														label="Saskatchewan"
														value="SK"
													/>
													<Picker.Item
														label="Yukon"
														value="YT"
													/>
												</>
											)}

											{country == "US" && (
												<>
													<Picker.Item
														label="Alabama"
														value="AL"
													/>
													<Picker.Item
														label="Alaska"
														value="AK"
													/>
													<Picker.Item
														label="Arizona"
														value="AZ"
													/>
													<Picker.Item
														label="Arkansas"
														value="AR"
													/>
													<Picker.Item
														label="California"
														value="CA"
													/>
													<Picker.Item
														label="Colorado"
														value="CO"
													/>
													<Picker.Item
														label="Connecticut"
														value="CT"
													/>
													<Picker.Item
														label="Delaware"
														value="DE"
													/>
													<Picker.Item
														label="Florida"
														value="FL"
													/>
													<Picker.Item
														label="Georgia"
														value="GA"
													/>
													<Picker.Item
														label="Hawaii"
														value="HI"
													/>
													<Picker.Item
														label="Idaho"
														value="ID"
													/>
													<Picker.Item
														label="Illinois"
														value="IL"
													/>
													<Picker.Item
														label="Indiana"
														value="IN"
													/>
													<Picker.Item
														label="Iowa"
														value="IA"
													/>
													<Picker.Item
														label="Kansas"
														value="KS"
													/>
													<Picker.Item
														label="Kentucky"
														value="KY"
													/>
													<Picker.Item
														label="Louisiana"
														value="LA"
													/>
													<Picker.Item
														label="Maine"
														value="ME"
													/>
													<Picker.Item
														label="Maryland"
														value="MD"
													/>
													<Picker.Item
														label="Massachusetts"
														value="MA"
													/>
													<Picker.Item
														label="Michigan"
														value="MI"
													/>
													<Picker.Item
														label="Minnesota"
														value="MN"
													/>
													<Picker.Item
														label="Mississippi"
														value="MS"
													/>
													<Picker.Item
														label="Missouri"
														value="MO"
													/>
													<Picker.Item
														label="Montana"
														value="MT"
													/>
													<Picker.Item
														label="Nebraska"
														value="NE"
													/>
													<Picker.Item
														label="Nevada"
														value="NV"
													/>
													<Picker.Item
														label="New Hampshire"
														value="NH"
													/>
													<Picker.Item
														label="New Jersey"
														value="NJ"
													/>
													<Picker.Item
														label="New Mexico"
														value="NM"
													/>
													<Picker.Item
														label="New York"
														value="NY"
													/>
													<Picker.Item
														label="North Carolina"
														value="NC"
													/>
													<Picker.Item
														label="North Dakota"
														value="ND"
													/>
													<Picker.Item
														label="Ohio"
														value="OH"
													/>
													<Picker.Item
														label="Oklahoma"
														value="OK"
													/>
													<Picker.Item
														label="Oregon"
														value="OR"
													/>
													<Picker.Item
														label="Pennsylvania"
														value="PA"
													/>
													<Picker.Item
														label="Rhode Island"
														value="RI"
													/>
													<Picker.Item
														label="South Carolina"
														value="SC"
													/>
													<Picker.Item
														label="South Dakota"
														value="SD"
													/>
													<Picker.Item
														label="Tennessee"
														value="TN"
													/>
													<Picker.Item
														label="Texas"
														value="TX"
													/>
													<Picker.Item
														label="Utah"
														value="UT"
													/>
													<Picker.Item
														label="Vermont"
														value="VT"
													/>
													<Picker.Item
														label="Virginia"
														value="VA"
													/>
													<Picker.Item
														label="Washington"
														value="WA"
													/>
													<Picker.Item
														label="West Virginia"
														value="WV"
													/>
													<Picker.Item
														label="Wisconsin"
														value="WI"
													/>
													<Picker.Item
														label="Wyoming"
														value="WY"
													/>
												</>
											)}
										</Picker>
										{/* City */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="City"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setCity(text);
											}}
											value={city}
										></TextInput>
									</View>

									<View
										style={[
											styles.inputContainer,
											{ marginTop: 15 },
										]}
									>
										{/* Address */}
										<TextInput
											style={[styles.input, { flex: 1 }]}
											placeholder="Address"
											placeholderTextColor={textVariant}
											onChangeText={(text) => {
												setAddress(text);
											}}
											value={address}
										></TextInput>
									</View>
								</>
							)}

							{/* ADD TOS CHECKBOX */}
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginTop: 30,
								}}
							>
								<TouchableOpacity
									onPress={() => {
										setTerms(!terms);
									}}
									style={{
										width: 20,
										height: 20,
										backgroundColor: terms
											? primary
											: backgroundVariant,
										border: "1px solid" + border,
										borderRadius: defaultStyles.radius,
										marginRight: 10,
									}}
								></TouchableOpacity>
								<Text
									style={{
										color: text,
										fontSize: 12,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									I agree to the{" "}
									<TouchableOpacity
										onPress={() => {
											window.open(
												"/tos",
												"tutred-terms",
												"width=600,height=600"
											);
										}}
									>
										<Text
											style={{
												color: primaryVariant,
												fontFamily:
													defaultStyles.boldFont,
											}}
										>
											Terms of Service
										</Text>
									</TouchableOpacity>
									.
								</Text>
							</View>
						</ScrollView>
						<View
							style={{
								paddingLeft: 30,
								paddingRight: 30,
							}}
						>
							{error && (
								<Text
									style={{
										color: redVariant,
										fontSize: 16,
										fontFamily: defaultStyles.boldFont,
										marginBottom: 30,
									}}
								>
									{error}
								</Text>
							)}

							<TouchableOpacity
								disabled={
									loading ||
									!firstName ||
									!lastName ||
									!type ||
									!postalCode ||
									!city ||
									!province ||
									!terms
								}
								style={{
									backgroundColor: primary,
									padding: 10,
									borderRadius: defaultStyles.buttonRadius,
									alignContent: "center",
									justifyContent: "center",
									alignItems: "center",
									opacity:
										loading ||
											!firstName ||
											!lastName ||
											!type ||
											!postalCode ||
											!city ||
											!province ||
											!terms
											? 0.7
											: 1,
								}}
								onPress={handleSubmit}
							>
								<Text
									style={{
										color: "#fff",
										fontSize: 14,
										fontFamily: defaultStyles.boldFont,
									}}
								>
									{loading ? "..." : "Submit"}
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};