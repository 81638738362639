import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { Avatar, SxProps } from '@mui/material'
import { useMemo } from 'react'

const TutredAvatar: React.FC<{
    user: any
    size?: 'xx-small' | 'extra-small' | 'small' | 'medium' | 'large'
    clickable?: boolean
    sx?: SxProps
}> = ({ user, size, clickable = true, sx }) => {
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const style = useMemo(() => {
        if (size === 'xx-small') {
            return {
                width: 30,
                height: 30,
                borderRadius: 2.5,
                cursor: clickable ? 'pointer' : 'default',
                ...sx,
            }
        }
        if (size === 'extra-small') {
            return {
                width: 40,
                height: 40,
                borderRadius: 3,
                cursor: clickable ? 'pointer' : 'default',
                ...sx,
            }
        }
        if (size === 'medium') {
            return {
                width: 82,
                height: 82,
                borderRadius: 7,
                cursor: clickable ? 'pointer' : 'default',
                ...sx,
            }
        }
        if (size === 'large') {
            return {
                width: 150,
                height: 150,
                borderRadius: 12,
                cursor: clickable ? 'pointer' : 'default',
                ...sx,
            }
        } else {
            return {
                width: 64,
                height: 64,
                borderRadius: 5,
                cursor: clickable ? 'pointer' : 'default',
                ...sx,
            }
        }
    }, [clickable, size, sx])

    return (
        <Avatar
            onClick={() => {
                if (!clickable) return
                navigation.navigate('TutorProfile', {
                    id: user?.URL,
                })
            }}
            sx={style}
            alt={user?.name}
            src={user?.picture}
        />
    )
}

export default TutredAvatar
