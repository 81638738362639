import { FontAwesome } from '@expo/vector-icons'
import { Link } from '@react-navigation/native'
import {
    collection,
    getDocs,
    query,
    Timestamp,
    where,
} from 'firebase/firestore'
import React, { useEffect } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { useMediaQuery } from 'react-responsive'
import { useFirebaseContext } from '../contexts/firebaseContext'
import { useColorsContext, useStyles } from '../utils/styles'
import { Avatar, Box, Card, Rating, Typography } from '@mui/material'
import formatName from '@helpers/formatName'
import moment from 'moment'

interface Props {
    review: Review
    user: any
    tutor: any
}

interface Review {
    review: string
    rating: number
    tutor: string
    fromTutor: boolean
    created: Timestamp
    user: {
        uid: string
        name: string
        picture: null | string
    }
    sessionId: string
}

// Tutor Review component
export const TutorReview: React.FC<Props> = ({ review, user, tutor }) => {
    const { db } = useFirebaseContext()

    const [userURL, setUserURL] = React.useState(null)

    // Colors and styles
    const {
        primary,
        primaryVariant,
        red,
        redVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
    } = useColorsContext()
    const defaultStyles = useStyles()

    // Responsive Breakpoints
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    // Create stylesheet
    const styles = StyleSheet.create({
        reviewImg: {
            position: 'block',
            marginVertical: 2,
            marginRight: 15,
            borderRadius: 100,
            width: 50,
            height: 50,
            opacity: 1,
        },
        reviewTitle: {
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },

        reviewDate: {
            fontFamily: defaultStyles.regularFont,
            fontSize: 16,
            color: textVariant,
        },
        reviewBody: {
            fontFamily: defaultStyles.regularFont,
            fontSize: 16,
            color: text,
            minHeight: 20,
        },
    })

    const getURL = async (userId) => {
        // Get TUTOR doc from users collection
        const q = query(collection(db, 'users'), where('uid', '==', userId))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            setUserURL(doc.data().URL)
        })
    }

    useEffect(() => {
        getURL(user.uid)
    }, [review])

    return (
        <Card
            sx={{ marginBottom: 2, padding: 3, borderRadius: 2 }}
            variant="outlined"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    sx={{ marginRight: 2, width: 72, height: 72 }}
                    alt={tutor.name}
                    src={user.picture}
                ></Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">
                        {formatName(user.name)}
                    </Typography>
                    <Typography variant="body2">
                        {moment(review.created.toDate()).format('MMMM Do YYYY')}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Rating
                    value={review.rating}
                    readOnly
                    icon={
                        <FontAwesome
                            name="star"
                            size={20}
                            color="#FDCC0D"
                            style={{ marginLeft: 8, marginRight: 2 }}
                        />
                    }
                    emptyIcon={
                        <FontAwesome
                            name="star-o"
                            size={20}
                            color="#FDCC0D"
                            style={{ marginLeft: 8, marginRight: 2 }}
                        />
                    }
                    sx={{ marginTop: 2 }}
                />
                {review.review && (
                    <Typography
                        variant="body1"
                        sx={{ color: textVariant, padding: 2 }}
                    >
                        "{review.review}"
                    </Typography>
                )}
            </Box>
        </Card>
        // <View
        // 	style={{
        // 		borderBottomWidth: 1,
        // 		borderBottomColor: border,
        // 		width: "100%",
        // 		display: "flex",
        // 		flexDirection: "row",
        // 		paddingVertical: 15,
        // 		flex: 1,
        // 		// minHeight: 110,
        // 	}}
        // >

        // 	<View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        // 		<Link
        // 			to={tutor ? `/tutors/${userURL}` : `/students/${userURL}`}
        // 		>
        // 			<Text style={styles.reviewTitle}>
        // 				{user ? (
        // 					<>
        // 						{user.name.split(" ").length > 1
        // 							? `${user.name.split(" ")[0]} ${
        // 									user.name.split(" ")[1][0]
        // 							  }.`
        // 							: user.name}
        // 					</>
        // 				) : (
        // 					"Anonymous"
        // 				)}
        // 			</Text>
        // 		</Link>

        // 		<Text style={styles.reviewDate}>
        // 			{review.created &&
        // 				review.created.toDate().toLocaleDateString()}
        // 		</Text>

        // 		<Text style={styles.reviewBody}>
        // 			{/* Render as many stars as rating */}
        // 			{[...Array(review.rating)].map((e, i) => {
        // 				return (
        // 					<FontAwesome
        // 						key={i}
        // 						style={{ marginRight: 5 }}
        // 						name="star"
        // 						size={14}
        // 						color={yellow}
        // 					/>
        // 				);
        // 			})}
        // 		</Text>

        // 		<Text numberOfLines={3} style={styles.reviewBody}>
        // 			{review.review}
        // 		</Text>
        // 	</View>
        // </View>
    )
}
