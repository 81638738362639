import React from 'react';

const ReviewsIcon = ({ size = 32 }) => {
    const scale = size / 32; // Calculate scale based on original size of 32

    return (
        <svg
            width={32 * scale}
            height={32 * scale}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32 10H20L16 0L12 10H0L8 20L4 32L16 25L28 32L24 20L32 10ZM16 22.406L8.125 27L10.75 19.125L5.5 12.562H13.375L16 6L18.625 12.562H26.5L21.25 19.124L23.875 27L16 22.406Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ReviewsIcon;