import React from 'react';
import { useColorsContext } from '@utils/styles';

const ListingsIcon = ({ size = 24 }) => {
    const { text } = useColorsContext();
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5H0V8H3V5Z" fill={text} />
            <path d="M3 9.9585H0V12.9585H3V9.9585Z" fill={text} />
            <path d="M3 15.042H0V18.042H3V15.042Z" fill={text} />
            <path d="M24 5H5V8H24V5Z" fill={text} />
            <path d="M24 9.9585H5V12.9585H24V9.9585Z" fill={text} />
            <path d="M24 15.042H5V18.042H24V15.042Z" fill={text} />
        </svg>
    );
}

export default ListingsIcon;
