import { Box, Drawer, Typography, Fade, Slide } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useStyles } from '@utils/styles'
import { useEffect, useState } from 'react'

const TutorSideBar = () => {
    const theme = useTheme()
    const defaultStyles = useStyles()
    const [currentSlide, setCurrentSlide] = useState(0)
    const slides = [
        {
            titleLine1: 'Become a',
            titleLine2: 'tutor with us.',
            description:
                'Your journey to inspiring others starts here! Teach flexibly from anywhere, anytime.',
            image: require('@assets/images/signup/animal-school.png'),
        },
        {
            titleLine1: 'Connect with',
            titleLine2: 'more students.',
            description:
                'Discover the new and diverse opportunities to connect with students, that Tutred has to offer.',
            image: require('@assets/images/signup/reading-squirrel.png'),
        },
        {
            titleLine1: 'Decide your',
            titleLine2: 'own rates.',
            description:
                'You have the freedom to set your own hourly rates based on your qualifications, and the demand for your expertise while retaining 100% of your earnings!',
            image: require('@assets/images/signup/money-carpet.png'),
        },
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
        }, 5000)

        return () => clearInterval(interval)
    }, [])

    return (
        <Drawer
            sx={{
                width: 374,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 424,
                    boxSizing: 'border-box',
                },
                padding: 2,
            }}
            PaperProps={{
                style: {
                    position: 'absolute',
                    borderRight: 'none',
                    width: 374,
                    padding: 16,
                },
            }}
            variant="persistent"
            open={true}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 4,
                    padding: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                <Box>
                    <img
                        src={require('@assets/images/logo-white.svg')}
                        alt="logo"
                        height="50"
                        width="auto"
                        style={{
                            marginTop: 8,
                        }}
                    />
                </Box>

                {slides.map((slide, index) => (
                    <Slide
                        key={index}
                        direction="right"
                        in={currentSlide === index}
                        mountOnEnter
                        easing={{
                            enter: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
                            exit: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
                        }}
                    >
                        <Box
                            sx={{
                                display:
                                    currentSlide === index ? 'flex' : 'none',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Box>
                                <Typography
                                    fontFamily="Inter_700Bold"
                                    color="#FFF"
                                    sx={{
                                        mt: 3,
                                        display: 'inline-block',
                                        fontSize: '2.5rem',
                                        lineHeight: '3.25rem',
                                    }}
                                >
                                    {slide.titleLine1}
                                </Typography>
                                <Typography
                                    fontFamily="Inter_700Bold"
                                    color="#FFF"
                                    sx={{
                                        display: 'inline-block',
                                        fontSize: '2.5rem',
                                        lineHeight: '3.25rem',
                                        mb: 1,
                                    }}
                                >
                                    {slide.titleLine2}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    color="#FFF"
                                    fontFamily="Poppins_300Light"
                                    sx={{ display: 'inline-block' }}
                                >
                                    {slide.description}
                                </Typography>
                            </Box>

                            <img
                                src={slide.image}
                                alt={`tutor-side-bar-${index}`}
                                style={{ height: 240, objectFit: 'contain' }}
                            />
                        </Box>
                    </Slide>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {slides.map((_, dotIndex) => (
                        <Box
                            key={dotIndex}
                            onClick={() => setCurrentSlide(dotIndex)}
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                mx: 0.5,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                backgroundColor:
                                    currentSlide === dotIndex
                                        ? '#FFFFFF'
                                        : theme.palette.grey[500],
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Drawer>
    )
}

export default TutorSideBar
