import React from 'react'
import {
    ChipProps,
    useTheme,
    useMediaQuery,
    Box,
    Typography,
} from '@mui/material'

import { useColorsContext, useStyles } from '@utils/styles'
import { subjectIcons } from '@config/subjects'
import TutredBaseChip from '../TutredBaseChip'

interface SubjectChipProps extends ChipProps {
    item: string
    size?: 'large' | 'medium' | 'small'
}

const SubjectChip = React.forwardRef<HTMLDivElement, SubjectChipProps>(
    ({ item, onDelete, size = 'medium', ...rest }, ref) => {
        const theme = useTheme()
        const { darkMode } = useColorsContext()
        const defaultStyles = useStyles()

        return (
            <TutredBaseChip
                ref={ref}
                size={size}
                sx={{
                    paddingHorizontal:
                        size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                    paddingVertical: 0,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#005FDC66',
                    borderRadius:
                        size === 'large' ? 2 : size === 'medium' ? 1.5 : 1,
                    height: size === 'large' ? 36 : size === 'medium' ? 30 : 24,
                }}
                icon={
                    <Box
                        sx={{
                            width:
                                size === 'large'
                                    ? 22
                                    : size === 'medium'
                                      ? 18
                                      : 14,
                            height:
                                size === 'large'
                                    ? 22
                                    : size === 'medium'
                                      ? 18
                                      : 14,
                            backgroundColor: '#FFF',
                            WebkitMask: `url(${subjectIcons[item]}) no-repeat center `,
                            mask: `url(${subjectIcons[item]}) no-repeat center `,
                            maskSize: 'contain',
                            WebkitMaskSize: 'contain',
                            mr: 1,
                        }}
                    />
                }
                label={
                    <Typography
                        sx={{
                            fontSize:
                                size === 'large'
                                    ? 16
                                    : size === 'medium'
                                      ? 14
                                      : 10,
                        }}
                        color="#005FDC"
                        fontFamily={defaultStyles.titleFont}
                    >
                        {item}
                    </Typography>
                }
                onDelete={onDelete}
                {...rest}
            />
        )
    }
)

export default SubjectChip
