import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';

// Import screens
import { Chats } from '../../screens/loggedIn/Chats/Chats';

const Stack = createNativeStackNavigator();

export const ChatsStack = ({ navigation, route, params }) => {
  return (
    <SafeAreaProvider>
      <Stack.Navigator initialRouteName="Chats">
        <Stack.Screen
          options={{ headerShown: false }}
          name="Chats"
          component={Chats}
        />
      </Stack.Navigator>
    </SafeAreaProvider>
  );
};
