import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useDialog } from '@contexts/dialogContext'

const Opportunities = () => {
    const { primary, primaryDark } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const { openSignupDialog } = useDialog()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isXs = useMediaQuery(theme.breakpoints.up('xs'))
    return (
        <Box>
            <Card
                sx={{
                    backgroundColor: '#0048FF',
                    borderRadius: '30px',
                    p: { xs: 1, sm: 3, md: 4 },
                    mt: { xs: 4, md: 10 },
                    position: 'relative',
                    overflow: 'hidden',
                }}
                elevation={0}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'flex-end', sm: 'flex-start' },
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            {' '}
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    mb: 2,
                                }}
                                fontFamily={defaultStyles.titleFont}
                                variant={isMd ? 'h2' : 'h4'}
                            >
                                Discover new teaching
                                <br /> opportunities
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#FFF',

                                    fontSize: {
                                        xs: '1.2rem',
                                        md: '2rem',
                                    },
                                }}
                                fontFamily="Poppins_500Medium"
                                variant="body1"
                            >
                                At{' '}
                                <span
                                    style={{
                                        color: '#000',
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    tutred
                                </span>{' '}
                                you have the flexibility to decide your rates
                                and availability!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            src={require('@assets/images/landingpage/opportunities1.svg')}
                            alt="landing-page-image"
                            sx={{
                                width: '100%',
                                maxWidth: '28%',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                fontSize: {
                                    xs: '1rem',
                                    md: '1.25rem',
                                },
                                px: { xs: 2, sm: 3 },
                                height: { xs: 48, sm: 50, md: 52 },
                                backgroundColor: '#FFF',
                                border: `3px solid black`,
                                color: '#000',
                                borderRadius: '12px',
                                width: { xs: '100%', sm: 'auto' },
                            }}
                            onClick={() => {
                                openSignupDialog('tutor')
                            }}
                        >
                            Become a tutor
                        </Button>

                        <Box
                            component="img"
                            src={require('@assets/images/landingpage/opportunities2.svg')}
                            alt="landing-page-image"
                            sx={{
                                maxWidth: { xs: '100%', sm: '60%', lg: '50%' },
                                mt: { xs: 3, sm: 0 },
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Opportunities
