import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import FadeIn from "react-native-fade-in-image";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";

// Tutor Review component
export const ListingCard = ({ listing }) => {
	function getTimeElapsed(date) {
		const elapsedMinutes = Math.floor((new Date() - date) / 60000); // elapsed time in minutes
		if (elapsedMinutes < 60) {
			return `${elapsedMinutes} ${
				elapsedMinutes === 1 ? "minute" : "minutes"
			} ago`;
		} else if (elapsedMinutes < 1440) {
			const elapsedHours = Math.floor(elapsedMinutes / 60);
			const remainingMinutes = elapsedMinutes % 60;
			return `${elapsedHours} ${elapsedHours === 1 ? "hour" : "hours"}${
				remainingMinutes > 0
					? ` and ${remainingMinutes} ${
							remainingMinutes === 1 ? "minute" : "minutes"
					  }`
					: ""
			} ago`;
		} else {
			const elapsedDays = Math.floor(elapsedMinutes / 1440);
			const remainingHours = Math.floor((elapsedMinutes % 1440) / 60);
			return `${elapsedDays} ${elapsedDays === 1 ? "day" : "days"} ago`;
		}
	}
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Responsive Breakpoints
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		image: {
			position: "block",
			marginRight: 15,
			width: 60,
			height: 60,
			borderRadius: 100,
		},
		listingTitle: {
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		info: {
			marginTop: 5,
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.regularFont,
		},
		listingBody: {
			marginTop: 2.5,
			fontSize: 16,
			color: text,
			fontFamily: defaultStyles.regularFont,
			minHeight: 20,
		},
	});

	return (
		<View
			style={{
				borderBottomWidth: 1,
				borderBottomColor: border,
				width: "100%",
				display: "flex",
				flexDirection: "row",
				paddingVertical: 15,
				flex: 1,
			}}
		>
			<FadeIn placeholderStyle={{ backgroundColor: backgroundVariant }}>
				<Image
					style={styles.image}
					source={{
						uri: listing.user.avatar
							? listing.user.avatar
							: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
					}}
				/>
			</FadeIn>

			<View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
				<Text style={styles.listingTitle}>{listing.title}</Text>

				{/* Skills and how long ago posted */}
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						marginTop: 0,
						alignItems: "center",
					}}
				>
					<View
						style={{
							marginRight: 5,
							marginTop: 5,
							backgroundColor: backgroundVariant,
							borderRadius: 100,
							paddingVertical: 2,
							paddingHorizontal: 10,
							border: `.5px solid ${border}`,
							display: "flex",
							flexDirection: "row",
							alignContent: "center",
							alignItems: "center",
							justifyContent: "space-evenly",
							height: 30,
						}}
					>
						<Text
							style={{
								fontSize: 12,
								color: textVariant,
								fontFamily: defaultStyles.regularFont,
							}}
						>
							{listing.subject}
						</Text>
					</View>

					{listing.skills.map((skill, index) => {
						return (
							<View
								key={index}
								style={{
									marginRight: 5,
									marginTop: 5,
									backgroundColor: backgroundVariant,
									borderRadius: 100,
									paddingVertical: 2,
									paddingHorizontal: 10,
									border: `.5px solid ${border}`,
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
									justifyContent: "space-evenly",
									height: 30,
								}}
							>
								<Text
									style={{
										fontSize: 12,
										color: textVariant,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									{skill}
								</Text>
							</View>
						);
					})}

					<Text style={[styles.info, { marginLeft: 10 }]}>
						{listing.createdAt &&
							getTimeElapsed(listing.createdAt.toDate())}
					</Text>
				</View>

				<Text numberOfLines={3} style={styles.listingBody}>
					{listing.description}
				</Text>
			</View>
		</View>
	);
};
