import TutredTooltip from '@components/TutredTooltip'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React, { useMemo } from 'react'
import { TouchableOpacity } from 'react-native'

export interface MultiSwitchToggleOption {
    label: string
    contextLabel?: string
    value: string
    error?: string
    disabled?: boolean
    disabledMessage?: string
}

export interface MutliSwitchToggleOptionProps {
    option: MultiSwitchToggleOption
    selectedIndex: number
    setSelectedIndex: (index: number) => void
    onLayout: (event: any, index: number) => void
    index: number
    highlightTextColor?: string
}

const MutliSwitchToggleOption: React.FC<MutliSwitchToggleOptionProps> = ({
    option,
    selectedIndex,
    setSelectedIndex,
    onLayout,
    index,
    highlightTextColor,
}) => {
    const { text, grey, darkMode } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    console.log('mode', theme.palette.mode)

    return useMemo(() => {
        return (
            <TutredTooltip title={option.disabledMessage} placement="top-end">
                <Box>
                    <TouchableOpacity
                        onLayout={(event) => onLayout(event, index)}
                        key={option.value}
                        style={{
                            borderRadius: defaultStyles.radius,
                            paddingLeft: isMd ? 7.5 : 3,
                            paddingRight: isMd ? 7.5 : 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'baseline',
                        }}
                        disabled={option.disabled}
                        onPress={() => {
                            setSelectedIndex(index)
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontSize={isMd ? null : 12}
                            style={{
                                color: option.disabled
                                    ? theme.palette.text.disabled
                                    : index === selectedIndex
                                      ? highlightTextColor || text
                                      : text,
                                fontFamily:
                                    index === selectedIndex
                                        ? defaultStyles.titleFont
                                        : 'Poppins_500Medium',
                                textAlign: 'center',
                                transition: 'color 0.3s ease', // Added transition for color change
                            }}
                        >
                            {option.label}
                        </Typography>
                        {option.contextLabel && (
                            <Typography
                                variant="body2"
                                style={{
                                    color: option?.error
                                        ? theme.palette.error.main
                                        : grey,
                                    fontFamily: defaultStyles.titleFont,
                                    marginLeft: 2,
                                    textAlign: 'center',
                                    fontSize: isMd ? 12 : 10,
                                }}
                            >
                                {option.contextLabel}
                            </Typography>
                        )}
                    </TouchableOpacity>
                </Box>
            </TutredTooltip>
        )
    }, [option, setSelectedIndex, text, defaultStyles, grey, isMd])
}

export default MutliSwitchToggleOption
