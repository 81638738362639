import TutredButton from '@components/TutredButton'
import { useSessionContext } from '@contexts/sessionContext'
import formatName from '@helpers/formatName'
import { Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React, { FC, useEffect, useMemo } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import Animated, { SlideInLeft, SlideOutLeft } from 'react-native-reanimated'

import { useAlert } from 'react-alert'
interface SessionExtensionPromptProps {
    extendSession: () => void
    headerHeight: number
    session: any
    isTutor?: boolean
    setShowExtendPrompt: (show: boolean) => void
    showExtendPrompt: boolean
    endCountDown: string
}

const SessionExtensionPrompt: FC<SessionExtensionPromptProps> = ({
    extendSession,
    headerHeight,
    session,
    isTutor,
    setShowExtendPrompt,
    showExtendPrompt,
    endCountDown,
}) => {
    const defaultStyles = useStyles()
    const { background, red } = useColorsContext()
    const { approveExtension, cancelExtension } = useSessionContext()
    const customAlert = useAlert()
    const [shouldShowPrompt, setShouldShowPrompt] = React.useState(true)

    const latestExtension = useMemo(() => {
        return (
            session?.extensions &&
            session?.extensions[session?.extensions.length - 1]
        )
    }, [session?.extensions])

    const title = useMemo(() => {
        if (latestExtension?.status === 'pending') {
            if (isTutor) {
                return `A session extension was requested!`
            } else {
                return 'Your extension request is pending!'
            }
        } else {
            return 'Your session is almost over!'
        }
    }, [latestExtension, isTutor])

    const message = useMemo(() => {
        if (latestExtension?.status === 'pending') {
            if (isTutor) {
                return `Would you like to extend your session with ${formatName(session?.user?.name)}?`
            } else {
                return `${formatName(session?.tutorProfile?.name)} is reviewing your request.`
            }
        } else {
            return 'Would you like to extend your session?'
        }
    }, [latestExtension, isTutor, session?.user?.name])

    const actionButton = useMemo(() => {
        if (latestExtension?.status === 'pending') {
            if (isTutor) {
                return (
                    <TutredButton
                        title="Accept"
                        outlined={false}
                        onPress={() => {
                            approveExtension(
                                session.id,
                                latestExtension.id,
                                latestExtension.paymentIntentId
                            )
                                .then(() => {
                                    customAlert.success(
                                        'The extension has been approved!'
                                    )
                                })
                                .catch((error) => {
                                    customAlert.error(
                                        'We were unable to request your extension. Please try again later.'
                                    )
                                })
                                .finally(() => {
                                    setShowExtendPrompt(false)
                                })
                        }}
                        customStyles={{
                            width: 'auto',
                            paddingHorizontal: 20,
                            marginRight: 16,
                        }}
                    />
                )
            } else {
                return (
                    <TutredButton
                        title="Cancel"
                        outlined={false}
                        onPress={() => {
                            cancelExtension(
                                session.id,
                                latestExtension.id,
                                latestExtension.paymentIntentId,
                                'cancelled'
                            )
                                .then(() => {
                                    customAlert.success(
                                        'The extension has been cancelled!'
                                    )
                                    setShowExtendPrompt(false)
                                })
                                .catch((error) => {
                                    customAlert.error(
                                        'We were unable to cancel your extension. Please try again later.'
                                    )
                                    setShowExtendPrompt(false)
                                })
                        }}
                        customStyles={{
                            width: 'auto',
                            paddingHorizontal: 20,
                            marginRight: 16,
                            backgroundColor: red,
                        }}
                    />
                )
            }
        } else {
            return (
                <TutredButton
                    title="Extend"
                    outlined={false}
                    onPress={extendSession}
                    customStyles={{
                        width: 'auto',
                        paddingHorizontal: 20,
                        marginRight: 16,
                    }}
                />
            )
        }
    }, [latestExtension, isTutor, extendSession, session.id])

    const dismissButton = useMemo(() => {
        if (latestExtension?.status === 'pending' && !isTutor) {
            return null
        } else {
            return (
                <TutredButton
                    title={
                        latestExtension?.status === 'pending'
                            ? 'Decline'
                            : 'Dismiss'
                    }
                    outlined={true}
                    onPress={() => {
                        if (latestExtension?.status === 'pending') {
                            cancelExtension(
                                session.id,
                                latestExtension.id,
                                latestExtension.paymentIntentId,
                                isTutor ? 'declined' : 'cancelled'
                            )
                                .then(() => {
                                    customAlert.success(
                                        `The extension has been ${isTutor ? 'declined' : 'cancelled'}!`
                                    )
                                    setShouldShowPrompt(false)
                                    setShowExtendPrompt(false)
                                })
                                .catch((error) => {
                                    customAlert.error(
                                        'We were unable to cancel your extension. Please try again later.'
                                    )
                                    setShouldShowPrompt(false)
                                    setShowExtendPrompt(false)
                                })
                        } else {
                            setShouldShowPrompt(false)
                            setShowExtendPrompt(false)
                        }
                    }}
                    textColor={red}
                    customStyles={{
                        width: 'auto',
                        paddingHorizontal: 20,
                        borderColor: red,
                    }}
                />
            )
        }
    }, [latestExtension, isTutor, session, customAlert])

    useEffect(() => {
        if (session.status !== 'in progress') {
            setShowExtendPrompt(false)
        } else if (latestExtension && latestExtension.status === 'pending') {
            setShowExtendPrompt(true)
        } else if (!isTutor) {
            if (endCountDown && shouldShowPrompt) {
                const minsLeft = endCountDown.split(':')[0]
                if (Number(minsLeft) < 14) {
                    if (
                        !latestExtension ||
                        latestExtension?.status === 'confirmed'
                    ) {
                        setShowExtendPrompt(true)
                    } else {
                        setShowExtendPrompt(false)
                    }
                } else {
                    setShowExtendPrompt(false)
                }
            } else {
                setShowExtendPrompt(false)
            }
        } else {
            setShowExtendPrompt(false)
        }
    }, [
        endCountDown,
        shouldShowPrompt,
        isTutor,
        latestExtension,
        setShowExtendPrompt,
        setShouldShowPrompt,
        session?.status,
    ])

    if (showExtendPrompt === false) {
        return null
    }

    return (
        <Animated.View
            entering={SlideInLeft.duration(400)}
            exiting={SlideOutLeft.duration(400)}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                backgroundColor: background,
                top: headerHeight + 16,
                left: 0,
                zIndex: 9999,
                borderTopRightRadius: defaultStyles.radius,
                borderBottomRightRadius: defaultStyles.radius,
                padding: 16,
                shadowColor: '#000',
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
            }}
        >
            <View style={{ marginRight: 30 }}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="body1">{message}</Typography>
            </View>
            {actionButton}
            {dismissButton}
        </Animated.View>
    )
}

export default SessionExtensionPrompt
