import React from 'react'
import { Box, Checkbox, Typography } from '@mui/material'
import TutredTimePicker from '@components/TutredTimePicker'
import { useStyles, useColorsContext } from '@utils/styles'
import TutredCheckbox from '@components/TutredCheckbox'

const AvailabilityListView = ({ availability, setAvailability, errors }) => {
    const defaultStyles = useStyles()
    const { red } = useColorsContext()

    return (
        <Box sx={{ flex: 1, padding: 2, width: '100%', overflowY: 'auto' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    pt: 1,
                    pb: 1,
                }}
            >
                {Object.keys(availability).map((key) => {
                    const hasError = errors && errors[key]
                    return (
                        <Box
                            key={key}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <TutredCheckbox
                                checked={availability[key]}
                                onChange={(event) => {
                                    setAvailability((prev) => {
                                        return {
                                            ...prev,
                                            [key]: event.target.checked
                                                ? {
                                                      start: null,
                                                      end: null,
                                                  }
                                                : null,
                                        }
                                    })
                                }}
                            />
                            {availability[key] ? (
                                <>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.titleFont}
                                        textTransform={'uppercase'}
                                        sx={{ width: 80 }}
                                    >
                                        {key.slice(0, 3)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TutredTimePicker
                                            size="small"
                                            label="Start"
                                            value={availability[key].start}
                                            onChange={(value) =>
                                                setAvailability((prev) => ({
                                                    ...prev,
                                                    [key]: {
                                                        ...prev[key],
                                                        start: value,
                                                    },
                                                }))
                                            }
                                            error={hasError}
                                        />
                                        <Typography
                                            variant="body2"
                                            fontFamily={defaultStyles.titleFont}
                                            sx={{
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                            }}
                                        >
                                            -
                                        </Typography>
                                        <TutredTimePicker
                                            size="small"
                                            label="End"
                                            value={availability[key].end}
                                            onChange={(value) =>
                                                setAvailability((prev) => ({
                                                    ...prev,
                                                    [key]: {
                                                        ...prev[key],
                                                        end: value,
                                                    },
                                                }))
                                            }
                                            error={hasError}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        variant="body2"
                                        fontFamily={defaultStyles.titleFont}
                                        textTransform={'uppercase'}
                                        sx={{ width: 80 }}
                                    >
                                        {key.slice(0, 3)}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        fontFamily={defaultStyles.titleFont}
                                        textTransform={'uppercase'}
                                    >
                                        Unavailable
                                    </Typography>
                                </>
                            )}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default AvailabilityListView
