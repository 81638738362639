import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useColorsContext, useStyles } from '../utils/styles'

import { Link } from '@react-navigation/native'

import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useDialog } from '@contexts/dialogContext'

export const Header = () => {
    // Appearance
    const { text, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const { openSignupDialog, openLoginDialog } = useDialog()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const styles = StyleSheet.create({
        header: {
            backgroundColor: primaryLight,
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: !isSm ? 15 : 45,
            paddingVertical: 20,
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 100,
        },
        headerLeft: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        menuText: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            marginBottom: 15,
        },
    })

    return (
        <>
            <View style={styles.header}>
                {/* Logo left */}
                <View style={styles.headerLeft}>
                    <Link
                        to={{
                            screen: 'LandingPage',
                        }}
                        style={{ paddingBottom: isMd ? 12 : 0 }}
                    >
                        <img
                            src={require('@assets/images/logo.svg')}
                            alt="logo"
                            height={isMd ? 48 : 36}
                            width="auto"
                        />
                    </Link>
                    {isMd && (
                        <Link
                            to={{
                                screen: 'Tutors',
                                params: {
                                    screen: 'TutorList',
                                },
                            }}
                        >
                            <Text
                                style={{
                                    color: text,
                                    marginLeft: 64,
                                    marginTop: 8,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.titleFont,
                                }}
                            >
                                Find Tutors
                            </Text>
                        </Link>
                    )}
                </View>

                {/* Buttons */}

                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isMd && (
                        <TouchableOpacity
                            onPress={() => {
                                openSignupDialog('tutor')
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: defaultStyles.buttonRadius,
                                paddingVertical: 10,
                                paddingHorizontal: 15,
                            }}
                        >
                            <Text
                                style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.titleFont,
                                }}
                            >
                                Become a tutor
                            </Text>
                        </TouchableOpacity>
                    )}
                    {/* Tutors */}

                    {/* Login */}
                    {isMd && (
                        <TouchableOpacity
                            onPress={() => {
                                openLoginDialog()
                            }}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: defaultStyles.buttonRadius,
                                paddingVertical: 10,
                                paddingHorizontal: 15,
                            }}
                        >
                            <Text
                                style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.titleFont,
                                }}
                            >
                                Login
                            </Text>
                        </TouchableOpacity>
                    )}

                    <Button
                        sx={{ marginLeft: 2 }}
                        size={isMd ? 'medium' : 'small'}
                        variant="outlined"
                        onClick={() => {
                            openSignupDialog('student')
                        }}
                        disableElevation
                    >
                        Sign up
                    </Button>
                </View>
            </View>
        </>
    )
}
