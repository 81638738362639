import React from 'react'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useUserContext } from '@contexts/userContext'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Link,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useColorsContext, useStyles } from '@utils/styles'
import { addMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { useAlert } from 'react-alert'
import { AxiosError } from 'axios'

interface Props {
    open: boolean
    setOpen: (open: boolean) => void
}
interface ValidateCodeForm {
    num1: string
    num2: string
    num3: string
    num4: string
}

const VerifyEmailDialog: React.FC<Props> = ({ open, setOpen }) => {
    const { user, signOut } = useUserContext()
    const defaultStyles = useStyles()
    const { primary, text, background } = useColorsContext()
    const { authenticatedAxios } = useAxiosWithAuth()
    const [nextResend, setNextResend] = useState(0)
    const alert = useAlert()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<ValidateCodeForm>()

    const codeVerification = useMutation({
        mutationFn: async (data: ValidateCodeForm) => {
            return authenticatedAxios.put(`/api/email/verification`, {
                code: data.num1 + data.num2 + data.num3 + data.num4,
            })
        },
        onSuccess: (response) => {
            alert.success(
                '[Account Verification] Your email has been verified!'
            )
            setOpen(false)
        },
        onError: (error: AxiosError) => {
            const message =
                error?.response?.data?.message ||
                'Something went wrong. Please try again later.'
            setError('num1', { message: message })
            setError('num2', { message: message })
            setError('num3', { message: message })
            setError('num4', { message: message })
            setFocus('num4')

            alert.error(`[Account Verification] ${message}`)
        },
    })

    const resendCode = useMutation({
        mutationFn: async () => {
            return authenticatedAxios.post(`/api/email/verification`)
        },
        onSuccess: () => {
            setNextResend(150)
            alert.success(
                '[Account Verification] A new verification code has been sent!'
            )
        },
        onError: () => {
            const message =
                error?.response?.data?.message ||
                'Something went wrong. Please try again later.'
            setError('num1', { message: message })
            setError('num2', { message: message })
            setError('num3', { message: message })
            setError('num4', { message: message })
            setFocus('num4')

            alert.error(`[Account Verification] ${message}`)
        },
    })

    useEffect(() => {
        if (nextResend > 0) {
            setTimeout(() => setNextResend(nextResend - 1), 1000)
        }
    }, [nextResend])

    const slotProps = (focused: boolean) => {
        return {
            htmlInput: {
                maxLength: 1,
                style: {
                    textAlign: 'center',
                    fontSize: isSm ? 30 : 24,
                    width: isSm ? 40 : 30,
                    fontFamily: 'Poppins_600SemiBold',
                    backgroundColor: background,
                    borderRadius: '16px',
                    boxShadow: focused ? `0px 0px 16px 0px ${primary}` : 'none',
                },
            },
            input: {
                style: {
                    borderRadius: '16px',
                },
            },
        }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = event.clipboardData
            .getData('text')
            .replace(/[^\d]/g, '')

        setValue('num1', pastedText.slice(0, 1))
        setValue('num2', pastedText.slice(1, 2))
        setValue('num3', pastedText.slice(2, 3))
        setValue('num4', pastedText.slice(3, 4))
        setFocus('num4')
    }

    const onSubmit = (data: ValidateCodeForm) => {
        codeVerification.mutate(data)
    }

    const minutes = Math.floor(nextResend / 60)
    const seconds = nextResend % 60
    const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`

    return (
        <Dialog open={open} maxWidth="sm">
            <DialogTitle>
                <Typography
                    variant={isSm ? 'h4' : 'h6'}
                    fontFamily={defaultStyles.titleFont}
                    textAlign={'center'}
                    sx={{ marginBottom: 1 }}
                >
                    Enter verification code
                </Typography>
                <Typography
                    variant={isSm ? 'body1' : 'body2'}
                    noWrap
                    sx={{
                        color: primary,
                    }}
                >
                    We have sent a code to{' '}
                    <span
                        style={{
                            color: text,
                            fontWeight: 600,
                            textAlign: 'center',
                        }}
                    >
                        {user?.email}
                    </span>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={[styles.inputContainer, { columnGap: isSm ? 4 : 2 }]}>
                    <Controller
                        name="num1"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                {...field}
                                onPaste={handlePaste}
                                onChange={(e) => {
                                    const val = e.target.value.replace(
                                        /[^\d]/g,
                                        ''
                                    )
                                    if (val.length > 0) setFocus('num2')
                                    field.onChange(val)
                                }}
                                onKeyUp={(e) => {
                                    if (
                                        field.value.length > 0 &&
                                        e.code.includes('Digit')
                                    ) {
                                        setValue('num2', e.key)
                                        setFocus('num2')
                                    }
                                }}
                                focused={field.value.length > 0}
                                inputRef={ref}
                                sx={{ borderRadius: '16px' }}
                                slotProps={slotProps(field.value.length > 0)}
                                error={!!errors?.num1}
                            />
                        )}
                    />
                    <Controller
                        name="num2"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                {...field}
                                onPaste={handlePaste}
                                onKeyUp={(e) => {
                                    if (e.code === 'Backspace') setFocus('num1')
                                    if (
                                        field.value.length > 0 &&
                                        e.code.includes('Digit')
                                    ) {
                                        setValue('num3', e.key)
                                        setFocus('num3')
                                    }
                                }}
                                onChange={(e) => {
                                    const val = e.target.value.replace(
                                        /[^\d]/g,
                                        ''
                                    )
                                    if (val.length > 0) setFocus('num3')
                                    field.onChange(val)
                                }}
                                focused={field.value.length > 0}
                                inputRef={ref}
                                sx={{ borderRadius: 2 }}
                                slotProps={slotProps(field.value.length > 0)}
                                error={!!errors?.num2}
                            />
                        )}
                    />

                    <Controller
                        name="num3"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                {...field}
                                onPaste={handlePaste}
                                onKeyUp={(e) => {
                                    if (e.code === 'Backspace') setFocus('num2')
                                    if (
                                        field.value.length > 0 &&
                                        e.code.includes('Digit')
                                    ) {
                                        setValue('num4', e.key)
                                        setFocus('num4')
                                    }
                                }}
                                onChange={(e) => {
                                    const val = e.target.value.replace(
                                        /[^\d]/g,
                                        ''
                                    )
                                    if (val.length > 0) setFocus('num4')
                                    field.onChange(val)
                                }}
                                focused={field.value.length > 0}
                                inputRef={ref}
                                sx={{ borderRadius: 2 }}
                                slotProps={slotProps(field.value.length > 0)}
                                error={!!errors?.num3}
                            />
                        )}
                    />

                    <Controller
                        name="num4"
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                {...field}
                                onPaste={handlePaste}
                                onKeyUp={(e) => {
                                    if (e.code === 'Backspace') setFocus('num3')
                                }}
                                onChange={(e) => {
                                    console.log('e', e)
                                    const val = e.target.value.replace(
                                        /[^\d]/g,
                                        ''
                                    )
                                    field.onChange(val)
                                }}
                                inputRef={ref}
                                sx={{ borderRadius: 2 }}
                                focused={field.value.length > 0}
                                slotProps={slotProps(field.value.length > 0)}
                                error={!!errors?.num4}
                            />
                        )}
                    />
                </Box>
                <>
                    {resendCode?.isPending ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant={isSm ? 'body1' : 'body2'}
                                sx={{
                                    color: primary,
                                    textAlign: 'center',
                                    marginRight: 2,
                                }}
                            >
                                Resending code
                            </Typography>
                            <ActivityIndicator size="small" />
                        </Box>
                    ) : (
                        <>
                            {nextResend > 0 ? (
                                <Typography
                                    variant={isSm ? 'body1' : 'body2'}
                                    sx={{
                                        color: primary,
                                        textAlign: 'center',
                                    }}
                                >
                                    Resend code in {formattedTime}
                                </Typography>
                            ) : (
                                <Typography
                                    variant={isSm ? 'body1' : 'body2'}
                                    sx={{
                                        color: primary,
                                        textAlign: 'center',
                                    }}
                                >
                                    Didn't get a code?{' '}
                                    <Link
                                        sx={{
                                            color: text,
                                            fontWeight: 600,
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            resendCode.mutate()
                                        }}
                                    >
                                        Click to resend
                                    </Link>
                                </Typography>
                            )}
                        </>
                    )}
                </>
            </DialogContent>
            <Divider variant="middle" sx={{ mt: isSm ? 2 : 1 }} />
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: isSm ? 4 : 2,
                }}
            >
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                        setOpen(false)
                        signOut()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    fullWidth
                    disabled={codeVerification?.isPending}
                    variant="contained"
                    onClick={() => {
                        handleSubmit(onSubmit)()
                    }}
                >
                    {codeVerification?.isPending ? (
                        <ActivityIndicator
                            size="small"
                            color="#FFF"
                            style={{ paddingHorizontal: 7, paddingVertical: 3 }}
                        />
                    ) : (
                        'Verify'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },
})

export default VerifyEmailDialog
