import React from 'react'
import { Typography, Box } from '@mui/material'

interface GroupHeaderProps {
    title: string
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ title }) => {
    return (
        <Box
            sx={{
                backgroundColor: '#0000001A',
                py: 1,
            }}
        >
            <Typography
                variant="body2"
                component="div"
                fontFamily="Poppins_600SemiBold"
                textAlign="center"
                sx={{ margin: '0 auto' }}
            >
                {title}
            </Typography>
        </Box>
    )
}

export default GroupHeader
