import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useColorsContext, useStyles } from "../utils/styles";

const useInstaBookTutorial = () => {

    const defaultStyles = useStyles();
    const {
        primary,
        primaryVariant,
        background,
        text,
        border,
    } = useColorsContext();

    return () => {
        localStorage.setItem("instaBook2", true);
        confirmAlert({
            title: "InstaBook",
            customUI: ({ onClose }) => {
                return (
                    <View
                        style={{
                            width: "75%",
                            maxWidth: 1050,
                            margin: "auto",
                            backgroundColor: background,
                            borderRadius: 10,
                            padding: 30,
                            border: "1px solid" + border,
                        }}
                    >
                        <Text
                            style={{
                                color: text,
                                fontSize: 19,
                                fontFamily: defaultStyles.titleFont,
                                marginTop: 10,
                            }}
                        >
                            How to use InstaBook
                        </Text>

                        <Image
                            source={require("../assets/guide.gif")}
                            style={{
                                height: 350,
                                width: 350,
                                margin: "auto",
                                marginTop: 15,
                                border: "1px solid" + primaryVariant,
                                borderRadius: 10,
                            }}
                        />

                        <Text
                            style={{
                                color: text,
                                fontSize: 14,
                                fontFamily: defaultStyles.regularFont,
                                marginTop: 30,
                            }}
                        >
                            InstaBook gives you the ability to be instantly
                            booked by students looking for on-demand help.
                        </Text>

                        <Text
                            style={{
                                color: text,
                                fontSize: 14,
                                fontFamily: defaultStyles.regularFont,
                                marginTop: 8,
                            }}
                        >
                            1. To use InstaBook, simply set the default length
                            of your sessions, and the minimum fee you would like
                            to charge. The standard rate will be your hourly
                            rate multiplied by the length of the session however
                            if the amount is less than the minimum fee you will
                            be receive the minimum fee.
                        </Text>

                        <Text
                            style={{
                                color: text,
                                fontSize: 14,
                                fontFamily: defaultStyles.regularFont,
                                marginTop: 8,
                            }}
                        >
                            2. After configuring your settings you can flip on
                            InstaBook and you will be able to be booked by
                            students looking for on-demand help!
                        </Text>

                        <Text
                            style={{
                                color: text,
                                fontSize: 14,
                                fontFamily: defaultStyles.regularFont,
                                marginTop: 8,
                            }}
                        >
                            3. When a student books a session with you you will
                            be redirected to a video session, you will start the
                            session after you both discuss the details in the 5
                            minute window provided before the session starts.
                        </Text>

                        <View
                            style={{
                                flexDirection: "row",
                                marginTop: 20,
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    onClose();
                                }}
                                style={{
                                    backgroundColor: primary,
                                    width: 100,
                                    height: 40,
                                    borderRadius: defaultStyles.buttonRadius,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    style={{
                                        color: "white",
                                        fontSize: 16,
                                        fontFamily: defaultStyles.regularFont,
                                        textAlign: "center",
                                    }}
                                >
                                    Got it
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                );
            },
        });
    }
}

export default useInstaBookTutorial