import React, { useContext } from 'react'
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import Explore from './Sections/Explore'
import Discover from './Sections/Discover'
import FindTutors from './Sections/FindTutors'
import SearchTutors from './Sections/SearchTutors'
import Opportunities from './Sections/Opportunities'
import Footer from '@components/Footer'

const LandingPage = ({ navigation }) => {
    const { primary, primaryLight, primaryDark } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: primaryLight,
                width: '100%',
                pt: { xs: 2, sm: 6 },

                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: 'auto',
                    overflow: 'hidden',
                    p: isSm ? 4 : 2,
                }}
            >
                <Explore />
                <Discover />
                <FindTutors />
                <SearchTutors />
                <Opportunities />
            </Box>
            <Footer />
        </Box>
    )
}

export default LandingPage
