import StarIcon from '@icons/StarIcon'
import { Box, Typography } from '@mui/material'
import { useColorsContext } from '@utils/styles'

const Rating = ({ reviewCount, size = 'medium', rating }) => {
    const { text, textSecondary } = useColorsContext()

    const getSizeProps = (size) => {
        switch (size) {
            case 'small':
                return {
                    iconSize: 24,
                    typographyVariant: 'body2',
                    ratingVariant: 'body2',
                    fontFamily: 'Poppins_600SemiBold',
                }
            case 'large':
                return {
                    iconSize: 32,
                    typographyVariant: 'h6',
                    ratingVariant: 'body1',
                    fontFamily: 'Poppins_600SemiBold',
                }
            case 'medium':
            default:
                return {
                    iconSize: 26,
                    typographyVariant: 'h5',
                    ratingVariant: 'h6',
                    fontFamily: 'Poppins_600SemiBold',
                }
        }
    }

    const { iconSize, typographyVariant, fontFamily, ratingVariant } =
        getSizeProps(size)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <StarIcon size={iconSize} />
            <Typography
                variant={typographyVariant}
                fontFamily={fontFamily}
                sx={{
                    color: reviewCount > 0 ? text : textSecondary,
                    ml: 0.5,
                }}
            >
                {reviewCount > 0 ? rating : 'Unrated'}
            </Typography>
            {reviewCount > 0 && (
                <Typography
                    variant={ratingVariant}
                    sx={{
                        color: textSecondary,
                        marginLeft: 0.25,
                    }}
                >
                    ({reviewCount})
                </Typography>
            )}
        </Box>
    )
}

export default Rating
