import React from 'react'
import VerifiedFilterIcon from '@icons/VerifiedFilterIcon'
import { useColorsContext } from '@utils/styles'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import InstabookIcon from '@icons/InstabookIcon'
import SessionFeeIcon from '@icons/SessionFeeIcon'
import LocationIcon from '@icons/LocationIcon'
import StarIcon from '@icons/StarIcon'
import ExamPrepIcon from '@icons/ExamPrepIcon'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import SubjectIcon from '@icons/SubjectIcon'

const useGetFilterItemIcon = () => {
    return (filterItem: string) => {
        switch (filterItem) {
            case 'Subjects':
                return <SubjectIcon size={16} />
            case 'Price':
                return <SessionFeeIcon size={20} />
            case 'Location':
                return <LocationIcon size={16} />
            case 'Rating':
                return <StarIcon size={20} filled={false} />
            case 'Exams':
                return <ExamPrepIcon size={20} />
            case 'Specialties':
                return <SpecialtyIcon size={16} />
            case 'InstaBook':
                return <InstabookIcon size={24} />
            case 'Verified':
                return <VerifiedFilterIcon size={18} />
            default:
                return <></>
        }
    }
}

export default useGetFilterItemIcon
