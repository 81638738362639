import { useColorsContext } from '@utils/styles';
import React from 'react';

const NotificationsIcon = ({ size = 24 }) => {
    const { text } = useColorsContext();

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9888 7.25244C9.26251 7.25244 7.04626 9.46869 7.04626 12.1949C7.04626 12.6074 7.38376 12.9449 7.79626 12.9449C8.20876 12.9449 8.54626 12.6074 8.54626 12.1949C8.54626 10.2974 10.0913 8.75244 11.9888 8.75244C12.4013 8.75244 12.7388 8.41494 12.7388 8.00244C12.7388 7.58994 12.4013 7.25244 11.9888 7.25244Z" fill={text} />
            <path d="M20.715 18.5625H19.95V12.195C19.95 8.78625 17.79 5.88375 14.76 4.75875V3.50625C14.76 1.98375 13.5225 0.75 11.9963 0.75C10.4738 0.75 9.24 1.9875 9.24 3.50625V4.75125C6.21375 5.8725 4.05 8.77875 4.05 12.1912V18.5625H3.285C2.8725 18.5625 2.535 18.9 2.535 19.3125C2.535 19.725 2.8725 20.0625 3.285 20.0625H4.8H8.29125C8.565 21.8625 10.1213 23.25 12.0038 23.25C13.8863 23.25 15.4425 21.8625 15.7163 20.0625H19.2075H20.7225C21.135 20.0625 21.4725 19.725 21.4725 19.3125C21.4725 18.9 21.1313 18.5625 20.715 18.5625ZM10.74 3.50625C10.74 2.8125 11.3025 2.25 12.0038 2.25C12.6975 2.25 13.26 2.8125 13.26 3.50625V4.36125C12.8438 4.29375 12.42 4.24875 11.9888 4.24875C11.5613 4.24875 11.1488 4.29375 10.74 4.3575V3.50625ZM12 21.75C10.95 21.75 10.0688 21.0338 9.81375 20.0625H14.1825C13.9313 21.0338 13.05 21.75 12 21.75ZM5.54625 18.5625V12.195C5.54625 8.64375 8.4375 5.7525 12.0113 5.7525C15.5625 5.7525 18.4538 8.64375 18.4538 12.195V18.5625H5.54625Z" fill={text} />
        </svg>
    );
}

export default NotificationsIcon;
