import React from 'react'
import { Box } from '@mui/material'
import { useNotificationsContext } from '../../../contexts/notificationsContext'
import NotificationItem from '../NotificationItem'
import { Notification } from 'dataTypes'
import { FlatList } from 'react-native'
import SleepyDino from '@icons/sleepy-dino'
import EmptyNotifications from '../EmptyNotifications'
import NotificationListHeader from '../NotificationListHeader'
import { useColorsContext } from '@utils/styles'

const PendingNotifications = ({
    showHeader = true,
    dense = false,
    handleNavigateToNotifications,
}) => {
    const { pendingNotifications } = useNotificationsContext()
    const { primary } = useColorsContext()

    return (
        <Box sx={{ py: dense ? 0 : 4 }}>
            <FlatList
                ListHeaderComponent={
                    showHeader && (
                        <NotificationListHeader
                            showMarkAllAsRead={false}
                            titleColor={primary}
                            title={`${pendingNotifications.length} pending ${pendingNotifications.length === 1 ? 'request' : 'requests'}`}
                        />
                    )
                }
                data={pendingNotifications}
                style={{ paddingBottom: 16 }}
                renderItem={({ item, index }) => (
                    <NotificationItem
                        notification={item}
                        lastNotification={pendingNotifications[index - 1]}
                        index={index}
                        type="pending"
                        dense={dense}
                    />
                )}
                ListEmptyComponent={
                    <EmptyNotifications
                        title="No pending activity..."
                        dense={dense}
                        handleNavigateToNotifications={
                            handleNavigateToNotifications
                        }
                    />
                }
            />
        </Box>
    )
}

export default PendingNotifications
