import AddressAutoComplete from '@components/AddressAutoComplete'
import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useUserContext } from '@contexts/userContext'
import { A } from '@expo/html-elements'
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import { parse } from 'date-fns'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { useAlert } from 'react-alert'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import YourDetailsIcon from '@icons/YourDetailsIcon'
import FontAwesome from '@expo/vector-icons/FontAwesome'

interface YourDetailsForm {
    address2: string
    address: string
    city: string
    province: string
    postalCode: string
    country: string
}

interface YourDetailsProps {
    setStep: (step: number) => void
}

const YourDetails: React.FC<YourDetailsProps> = ({ setStep }) => {
    const { text, primaryExtraLight } = useColorsContext()

    const { db } = useFirebaseContext()
    const { user, extendedUser } = useUserContext()

    const [loading, setLoading] = useState(false)
    const { authenticatedAxios } = useAxiosWithAuth()
    const customAlert = useAlert()

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<YourDetailsForm>()

    const parseAddress = (address) => {
        setValue(
            'address',
            `${address.streetNumber ? address.streetNumber + ' ' : ''}${address.street}`
        )
        setValue('city', address.city)
        setValue('province', address.province)
        setValue('postalCode', address.postalCode)
        setValue('country', address.country)
    }

    useEffect(() => {
        if (extendedUser) {
            setValue('address', extendedUser?.location?.address)
            setValue('address2', extendedUser?.location?.address2)
            setValue('city', extendedUser?.location?.city)
            setValue('province', extendedUser?.location?.province)
            setValue('postalCode', extendedUser?.location?.postalCode)
            setValue('country', extendedUser?.location?.country)
        }
    }, [extendedUser])
    const watchCountry = watch('country')

    const handleSave = (data) => {
        setLoading(true)
        let urlId = Math.floor(100000 + Math.random() * 900000)
        // Hit api to create stripe account
        if (!extendedUser?.stripe?.confirmed) {
            const newAccount = {
                userId: user.uid,
                email: user.email,
                name: extendedUser?.name,
                dob: extendedUser?.birthday,
                address: data.address,
                postalCode: data.postalCode,
                city: data.city,
                province: data.province,
                country: data.country === 'United States' ? 'US' : 'CA',
                tutorUrl: extendedUser?.urlId || urlId,
            }
            authenticatedAxios
                .post('/api/create-account', newAccount)
                .then((res) => {
                    updateDoc(doc(db, 'users', user.uid), {
                        location: {
                            address: data.address,
                            address2: data.address2 || '',
                            city: data.city,
                            province: data.province,
                            postalCode: data.postalCode,
                            country: data.country,
                        },
                        onboarding: arrayUnion('details'),
                        URL: extendedUser?.urlId || urlId,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    customAlert.error(
                        '[Error] Something went wrong, please try again'
                    )
                })
                .finally(() => {
                    setStep(5)
                    setLoading(false)
                })
        } else {
            updateDoc(doc(db, 'users', user.uid), {
                location: {
                    address: data.address,
                    address2: data.address2 || '',
                    city: data.city,
                    province: data.province,
                    postalCode: data.postalCode,
                    country: data.country,
                },
                onboarding: arrayUnion('details'),
                URL: extendedUser?.urlId || urlId,
            })
            setStep(5)
            setLoading(false)
        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <OnboardingSectionHeader
                title="Your Details"
                subtitle="Provide your address details. This information is needed by our third-party payment processors to withdraw your payments."
                icon={<YourDetailsIcon size={28} />}
            />
            <Box
                sx={{
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item md={9}>
                            <AddressAutoComplete
                                control={control}
                                error={errors?.address}
                                setAddress={parseAddress}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Controller
                                defaultValue=""
                                name="address2"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputRef={ref}
                                        {...field}
                                        label="Unit No."
                                        error={!!errors?.address2}
                                        placeholder="Unit No."
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                defaultValue=""
                                name="city"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputRef={ref}
                                        {...field}
                                        label="City"
                                        error={!!errors?.city}
                                        placeholder="Enter your city"
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                defaultValue=""
                                name="province"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputRef={ref}
                                        {...field}
                                        label={
                                            watchCountry === 'United States'
                                                ? 'State'
                                                : 'Province'
                                        }
                                        error={!!errors?.province}
                                        placeholder={
                                            watchCountry === 'United States'
                                                ? 'Enter your State'
                                                : 'Enter your Province'
                                        }
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {' '}
                            <Controller
                                defaultValue=""
                                name="country"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputRef={ref}
                                        {...field}
                                        label="Country"
                                        error={!!errors?.country}
                                        placeholder="Enter your country"
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                defaultValue=""
                                name="postalCode"
                                control={control}
                                rules={{
                                    validate: {
                                        isValid: (value) => {
                                            if (
                                                watchCountry === 'United States'
                                            ) {
                                                return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
                                                    value
                                                )
                                            } else {
                                                return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
                                                    value
                                                )
                                            }
                                        },
                                    },
                                }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputRef={ref}
                                        inputProps={{
                                            maxLength:
                                                watchCountry === 'United States'
                                                    ? 5
                                                    : 6,
                                        }}
                                        {...field}
                                        label={
                                            watchCountry === 'United States'
                                                ? 'Zip Code'
                                                : 'Postal Code'
                                        }
                                        error={!!errors?.postalCode}
                                        placeholder={`Enter your ${watchCountry === 'United States' ? 'Zip Code' : 'Postal Code'}`}
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            gap: 1,
                            p: 4,
                        }}
                    >
                        <FontAwesome
                            name="question-circle-o"
                            size={32}
                            color={text}
                            style={{ marginTop: 4 }}
                        />
                        <Typography
                            variant="body2"
                            fontFamily="Poppins_600SemiBold"
                        >
                            Your privacy is important to us. Only your
                            state/province will be visible to students. Your
                            full address and identification details are kept
                            secure and private, and are only used for account
                            verification and security purposes.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <OnboardingFooter
                step={4}
                onNext={() => {
                    handleSubmit(handleSave)()
                }}
                onPrev={() => {
                    setStep(3)
                }}
                loading={loading}
            />
        </Box>
    )
}

const styles = StyleSheet.create({
    inputContainer: {
        marginBottom: 2,
    },
})

export default YourDetails
