import React from 'react'
import TutredBaseChip from '@components/Chips/TutredBaseChip'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import ExamChip from '@components/Chips/ExamChip'
import SubjectChip from '@components/Chips/SubjectChip'

interface Filter {
    label: string
    optionType: 'string' | 'object'
}

interface Option {
    label: string
}

interface TagProps {
    [key: string]: any
}

const useGetFilterChip = () => {
    return (
        key: number,
        filter: Filter,
        tagProps: TagProps,
        option: string | Option
    ) => {
        switch (filter.label) {
            case 'Subjects':
                return (
                    <SubjectChip
                        key={key}
                        item={
                            filter.optionType === 'string'
                                ? (option as string)
                                : (option as Option).label
                        }
                        {...tagProps}
                    />
                )
            case 'Exams':
                return (
                    <ExamChip
                        key={key}
                        item={
                            filter.optionType === 'string'
                                ? (option as string)
                                : (option as Option).label
                        }
                        {...tagProps}
                    />
                )
            case 'Specialties':
                return (
                    <SpecialtyChip
                        key={key}
                        item={
                            filter.optionType === 'string'
                                ? (option as string)
                                : (option as Option).label
                        }
                        {...tagProps}
                    />
                )

            default:
                return (
                    <TutredBaseChip
                        key={key}
                        label={
                            filter.optionType === 'string'
                                ? (option as string)
                                : (option as Option).label
                        }
                        {...tagProps}
                    />
                )
        }
    }
}

export default useGetFilterChip
