
const languageData = [
    { name: "English", icon: require("@assets/images/languages/English.svg") },
    { name: "French", icon: require("@assets/images/languages/French.svg") },
    { name: "Spanish", icon: require("@assets/images/languages/Spanish.svg") },
    { name: "Arabic", icon: require("@assets/images/languages/Arabic.svg") },
    { name: "Bengali", icon: require("@assets/images/languages/Bengali.svg") },
    { name: "Bulgarian", icon: require("@assets/images/languages/Bulgarian.svg") },
    { name: "Czech", icon: require("@assets/images/languages/Czech.svg") },
    { name: "Danish", icon: require("@assets/images/languages/Danish.svg") },
    { name: "Dutch", icon: require("@assets/images/languages/Dutch.svg") },
    { name: "Finnish", icon: require("@assets/images/languages/Finnish.svg") },
    { name: "German", icon: require("@assets/images/languages/German.svg") },
    { name: "Greek", icon: require("@assets/images/languages/Greek.svg") },
    { name: "Hebrew", icon: require("@assets/images/languages/Hebrew.svg") },
    { name: "Hindi", icon: require("@assets/images/languages/Hindi.svg") },
    { name: "Hungarian", icon: require("@assets/images/languages/Hungarian.svg") },
    { name: "Italian", icon: require("@assets/images/languages/Italian.svg") },
    { name: "Japanese", icon: require("@assets/images/languages/Japanese.svg") },
    { name: "Korean", icon: require("@assets/images/languages/Korean.svg") },
    { name: "Mandarin", icon: require("@assets/images/languages/Mandarin.svg") },
    { name: "Norwegian", icon: require("@assets/images/languages/Norway.svg") },
    { name: "Polish", icon: require("@assets/images/languages/Polish.svg") },
    { name: "Portuguese", icon: require("@assets/images/languages/Portugese.svg") },
    { name: "Romanian", icon: require("@assets/images/languages/Romanian.svg") },
    { name: "Russian", icon: require("@assets/images/languages/Russian.svg") },
    { name: "Swedish", icon: require("@assets/images/languages/Swedish.svg") },
    { name: "Tagalog", icon: require("@assets/images/languages/Tagolog.svg") },
    { name: "Thai", icon: require("@assets/images/languages/Thai.svg") },
    { name: "Turkish", icon: require("@assets/images/languages/Turkish.svg") },
    { name: "Ukrainian", icon: require("@assets/images/languages/Ukrainian.svg") },
    { name: "Urdu", icon: require("@assets/images/languages/Urdu.svg") },
    { name: "Vietnamese", icon: require("@assets/images/languages/Vietnamese.svg") },

]

const languages = languageData.map((language) => language.name)
const languageIcons = {}
languageData.forEach((language) => {
    languageIcons[language.name] = language.icon
})


module.exports = {
    languages,
    languageIcons,
}