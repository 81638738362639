import mixpanel from 'mixpanel-browser';

/**
 * Tracks a Mixpanel event only in production environment
 * @param eventName - The name of the event to track
 * @param properties - Optional properties to include with the event
 */
export const trackEvent = (eventName: string, properties?: Record<string, any>): void => {
    if (process.env.NODE_ENV === 'production') {
        mixpanel.track(eventName, properties);
    } else {
        console.log('trackEvent', eventName, properties)
    }
};
