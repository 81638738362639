import {
    addDays,
    addMinutes,
    format,
    getISODay,
    parse,
    parseISO,
    setHours,
    setMinutes,
    subMinutes,
} from 'date-fns'
import { Timestamp } from 'firebase/firestore'

function getDayInPast(dayOfWeek, minutes) {
    const today = new Date()
    const fromDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        minutes,
        0
    )
    // follow the getISODay format (7 for Sunday, 1 for Monday)
    const dayOfWeekMap = {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thur: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
    }

    // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek
    const targetISODay = dayOfWeekMap[dayOfWeek]
    const fromISODay = getISODay(fromDate)

    // targetISODay >= fromISODay means we need to trace back to last week
    // e.g. target is Wed(3), from is Tue(2)
    // hence, need to -7 the account for the offset of a week
    const offsetDays =
        targetISODay >= fromISODay
            ? -7 + (targetISODay - fromISODay)
            : targetISODay - fromISODay

    return addDays(fromDate, offsetDays)
}
export const getMinutesForTime = (timeString: string) => {
    const [time, period] = timeString.split(' ')
    const [hours, minutes] = timeString.split(':')
    let convertedHours = parseInt(hours)
    if (period === 'PM' && convertedHours !== 12) {
        convertedHours += 12
    }
    if (period == 'AM' && convertedHours == 12) {
        convertedHours = 0
    }

    return convertedHours * 60 + parseInt(minutes)
}

export const convertAvailabilityTime = (
    time: string,
    direction: 'out' | 'in',
    day?: string
) => {
    const timeZoneOffset = new Date().getTimezoneOffset()

    if (direction === 'in') {
        let newDate = new Date(time)
        newDate = addMinutes(newDate, -timeZoneOffset)
        const UTCHours = newDate.getUTCHours()
        const UTCMinutes = newDate.getUTCMinutes()
        const hoursIn12 = UTCHours % 12 || 12
        return `${hoursIn12}:${UTCMinutes === 0 ? '00' : UTCMinutes} ${UTCHours >= 12 ? 'PM' : 'AM'}`
    } else {
        const minutes = getMinutesForTime(time)

        const newDate = getDayInPast(day, minutes)

        addMinutes(newDate, timeZoneOffset)

        return newDate.toUTCString()
    }
}
