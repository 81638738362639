import PaymentMethod from '@components/PaymentMethod'
import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useUserContext } from '@contexts/userContext'

import { HelpOutline } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    Divider,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useColorsContext, useStyles } from '@utils/styles'
import { AxiosResponse } from 'axios'
import React, { useMemo, useState } from 'react'
import {
    ActivityIndicator,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import paymentMethodIcon from '@icons/payment-method.png'
import sessionRecordingIcon from '@icons/session-recording.png'
import serviceFeeIcon from '@icons/service-fee.png'
import totalIcon from '@icons/total.png'
import sessionLengthIcon from '@icons/session-length.png'
import sessionFeeIcon from '@icons/session-fee.png'
import { format } from 'date-fns'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import formatName from '@helpers/formatName'
import { useSessionContext } from '@contexts/sessionContext'
import { useAlert } from 'react-alert'
import SpecialtyIcon from '@icons/SpecialtyIcon'
import ExamPrepIcon from '@icons/ExamPrepIcon'
import NoteIcon from '@icons/NoteIcon'
import SubjectChip from '@components/Chips/SubjectChip'
import TutredChipDisplay from '@components/TutredChipDisplay'
import SubjectIcon from '@icons/SubjectIcon'
import SpecialtyChip from '@components/Chips/SpecialtyChip'
import ExamChip from '@components/Chips/ExamChip'
import InvoiceLineItem from '@components/InvoiceLineItem'
import SessionFeeIcon from '@icons/SessionFeeIcon'
import SessionRecordingFeeIcon from '@icons/SessionRecordingFeeIcon'
import SessionLengthIcon from '@icons/SessionLengthIcon'
import PaymentMethodIcon from '@components/PaymentMethodIcon'
import TotalIcon from '@icons/TotalIcon'
import ServiceFeeIcon from '@icons/ServiceFeeIcon'

interface Props {
    selectedPricingOption: any
    tutor: any
    setView: (view: number) => void
    details: any
    setVisible: (visible: boolean) => void
    selectedTime: Date
    view: number
    loading?: boolean
    setLoading?: (loading: boolean) => void
}

const ConfirmSession: React.FC<Props> = ({
    selectedPricingOption,
    tutor,
    setView,
    details,
    setVisible,
    selectedTime,
    view,
    loading,
    setLoading,
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [enableRecording, setEnableRecording] = useState(false)
    const [showPaymentChange, setShowPaymentChange] = useState(false)
    const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] =
        useState(false)
    // const { finalizeInstaBookSession } = useFinalizeInstaBookSession()
    // const navigation: NavigationProp<ParamListBase> = useNavigation()

    const { background, border, text, green, redVariant, primary } =
        useColorsContext()
    const { customerId, profile, user, name } = useUserContext()
    const { authenticatedAxios } = useAxiosWithAuth()
    const defaultStyles = useStyles()
    const { paymentMethods } = useUserContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const { createSession } = useSessionContext()
    const customAlert = useAlert()

    const totalSessionFee = selectedPricingOption?.rate
    const totalRecordingFee = enableRecording ? tutor.sessionRecordingFee : 0
    const taxesAndFees = useMemo(() => {
        return Number((totalSessionFee + totalRecordingFee) * 0.1)
    }, [totalSessionFee, totalRecordingFee])

    const totalCost = useMemo(() => {
        return totalSessionFee + totalRecordingFee + Number(taxesAndFees)
    }, [totalSessionFee, totalRecordingFee, taxesAndFees])

    const handleBooking = async (paymentMethodId) => {
        //TODO: refactor the payment logic.

        setLoading(true)
        const tutorPayout = (totalSessionFee + totalRecordingFee) * 0.9
        try {
            const firstCharge = await authenticatedAxios.post(
                `/api/authorize-charge`,
                {
                    amount: tutor.rate,
                    customerId: customerId,
                    accountId: tutor.stripe.accountId,
                    paymentMethodId,
                }
            )

            if (firstCharge?.data?.success) {
                let paymentIntentId = firstCharge?.data.paymentIntentId
                const secondCharge = await authenticatedAxios.post(
                    `/api/authorize-charge`,
                    {
                        amount: tutor.minfee,
                        customerId: customerId,
                        accountId: tutor.stripe.accountId,
                    }
                )
                if (secondCharge?.data?.success) {
                    let secondPaymentIntentId =
                        secondCharge?.data.paymentIntentId
                    let specialties = ''
                    if (
                        details?.specialties &&
                        details?.specialties.length > 0
                    ) {
                        specialties = details?.specialties
                            .map((s) => s.label)
                            .join(', ')
                    }

                    createSession(
                        details.note,
                        details?.subjects?.join(', '),
                        format(selectedTime, 'yyyy-MM-dd'),
                        format(selectedTime, 'p'),
                        specialties,
                        profile,
                        name,
                        user.uid,
                        selectedPricingOption?.length,
                        tutor.id,
                        tutor,
                        paymentIntentId,
                        customerId,
                        tutor?.autoAccept,
                        selectedTime,
                        tutorPayout,
                        enableRecording,
                        totalCost
                    )
                    await authenticatedAxios.post('/api/email/session', {
                        email: tutor.email,
                        other: name,
                        name: formatName(tutor.name),
                    })

                    customAlert.success(
                        '[Session Booked] Thank you for booking a session!'
                    )
                    setVisible(false)
                }
            }
        } catch (e) {
            console.error(e)
            customAlert.error(
                '[Error Booking Session] Something went wrong. Please try again later!'
            )
        }
    }

    const onSubmitPayment = async () => {
        setLoading(true)
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            console.error('CardElement not found')
            // setError('Card details not entered')
            return
        }

        const { error: paymentMethodError, paymentMethod } =
            await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

        if (paymentMethodError) {
            console.error(
                'Error creating payment method:',
                paymentMethodError.message
            )
            // setError(paymentMethodError.message)
            return
        }
        let newPaymentMethodId = null

        authenticatedAxios
            .post(`api/${customerId}/payment-method`, {
                paymentMethodId: paymentMethod.id,
                setAsDefault: newDefaultPaymentMethod,
            })
            .then(
                (
                    response: AxiosResponse<{
                        success: boolean
                        paymentMethodId: string
                    }>
                ) => {
                    newPaymentMethodId = response.data.paymentMethodId
                    setLoading(true)
                    handleBooking(newPaymentMethodId)
                }
            )
            .catch((error) => {
                console.log('error', error)
            })
    }

    return (
        <>
            <DialogContent
                sx={{
                    minWidth: { xs: '100%', md: 600 },
                    padding: 2,
                    display: view === 2 ? 'block' : 'none',
                }}
            >
                <Box>
                    <Typography
                        variant={isMd ? 'subtitle1' : 'subtitle2'}
                        sx={{
                            fontFamily: defaultStyles.titleFont,
                            marginBottom: isMd ? 2 : 1,
                            textAlign: 'center',
                            color: '#8c8c8c',
                        }}
                    >
                        Session Details
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{ textAlign: 'center', marginBottom: 2 }}
                    >
                        {format(selectedTime, 'PPPPp')}
                    </Typography>

                    <Divider
                        variant="middle"
                        sx={{
                            backgroundColor: border,
                            marginTop: isMd ? 2 : 1,
                            marginBottom: isMd ? 2 : 1,
                        }}
                    />

                    {details?.subjects && details?.subjects.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',

                                alignItems: 'center',
                                width: '100%',
                                marginBottom: 2,
                                rowGap: 1,
                            }}
                        >
                            <TutredChipDisplay
                                items={details?.subjects}
                                title="Subjects"
                                Chip={SubjectChip}
                                align="center"
                                containerStyle={{ marginTop: 0, width: '100%' }}
                                label={
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: 22,
                                                height: 22,
                                            }}
                                        >
                                            <SubjectIcon size={18} />
                                        </Box>
                                        <Typography
                                            variant={isMd ? 'body1' : 'body2'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                                marginLeft: 1,
                                                marginRight: 2,
                                            }}
                                        >
                                            Subjects:
                                        </Typography>
                                    </>
                                }
                            />
                        </Box>
                    )}

                    {details?.specialties &&
                        details?.specialties.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',

                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: 2,
                                    rowGap: 1,
                                }}
                            >
                                <TutredChipDisplay
                                    items={details?.specialties}
                                    title="Specialties"
                                    Chip={SpecialtyChip}
                                    align="center"
                                    containerStyle={{
                                        marginTop: 0,
                                        width: '100%',
                                    }}
                                    label={
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    width: 22,
                                                    height: 22,
                                                }}
                                            >
                                                <SpecialtyIcon size={18} />
                                            </Box>
                                            <Typography
                                                variant={
                                                    isMd ? 'body1' : 'body2'
                                                }
                                                sx={{
                                                    fontFamily:
                                                        defaultStyles.titleFont,
                                                    marginLeft: 1,
                                                    marginRight: 2,
                                                }}
                                            >
                                                Specialties:
                                            </Typography>
                                        </>
                                    }
                                />
                            </Box>
                        )}
                    {details?.exams && details?.exams.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',

                                alignItems: 'center',
                                width: '100%',
                                marginBottom: 2,
                                rowGap: 1,
                            }}
                        >
                            <TutredChipDisplay
                                items={details?.exams}
                                title="Exam Prep"
                                Chip={ExamChip}
                                align="center"
                                containerStyle={{ marginTop: 0, width: '100%' }}
                                label={
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: 22,
                                                height: 22,
                                            }}
                                        >
                                            <ExamPrepIcon size={22} />
                                        </Box>
                                        <Typography
                                            variant={isMd ? 'body1' : 'body2'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                                marginLeft: 1,
                                                marginRight: 2,
                                            }}
                                        >
                                            Exams:
                                        </Typography>
                                    </>
                                }
                            />
                        </Box>
                    )}
                    {details?.note && details?.note.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',

                                wordWrap: 'break-word',
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: 22,
                                    height: 22,
                                }}
                            >
                                <NoteIcon />
                            </Box>
                            <Typography
                                variant={isMd ? 'body1' : 'body2'}
                                sx={{
                                    fontFamily: defaultStyles.titleFont,
                                    marginLeft: 2,
                                }}
                            >
                                Note:
                            </Typography>

                            <Typography
                                variant={isMd ? 'body2' : 'caption'}
                                sx={{
                                    fontFamily: defaultStyles.regularFont,
                                    padding: 1,
                                    textWrap: 'wrap',
                                }}
                            >
                                {details.note}
                            </Typography>
                        </Box>
                    )}
                    <Divider
                        variant="middle"
                        sx={{
                            backgroundColor: border,
                            marginTop: isMd ? 2 : 1,
                            marginBottom: isMd ? 2 : 1,
                        }}
                    />

                    <InvoiceLineItem
                        icon={<SessionFeeIcon size={22} />}
                        label="Session Fee"
                        amount={totalSessionFee}
                    />

                    {tutor.allowSessionRecording && (
                        <Box
                            sx={{
                                marginBottom: isMd ? 2 : 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: 22,
                                        height: 22,
                                    }}
                                >
                                    <SessionRecordingFeeIcon size={22} />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        variant={isMd ? 'body1' : 'body2'}
                                        sx={{
                                            fontFamily: defaultStyles.titleFont,
                                            ml: 2,
                                        }}
                                    >
                                        Would you like to enable session
                                        recording?
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {' '}
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.regularFont,
                                                marginRight: 0.5,
                                                ml: 2,
                                            }}
                                        >
                                            You can add a session recording for
                                        </Typography>
                                        <Typography
                                            variant={isMd ? 'body2' : 'caption'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                            }}
                                        >
                                            {tutor.sessionRecordingFee > 0
                                                ? `$${tutor.sessionRecordingFee.toFixed(2)}`
                                                : 'FREE'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Checkbox
                                checked={enableRecording}
                                onChange={() =>
                                    setEnableRecording(!enableRecording)
                                }
                            />
                        </Box>
                    )}

                    <Divider
                        variant="middle"
                        sx={{
                            backgroundColor: border,
                            marginTop: isMd ? 2 : 1,
                            marginBottom: isMd ? 2 : 1,
                        }}
                    />
                    <InvoiceLineItem
                        icon={<SessionLengthIcon size={22} />}
                        label={`${selectedPricingOption?.length} min session`}
                        amount={selectedPricingOption?.rate || 0.0}
                    />

                    <InvoiceLineItem
                        icon={<ServiceFeeIcon size={22} />}
                        label="Service Fee"
                        amount={taxesAndFees}
                        tooltip="Includes taxes and our service fee."
                    />
                    {totalRecordingFee > 0 && (
                        <InvoiceLineItem
                            icon={<SessionRecordingFeeIcon size={22} />}
                            label="Session Recording Fee"
                            amount={totalRecordingFee}
                        />
                    )}
                    <InvoiceLineItem
                        icon={<TotalIcon size={22} />}
                        label="Total"
                        amount={totalCost}
                    />

                    {paymentMethods?.length > 0 && (
                        <Box>
                            {showPaymentChange ? (
                                <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                width: 22,
                                                height: 22,
                                            }}
                                        >
                                            <PaymentMethodIcon size={22} />
                                        </Box>
                                        <Typography
                                            variant={isMd ? 'body1' : 'body2'}
                                            sx={{
                                                fontFamily:
                                                    defaultStyles.titleFont,
                                                marginLeft: 2,
                                            }}
                                        >
                                            Payment Method:
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            border: '1px solid #000',
                                            padding: 2,
                                            marginTop: 1,
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <CardElement />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            marginTop: 1,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Checkbox
                                                value={newDefaultPaymentMethod}
                                                onChange={() => {
                                                    setNewDefaultPaymentMethod(
                                                        !newDefaultPaymentMethod
                                                    )
                                                }}
                                                size="small"
                                            />
                                            <Typography variant="body2">
                                                Set as default?
                                            </Typography>
                                        </Box>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setShowPaymentChange(false)
                                            }}
                                        >
                                            <Typography
                                                variant={
                                                    isMd ? 'body2' : 'caption'
                                                }
                                                sx={{
                                                    fontFamily:
                                                        defaultStyles.boldFont,
                                                    textAlign: 'right',
                                                    color: primary,
                                                }}
                                            >
                                                Cancel
                                            </Typography>
                                        </TouchableOpacity>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{ marginBottom: isMd ? 2 : 1 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    width: 22,
                                                    height: 22,
                                                }}
                                            >
                                                <PaymentMethodIcon size={22} />
                                            </Box>
                                            <Typography
                                                variant={
                                                    isMd ? 'body1' : 'body2'
                                                }
                                                sx={{
                                                    marginLeft: 2,
                                                    fontFamily:
                                                        defaultStyles.titleFont,
                                                }}
                                            >
                                                Payment Method:
                                            </Typography>
                                        </Box>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setShowPaymentChange(true)
                                            }}
                                        >
                                            <Typography
                                                variant={
                                                    isMd ? 'body2' : 'caption'
                                                }
                                                sx={{
                                                    fontFamily:
                                                        defaultStyles.boldFont,
                                                    textAlign: 'right',
                                                    color: primary,
                                                }}
                                            >
                                                Change payment method
                                            </Typography>
                                        </TouchableOpacity>
                                    </Box>
                                    <PaymentMethod
                                        brand={paymentMethods[0].card.brand}
                                        endingIn={paymentMethods[0].card.last4}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: view === 2 ? 'flex' : 'none' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        setView(1)
                    }}
                    disabled={loading}
                >
                    Back
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    onClick={() => {
                        if (showPaymentChange) {
                            onSubmitPayment()
                        } else {
                            handleBooking(paymentMethods[0].id)
                        }
                    }}
                >
                    {loading ? <ActivityIndicator /> : 'Book'}
                </Button>
            </DialogActions>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
    },
    button: {
        width: 100,
        height: 40,
        marginLeft: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default ConfirmSession
