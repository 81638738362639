import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
    DialogContent,
    DialogActions,
    TextField,
    Button,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
    Divider,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useMutation } from '@tanstack/react-query'
import { useAxiosWithAuth } from '@contexts/axiosContext'

import { ActivityIndicator } from 'react-native'

interface FormData {
    email: string
}
interface Props {
    next: (email: string) => void
    onClose: () => void
    email: string
}

const SendResetEmail: React.FC<Props> = ({ next, onClose, email }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()
    const { primary, text } = useColorsContext()
    const defaultStyles = useStyles()

    const onSubmit = (data: FormData) => {
        next(data.email)
    }

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { authenticatedAxios } = useAxiosWithAuth()

    const { mutate: sendResetEmail, isPending } = useMutation({
        mutationFn: async (data: FormData) => {
            return authenticatedAxios.post(`/api/reset-password`, {
                email: data.email,
            })
        },
        onSuccess: (response, variables) => {
            // alert.success('[Reset Password] A reset email has been sent!')
            next(variables.email)
        },
    })

    return (
        <>
            <DialogTitle>
                <Typography
                    variant={isSm ? 'h4' : 'h6'}
                    fontFamily={defaultStyles.titleFont}
                    textAlign={'center'}
                    sx={{ marginBottom: 1 }}
                >
                    Reset your password
                </Typography>
                <Typography
                    variant={isSm ? 'body1' : 'body2'}
                    sx={{
                        color: primary,
                        textAlign: 'center',
                    }}
                >
                    We'll send a reset code to the email provided.
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 4, mb: 4 }}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue={email}
                    rules={{
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email"
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            margin="normal"
                            variant="outlined"
                        />
                    )}
                />
            </DialogContent>
            <Divider variant="middle" sx={{ mt: isSm ? 2 : 1 }} />
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: isSm ? 4 : 2,
                }}
            >
                <Button onClick={onClose} fullWidth variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit((data) => sendResetEmail(data))}
                    variant="contained"
                    fullWidth
                    disabled={isPending}
                >
                    {isPending ? <ActivityIndicator size="small" /> : 'Request'}
                </Button>
            </DialogActions>
        </>
    )
}

export default SendResetEmail
function useAxios() {
    throw new Error('Function not implemented.')
}

function useAlert() {
    throw new Error('Function not implemented.')
}
