import React from 'react'
import mixpanel from 'mixpanel-browser'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
    getFocusedRouteNameFromRoute,
    NavigationContainer,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useFonts } from 'expo-font'
import { ChatsStack } from './navigators/loggedIn/ChatsStack'
import { ProfileStack } from './navigators/loggedIn/ProfileStack'
import { SessionsStack } from './navigators/loggedIn/SessionsStack'
import { TutorsStack } from './navigators/TutorsStack'
import { Privacy } from './screens/Privacy'
import { TOS } from './screens/Tos'
import { Chats } from './screens/loggedIn/Chats/Chats'
import { useEffect, useState } from 'react'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { Provider, positions } from 'react-alert'
import { ActivityIndicator, Platform } from 'react-native'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { DialogContent, useMediaQuery, useTheme } from '@mui/material'

let Helmet

if (Platform.OS === 'web') {
    Helmet = require('react-helmet').Helmet
}

import { MenuProvider } from 'react-native-popup-menu'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'

import { AlertTemplate } from './components/Alert'
import { FirebaseContextProvider } from './contexts/firebaseContext'
import { SessionContextProvider } from './contexts/sessionContext'
import { UserContextProvider, useUserContext } from './contexts/userContext'
import { NotificationsProvider } from './contexts/notificationsContext'
import { ListingsStack } from './navigators/loggedIn/ListingsStack'

import {
    ColorsContextProvider,
    useColorsContext,
    useStyles,
} from './utils/styles'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { LoadingPage } from './components/LoadingPage'
import { About } from './components/About'
import { Press } from '@components/Press'
import { FAQ } from '@components/FAQ'
import { Blog } from '@components/Blog'
import HelpStack from './navigators/HelpStack'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AxiosProvider } from '@contexts/axiosContext'
import { ThemeProvider } from '@mui/material'
import {
    Poppins_100Thin,
    Poppins_200ExtraLight,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_800ExtraBold,
    Poppins_900Black,
} from '@expo-google-fonts/poppins'
import {
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
} from '@expo-google-fonts/inter'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import VerifyEmailDialog from '@components/VerifyEmailDialog'
import { Header } from '@components/Header'
import { Header as AuthHeader } from '@components/AuthHeader'
import { SettingsStack } from './navigators/loggedIn/SettingsStack'
import { NotificationsStack } from './navigators/loggedIn/NotificationsStack'
import { StudentsStack } from './navigators/loggedIn/StudentsStack'
import { FavouritesStack } from './navigators/loggedIn/FavouritesStack'
import LandingPage from '@screens/LandingPage'
import { DialogProvider } from '@contexts/dialogContext'
import { parse } from 'path'
import useCustomMuiTheme from '@hooks/useCustomMuiTheme'

if (process.env.NODE_ENV === 'production' && process.env.MIXPANEL_TOKEN) {
    mixpanel.init(process.env.MIXPANEL_TOKEN, {
        debug: false,
        track_pageview: 'full-url',
        persistance: 'localStorage',
    })
}

const Tab = createBottomTabNavigator()
const Stack = createNativeStackNavigator()

const stripePromise = loadStripe(
    'pk_test_51PLWE7P5dtDg92MqH8V8CK7IureECRnLGStHiqja691B9iW2SHuk8U7uqDsjZVFerY0kjfsAzIxF5dv1G92uCKFK005KwJl7bE'
)
// This object sets up deep linking within the app
const queryClient = new QueryClient()

const linking = {
    prefixes: [
        /* your linking prefixes */
    ],
    config: {
        screens: {
            Home: {
                screens: {
                    Privacy: 'privacy',
                    About: 'about',
                    Press: 'press',
                    TOS: 'tos',
                    FAQ: 'faq',
                    Blog: 'blog',
                    LandingPage: {
                        screens: {
                            LangingPage: {
                                path: '/',
                            },
                        },
                    },
                    Help: {
                        screens: {
                            Help: 'help',
                            HelpArticle: {
                                path: 'help/:slug',
                            },
                        },
                    },
                    Tutors: {
                        screens: {
                            TutorList: {
                                path: 'tutors',
                                parse: {
                                    subject: (subject) => `${subject}`,
                                },
                            },
                            TutorProfile: {
                                path: 'tutors/:id',
                            },
                            Favourites: {
                                path: 'tutors/favourites',
                            },
                        },
                    },
                    Favourites: {
                        screens: {
                            Favourites: 'favourites',
                            TutorProfile: {
                                path: 'favourites/:id',
                            },
                        },
                    },
                    Sessions: {
                        screens: {
                            Sessions: 'sessions',
                            Meeting: {
                                path: 'sessions/:sessionId',
                                parse: {
                                    sessionId: (sessionId) => `${sessionId}`,
                                },
                            },
                        },
                    },
                    Notifications: {
                        screens: {
                            Notifications: 'notifications',
                        },
                    },
                    Chats: {
                        screens: {
                            Chats: 'chats',
                        },
                    },
                    Students: {
                        screens: {
                            Students: 'students',
                            StudentProfile: {
                                path: 'students/:student',
                                parse: {
                                    student: (student) => `${student}`,
                                },
                            },
                        },
                    },
                    Profile: {
                        screens: {
                            Profile: {
                                path: 'profile',
                            },
                            EditProfile: {
                                path: 'profile/edit',
                            },
                        },
                    },
                    Settings: {
                        screens: {
                            Settings: {
                                path: 'settings',
                            },
                            Account: {
                                path: 'settings/account',
                            },
                            Notifications: {
                                path: 'settings/notifications',
                            },
                            EditProfile: {
                                path: 'settings/profile',
                            },
                            PaymentProfile: {
                                path: 'settings/payment',
                            },
                            Performance: {
                                path: 'settings/performance',
                            },
                            InstaBook: {
                                path: 'settings/instabook',
                            },
                            SessionSettings: {
                                path: 'settings/sessions',
                            },
                        },
                    },
                },
            },
        },
    },
}

// Main navigator for the home screen, which includes the bottom tab bar navigation
const HomeNavigator = () => {
    const defaultStyles = useStyles()
    const {
        darkMode,
        text,
        textVariant,
        border,
        backgroundVariant,
        setDarkMode,
    } = useColorsContext()
    const { user, loading, isTutor } = useUserContext()
    const [emailVerificationDialog, setEmailVerificationDialog] =
        useState(false)
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    let hideNav = Platform.OS === 'web' && isSm ? 'none' : null

    useEffect(() => {
        if (user == null) {
            setDarkMode(false)
        }
    }, [user, setDarkMode])

    useEffect(() => {
        if (user && user.email) {
            setDarkMode(true)
        }
    }, [user, setDarkMode])

    useEffect(() => {
        if (user && !user?.emailVerified) {
            setEmailVerificationDialog(true)
        }
    }, [user, user?.emailVerified])

    if (loading) {
        return <LoadingPage />
    }

    return (
        <>
            {user && !user.emailVerified && (
                <VerifyEmailDialog
                    open={emailVerificationDialog}
                    setOpen={setEmailVerificationDialog}
                />
            )}
            {Platform.OS === 'web' && (
                <Helmet>
                    {darkMode ? (
                        <style>{`
              ::-webkit-scrollbar {
                height: 12px;
              }
              ::-webkit-scrollbar-track {
                background: #1F1F1F;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb {
                background: #3D3D3D;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #5B5B5B;
              }
            `}</style>
                    ) : (
                        <style>{`
              ::-webkit-scrollbar {
                height: 12px;
              }
              ::-webkit-scrollbar-track {
                background: #F9F9F9;
                     border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb {
                background: #D6D6D6;
                border-radius: 100px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #BFBFBF;
              }
            `}</style>
                    )}
                </Helmet>
            )}
            {/* <Header navigation={navigation}></Header> */}
            <Tab.Navigator
                tabBar={() => null}
                screenOptions={{
                    header: ({ navigation, route }) => {
                        const name = getFocusedRouteNameFromRoute(route)
                        if (name === 'Meeting') return null
                        if (user) {
                            return (
                                <Header navigation={navigation} route={route} />
                            )
                        } else {
                            return <AuthHeader navigation={navigation} />
                        }
                    },
                }}
            >
                {user == null && (
                    <Tab.Screen
                        name="LandingPage"
                        component={LandingPage}
                        options={{
                            tabBarButton: () => null,
                            header: () => {
                                return <AuthHeader />
                            },
                            title: 'Tutred',
                        }}
                    />
                )}

                {user && (
                    <>
                        <Tab.Screen
                            name="Sessions"
                            component={SessionsStack}
                            options={({ route, navigation }) => {
                                return {
                                    headerShown: true,
                                    tabBarIcon: ({ color, size }) => (
                                        <MaterialCommunityIcons
                                            name="calendar"
                                            color={color}
                                            size={size}
                                        />
                                    ),
                                }
                            }}
                        />

                        <Tab.Screen
                            name="Chats"
                            component={ChatsStack}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <MaterialCommunityIcons
                                        name="chat"
                                        color={color}
                                        size={size}
                                    />
                                ),
                            }}
                        />
                        <Tab.Screen
                            name="Notifications"
                            component={NotificationsStack}
                        />
                        {isTutor && (
                            <Tab.Screen
                                name="Students"
                                component={StudentsStack}
                            />
                        )}
                        <Tab.Screen name="Settings" component={SettingsStack} />
                        <Tab.Screen name="Profile" component={ProfileStack} />
                        <Tab.Screen
                            name="Favourites"
                            component={FavouritesStack}
                        />
                    </>
                )}

                <Tab.Screen name="Tutors" component={TutorsStack} />

                <Tab.Screen name="Help" component={HelpStack} />

                {/* 
                <Tab.Screen
                    name="Profile"
                    component={ProfileStack}
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ color, size }) => (
                            <MaterialCommunityIcons
                                name="account"
                                color={color}
                                size={size}
                            />
                        ),
                    }}
                /> */}

                <Tab.Screen
                    name="Privacy"
                    component={Privacy}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />

                <Tab.Screen
                    name="TOS"
                    component={TOS}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />
                <Tab.Screen
                    name="About"
                    component={About}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />
                <Tab.Screen
                    name="Press"
                    component={Press}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />
                <Tab.Screen
                    name="FAQ"
                    component={FAQ}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />
                <Tab.Screen
                    name="Blog"
                    component={Blog}
                    options={{
                        headerShown: false,
                        tabBarButton: () => null,
                    }}
                />
            </Tab.Navigator>
        </>
    )
}

// Wrapper for navigators, using react-navigation
const NavigationWrapper = ({ children }) => {
    const { darkMode } = useColorsContext()

    // Fonts
    const [fontsLoaded] = useFonts({
        Poppins_100Thin,
        Poppins_200ExtraLight,
        Poppins_300Light,
        Poppins_400Regular,
        Poppins_500Medium,
        Poppins_600SemiBold,
        Poppins_700Bold,
        Poppins_800ExtraBold,
        Poppins_900Black,
        Inter_400Regular,
        Inter_500Medium,
        Inter_600SemiBold,
        Inter_700Bold,
        Inter_800ExtraBold,
        Inter_900Black,
    })

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const customMuiTheme = useCustomMuiTheme()

    if (!fontsLoaded) {
        return <ActivityIndicator style={{ flex: 1 }} />
    }

    return (
        <ThemeProvider theme={customMuiTheme}>
            <SafeAreaProvider>
                <NavigationContainer linking={linking}>
                    <DialogProvider>
                        <MenuProvider>
                            <Stack.Navigator initialRouteName="Home">
                                <Stack.Screen
                                    name="Home"
                                    component={HomeNavigator}
                                    options={{ headerShown: false }}
                                />
                            </Stack.Navigator>
                        </MenuProvider>
                    </DialogProvider>
                </NavigationContainer>
            </SafeAreaProvider>
        </ThemeProvider>
    )
}

// Main App component
export default function App() {
    // Alert options
    const options = {
        position: positions.TOP_RIGHT,
        timeout: 10000,
        containerStyle: {
            zIndex: 9999,
        },
    }

    // Using various providers to wrap the application, ensuring context and other functionalities are available throughout the app
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
                <FirebaseContextProvider>
                    <ColorsContextProvider>
                        <Provider template={AlertTemplate} {...options}>
                            <UserContextProvider>
                                <AxiosProvider>
                                    <NotificationsProvider>
                                        <SessionContextProvider>
                                            <ActionSheetProvider
                                                useCustomActionSheet={
                                                    Platform.OS === 'web'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <Elements
                                                    stripe={stripePromise}
                                                    options={{
                                                        appearance: {
                                                            theme: 'night',
                                                        },
                                                    }}
                                                >
                                                    <NavigationWrapper />
                                                </Elements>
                                            </ActionSheetProvider>
                                        </SessionContextProvider>
                                    </NotificationsProvider>
                                </AxiosProvider>
                            </UserContextProvider>
                        </Provider>
                    </ColorsContextProvider>
                </FirebaseContextProvider>
            </QueryClientProvider>
        </LocalizationProvider>
    )
}
