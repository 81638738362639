import { useColorsContext } from '@utils/styles';
import Svg, { Path, G } from 'react-native-svg';

const NoteIcon = ({
    size = 20,
}) => {

    const { text, } = useColorsContext();
    return (
        <Svg width={size} height={size} viewBox="0 0 24 24">
            <G data-name="Layer 2">
                <G fill="none" stroke={text} strokeLinecap="round" strokeLinejoin="round" data-name="Layer 1">
                    <Path d="m6.972 19.517-5.858-1.098L4.086 2.561l6.313 1.183M17.36 19.32l-.389 2.072-7.355-1.379" />
                    <Path d="m5.6 4.173 16.07-1.436 1.437 16.07-16.07 1.436z" />
                    <Path d="M10.757 5.678v1.777a1.377 1.377 0 0 0 2.754 0V1.639a1.033 1.033 0 0 0-2.065 0V3.65" />
                </G>
                <Path fill="none" d="M0 0h24v24H0z" data-name="Layer 2" />
            </G>
        </Svg>
    );
};

export default NoteIcon;