import { useColorsContext } from '@utils/styles';
import React from 'react';

const ServiceFeeIcon = ({ size = 24 }) => {
    const { text } = useColorsContext();

    return (
        <svg
            id="OBJECT"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={text}
        >
            <path d="M406.72,147.19A42.74,42.74,0,0,0,410,131c0-18-11.36-43.64-65.46-63.57C307,53.61,257.48,46,205,46S103,53.61,65.46,67.43C11.36,87.36,0,113,0,131V381c0,18,11.36,43.64,65.46,63.57C103,458.39,152.52,466,205,466a488.14,488.14,0,0,0,90.65-8.19A164.31,164.31,0,0,0,347,466c91,0,165-74,165-165C512,231.07,468.27,171.17,406.72,147.19ZM205,76c106.83,0,175,32.57,175,55a14.89,14.89,0,0,1-2.57,7.82,164.86,164.86,0,0,0-148,46.58c-8.09.39-16.27.6-24.39.6C98.17,186,30,153.43,30,131S98.17,76,205,76ZM30,177.19c9,6,20.65,11.93,35.46,17.38C103,208.39,152.52,216,205,216h.63A164,164,0,0,0,182,301c0,3.18.1,6.35.28,9.49C141,308.63,103.06,301.75,74,290.75c-32.47-12.31-44-26-44-34.75ZM205,436C98.17,436,30,403.43,30,381V302.17c34.77,23.24,95.23,36.37,156.83,38.51A165.46,165.46,0,0,0,249.46,434C235,435.32,220,436,205,436Zm142,0c-74.44,0-135-60.56-135-135s60.56-135,135-135,135,60.56,135,135S421.44,436,347,436Z" />
            <path d="M334.8,248.88h58a15,15,0,0,0,0-30H362V211a15,15,0,0,0-30,0v8a48.56,48.56,0,0,0,2.8,97h24.4a18.56,18.56,0,0,1,0,37.12h-58a15,15,0,0,0,0,30H332V391a15,15,0,0,0,30,0v-8a48.56,48.56,0,0,0-2.8-97H334.8a18.56,18.56,0,0,1,0-37.12Z" />
        </svg>
    );
};

export default ServiceFeeIcon;