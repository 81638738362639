import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

import { useUserContext } from "../contexts/userContext";

import TutorList from "@screens/TutorList";
import TutorProfile from "@screens/loggedIn/Tutors/TutorProfile";

const Stack = createNativeStackNavigator();

export const TutorsStack = () => {

	return (
		<Stack.Navigator initialRouteName="Tutors">
			<Stack.Screen
				options={{ headerShown: false, title: "Tutors" }}
				name="TutorList"
				component={TutorList}
			/>
			<Stack.Screen
				options={{ headerShown: false, title: "Tutor Profile" }}
				name="TutorProfile"
				component={TutorProfile}
			/>
		</Stack.Navigator>
	);
};
