import React from 'react'
import {
    Badge,
    Box,
    Divider,
    Tab,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useColorsContext } from '../../utils/styles'

interface LinkTabProps {
    value: string
    label: string
    onClick: () => void
    icon: React.ReactNode
    badgeContent?: string | number
}

export const LinkTab = ({
    value,
    label,
    onClick,
    icon,
    badgeContent,
}: LinkTabProps) => {
    const { text } = useColorsContext()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Badge
            badgeContent={badgeContent}
            color="primary"
            overlap="circular"
            sx={{
                '& .MuiBadge-badge': {
                    right: 8,
                    top: 15,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {label === 'Profile' && (
                    <Divider orientation="vertical" flexItem variant="middle" />
                )}
                <Tab
                    aria-label={label}
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        lineHeight: 1,
                        textTransform: 'capitalize',
                        fontWeight: 900,
                        px: 2,
                        py: 0,
                        minHeight: 48,
                        fontSize: '1rem',
                        opacity: 1,
                        maxWidth: 300,
                    }}
                    value={value}
                    label={
                        <Typography
                            variant={isSm ? 'body1' : 'subtitle2'}
                            fontFamily="Poppins_600SemiBold"
                            color={text}
                        >
                            {label}
                        </Typography>
                    }
                    onClick={onClick}
                    iconPosition="start"
                    icon={<Box sx={{ marginRight: 1 }}>{icon}</Box>}
                />
            </Box>
        </Badge>
    )
}
