import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

interface InputIconLabelProps {
    icon: React.ReactNode
    label: string
    optional?: boolean
    description?: string
    center?: boolean
}

const InputIconLabel: React.FC<InputIconLabelProps> = ({
    icon,
    label,
    optional = false,
    description,
    center = false,
}) => {
    const theme = useTheme()

    return (
        <Box sx={{ mb: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: center ? 'center' : 'flex-start',
                }}
            >
                {icon}
                <Typography
                    variant="h6"
                    fontFamily="Poppins_600SemiBold"
                    sx={{ mr: 0.5, ml: 0.5 }}
                    lineHeight={1}
                >
                    {label}
                </Typography>
                {optional && (
                    <Typography
                        variant="body1"
                        fontFamily="Poppins_600SemiBold"
                        color={theme.palette.grey[500]}
                        lineHeight={1}
                    >
                        (Optional)
                    </Typography>
                )}
            </Box>
            {description && (
                <Typography
                    variant="caption"
                    fontFamily="Poppins_500Medium"
                    lineHeight={1}
                    sx={{
                        display: 'block',
                        mt: 1,
                        textAlign: center ? 'center' : 'left',
                    }}
                >
                    {description}
                </Typography>
            )}
        </Box>
    )
}

export default InputIconLabel
