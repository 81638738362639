import OnboardingFooter from '@components/OnboardingDialog/OnboardingFooter'
import { useUserContext } from '@contexts/userContext'
import {
    Box,
    Card,
    Divider,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { doc, getFirestore } from 'firebase/firestore'
import { arrayUnion } from 'firebase/firestore'
import { updateDoc } from 'firebase/firestore'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { ScrollView, StyleSheet } from 'react-native'
import { useAlert } from 'react-alert'
import OnboardingSectionHeader from '@components/OnboardingDialog/OnboardingSectionHeader'
import AboutMeIcon from '@icons/AboutMeIcon'
import Feather from '@expo/vector-icons/Feather'
import { useColorsContext } from '@utils/styles'
import InputIconLabel from '@components/InputIconLabel'
import LightBulbIcon from '@icons/LightBulbIcon'
import SessionRateIcon from '@icons/SessionRateIcon'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { useEffect, useMemo, useState, useRef } from 'react'
import { decode } from 'html-entities'
import ProfileIcon from '@icons/ProfileIcon'
import InformationIcon from '@icons/InformationIcon'

interface AboutYouForm {
    headline: string
    description: string

    sessionPricing: {
        30: {
            rate: number
            length: number
        }
        60: {
            rate: number
            length: number
        }
    }
}

const AboutYou = ({ setStep }) => {
    const { extendedUser } = useUserContext()
    const { user } = useUserContext()
    const { text, primaryExtraLight } = useColorsContext()
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        setError,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm<AboutYouForm>()

    const scrollRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setValue(
            'sessionPricing',
            extendedUser?.sessionPricing || {
                30: {
                    length: 30,
                    rate: '',
                },
                60: {
                    length: 60,
                    rate: '',
                },
            },
            { shouldDirty: true }
        )
    }, [extendedUser?.sessionPricing])

    const alert = useAlert()
    const handleSave = (data: AboutYouForm) => {
        const db = getFirestore()
        updateDoc(doc(db, 'users', user.uid), {
            headline: data.headline,
            description: data.description,
            sessionPricing: data.sessionPricing,
            onboarding: arrayUnion('about'),
        })
            .then(() => {
                setStep(4)
            })
            .catch((error) => {
                alert.error('[Error] Failed to save.')
            })
    }

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const sessionPricing = watch('sessionPricing')

    const pricingOptions = useMemo(() => {
        if (sessionPricing) {
            return Object.keys(sessionPricing).map((key) => {
                const pricing = sessionPricing[key]
                return {
                    label: `${key} mins`,
                    contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate || 0}`,
                    value: pricing,
                    error: errors?.sessionPricing?.[key]?.rate,
                }
            })
        } else {
            return []
        }
    }, [
        sessionPricing?.[30]?.rate,
        sessionPricing?.[60]?.rate,
        errors?.sessionPricing?.[30]?.rate,
        errors?.sessionPricing?.[60]?.rate,
    ])

    const [selectedSessionRate, setSelectedSessionRate] = useState(0)
    const validatePricing = (data: AboutYouForm): boolean => {
        let isValid = true

        // Check 30 min rate
        if (!data.sessionPricing[30].rate) {
            setError('sessionPricing.30.rate', {
                type: 'manual',
                message: 'Please set a rate for 30 minute sessions',
            })
            isValid = false
        } else {
            const rate30 = data.sessionPricing[30].rate
            if (rate30 < 20 || rate30 > 80) {
                setError('sessionPricing.30.rate', {
                    type: 'manual',
                    message:
                        '30-minute session rate must be between CA$20 and CA$80',
                })
                isValid = false
            } else {
                clearErrors('sessionPricing.30.rate')
            }
        }

        // Check 60 min rate
        if (!data.sessionPricing[60].rate) {
            setError('sessionPricing.60.rate', {
                type: 'manual',
                message: 'Please set a rate for 60 minute sessions',
            })
            isValid = false
        } else {
            const rate60 = data.sessionPricing[60].rate
            if (rate60 < 40 || rate60 > 160) {
                setError('sessionPricing.60.rate', {
                    type: 'manual',
                    message:
                        '60-minute session rate must be between CA$40 and CA$160',
                })
                isValid = false
            } else {
                clearErrors('sessionPricing.60.rate')
            }
        }
        if (!isValid) {
            // Scroll to bottom where errors are displayed
            scrollRef.current?.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            })
        }

        return isValid
    }

    if (pricingOptions.length === 0) {
        return null
    }
    const sessionPricingName = `sessionPricing.${pricingOptions[selectedSessionRate].value.length}.rate`
    const sessionPricingError =
        errors?.sessionPricing?.[
            pricingOptions[selectedSessionRate]?.value?.length
        ]?.rate

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <OnboardingSectionHeader
                title="Profile"
                subtitle={`Choose your headline, bio, and session settings. Craft a compelling profile to attract learners.`}
                icon={<ProfileIcon size={32} />}
            />

            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    pl: { xs: 0, md: 2 },
                    pr: { xs: 0, md: 0.5 },
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: primaryExtraLight,
                        borderRadius: 4,
                        overflowY: 'auto',
                    }}
                    ref={scrollRef}
                >
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <InputIconLabel
                                icon={
                                    <Feather
                                        name="menu"
                                        size={22}
                                        color={text}
                                    />
                                }
                                label="Headline"
                                description="Captivate learners and write something that will catch their attention with an exciting profile headline!"
                            />
                            <Controller
                                name="headline"
                                defaultValue={extendedUser?.headline || ''}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Headline is required',
                                    },
                                    maxLength: {
                                        value: 80,
                                        message:
                                            'Headline must be at most 80 characters',
                                    },
                                }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        inputRef={ref}
                                        {...field}
                                        fullWidth
                                        multiline
                                        rows={2}
                                        error={!!errors?.headline}
                                        placeholder="Enter a Headline"
                                        onChange={(e) => {
                                            field.onChange(e.target.value)
                                        }}
                                        helperText={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    {errors.headline?.message}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {field?.value?.length}
                                                    /80
                                                </Typography>
                                            </Box>
                                        }
                                        slotProps={{
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        {/* {isSm && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 200,
                                    paddingBottom: '23px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb: 1.5,
                                    }}
                                >
                                    <LightBulbIcon size={35} />
                                    <Typography
                                        variant="h5"
                                        fontFamily="Poppins_700Bold"
                                    >
                                        Tips!
                                    </Typography>
                                </Box>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 1,
                                        alignItems: 'flex-start',
                                    }}
                                    variant="outlined"
                                >
                                    <Typography
                                        variant="h6"
                                        fontFamily="Poppins_700Bold"
                                        sx={{ mr: 1 }}
                                    >
                                        1.
                                    </Typography>
                                    <Typography variant="caption">
                                        Captivate learners and write something
                                        that will catch their attention with an
                                        exciting headline!
                                    </Typography>
                                </Card>
                            </Box>
                        )} */}
                    </Box>
                    <Divider
                        variant="middle"
                        color={text}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <InputIconLabel
                                icon={<AboutMeIcon size={22} color={text} />}
                                label="About Me"
                                description="Share your teaching journey, qualifications, and what makes your tutoring unique to inspire and connect with learners!"
                            />
                            <Controller
                                name="description"
                                defaultValue={extendedUser?.description || ''}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Bio is required',
                                    },
                                    maxLength: {
                                        value: 1000,
                                        message:
                                            'Bio must be at most 1000 characters',
                                    },
                                    minLength: {
                                        value: 200,
                                        message:
                                            'Bio must be at least 200 characters',
                                    },
                                }}
                                render={({ field: { ref, ...field } }) => (
                                    <TextField
                                        inputRef={ref}
                                        fullWidth
                                        minRows={5}
                                        {...field}
                                        multiline
                                        slotProps={{
                                            htmlInput: {
                                                maxLength: 1000,
                                            },
                                            input: {
                                                style: {
                                                    backgroundColor: '#FFF',
                                                },
                                            },
                                        }}
                                        error={!!errors?.description}
                                        placeholder="Enter a Bio about yourself"
                                        helperText={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    {
                                                        errors?.description
                                                            ?.message
                                                    }
                                                </Typography>
                                                <Typography variant="caption">
                                                    {field?.value?.length}
                                                    /1000
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                )}
                            />
                        </Box>

                        {/* {isSm && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 200,
                                    paddingBottom: '23px',
                                }}
                            >
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 1,
                                        alignItems: 'flex-start',
                                    }}
                                    variant="outlined"
                                >
                                    <Typography
                                        variant="h6"
                                        fontFamily="Poppins_700Bold"
                                        sx={{ mr: 1 }}
                                    >
                                        2.
                                    </Typography>
                                    <Typography variant="caption">
                                        Share your teaching journey,
                                        qualifications, and what makes your
                                        tutoring unique to inspire and connect
                                        with learners!
                                    </Typography>
                                </Card>
                            </Box>
                        )} */}
                    </Box>
                    <Divider
                        variant="middle"
                        color={text}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',

                            gap: 2,
                        }}
                    >
                        <InputIconLabel
                            icon={
                                <Box sx={{ pb: 0.5 }}>
                                    <SessionRateIcon size={22} />
                                </Box>
                            }
                            label="Session rate"
                            description="Set a rate that reflects your expertise and experience—balance fair compensation with competitive pricing to attract more learners!"
                        />
                        <Box sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                <MultiSwitchToggle
                                    options={pricingOptions}
                                    selectedIndex={selectedSessionRate}
                                    setSelectedIndex={setSelectedSessionRate}
                                    error={sessionPricingError}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Controller
                                        name={sessionPricingName}
                                        key={sessionPricingName}
                                        defaultValue={''}
                                        control={control}
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <TextField
                                                size="small"
                                                sx={{ width: 200 }}
                                                inputRef={ref}
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ''
                                                        )
                                                    const sessionPricing = {
                                                        ...watch(
                                                            'sessionPricing'
                                                        ),
                                                        [pricingOptions[
                                                            selectedSessionRate
                                                        ].value.length]: {
                                                            rate: Number(value),
                                                            length: pricingOptions[
                                                                selectedSessionRate
                                                            ].value.length,
                                                        },
                                                    }
                                                    setValue(
                                                        'sessionPricing',
                                                        sessionPricing
                                                    )
                                                    if (sessionPricingError) {
                                                        const data = getValues()
                                                        data.sessionPricing =
                                                            sessionPricing
                                                        validatePricing(data)
                                                    }
                                                }}
                                                slotProps={{
                                                    input: {
                                                        style: {
                                                            backgroundColor:
                                                                '#FFF',
                                                            fontFamily:
                                                                'Poppins_600SemiBold',
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                sx={{
                                                                    fontFamily:
                                                                        'Poppins_600SemiBold',
                                                                    '& .MuiTypography-root':
                                                                        {
                                                                            fontFamily:
                                                                                'Poppins_600SemiBold',
                                                                        },
                                                                }}
                                                            >
                                                                CA$
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                    htmlInput: {
                                                        maxLength: 3,
                                                        fontFamily:
                                                            'Poppins_600SemiBold',
                                                    },
                                                }}
                                                error={!!sessionPricingError}
                                                placeholder="0"
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            {sessionPricingError && (
                                <Typography
                                    color="error"
                                    variant="caption"
                                    width="100%"
                                    sx={{
                                        mt: 1,
                                        textAlign: 'right',
                                        display: 'inline-block',
                                    }}
                                >
                                    {sessionPricingError.message}
                                </Typography>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <InformationIcon size={18} />
                            <Typography
                                variant="subtitle2"
                                fontFamily="Poppins_600SemiBold"
                                sx={{ ml: 1 }}
                            >
                                You can modify more session settings later in
                                your settings tab on the navigation bar.
                            </Typography>
                        </Box>

                        {/* {isSm && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 200,
                                }}
                            >
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        p: 1,
                                        alignItems: 'flex-start',
                                    }}
                                    variant="outlined"
                                >
                                    <Typography
                                        variant="h6"
                                        fontFamily="Poppins_700Bold"
                                        sx={{ mr: 1 }}
                                    >
                                        3.
                                    </Typography>
                                    <Typography variant="caption">
                                        Set a rate that reflects your expertise
                                        and experience—balance fair compensation
                                        with competitive pricing to attract more
                                        learners!
                                    </Typography>
                                </Card>
                            </Box>
                        )} */}
                    </Box>
                </Box>
            </Box>
            <OnboardingFooter
                onNext={() => {
                    handleSubmit((data) => {
                        const isValid = validatePricing(data)
                        if (isValid) {
                            handleSave(data)
                        }
                    })()
                }}
                step={4}
                onPrev={() => {
                    setStep(2)
                }}
            />
        </Box>
    )
}

export default AboutYou
