import EmptyTutorListIcon from '@icons/EmptyTutorListIcon'
import { Box, Button, Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'

interface EmptyTutorListProps {
    resetFilters: () => void
    loading?: boolean
}

const EmptyTutorList: React.FC<EmptyTutorListProps> = ({
    resetFilters,
    loading,
}) => {
    const defaultStyles = useStyles()
    const { text } = useColorsContext()
    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '56px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <ActivityIndicator size="large" />
                        <Typography variant="h6" sx={{ marginTop: 4 }}>
                            Loading Tutors...
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            marginBottom: 12,
                            textAlign: 'center',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxHeight: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                            }}
                        >
                            <EmptyTutorListIcon />
                        </Box>
                        <Typography
                            variant="h4"
                            fontFamily={defaultStyles.titleFont}
                            sx={{ pt: 4 }}
                        >
                            No results found
                        </Typography>
                        <Typography
                            variant="body2"
                            fontFamily={defaultStyles.titleFont}
                        >
                            Try changing your search criteria
                        </Typography>
                    </Box>
                )}

                {!loading && (
                    <Button
                        variant="outlined"
                        onClick={resetFilters}
                        sx={{
                            borderColor: text,
                            color: text,
                        }}
                    >
                        Reset Filters
                    </Button>
                )}
            </Box>
        </Box>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 24,
        color: '#4d5153',
    },
})

export default EmptyTutorList
