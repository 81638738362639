import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'

import { useColorsContext } from '@utils/styles'

import Schedule from './Schedule'
import Education from './Education'
import ExamPrep from './ExamPrep'
import Instabook from './Instabook'
import Reviews from './Reviews'
import { useNavigation, useRoute } from '@react-navigation/native'
import EducationIcon from '@icons/EducationIcon'
import { TutredTabs } from '@components/TutredTabs'
import ScheduleIcon from '@icons/ScheduleIcon'
import ReviewsIcon from '@icons/ReviewsIcon'

interface Props {
    tutor: any
}

const Sections: React.FC<Props> = ({ tutor }) => {
    const [value, setValue] = React.useState<string>('Schedule')
    const route = useRoute()
    const navigation = useNavigation()
    const { tab } = route.params || ''
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigation.setParams({ tab: newValue })
        setValue(newValue)
    }

    useEffect(() => {
        if (tab) {
            setValue(tab)
        }
    }, [tab])

    const { primary } = useColorsContext()

    return (
        <Container sx={{ width: '100%', marginTop: 3, padding: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <TutredTabs
                    value={value}
                    onChange={handleChange}
                    variant={isMd ? 'fullWidth' : 'scrollable'}
                    color={primary}
                    tabs={[
                        {
                            label: 'Schedule',
                            icon: <ScheduleIcon size={22} />,
                        },
                        {
                            label: 'Education',
                            icon: <EducationIcon size={26} />,
                        },
                        {
                            label: 'Reviews',
                            icon: <ReviewsIcon size={22} />,
                        },
                    ]}
                />
                {/* <Tab
                        label={
                            <Typography
                                sx={{
                                    fontFamily:
                                        value === 'schedule'
                                            ? 'Poppins_600SemiBold'
                                            : 'Poppins_500Medium',
                                    color: value === 'schedule' ? 'black' : '',
                                }}
                            >
                                Schedule
                            </Typography>
                        }
                        iconPosition="start"
                        value={'schedule'}
                        icon={
                            <Box sx={{ mr: 1 }}>
                                <CalendarIcon size={22} />
                            </Box>
                        }
                        sx={{
                            fontSize: 16,
                        }}
                    />
                    <Tab
                        label={
                            <Typography
                                sx={{
                                    fontFamily:
                                        value === 'education'
                                            ? 'Poppins_600SemiBold'
                                            : 'Poppins_500Medium',
                                    color: value === 'education' ? 'black' : '',
                                }}
                            >
                                Education
                            </Typography>
                        }
                        iconPosition="start"
                        value={'education'}
                        icon={
                            <Box sx={{ mr: 1 }}>
                                <EducationIcon size={22} />
                            </Box>
                        }
                        sx={{
                            fontSize: 16,
                            '& .MuiTab-root': {
                                fontFamily:
                                    value === 'reviews'
                                        ? 'Poppins_600SemiBold'
                                        : 'Poppins_500Medium',
                            },
                        }}
                    /> */}
                {/* <Tab
                        label="Exam Prep"
                        iconPosition="start"
                        value={'examPrep'}
                        icon={<ExamPrepIcon style={{ marginRight: 8 }} />}
                        sx={{ fontSize: 16 }}
                    /> */}
                {/* <Tab
                        label="Instabook"
                        iconPosition="start"
                        value={'instabook'}
                        icon={
                            <MaterialCommunityIcons
                                name="lightning-bolt-outline"
                                size={24}
                                color={
                                    value === 'instabook' ? primary : 'black'
                                }
                                style={{ marginRight: 8 }}
                            />
                        }
                        sx={{ fontSize: 16 }}
                    /> */}
                {/* <Tab
                        label={
                            <Typography
                                sx={{
                                    fontFamily:
                                        value === 'reviews'
                                            ? 'Poppins_600SemiBold'
                                            : 'Poppins_500Medium',
                                    color: value === 'reviews' ? 'black' : '',
                                }}
                            >
                                Reviews
                            </Typography>
                        }
                        iconPosition="start"
                        value={'reviews'}
                        icon={
                            <Box sx={{ mr: 1 }}>
                                <StarIcon size={22} fill="#FFF" />
                            </Box>
                        }
                        sx={{
                            fontSize: 16,
                        }}
                    /> */}
            </Box>
            <Box sx={{ padding: 2 }}>
                {value === 'Schedule' && <Schedule tutor={tutor} />}
                {value === 'Education' && (
                    <Education degrees={tutor.degrees} name={tutor?.name} />
                )}
                {value === 'examPrep' && <ExamPrep exams={tutor?.examTutor} />}
                {value === 'instabook' && <Instabook tutor={tutor} />}
                {value === 'Reviews' && <Reviews tutor={tutor} />}
            </Box>
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default Sections
