import React from 'react'
import { Box, Typography } from '@mui/material'
import { View } from 'react-native'
import AntDesign from '@expo/vector-icons/AntDesign'
import TutredTooltip from '@components/TutredTooltip'
import formatName from '@helpers/formatName'
import { useColorsContext, useStyles } from '@utils/styles'

const TutorName = ({ tutor }) => {
    if (!tutor) return null
    const { text, primary } = useColorsContext()
    const defaultStyles = useStyles()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                my: 2,
                width: '100%',
            }}
        >
            <Typography
                variant="h5"
                fontFamily={defaultStyles.boldFont}
                sx={{
                    color: text,
                }}
            >
                {formatName(tutor.name)}
            </Typography>
            {tutor.isVerified && (
                <TutredTooltip title="Verified ID" placement="top-end">
                    <View>
                        <AntDesign
                            name="checkcircle"
                            size={16}
                            color={primary}
                            style={{
                                marginLeft: 4,
                            }}
                        />
                    </View>
                </TutredTooltip>
            )}
        </Box>
    )
}

export default TutorName
