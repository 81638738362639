import { useColorsContext } from '@utils/styles';
import React from 'react';

const HeartIcon = ({ size = 54, filled }) => {

    const { text } = useColorsContext();
    return (
        <svg width={size} height={size * 49 / 54} viewBox="0 0 54 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.5975 6.08437C42.6201 5.10654 41.4596 4.33085 40.1824 3.80162C38.9052 3.27239 37.5362 3 36.1536 3C34.7711 3 33.4021 3.27239 32.1248 3.80162C30.8476 4.33085 29.6871 5.10654 28.7098 6.08437L26.6813 8.11278L24.6529 6.08437C22.6787 4.11014 20.0011 3.00102 17.2091 3.00102C14.4171 3.00102 11.7394 4.11014 9.7652 6.08437C7.79097 8.05861 6.68185 10.7362 6.68185 13.5282C6.68185 16.3202 7.79097 18.9979 9.7652 20.9721L11.7936 23.0005L26.6813 37.8882L41.5691 23.0005L43.5975 20.9721C44.5753 19.9947 45.351 18.8343 45.8802 17.557C46.4095 16.2798 46.6819 14.9108 46.6819 13.5282C46.6819 12.1457 46.4095 10.7767 45.8802 9.49945C45.351 8.22221 44.5753 7.06175 43.5975 6.08437Z" fill={filled ? "#E90000" : 'none'} stroke={text} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


export default HeartIcon;