import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

const TutredTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.common.black
                : theme.palette.common.white,
    },
}))

export default TutredTooltip
