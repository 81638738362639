import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Slider,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useColorsContext, useStyles } from '@utils/styles'
import { useUserContext } from '@contexts/userContext'
import AntDesign from '@expo/vector-icons/AntDesign'
import * as ImagePicker from 'expo-image-picker'
import Cropper from 'react-easy-crop'
import useUpdateProfilePicture from '@screens/loggedIn/Profile/useUpdateProfilePicture'
import { useAlert } from 'react-alert'
import Entypo from '@expo/vector-icons/Entypo'
import getCroppedImage from '@helpers/getCroppedImage'
import { doc, getFirestore } from 'firebase/firestore'
import { updateDoc } from 'firebase/firestore'
import app from '@config/firebase'
import { useFirebaseContext } from '@contexts/firebaseContext'

interface UploadVerificationDialogProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    id: string
}

const UploadVerificationDialog: React.FC<UploadVerificationDialogProps> = ({
    id,
    visible,
    setVisible,
}) => {
    const { width, height } = useWindowDimensions()
    const { user, extendedUser } = useUserContext()

    const { primary } = useColorsContext()
    const [image, setImage] = useState(null)
    const [dragOver, setDragOver] = useState(false)
    const alert = useAlert()
    const defaultStyles = useStyles()
    const { db } = useFirebaseContext()

    useEffect(() => {
        if (!visible) {
            setImage(null)
        }
    }, [visible])

    return (
        <Dialog
            open={visible}
            // scroll="paper"
            onClose={() => {
                setVisible(false)
            }}
            maxWidth="md"
            sx={{ padding: 0, overflow: 'hidden' }}
        >
            <DialogTitle
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    fontFamily={defaultStyles.titleFont}
                    textAlign="center"
                    sx={{ mb: 1 }}
                >
                    Upload Image
                </Typography>
                <Typography variant="body1" textAlign="center">
                    {image
                        ? 'If you are satisfied with the image, click save.'
                        : 'Upload an image that clearly shows your degree.'}
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: width * 0.6,
                    padding: 1,
                    height: height * 0.6,
                    overflow: 'hidden',
                }}
            >
                {image ? (
                    <>
                        <img
                            src={image}
                            alt="cropped"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '16px',
                                objectFit: 'contain',
                            }}
                        />
                    </>
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            border: `2px dashed ${dragOver ? primary : 'grey'}`,
                            borderRadius: '16px',
                            p: 2,
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                            setDragOver(true)
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault()
                            setDragOver(false)
                        }}
                        onDrop={(e) => {
                            e.preventDefault()
                            setDragOver(false)
                            const fileType = e.dataTransfer.files[0].type
                            if (fileType.includes('image')) {
                                const resp = window.URL.createObjectURL(
                                    e.dataTransfer.files[0]
                                )
                                setImage(resp)
                            } else {
                                alert.error(
                                    '[Error] Please only upload image files.'
                                )
                            }
                        }}
                    >
                        <>
                            <Entypo
                                name="upload-to-cloud"
                                size={64}
                                color="#3a8efe"
                            />

                            <Typography variant="h6">
                                Drag and drop here
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                                - or -{' '}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    let result =
                                        await ImagePicker.launchImageLibraryAsync(
                                            {
                                                mediaTypes:
                                                    ImagePicker.MediaTypeOptions
                                                        .Images,
                                                quality: 1,
                                            }
                                        )
                                    if (!result.canceled) {
                                        setImage(result.assets[0].uri)
                                    }
                                }}
                            >
                                Browse
                            </Button>
                        </>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        if (image) {
                            setImage(null)
                        } else {
                            setVisible(false)
                        }
                    }}
                >
                    {image ? 'Back' : 'Cancel'}
                </Button>
                {image && (
                    <Button
                        variant="contained"
                        onClick={async () => {
                            const docRef = doc(db, 'users', user.uid)
                            const updatedDegrees = extendedUser.degrees.map(
                                (degree) => {
                                    if (degree.id === id) {
                                        return {
                                            ...degree,
                                            verificationImg: image,
                                        }
                                    }
                                    return degree
                                }
                            )
                            await updateDoc(docRef, {
                                degrees: updatedDegrees,
                            })
                            alert.success(
                                'Verification image uploaded successfully'
                            )
                            setVisible(false)
                        }}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default UploadVerificationDialog
