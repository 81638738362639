import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useColorsContext, useStyles } from "../utils/styles";
import { useUserContext } from "../contexts/userContext";
import { Button } from "@mui/material";
import TutredAvatar from "./TutredAvatar";

export const Header = ({
	navigation,
	endAt,
	endCountDown,
	image,
	name,
	description,
	isTutor,
	status,
	startSession,
	endSession,
	startVideoCall,
	extendSession,
	user
}) => {
	const {
		toggleDarkMode,
		darkMode,
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
	} = useColorsContext();
	const defaultStyles = useStyles();
	const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	const { preLiminaryWindow } = useUserContext();

	const [countDown, setCountDown] = useState(endCountDown);
	const [timer, setTimer] = useState(null);

	useEffect(() => {

		let initialTime;
		if (status === "live") {
			if (isTutor) {
				initialTime = preLiminaryWindow * 60; // Convert minutes to seconds
			} else {
				initialTime = endCountDown ? parseTimeString(endCountDown) : 0;
			}
		} else {
			initialTime = endCountDown ? parseTimeString(endCountDown) : 0;
		}

		setTimer(initialTime);

		const interval = setInterval(() => {
			setTimer((prevTimer) => {
				if (prevTimer <= 0) {
					clearInterval(interval);
					return 0;
				}
				return prevTimer - 1;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [status, preLiminaryWindow, endCountDown, isTutor]);

	useEffect(() => {
		setCountDown(formatTime(timer));
	}, [timer]);

	const parseTimeString = (timeString) => {
		const [minutes, seconds] = timeString.split(':').map(Number);
		return minutes * 60 + seconds;
	};

	const formatTime = (totalSeconds) => {
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	};
	const styles = StyleSheet.create({
		header: {
			backgroundColor: background,
			width: "100%",
			display: "flex",
			flexDirection: "row",
			paddingHorizontal: !isSm ? 15 : 45,
			paddingVertical: 20,
			alignItems: "center",
			justifyContent: "space-between",
			borderBottomWidth: 1,
			borderBottomColor: border,
			zIndex: 100,
		},
		title: {
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		description: {
			fontSize: 14,
			color: textVariant,
			fontFamily: defaultStyles.boldFont,
			flex: 1,
		},
		timeBox: {
			backgroundColor: backgroundVariant,
			padding: 10,
			width: 100,
			borderRadius: 100,
			marginRight: 15,
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
		},
		time: {
			color: textVariant,
			fontSize: 14,
			fontFamily: defaultStyles.boldFont,
		},
		headerButton: {
			padding: 10,
			paddingLeft: 15,
			paddingRight: 15,
			borderRadius: defaultStyles.buttonRadius,
			marginRight: 5,
		},
		buttonText: {
			color: "#fff",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		endTimer: {
			backgroundColor: red,
		},
	});

	return (
		<View style={styles.header}>
			{/* Left */}
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					flex: 1,
					alignItems: "center",
				}}
			>
				<TouchableOpacity
					onPress={() => {
						// Remove all iframes from the DOM to prevent the video from playing in the background
						var iframes = document.querySelectorAll("iframe");
						for (var i = 0; i < iframes.length; i++) {
							iframes[i].parentNode.removeChild(iframes[i]);
						}
						navigation.navigate("Sessions", {
							screen: "Sessions",
						});
					}}
				>
					<NavigateBeforeIcon
						style={{
							marginRight: 30,
							marginLeft: -12,
							fontSize: 32,
							color: text,
						}}
					/>
				</TouchableOpacity>
				{/* Tutor profile pic left */}
				<TutredAvatar user={user} size="small" sx={{ mr: 2 }} />
				{/* <Image
					style={{
						width: 60,
						height: 60,
						borderRadius: defaultStyles.buttonRadius,
						marginRight: 15,
					}}
					source={{
						uri: image
							? image
							: "https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e",
					}}
				/> */}
				<View
					style={{
						flex: 1,
						paddingRight: 15,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					{/* Tutor name */}
					<Text style={styles.title}>{name}</Text>
					{/* Description */}
					<Text
						numberOfLines={2}
						ellipsizeMode="tail"
						style={styles.description}
					>
						{description}
					</Text>
				</View>
			</View>


			{(endAt || status === "live") && status !== "completed" && status !== "canceled" && (
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						marginRight: 15,
					}}
				>
					<Text
						style={{
							color: text,
							fontSize: 14,
							marginBottom: 5,
							fontFamily: defaultStyles.boldFont,
						}}
					>
						{status === "live"
							? "Session will end if not started in"
							: "Time left in session"}
					</Text>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						<View style={[styles.timeBox, { marginRight: 0 }]}>
							<Text style={styles.time}>{endCountDown}</Text>
						</View>
					</View>
				</View>
			)}
			{/* If is tutor and the session is a live session (meaning it hasnt started yet...) then add a button to officially start the session */}
			{!isTutor && status === "live" && (

				<Button variant="outlined" color="primary" onClick={startSession} sx={{ mr: 1 }}>Start</Button>

			)
			}

			{/* If is tutor and session in progress add end button */}
			{
				(status === "in progress" || status === "live") && (
					<Button variant="outlined" color="secondary" onClick={endSession}>
						End
					</Button>
					// <TouchableOpacity
					// 	style={[styles.headerButton, styles.endTimer]}
					// 	onPress={() => {
					// 		endSession();
					// 	}}
					// >
					// 	<Text style={styles.buttonText}>End</Text>
					// </TouchableOpacity>
				)
			}
			{/* If is student and the session (not live) is going to end in less than 5 minutes then display button to add more length to session */}
		</View >
	);
};

export default Header;
