import React, { useState } from 'react'
import { Box, Typography, Button, Modal } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { DateCalendar } from '@mui/x-date-pickers'
import TutredTimePicker from '@components/TutredTimePicker'

interface Props {
    availability: {
        [key: string]: {
            start: Date | null
            end: Date | null
        } | null
    }
    setAvailability: React.Dispatch<React.SetStateAction<any>>
}

const AvailabilityCalendarView: React.FC<Props> = ({
    availability,
    setAvailability,
}) => {
    const { text, border, background } = useColorsContext()
    const defaultStyles = useStyles()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(date)
            setIsModalOpen(true)
        }
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleSaveAvailability = () => {
        if (selectedDate) {
            const dayOfWeek = selectedDate
                .toLocaleDateString('en-US', { weekday: 'long' })
                .toLowerCase()
            setAvailability((prev) => ({
                ...prev,
                [dayOfWeek]: {
                    start: availability[dayOfWeek]?.start || null,
                    end: availability[dayOfWeek]?.end || null,
                },
            }))
        }
        handleCloseModal()
    }

    return (
        <Box sx={{ padding: 2, overflowY: 'auto' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Ignore this
            </Typography>
            <DateCalendar
                onChange={handleDateChange}
                value={selectedDate}
                sx={{ width: '100%' }}
            />

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Set Availability for{' '}
                        {selectedDate?.toLocaleDateString('en-US', {
                            weekday: 'long',
                        })}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <TutredTimePicker
                            size="small"
                            label="Start"
                            value={
                                availability[
                                    selectedDate
                                        ?.toLocaleDateString('en-US', {
                                            weekday: 'long',
                                        })
                                        .toLowerCase()
                                ]?.start
                            }
                            onChange={(value) =>
                                setAvailability((prev) => ({
                                    ...prev,
                                    [selectedDate
                                        ?.toLocaleDateString('en-US', {
                                            weekday: 'long',
                                        })
                                        .toLowerCase()]: {
                                        ...prev[
                                            selectedDate
                                                ?.toLocaleDateString('en-US', {
                                                    weekday: 'long',
                                                })
                                                .toLowerCase()
                                        ],
                                        start: value,
                                    },
                                }))
                            }
                        />
                        <TutredTimePicker
                            size="small"
                            label="End"
                            value={
                                availability[
                                    selectedDate
                                        ?.toLocaleDateString('en-US', {
                                            weekday: 'long',
                                        })
                                        .toLowerCase()
                                ]?.end
                            }
                            onChange={(value) =>
                                setAvailability((prev) => ({
                                    ...prev,
                                    [selectedDate
                                        ?.toLocaleDateString('en-US', {
                                            weekday: 'long',
                                        })
                                        .toLowerCase()]: {
                                        ...prev[
                                            selectedDate
                                                ?.toLocaleDateString('en-US', {
                                                    weekday: 'long',
                                                })
                                                .toLowerCase()
                                        ],
                                        end: value,
                                    },
                                }))
                            }
                        />
                    </Box>
                    <Button onClick={handleSaveAvailability}>Save</Button>
                </Box>
            </Modal>
        </Box>
    )
}

export default AvailabilityCalendarView
