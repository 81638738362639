import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import React from "react";

import { View } from "react-native";
import { useColorsContext, useStyles } from "../utils/styles";

export const AlertTemplate = ({ message, options, style, close }) => {
	var title = message.includes("]")
		? message.split("]")[0].replace("[", "")
		: message;
	var details = message.includes("]") ? message.split("]")[1] : null;

	var detailsTest = details
		? details
		: "Please make sure you have a card added to your profile before booking a tutor.";
	// Colors and styles
	const {
		primary,
		primaryVariant,
		red,
		redVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
		yellow,
		green,
		darkMode,
	} = useColorsContext();
	const defaultStyles = useStyles();

	const alertStyle = {
		backgroundColor: darkMode ? backgroundVariant : background,
		borderRadius: 100,
		fontSize: 16,
		color: text,
		padding: "8px 16px",
		display: "flex",
		alignItems: "center",
		// boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
		// boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
		boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
		fontFamily: defaultStyles.boldFont,
		zIndex: 9999,
		maxWidth: 600,
	};

	const buttonStyle = {
		marginLeft: "20px",
		border: "none",
		backgroundColor: "transparent",
		cursor: "pointer",
		color: "#FFFFFF",
	};

	// Check if theres another alert mounted
	// get divs inside of __react-alert__ div
	// if there is one, return null
	const divs = document.querySelectorAll("#__react-alert__ > div > div");
	if (divs.length >= 1) {
		// Remove the alerts
		divs.forEach((div) => {
			try {
				// Check if the div exists in the dom
				if (div.parentNode === null) {
					return;
				}
				// Make div invisible
				div.style.display = "none";
			} catch { }
		});
	}

	return (
		<div style={{ ...alertStyle, ...style }}>
			{options.type === "info" && (
				// Info circle icon from fontawesome
				<View
					style={{
						width: 35,
						height: 35,
						borderRadius: 100,
						backgroundColor: "rgba(255,179,71, 0.15)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginRight: 10,
					}}
				>
					<FontAwesome name="info-circle" size={20} color={yellow} />
				</View>
			)}
			{options.type === "success" && (
				<View
					style={{
						width: 35,
						height: 35,
						borderRadius: 100,
						backgroundColor: "rgba(0,170,84, 0.15)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginRight: 10,
					}}
				>
					<FontAwesome
						name="check-circle"
						size={20}
						color={"#00AA54"}
						style={{}}
					/>
				</View>
			)}
			{options.type === "error" && (
				// Fontawesome exclamation circle icon
				<View
					style={{
						width: 35,
						height: 35,
						borderRadius: 100,
						backgroundColor: "rgba(255,61,81, 0.15)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginRight: 10,
					}}
				>
					<FontAwesome
						name="exclamation-circle"
						size={20}
						color={"#FF3D51"}
					/>
				</View>
			)}

			<View
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					flex: 1,
				}}
			>
				<span
					style={{
						fontFamily: defaultStyles.boldFont,
						color: text,
						fontSize: 16,
					}}
				>
					{title}
				</span>

				{details && (
					<span
						style={{
							fontFamily: defaultStyles.regularFont,
							color: textVariant,
							fontSize: 14,
						}}
					>
						{details}
					</span>
				)}
			</View>

			<button onClick={close} style={buttonStyle}>
				<MaterialIcons name="close" size={19} color={textVariant} />
			</button>
		</div>
	);
};
