import { useAxiosWithAuth } from '@contexts/axiosContext'
import { useSessionContext } from '@contexts/sessionContext'
import { useUserContext } from '@contexts/userContext'
import data from '@screens/Help/data'
import { useColorsContext, useStyles } from '@utils/styles'
import { useAlert } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert'
import { Text, TouchableOpacity, View } from 'react-native'
import { v4 as uuidv4 } from 'uuid';
// import CancelModal from '@components/CancelModal'

const useFinalizeInstaBookSession = () => {
    const { authenticatedAxios } = useAxiosWithAuth()
    const customAlert = useAlert()
    const { user, customerId, profile, name } = useUserContext()
    const { createInstaBookSession } = useSessionContext()
    const { background, primary, border, text } = useColorsContext()
    const defaultStyles = useStyles();

    const CancelModal = ({ title, message, onClose, onAccept }) => {
        return (
            <View
                style={{
                    zIndex: 999,
                    width: 450,
                    backgroundColor: background,
                    borderRadius: defaultStyles.radius,
                    padding: 15,
                    border: '1px solid' + border,
                }}
            >
                <Text
                    style={{
                        color: text,
                        fontSize: 19,
                        fontFamily: defaultStyles.titleFont,
                        textAlign: 'center',
                        marginTop: 15,
                    }}
                >
                    {title}
                </Text>

                <Text
                    style={{
                        color: text,
                        fontSize: 16,
                        fontFamily: defaultStyles.regularFont,
                        textAlign: 'center',
                        marginTop: 15,
                    }}
                >
                    {message}
                </Text>

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 30,
                    }}
                >
                    <TouchableOpacity
                        style={{
                            backgroundColor: border,
                            width: 100,
                            height: 40,
                            marginLeft: 5,
                            borderRadius: defaultStyles.buttonRadius,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onPress={() => {
                            onClose()
                        }}
                    >
                        <Text
                            style={{
                                color: text,
                                fontSize: 16,
                                fontFamily: defaultStyles.boldFont,
                            }}
                        >
                            Dismiss
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={{
                            backgroundColor: primary,
                            // width: ,
                            height: 40,
                            marginLeft: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: defaultStyles.buttonRadius,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onPress={() => {
                            onAccept()
                        }}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                fontSize: 16,
                                fontFamily: defaultStyles.boldFont,
                            }}
                        >
                            Take me there
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }


    const finalizeInstaBookSession = async (
        chargeAmount,
        tutor,
        recordSession,
        length,
        tutorPayout,
        paymentMethodId,
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                let sessionId = uuidv4()

                // Authorize the charge with stripe
                const stripeAuthorizationRequest =
                    await authenticatedAxios.post('api/authorize-charge', {
                        amount: chargeAmount,
                        customerId,
                        accountId: tutor.stripe.accountId,
                        paymentMethodId
                    })


                const paymentIntentId = stripeAuthorizationRequest?.data?.paymentIntentId
                const success = stripeAuthorizationRequest?.data?.success
                // Schedule the session for the tutor
                if (!success || !paymentIntentId) {
                    confirmAlert(
                        {
                            customUI: ({ onClose }) => {
                                return (
                                    <CancelModal
                                        onClose={() => {
                                            onClose();
                                        }}
                                        title="Failed to Book Tutor"
                                        message={
                                            "In order to book a tutor, you must add a payment method to your account, please click the button below to add a payment method you will be redirected to the settings page. Once you have added a payment method, please try booking the tutor again."
                                        }
                                        onAccept={() => {
                                            onClose();
                                            navigation.navigate(
                                                "Home",
                                                {
                                                    screen: "Profile",
                                                },
                                            );
                                        }}
                                    ></CancelModal>
                                );
                            },
                        },
                    );
                    return reject('Unable to book session');
                }
                const scheduleRequest = await authenticatedAxios.post(
                    'api/schedule',
                    {
                        event: "end-session",
                        // Date is today YYYY-MM-DD
                        date: new Date()
                            .toISOString()
                            .slice(
                                0,
                                10,
                            ),
                        // Time is hour:minute in AM/PM
                        time: new Date().toLocaleTimeString(
                            "en-US",
                            {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            },
                        ),
                        data: {
                            sessionId:
                                sessionId,
                            uid: user.uid,
                            length: length || 10,
                        },
                    }
                )
                if (!scheduleRequest) throw new Error('Unable to schedule session')
                // Create instabook session
                const createInstaBookSessionRequest =
                    await createInstaBookSession(
                        sessionId,
                        user.uid,
                        name,
                        profile,
                        tutor,
                        paymentIntentId,
                        customerId,
                        recordSession,
                        chargeAmount,
                        tutorPayout,
                        length
                    )
                customAlert.success(
                    '[InstaBook Session] Now creating session please wait...'
                )
                // navigation.navigate('Sessions', {screen: 'Meeting', params: {sessionId: sessionId}})
                window.location.href = `/sessions/${sessionId}`
                return resolve('Success')
            } catch (error) {
                console.error(error)
                customAlert.error(
                    '[Error with Instabook] Error creating instant session'
                )
                return reject(error)
            }
        })


    }

    return { finalizeInstaBookSession }
}

export default useFinalizeInstaBookSession

