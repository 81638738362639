import React from 'react';
import { useColorsContext } from '@utils/styles';
const QuestionMarkIcon = ({ size = 16, color }) => {

    const { text, textInverse } = useColorsContext()

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_32_7)">
                <path d="M9.99999 18.3334C14.6024 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6024 1.66669 9.99999 1.66669C5.39762 1.66669 1.66666 5.39765 1.66666 10C1.66666 14.6024 5.39762 18.3334 9.99999 18.3334Z" stroke={color || text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.57501 7.49999C7.77093 6.94304 8.15764 6.47341 8.66664 6.17426C9.17564 5.87512 9.77409 5.76577 10.356 5.86558C10.9379 5.96539 11.4657 6.26792 11.8459 6.71959C12.2261 7.17126 12.4342 7.74292 12.4333 8.33332C12.4333 9.99999 9.93335 10.8333 9.93335 10.8333" stroke={color || text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 14.1667H10.0083" stroke="#939191" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>

        </svg>
    )
}

export default QuestionMarkIcon;
