import React, { ReactNode } from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import Tab, { tabClasses, TabProps } from '@mui/material/Tab'
import Tabs, { tabsClasses, TabsProps } from '@mui/material/Tabs'
import { Box } from '@mui/material'

export const tabsStyles = (theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.mode === 'light' ? '#FBFBFB' : '#262626',
        borderRadius: '10px',
        borderColor: '#959595',
        minHeight: 44,
        fontFamily: 'Poppins_500Medium',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    flexContainer: {
        position: 'relative',
        padding: '0 3px',
        zIndex: 1,
    },
    indicator: {
        top: 3,
        bottom: 3,
        right: 3,
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontFamily: 'Poppins_600SemiBold',
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
    },
})

export const tabItemStyles = (theme: Theme) => ({
    root: {
        fontWeight: 500,
        minHeight: 44,
        minWidth: 96,
        fontSize: '1.2rem',
        color: (theme.vars || theme).palette.text.primary,
        textTransform: 'initial',
        lineHeight: 1,
        '&:hover': {
            opacity: 1,
        },
        [`& .MuiTab-iconWrapper`]: {
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            mr: 0.5,
        },
        [`&.${tabClasses.selected}`]: {
            color: theme.palette.common.white,
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 120,
        },
    },
})

function toSx<ClassKey extends string>(
    styles: (theme: Theme) => Partial<Record<ClassKey, any>>,
    classes: Record<ClassKey, string>
) {
    return function sxCallback(theme: Theme) {
        let sx = {}
        Object.entries<any>(styles(theme)).forEach(([key, value]) => {
            if (key === 'root') {
                sx = { ...sx, ...value }
            } else {
                sx[`& .${classes[key]}`] = value
            }
        })
        return sx
    } as SxProps<Theme>
}

interface TutredTabsProps extends TabsProps {
    tabs: {
        label: string
        icon: ReactNode
    }[]
}

export function TutredTabs({ sx, tabs, ...rest }: TutredTabsProps) {
    const tabItemSx = toSx(tabItemStyles, tabClasses)
    return (
        <Tabs
            sx={[
                toSx(tabsStyles, tabsClasses),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        >
            {tabs.map((tab) => {
                return (
                    <Tab
                        disableRipple
                        label={tab.label}
                        icon={<Box>{tab.icon}</Box>}
                        value={tab.label}
                        iconPosition="start"
                        sx={tabItemSx}
                    />
                )
            })}
        </Tabs>
    )
}
