import { createTheme } from '@mui/material'
import { useColorsContext } from '@utils/styles'

const useCustomMuiTheme = () => {
    const { darkMode, primary, disabled, primaryLight } = useColorsContext()

    const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        zIndex: 999999,
                    },
                    input: {
                        backgroundColor: '#FFF',
                    },
                    inputRoot: {
                        backgroundColor: '#FFF',
                        borderRadius: 10,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 10,
                        borderWidth: 3,
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderWidth: 1,
                    },

                    input: {
                        '&::placeholder': {
                            opacity: 1,
                        },
                        backgroundColor: '#FFF',
                        borderRadius: 10,
                    },

                    root: {
                        borderRadius: 10,
                        color: '#000',
                    },
                },
            },

            MuiInput: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 1,
                        },
                        backgroundColor: '#FFF',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 16,
                        padding: 16,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#CCCCCC',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                        padding: '0px 64px',
                        height: 48,
                        borderRadius: 10,
                        fontFamily: 'Poppins_600SemiBold',
                        color: '#FFF',
                        fontSize: '1.25rem',
                        borderWidth: 3,
                        borderColor: '#000',
                    },
                    outlined: {
                        borderWidth: 3,
                        backgroundColor: primary,
                        ':disabled': {
                            backgroundColor: disabled,
                            borderWidth: 3,
                            opacity: 1,
                            color: '#FFF',
                            borderColor: '#000',
                        },
                    },
                    outlinedPrimary: {},
                    outlinedSecondary: {
                        color: '#000',
                        backgroundColor: primaryLight,
                        borderColor: '#D0D0D0',
                    },

                    sizeLarge: {
                        padding: '0px 64px',
                        height: 64,
                        borderRadius: 10,
                        fontSize: 20,
                    },
                },
            },
        },
        palette: {
            mode: darkMode ? 'dark' : 'light',
            primary: {
                main: '#0060DE',
            },
        },
        zIndex: {
            tooltip: 99999,
        },
        typography: {
            fontFamily: 'Poppins_400Regular',
            h1: {
                fontFamily: 'Poppins_600SemiBold',
            },
            h2: {
                fontFamily: 'Poppins_600SemiBold',
            },
            h3: {
                fontFamily: 'Poppins_500Medium',
            },
            h4: {
                fontFamily: 'Poppins_500Medium',
            },
            h5: {
                fontFamily: 'Poppins_500Medium',
            },
            h6: {
                fontFamily: 'Poppins_500Medium',
            },
            body1: {
                fontFamily: 'Poppins_400Regular',
            },
            subtitle1: {
                fontFamily: 'Poppins_600SemiBold',
            },
            subtitle2: {
                fontFamily: 'Poppins_500Medium',
            },
            body2: {
                fontFamily: 'Poppins_400Regular',
            },
            button: {
                fontFamily: 'Poppins_500Medium',
            },
            caption: {
                fontFamily: 'Poppins_400Regular',
            },
            overline: {
                fontFamily: 'Poppins_400Regular',
            },
        },
    })
    return theme
}

export default useCustomMuiTheme
