import React from 'react'
import {
    Box,
    Typography,
    Button,
    Collapse,
    List,
    useTheme,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import AutoAccept from '@icons/AutoAccept'
import TutredTooltip from '@components/TutredTooltip'
import QuestionMarkIcon from '@icons/QuestionMarkIcon'
import { useUserContext } from '@contexts/userContext'
import DebriefIcon from '@icons/DebriefIcon'
import ExtensionIcon from '@icons/ExtensionIcon'
import SessionRecordingFeeIcon from '@icons/SessionRecordingFeeIcon'

interface InstabookInfoProps {
    show: boolean
    tutor: any
}

const InstabookInfo: React.FC<InstabookInfoProps> = ({ show, tutor }) => {
    const defaultStyles = useStyles()
    const { primaryLight } = useColorsContext()
    const theme = useTheme()

    return (
        <Collapse in={show}>
            <Box
                sx={{
                    background: primaryLight,
                    borderRadius: 2,
                    p: 2,

                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                {' '}
                {tutor?.instaBookBufferPeriod && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <DebriefIcon size={16} />
                            <Typography
                                variant="body2"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ mx: 0.5 }}
                            >
                                Debrief window
                            </Typography>
                            <TutredTooltip
                                title="The tutor provides a window prior to starting to discuss the session."
                                placement="top-end"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <QuestionMarkIcon
                                        color={theme.palette.grey[500]}
                                    />
                                </Box>
                            </TutredTooltip>
                        </Box>
                        <Typography
                            variant="body2"
                            fontFamily={defaultStyles.titleFont}
                        >
                            {tutor?.instaBookBufferPeriod} mins
                        </Typography>
                    </Box>
                )}
                {tutor?.instaBookExtensionRate && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ExtensionIcon size={18} />
                            <Typography
                                variant="body2"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ mx: 0.5 }}
                            >
                                Extension rate
                            </Typography>
                            <TutredTooltip
                                title="Rate for extending a session."
                                placement="top-end"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <QuestionMarkIcon
                                        color={theme.palette.grey[500]}
                                    />
                                </Box>
                            </TutredTooltip>
                        </Box>
                        <Typography
                            variant="body2"
                            fontFamily={defaultStyles.titleFont}
                        >
                            CA ${tutor?.instaBookExtensionRate.toFixed(2)}/min
                        </Typography>
                    </Box>
                )}
                {tutor?.sessionRecordingFee && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <SessionRecordingFeeIcon size={18} />
                            <Typography
                                variant="body2"
                                fontFamily={defaultStyles.titleFont}
                                sx={{ mx: 0.5 }}
                            >
                                Screen recording fee
                            </Typography>
                            <TutredTooltip
                                title="Rate for recording a session"
                                placement="top-end"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <QuestionMarkIcon
                                        color={theme.palette.grey[500]}
                                    />
                                </Box>
                            </TutredTooltip>
                        </Box>
                        <Typography
                            variant="body2"
                            fontFamily={defaultStyles.titleFont}
                        >
                            {tutor?.sessionRecordingFee === 0
                                ? 'FREE'
                                : `CA $${tutor?.sessionRecordingFee.toFixed(2)}`}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Collapse>
    )
}

export default InstabookInfo
