import React from 'react'
import { Box, Typography, useTheme, useMediaQuery, Link } from '@mui/material'
import { useStyles } from '../../../utils/styles'
import SleepyDino from '@icons/sleepy-dino'
import { useNavigation } from '@react-navigation/native'
interface EmptyNotificationsProps {
    title?: string
    dense?: boolean
    handleNavigateToNotifications?: () => void
}

const EmptyNotifications = ({
    title = 'No notifications yet',
    dense = false,
    handleNavigateToNotifications,
}: EmptyNotificationsProps) => {
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    const navigation = useNavigation()

    return (
        <>
            {dense ? (
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={require('@assets/images/small-no-notifications.svg')}
                        sx={{
                            width: 200,
                            pt: 4,
                            pb: 2,
                        }}
                        alt="No notifications"
                    />
                    <Typography
                        variant="body2"
                        textAlign="center"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ mb: 2 }}
                    >
                        No notifications to show. Go to the
                        <br />
                        <span
                            style={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                            }}
                            onClick={handleNavigateToNotifications}
                        >
                            Notifications
                        </span>{' '}
                        tab to view more.
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        mt: isSm ? 2 : 4,
                        mb: isSm ? 2 : 4,
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        overflowY: 'auto',
                    }}
                >
                    <Typography
                        variant={isSm ? 'h5' : 'h3'}
                        textAlign="center"
                        fontFamily={defaultStyles.titleFont}
                        sx={{ mb: isSm ? 1 : 2 }}
                    >
                        {title}
                    </Typography>
                    <SleepyDino size={isSm ? 200 : 400} />
                </Box>
            )}
        </>
    )
}

export default EmptyNotifications
