
export default {
	students: [
		{
			"title": "How does on-demand tutoring work?",
			"slug": "how-does-on-demand-tutoring-work",
			"sections": [
				{
					"title": "What is on-demand tutoring?",
					"text": "On-demand tutoring refers to a tutoring service that allows students to get immediate assistance whenever they need it, without scheduling sessions in advance."
				},
				{
					"title": "How does on-demand tutoring work?",
					"text": " Our on-demand tutoring platform connects students with qualified tutors who are available in real-time. Students can simply log in to our website or app, browse through the available tutors, and connect with the one that best matches their needs. They can then engage in a live, one-on-one tutoring session using our virtual classroom tools."
				},
				{
					"title": "Is on-demand tutoring suitable for all subjects?",
					"text": "Yes, on-demand tutoring covers a wide range of subjects, including math, science, languages, history, and more. We have a diverse group of tutors who specialize in various subjects, ensuring that students can find assistance for their specific needs."
				}
			]
		},
		{
			"title": "What Qualifications do the Tutors Have?",
			"slug": "what-qualifications-do-the-tutors-have",
			"sections": [
				{
					"title": "What qualifications do your tutors have",
					"text": "Our tutors are highly qualified individuals who have expertise in their respective fields. They undergo a rigorous selection process to ensure their knowledge and teaching abilities. Many of our tutors hold advanced degrees or are professionals with extensive experience in their fields"
				},
				{
					"title": "Can I choose a tutor based on their qualifications?",
					"text": "Absolutely! When browsing through available tutors, you can view their profiles, which include their qualifications, educational background, areas of expertise, and ratings from previous students. This information can help you make an informed decision when selecting a tutor."
				},
				{
					"title": "Are the tutors background checked?",
					"text": "Yes, we prioritize the safety and security of our students. All tutors undergo a thorough background check before joining our platform to ensure their credibility and maintain a safe learning environment."
				}
			]
		},
		{
			"title": "What do I need for on-demand tutoring?",
			"slug": "what-technology-is-used-for-on-demand-tutoring",
			"sections": [
				{
					"title": "What technology do I need for on-demand tutoring?",
					"text": "To participate in on-demand tutoring, you will need a computer, tablet, or smartphone with a stable internet connection. Our tutoring platform is accessible through a web browser or mobile app, ensuring that you can easily connect with tutors from anywhere."
				},
				{
					"title": "What features are available in the virtual classroom?",
					"text": "Our virtual classroom offers various features to enhance the tutoring experience. These include video and audio communication, a shared whiteboard for interactive problem-solving, a text chat feature for additional clarifications, and the ability to share files and documents for review."
				},
				{
					"title": "Can I schedule tutoring sessions in advance?",
					"text": "While the primary focus of on-demand tutoring is immediate assistance, we also offer the option to schedule tutoring sessions in advance. This can be useful for students who prefer to have regular, structured sessions with a specific tutor."
				}
			]
		},
		{
			"title": "Payment",
			"slug": "payment",
			"sections": [
				{
					"title": "How do I make a payment for a tutoring session?",
					"text": "We offer secure online payment options. You can use major credit cards or other electronic payment methods to pay for your tutoring sessions."
				},
				{
					"title": "Is my payment information secure?",
					"text": "Yes, we prioritize the security of your payment information. Our platform uses industry-standard encryption technology to safeguard your data."
				},
				{
					"title": "Can I get a refund for a tutoring session?",
					"text": "Refund policies may vary depending on the circumstances. Please review our refund policy for more details or contact our support team for assistance."
				}
			]
		},
		{
			"title": "Cancellations",
			"slug": "cancellations",
			"sections": [
				{
					"title": "Can I cancel a tutoring session?",
					"text": "Yes, you can cancel a tutoring session. However, please note that our cancellation policy may apply, and cancellation fees may be applicable based on the timing of the cancellation. Refer to our cancellation policy for more information."
				},
				{
					"title": "What happens if a tutor cancels a session?",
					"text": "In the event of a tutor cancellation, we will make every effort to find a suitable replacement tutor. If a replacement cannot be found, a refund will be issued, and alternative options will be provided."
				},
				{
					"title": "How do I reschedule a tutoring session?",
					"text": "To reschedule a tutoring session, please contact your tutor or reach out to our support team. We will assist you in finding an alternative time that works for both you and the tutor."
				}
			]
		},
		{
			"title": "Booking a Session",
			"slug": "booking-a-session",
			"sections": [
				{
					"title": "How do I book a tutoring session?",
					"text": "Booking a tutoring session is easy. Simply log in to your account, browse through available tutors, select a tutor based on their profile and availability, and choose a suitable time slot to book the session."
				},
				{
					"title": "Can I book recurring sessions with a tutor?",
					"text": "Yes, you can book recurring sessions with a tutor based on their availability. This option allows for consistent tutoring and helps maintain a regular learning routine."
				},
				{
					"title": "Can I request a specific tutor?",
					"text": "Yes, you can request a specific tutor based on their profile and availability. However, please note that the availability of tutors may vary, so it's recommended to book in advance to secure your preferred tutor."
				}
			]
		},
		{
			"title": "Super tutor program",
			"slug": "super-tutor",
			"sections": [
				{
					"title": "What is the Super Tutor Program?",
					"text": "Our Super Tutor Program is designed to recognize tutors who consistently deliver exceptional tutoring experiences. These tutors have received positive feedback from students and have demonstrated a high level of expertise and professionalism."
				},
				{
					"title": "How are tutors selected for the Super program?",
					"text": "Tutors are selected for the Super program based on a combination of factors, including student ratings, session feedback, punctuality, and overall performance. Our team regularly evaluates tutors' performance to identify those who meet the program's criteria."
				},
				{
					"title": "What are the benefits of working with an Super tutor?",
					"text": "Super tutors are known for their exceptional teaching abilities and have a proven track record of success. Working with an Super tutor ensures that you receive top-quality tutoring from an experienced and highly-rated educator."
				}
			]
		}
	],
	tutors: [
		{
			"title": "Join Our On-Demand Tutoring Team and Make a Difference!",
			"slug": "article/become-a-tutor",
			"sections": [
				{
					"title": "Are you passionate about teaching and helping others succeed?",
					"text": "Do you have a strong academic background and expertise in a particular subject? If so, we invite you to become a tutor with our on-demand tutoring platform. Join our team of dedicated educators and make a difference in the lives of students around the world."
				},
				{
					"title": "Why Become a Tutor with Us?",
					"text": "Flexible Schedule: As a tutor with our on-demand tutoring platform, you have the freedom to set your own schedule. You can choose to work as little or as much as you want, fitting tutoring sessions around your existing commitments. This flexibility allows you to pursue your passion for teaching while maintaining a healthy work-life balance."
				},
				{
					"title": "Work from Anywhere",
					"text": "With our online tutoring platform, you can work from the comfort of your own home or anywhere with a stable internet connection. No need to commute or travel to students' locations. Our virtual classroom tools provide a seamless tutoring experience, allowing you to connect with students from all over the world."
				},
				{
					"title": "Competitive Compensation",
					"text": "We value the expertise and dedication of our tutors, which is why we offer competitive compensation rates. Your earnings will be based on the number of tutoring sessions you conduct, providing you with the opportunity to earn a meaningful income while doing what you love."
				},
				{
					"title": "Expand Your Teaching Skills",
					"text": "Becoming a tutor with our on-demand platform gives you the chance to expand your teaching skills and gain valuable experience. Working with students from diverse backgrounds and different learning styles will enhance your ability to adapt your teaching methods and cater to individual needs."
				},
				{
					"title": "Professional Development",
					"text": "We are committed to supporting our tutors in their professional growth. We provide ongoing training and resources to help you stay up-to-date with the latest teaching techniques and educational trends. Our goal is to empower you to deliver the highest quality tutoring sessions and continuously improve your skills as an educator."
				},
				{
					"title": "How to Apply",
					"text": "Visit our website and navigate to the \"Become a Tutor\" section. Fill out the online application form with your personal and academic details. Upload your resume or CV, highlighting your educational background and teaching experience. Select the subjects and grade levels you are qualified to tutor. If selected, you will be contacted for a virtual interview and assessment. Once approved, you can create your tutor profile and start tutoring students."
				},
				{
					"title": "Join Our On-Demand Tutoring Team Today!",
					"text": "Ready to embark on a rewarding journey as a tutor? Join our on-demand tutoring platform and help students achieve their academic goals. Together, we can make a positive impact on the lives of learners worldwide. Apply now and become a part of our passionate community of educators."
				}

			]
		},
		{
			"title": "What is instabook and how does it work?",
			"slug": "article/instabook-and-how-it-works",
			"sections": [

				{
					"title": "What is the Instant-Booking Feature?",
					"text": "At our on-demand tutoring site, we understand the importance of convenience and efficiency for both tutors and students. That's why we offer an innovative instant-booking feature that makes scheduling tutoring sessions a breeze. In this article, we'll explore how this feature works and the benefits it brings to tutors like you."
				},
				{
					"title": "How Does it Work?",

					"bullets": [
						{
							"title": "Set Your Availability",
							"text": "As a tutor, you have the flexibility to define your availability within the platform. You can set your preferred working hours, indicate the subjects you offer tutoring in, and specify the duration of your sessions.",
						}, {
							"title": "Display your Availability",
							"text": "Display Your Availability: Once your availability is set, the instant-booking feature will display your open time slots to students seeking tutoring services. Students can view your profile, qualifications, and available time slots to determine if you're the right fit for their needs.",
						}, {
							"title": "Students Book Sessions",
							"text": "When a student finds a suitable time slot, they can simply click on it and book the session instantly. The system will automatically update your schedule to reflect the confirmed session.",
						},
						{
							"title": "Confirmation and Reminders",
							"text": "Both you and the student will receive confirmation notifications once a session is booked. Additionally, automated reminders will be sent to both parties prior to the scheduled session to ensure everyone is prepared."
						}

					],
				},
				{
					"title": "Benefits for Tutors",
					"bullets": [
						{
							"title": "Increased Visibility and Bookings",
							"text": "By utilizing the instant-booking feature, you increase your visibility to potential students. Students can easily find and book sessions with you, leading to a higher likelihood of bookings and a steady stream of tutoring opportunities."
						},
						{
							"title": "Time Efficiency",
							"text": "The instant-booking feature eliminates the need for lengthy scheduling discussions or negotiations. With a clear overview of your availability, students can book sessions that align with their needs, saving both you and the student valuable time."
						},
						{
							"title": "Streamlined Workflow",
							"text": "The feature seamlessly integrates with your calendar, automatically updating your availability and eliminating the risk of double-bookings. This ensures a smooth and organized workflow, allowing you to focus on what you do best - tutoring."
						},
						{
							"title": "Improved Student Engagement",
							"text": "The convenience of instant-booking encourages students to engage with the platform and seek tutoring services. By offering this efficient scheduling option, you attract motivated students who are serious about their education and committed to their success."
						}
					]
				},
				{
					"text": "Our instant-booking feature revolutionizes the way tutors connect with students. By simplifying the scheduling process, you can focus on what matters most – providing quality tutoring sessions. Embrace this innovative feature and unlock the potential to reach more students, enhance your productivity, and make a greater impact in the lives of learners."
				}, {
					"text": "Join our on- demand tutoring platform today and experience the benefits of our instant - booking feature firsthand.Empower students, optimize your tutoring schedule, and enjoy the convenience of hassle - free session bookings.."
				}
			]

		},
		{
			"title": "Super Tutor Program",
			"slug": "article/super-tutor-program",

			"sections": [
				{
					"title": "What is the Super Tutor Program?",
					"text": "The Super Tutor Program recognizes and rewards the most highly-rated and experienced tutors on our platform. Super Tutors consistently deliver exceptional tutoring experiences to their students and receive exclusive benefits in return. There’s no need to apply for the Super Tutor Program. If you meet the necessary criteria, you’ll automatically be granted Super Tutor status during our next quarterly evaluation. The Super Tutor Program and its perks are offered as-is, with no express or implied warranties. We reserve the right to modify, suspend, or terminate the Super Tutor Program and its benefits at any time, with or without notice."
				},
				{
					"title": "What benefits come with being a Super Tutor?",
					"text": "Achieving Super Tutor status brings the following advantages:",
					"bullets": [
						{
							"title": "Increased profile visibility",
							"text": "Super Tutors’ profiles are highlighted in search results with a special badge and banner, signaling their outstanding performance to potential students. Students can also filter their searches to find Super Tutors. The Super Tutor badge will appear in various places throughout the platform. Please note, the badge and related designation should not be used outside of our platform."
						},
						{
							"title": "Priority support",
							"text": "Our Customer Support team prioritizes Super Tutor inquiries. When assisting students in rebooking a session, we look at Super Tutor listings first."
						},
						{
							"title": "No cancellation fees",
							"text": "Super Tutors are exempt from cancellation fees at the time of session cancellation. Although an automated cancellation review will still appear on your public profile, you can provide a response explaining the reason for cancellation. Frequent cancellations may still affect your performance metrics and could result in losing Super Tutor status."
						},
						{
							"title": "$100 promo code",
							"text": "Super Tutors who maintain Super status for four consecutive quarters will receive a $100 promo code, which can be used for sessions on our platform. The promo code is valid for 180 days and can be applied to session costs."
						}
					]
				},
				{
					"title": "How do I qualify to become a Super Tutor?",
					"text": "To be eligible for Super Tutor status, you must have a platform account in good standing and meet the following performance metrics on the quarterly evaluation date. We assess these metrics over the previous 365 days:",
					"bullets": [
						{
							"title": "Response rate: At least 95%",
						},
						{
							"title": "Acceptance rate: At least 90%",
						},
						{
							"title": "Commitment rate: At least 95%",
						},
						{
							"title": "Five-star ratings: At least 90%",
						},
						{
							"title": "No active policy violations",
						}, {
							"title": "At least 10 completed sessions"
						}
					]
				},
				{
					"text": "Our platform may adjust the qualifications and performance standards as needed, at our sole discretion."
				},
				{
					"title": "How often is Super Tutor eligibility evaluated?",
					"text": "Our system evaluates eligibility for the Super Tutor Program every quarter, based on metrics and behavior from the previous 365 days. You can check your current status and the next evaluation date on the Performance tab of the Tutor dashboard."
				},
				{
					"text": "If you achieve Super Tutor status, you’ll maintain it for the entire quarter, even if your metrics fall below the required standards during that period. If you don’t meet the criteria on the evaluation date, you’ll have to wait until the next quarterly assessment for another chance. If you’re an existing Super Tutor and fail to meet the standards at the next review, your status and badge will be removed without prior notice. You can regain Super status at the next evaluation if you meet the necessary criteria. We reserve the right to revoke Super Tutor status at any time for policy violations."
				},
				{
					"title": "Does Super Tutor status imply endorsement by the platform?",
					"text": "Being a Super Tutor does not imply endorsement by our platform. You may not claim to be an agent or representative of the platform. Misrepresentation of your affiliation or relationship with the platform is prohibited. Our platform does not endorse any Super Tutor, and Super Tutor status does not imply endorsement, certification, or approval of any session listing or tutor."
				},
				{
					"title": "Can I lose my Super Tutor status?",
					"text": "We may revoke your Super Tutor status and associated privileges at our sole discretion if you violate the program terms, our Terms of Service, or any other applicable terms, conditions, or policies. Your Super Tutor status and related badging will be automatically suspended or terminated if you no longer meet the qualifications, stop tutoring, or if your account is suspended, banned, or deactivated."
				},
				{
					"text": "In case of any conflict between the Super Tutor Terms and our Terms of Service, the Super Tutor Terms will govern with respect to the Super Tutor Program."
				}
			]
		}
	]
}

