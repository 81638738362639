const provinceToTimeZone = {
	"Alberta": "America/Edmonton",
	"British Columbia": "America/Vancouver",
	"Manitoba": "America/Winnipeg",
	"New Brunswick": "America/Moncton",
	"Newfoundland and Labrador": "America/St_Johns",
	"Northwest Territories": "America/Yellowknife",
	"Nova Scotia": "America/Halifax",
	"Nunavut": "America/Iqaluit",
	"Ontario": "America/Toronto",
	"Prince Edward Island": "America/Halifax",
	"Quebec": "America/Montreal",
	"Saskatchewan": "America/Regina",
	"Yukon": "America/Whitehorse",
};

module.exports = {
    provinceToTimeZone,
};
