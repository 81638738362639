import React from 'react';
import { useColorsContext } from '@utils/styles';

const InstabookIcon = ({
    size = 40,
    color,
}) => {
    const { text } = useColorsContext()
    return (
        <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 25H6.53229L25 1.91536V15H33.4677L15 38.0846V25ZM13.4677 21.6667H18.3333V28.582L26.5323 18.3333H21.6667V11.418L13.4677 21.6667Z" fill={color || text} />
        </svg>
    )
}

export default InstabookIcon;