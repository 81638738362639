import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {
    addDoc,
    collection,
    doc,
    onSnapshot,
    query,
    serverTimestamp,
    Timestamp,
    updateDoc,
    where,
} from "firebase/firestore";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import { LoadingPage } from "../../../components/LoadingPage";
import { Header as MeetingHeader } from "../../../components/MeetingHeader";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";
import SessionExtensionPrompt from "@components/Meeting/SessionExtensionPrompt";
import { SessionExtensionConfirmation } from "@components/Meeting/SessionExtensionConfirmation";



const hostname = process.env.HOSTNAME

export const Meeting = ({ navigation, route }) => {
    const sessionId = route.params.sessionId;
    const [session, setSession] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [selectedRating, setSelectedRating] = useState(5);
    const [review, setReview] = useState("");
    const [status, setStatus] = useState("");
    const [started, setStarted] = useState(false);
    const [endAt, setEndAt] = useState(null);
    const [endCountDown, setEndCountDown] = useState(null);
    const [reviewSubmitted, setReviewSubmitted] = useState(false);
    const [existingReview, setExistingReview] = useState(null);
    const [isSessionStarted, setIsSessionStarted] = useState(false);
    const [preliminaryCountdown, setPreliminaryCountdown] = useState(null);
    const [isInitializing, setIsInitializing] = useState(true);
    const [videoCallInitialized, setVideoCallInitialized] = useState(false);
    const [readyToInitialize, setReadyToInitialize] = useState(false);
    const [preliminaryWindow, setPreliminaryWindow] = useState(5);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [showExtendPrompt, setShowExtendPrompt] = useState(false);

    var interval;

    const customAlert = useAlert();
    const { db } = useFirebaseContext();
    const { isTutor, user, userId, name, customerId } = useUserContext();
    const tutorInstaBookStatus = useRef(false)

    const {
        darkMode,
        primary,
        primaryVariant,
        red,
        redVariant,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        yellow,
        green,
    } = useColorsContext();
    const defaultStyles = useStyles();

    const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
    const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
    const isMd = useMediaQuery({ query: "(min-width: 768px)" });
    const isSm = useMediaQuery({ query: "(min-width: 640px)" });


    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            display: "flex",
            width: "100%",
            margin: Platform.OS === "web" ? "auto" : null,
        },
        confirmation: {
            fontSize: 19,
            fontFamily: defaultStyles.boldFont,
            color: text,
            textAlign: "center",
        },
        confirmButton: {
            backgroundColor: primary,
            padding: 15,
            borderRadius: defaultStyles.buttonRadius,
            marginTop: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 150,
        },
        confirmButtonText: {
            color: "#fff",
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
        },
        completedText: {
            fontSize: 19,
            fontFamily: defaultStyles.boldFont,
            color: text,
            textAlign: "center",
        },
        rating: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15,
            border: "1px solid " + border,
            borderRadius: defaultStyles.radius,
            padding: 30,
            margin: "auto",
            height: "100%",
            width: isMd ? "60%" : "100%",
            maxWidth: 750,
        },
        reviewInput: {
            width: "100%",
            backgroundColor: backgroundVariant,
            borderRadius: defaultStyles.radius,
            padding: 15,
            marginTop: 15,
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            textAlignVertical: "top",
        },
    });




    useEffect(() => {
        if (sessionId && user) {
            setIsInitializing(true);
            setSession(null);
            setStarted(false);
            setCompleted(false);
            setStatus("");

            const q = query(
                collection(db, "sessions"),
                where("__name__", "==", sessionId)
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const tempSession = doc.data();
                    tempSession.id = doc.id;
                    setSession(tempSession);
                    const extensions = doc.data().extensions;

                    if (extensions) {
                        const confirmedExtensions = extensions.filter(
                            (extension) => extension.status === "confirmed"
                        )
                        let endTime = doc.data().endAt
                        if (confirmedExtensions.length > 0) {
                            confirmedExtensions.forEach((extension) => {
                                endTime.seconds += extension.length * 60
                            })
                        }

                        setEndAt(endTime)
                    } else {
                        setEndAt(doc.data().endAt);
                    }


                    if (
                        (status !== doc.data().status &&
                            doc.data().status == "completed") ||
                        doc.data().status == "canceled"
                    ) {
                        removeVideoContainer();
                    }
                    setStatus(doc.data().status);
                    if (doc.data().status === "completed") {
                        removeVideoContainer();
                        setCompleted(true);
                    }
                    const preliminaryWindow = doc.data()?.tutorProfile?.preLiminaryWindow || 5;
                    tutorInstaBookStatus.current = doc.data()?.tutorProfile?.instaBook || false

                    setPreliminaryWindow(preliminaryWindow);
                    if (doc.data().status === "live" && !preliminaryCountdown) {
                        setPreliminaryCountdown(preliminaryWindow * 60);
                    }

                    setIsInitializing(false);
                });
            });

            if (isTutor) {
                const q2 = query(
                    collection(db, "reviews"),
                    where("sessionId", "==", sessionId),
                    where("fromTutor", "==", true)
                );
                const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        setExistingReview(doc.data());
                    });
                });
                return () => {
                    unsubscribe();
                    unsubscribe2();
                };
            } else {
                const q2 = query(
                    collection(db, "reviews"),
                    where("sessionId", "==", sessionId),
                    where("fromTutor", "==", false)
                );
                const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        setExistingReview(doc.data());
                    });
                });
                return () => {
                    unsubscribe();
                    unsubscribe2();
                };
            }
        }
    }, [sessionId, isTutor, user]);

    useEffect(() => {
        if (endAt) {
            let mins = Math.floor(
                (endAt.seconds -
                    new Date().getTime() / 1000) /
                60
            );
            let secs = Math.floor(
                (endAt.seconds -
                    new Date().getTime() / 1000) %
                60
            );
            setEndCountDown(mins + ":" + secs);
        }

    }, [endAt])
    useEffect(() => {
        if (session && (session.status === "live" || session.status === "in progress") && !videoCallInitialized) {
            setReadyToInitialize(true);
        }
    }, [session, videoCallInitialized]);


    const removeVideoContainer = () => {
        var iframes = document.querySelectorAll("iframe");
        for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }
    };

    useEffect(() => {
        if (completed) {
            removeVideoContainer();
        }
    }, [completed]);

    useEffect(() => {
        if (status === "completed" && isTutor) {
            fetch(`${hostname}/api/email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + user.accessToken,
                },
                body: JSON.stringify({
                    reason: "session-ended",
                }),
            });
        }

        if (
            (!started && status === "in progress") ||
            status === "live" ||
            (status === "confirmed" &&
                new Date(session.date + " " + session.time) <= new Date())
        ) {
            if (isTutor) {
                updateDoc(doc(db, "users", userId), {
                    instaBook: false,
                });
            }
            setStarted(true);
        }

        // For the student: start the video call when the session status changes to "in progress"
        if (!isTutor && status === "in progress") {
            setIsSessionStarted(true);
        }
    }, [status]);

    useEffect(() => {
        let interval;
        if (!isInitializing && status === "live" && preliminaryCountdown !== null) {
            interval = setInterval(() => {
                if (preliminaryCountdown > 0) {
                    setPreliminaryCountdown(prev => prev - 1);
                } else {
                    clearInterval(interval);
                    if (isTutor) {
                        endSessionFromTimer(true);
                    }
                }
            }, 1000);
        } else if (endCountDown && status === "in progress") {
            interval = setInterval(() => {
                if (endAt == null) return clearInterval(interval);
                let mins = Math.floor((endAt.seconds - new Date().getTime() / 1000) / 60);
                let secs = Math.floor((endAt.seconds - new Date().getTime() / 1000) % 60);
                var displayMin = mins;
                var displaySec = secs;
                if (displayMin < 10) {
                    displayMin = "0" + displayMin;
                }
                if (displaySec < 10) {
                    displaySec = "0" + displaySec;
                }
                setEndCountDown(displayMin + ":" + displaySec);
                if (mins <= 0 && secs <= 0) {
                    setEndCountDown(0);
                    clearInterval(interval);
                    endSessionFromTimer(true);
                    return;
                }
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [status, preliminaryCountdown, endCountDown, isInitializing, endAt, isTutor,]);

    const startVideoCall = () => {
        if (videoCallInitialized) {
            return;
        }
        setReadyToInitialize(true);
    };

    // // Simulate a user interaction
    const simulateUserInteraction = () => {
        const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        });
        document.dispatchEvent(event);
    };

    useEffect(() => {
        if (readyToInitialize && !videoCallInitialized) {
            const initializeCall = () => {
                removeVideoContainer();
                const config = {
                    name: name ? name.split(" ")[0] : "User",
                    meetingId: sessionId,
                    apiKey: "5d0ad3f9-ba80-4131-b004-bd5b63c1a30e",
                    containerId: "video-container",
                    micEnabled: false,
                    webcamEnabled: false,
                    participantCanToggleSelfWebcam: true,
                    participantCanToggleSelfMic: true,
                    screenShareEnabled: true,
                    screenShareConfig: {
                        optimizationMode: "text",
                        resolution: "h1080p_15fps"
                    },
                    participantCanLeave: false,
                    raiseHandEnabled: false,
                    theme: darkMode ? "DARK" : "LIGHT",
                    recording: {
                        enabled: session?.recordSession,
                        autoStart: session?.recordSession && !isTutor,
                    },
                    joinScreen: {
                        visible: true,
                    },
                    whiteboardEnabled: true,
                    joinWithoutUserInteraction: false,
                    chatEnabled: true,
                    pollEnabled: false,
                    permissions: {
                        pin: false,
                        drawOnWhiteboard: true,
                        toggleWhiteboard: true,
                        removeParticipant: false,
                        endMeeting: false,
                        toggleRecording: false,
                    },
                    debug: true,
                };
                const meeting = new VideoSDKMeeting();
                meeting.init(config);
                setVideoCallInitialized(true);
            };




            // Initialize call after a short delay
            setTimeout(() => {
                simulateUserInteraction();
                initializeCall();
            }, 1000);
        }
    }, [readyToInitialize, videoCallInitialized]);

    const startSession = async () => {
        if (status === "live") {
            const endAt = new Date();
            var sessionLength = session.minutes ? session.minutes : 15;
            endAt.setMinutes(endAt.getMinutes() + sessionLength);

            await updateDoc(doc(db, "sessions", sessionId), {
                status: "in progress",
                endAt: endAt,
                preliminaryWindow: preliminaryWindow
            });
            setIsSessionStarted(true);
            setPreliminaryCountdown(null);
            simulateUserInteraction();  // Add this line
            // Remove the startVideoCall() from here
        }
    };
    // useEffect(() => {
    //     if (isSessionStarted) {
    //         startVideoCall();
    //     }
    // }, [isSessionStarted]);

    const endSession = () => {
        if (status === "in progress" || status === "live") {
            confirmAlert({
                title: "End Session",
                customUI: ({ onClose }) => {
                    return (
                        <View
                            style={{
                                width: 450,
                                backgroundColor: background,
                                borderRadius: defaultStyles.radius,
                                padding: 15,
                                border: "1px solid" + border,
                            }}
                        >
                            <Text
                                style={{
                                    color: text,
                                    fontSize: 19,
                                    fontFamily: defaultStyles.titleFont,
                                    textAlign: "center",
                                    marginTop: 15,
                                }}
                            >
                                Are you sure you want to end this session?
                            </Text>

                            <Text
                                style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.regularFont,
                                    textAlign: "center",
                                    marginTop: 15,
                                }}
                            >
                                {isTutor
                                    ? "Ending this session will end the call and the student will be charged for the session."
                                    : "Ending this session will end the call and you will be charged for the session."}
                            </Text>

                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    marginTop: 20,
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        onClose();
                                    }}
                                    style={{
                                        backgroundColor: "transparent",
                                        width: 100,
                                        height: 40,
                                        borderRadius:
                                            defaultStyles.buttonRadius,
                                        borderColor: border,
                                        borderWidth: 1,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: text,
                                            fontSize: 16,
                                            fontFamily:
                                                defaultStyles.regularFont,
                                            textAlign: "center",
                                        }}
                                    >
                                        Cancel
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        endSessionFromTimer(false);
                                        onClose();
                                    }}
                                    style={{
                                        backgroundColor: primary,
                                        width: 100,
                                        height: 40,
                                        borderRadius:
                                            defaultStyles.buttonRadius,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: "white",
                                            fontSize: 16,
                                            fontFamily:
                                                defaultStyles.regularFont,
                                            textAlign: "center",
                                        }}
                                    >
                                        End
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    );
                },
            });
        }
    };

    const [showExtendModal, setShowExtendModal] = useState(false);

    const extendSession = () => {
        setShowExtendModal(true);
        if (status === "in progress") {

        }
    };

    const endSessionFromTimer = async (fromTimer) => {
        if (status === "live" && !isSessionStarted && !isInitializing) {
            await updateDoc(doc(db, "sessions", sessionId), {
                status: "canceled",
                canceledAt: serverTimestamp(),
            });

            if (tutorInstaBookStatus?.current === true && session?.tutor && session?.tutorProfile?.keepInstaBookOn) {
                updateDoc(doc(db, "users", session?.tutor), {
                    instaBook: tutorInstaBookStatus?.current,
                });
            }
        } else if (status === "in progress") {
            try {
                let startAt;
                if (session.startAt && typeof session.startAt.toDate === 'function') {
                    startAt = session.startAt.toDate();
                } else if (session.date && session.time) {
                    startAt = new Date(session.date + ' ' + session.time);
                } else {
                    throw new Error('Unable to determine session start time');
                }

                const endAtDate = fromTimer ? new Date(endAt.seconds * 1000) : new Date();
                const length = Math.floor((endAtDate - startAt) / 1000 / 60); // Session length in minutes
                let amount = Math.floor(length * (session.tutorProfile.rate / 60)); // Calculate amount

                if (amount < 1) {
                    amount = 1;
                }
                if (session?.totalFee) {
                    amount = session?.totalFee
                }
                amount = amount.toFixed(2);

                const response = await fetch(`${hostname}/api/capture-charge`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.accessToken}`,
                    },
                    body: JSON.stringify({
                        amount: amount,
                        accountId: session.tutorProfile.stripe.accountId,
                        customerId: session.user.customerId,
                        paymentIntentId: session.paymentIntentId,
                        sessionId: sessionId,
                    }),
                });

                const data = await response.json();

                const paymentIntentId = data.paymentIntentId;
                const paymentStatus = data.status;

                await updateDoc(doc(db, "sessions", sessionId), {
                    status: "completed",
                    completedAt: serverTimestamp(),
                    lengthInMinutes: parseFloat(length.toFixed(1)),
                    paymentIntentId: paymentIntentId,
                    paymentStatus: paymentStatus,
                });
                if (tutorInstaBookStatus?.current === true && session?.tutor && session?.tutorProfile?.keepInstaBookOn) {
                    updateDoc(doc(db, "users", session?.tutor), {
                        instaBook: tutorInstaBookStatus?.current,
                    });
                }

            } catch (error) {
                console.error('Error ending session:', error);

                // Set a default amount if it wasn't calculated due to an error
                const amount = session.tutorProfile.rate ? session.tutorProfile.rate : 0;

                await updateDoc(doc(db, "sessions", sessionId), {
                    status: "completed",
                    completedAt: serverTimestamp(),
                    lengthInMinutes: 0,
                    paymentStatus: "failed",
                });
                if (tutorInstaBookStatus?.current === true && session?.tutor && session?.tutorProfile?.keepInstaBookOn) {
                    updateDoc(doc(db, "users", session?.tutor), {
                        instaBook: tutorInstaBookStatus?.current,
                    });
                }
            }
        }
    };


    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };


    const tutorEarnings = useMemo(() => {
        let earnings = Number(session?.earnings || 0);
        if (session?.extensions) {
            session?.extensions.forEach((extension) => {
                if (extension.status === "confirmed") {
                    earnings += Number(extension.earnings || 0);
                }
            })
        }
        return earnings;
    }, [session?.earnings, session?.extensions]);

    if (!session || !user || isTutor === null) {
        return <LoadingPage></LoadingPage>;
    }

    if (session.status === "canceled") {
        return (
            <SafeAreaView style={styles.container}>
                <MeetingHeader
                    status={session.status}
                    isTutor={isTutor}
                    user={isTutor ? session.user : session.tutorProfile}

                    name={
                        isTutor
                            ? session.user.name.split(" ")[0]
                            : session.tutorProfile.name.split(" ")[0] + "."
                    }
                    image={
                        isTutor
                            ? session.user.image
                            : session.tutorProfile.picture
                    }
                    description={session.note}
                    navigation={navigation}
                ></MeetingHeader>
                <View style={styles.maxWidth}>
                    <View
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Text style={styles.completedText}>
                            We're sorry it didn't work out!
                        </Text>
                    </View>
                </View>
            </SafeAreaView>
        );
    }

    if (session.status === "completed") {
        return (
            <SafeAreaView style={styles.container}>
                <MeetingHeader
                    status={session.status}
                    endAt={endAt}
                    endCountDown={endCountDown}
                    isTutor={isTutor}
                    user={isTutor ? session.user : session.tutorProfile}
                    name={
                        isTutor
                            ? session.user.name.split(" ")[0]
                            : session.tutorProfile.name.split(" ")[0] + "."
                    }
                    image={
                        isTutor
                            ? session.user.image
                            : session.tutorProfile.picture
                    }
                    description={session.note}
                    navigation={navigation}
                ></MeetingHeader>

                <View style={styles.maxWidth}>
                    <ScrollView
                        contentContainerStyle={{ flexGrow: 1 }}
                        showsVerticalScrollIndicator={false}
                        style={{
                            flex: 1,
                            padding: !isSm ? 15 : 45,
                            paddingTop: 0,
                            marginTop: 30,
                        }}
                    >
                        <View style={styles.completed}>
                            {isTutor ? (
                                <>
                                    <View
                                        style={{
                                            padding: 15,
                                            width: "100%",
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text style={styles.completedText}>
                                            Session completed
                                        </Text>
                                        <Text style={styles.completedText}>
                                            ${tutorEarnings.toFixed(2)} earned
                                        </Text>
                                    </View>

                                    {existingReview ? (
                                        <View>
                                            <View style={styles.rating}>
                                                <Text
                                                    style={styles.completedText}
                                                >
                                                    Your rating for{" "}
                                                    {
                                                        session.user.name.split(
                                                            " "
                                                        )[0]
                                                    }
                                                </Text>
                                                <Text style={{ marginTop: 15 }}>
                                                    {[
                                                        ...Array(
                                                            existingReview.rating
                                                        ),
                                                    ].map((e, i) => {
                                                        return (
                                                            <FontAwesome
                                                                key={i}
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                                name="star"
                                                                size={18}
                                                                color={yellow}
                                                            />
                                                        );
                                                    })}
                                                </Text>
                                                <Text
                                                    style={{
                                                        marginTop: 15,
                                                        color: text,
                                                    }}
                                                >
                                                    {existingReview.review}
                                                </Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <View>
                                            <View style={styles.rating}>
                                                <Text
                                                    style={styles.completedText}
                                                >
                                                    Review{" "}
                                                    {
                                                        session.user.name.split(
                                                            " "
                                                        )[0]
                                                    }
                                                </Text>
                                                <Text style={{ marginTop: 15 }}>
                                                    {[
                                                        ...Array(
                                                            selectedRating
                                                        ),
                                                    ].map((e, i) => {
                                                        return (
                                                            <FontAwesome
                                                                key={i}
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                                name="star"
                                                                size={18}
                                                                color={yellow}
                                                            />
                                                        );
                                                    })}
                                                </Text>
                                                <Slider
                                                    style={{
                                                        width: "100%",
                                                        height: 40,
                                                    }}
                                                    step={1}
                                                    value={selectedRating}
                                                    minimumValue={0}
                                                    maximumValue={5}
                                                    onValueChange={(val) =>
                                                        setSelectedRating(val)
                                                    }
                                                    minimumTrackTintColor={
                                                        primary
                                                    }
                                                    maximumTrackTintColor={
                                                        border
                                                    }
                                                    thumbTintColor={primary}
                                                />

                                                <TextInput
                                                    style={styles.reviewInput}
                                                    placeholder="Write a review"
                                                    placeholderTextColor={
                                                        textVariant
                                                    }
                                                    value={review}
                                                    onChangeText={(text) =>
                                                        setReview(text)
                                                    }
                                                />

                                                <TouchableOpacity
                                                    disabled={
                                                        reviewSubmitted
                                                            ? true
                                                            : false
                                                    }
                                                    style={[
                                                        styles.confirmButton,
                                                        { width: "100%" },
                                                    ]}
                                                    onPress={() => {
                                                        if (reviewSubmitted) {
                                                            return;
                                                        }
                                                        setReviewSubmitted(true);
                                                        addDoc(
                                                            collection(
                                                                db,
                                                                "reviews"
                                                            ),
                                                            {
                                                                tutor: session
                                                                    .user.uid,
                                                                user: {
                                                                    picture:
                                                                        session
                                                                            .user
                                                                            .image,
                                                                    name: session
                                                                        .user.name,
                                                                    uid: user.uid,
                                                                },
                                                                rating: selectedRating,
                                                                review: review
                                                                    ? review
                                                                    : "",
                                                                created:
                                                                    new Date(),
                                                                sessionId:
                                                                    sessionId,
                                                                fromTutor: true,
                                                            }
                                                        )
                                                            .then(() => {
                                                                console.log('Review submitted successfully.');
                                                                customAlert.success(
                                                                    "[Review Submitted] Thank you for leaving your feedback!"
                                                                );
                                                                fetch(`${hostname}/api/submit-review`, {
                                                                    method: "POST",
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        Authorization: `Bearer ${user.accessToken}`,
                                                                    },
                                                                    body: JSON.stringify({
                                                                        sessionId: session.user.uid,
                                                                        tutorId: session.user.uid,
                                                                        userId: user.uid,
                                                                        reviewerName: session.tutorProfile.name,
                                                                        reviewerPicture: session.user.image,
                                                                        review: review ? review : "",
                                                                        rating: selectedRating,
                                                                        email: session.tutorProfile.email,
                                                                        fromTutor: true,
                                                                    }),
                                                                })
                                                                    .then(response => response.json())
                                                                    .then(emailData => {
                                                                        if (emailData.success) {
                                                                            console.log('Review email sent successfully.');
                                                                        } else {
                                                                            console.error('Error sending review email:', emailData.message);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        console.error('Error sending review email:', error);
                                                                    });

                                                                return;
                                                            })
                                                            .catch((error) => {
                                                                console.error('Error submitting review:', error);
                                                                setReviewSubmitted(false);
                                                                customAlert.error(
                                                                    "[Error with Review] There was a network error... Please try again later."
                                                                );
                                                                return;
                                                            });
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            styles.confirmButtonText
                                                        }
                                                    >
                                                        Submit
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    )}
                                </>
                            ) : (
                                <>
                                    {existingReview ? (
                                        <View>
                                            <View style={styles.rating}>
                                                <Text
                                                    style={styles.completedText}
                                                >
                                                    Your rating for{" "}
                                                    {
                                                        session.tutorProfile.name.split(
                                                            " "
                                                        )[0]
                                                    }
                                                </Text>
                                                <Text style={{ marginTop: 15 }}>
                                                    {[
                                                        ...Array(
                                                            existingReview.rating
                                                        ),
                                                    ].map((e, i) => {
                                                        return (
                                                            <FontAwesome
                                                                key={i}
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                                name="star"
                                                                size={18}
                                                                color={yellow}
                                                            />
                                                        );
                                                    })}
                                                </Text>
                                                <Text
                                                    style={{
                                                        marginTop: 15,
                                                        color: text,
                                                    }}
                                                >
                                                    {existingReview.review}
                                                </Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <View>
                                            <View style={styles.rating}>
                                                <Text
                                                    style={styles.completedText}
                                                >
                                                    Review{" "}
                                                    {
                                                        session.tutorProfile.name.split(
                                                            " "
                                                        )[0]
                                                    }
                                                </Text>
                                                <Text style={{ marginTop: 15 }}>
                                                    {[
                                                        ...Array(
                                                            selectedRating
                                                        ),
                                                    ].map((e, i) => {
                                                        return (
                                                            <FontAwesome
                                                                key={i}
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                                name="star"
                                                                size={18}
                                                                color={yellow}
                                                            />
                                                        );
                                                    })}
                                                </Text>
                                                <Slider
                                                    style={{
                                                        width: "100%",
                                                        height: 40,
                                                    }}
                                                    step={1}
                                                    value={selectedRating}
                                                    minimumValue={0}
                                                    maximumValue={5}
                                                    onValueChange={(val) =>
                                                        setSelectedRating(val)
                                                    }
                                                    minimumTrackTintColor={
                                                        primary
                                                    }
                                                    maximumTrackTintColor={
                                                        border
                                                    }
                                                    thumbTintColor={primary}
                                                />

                                                <TextInput
                                                    style={styles.reviewInput}
                                                    placeholder="Write a review"
                                                    placeholderTextColor={
                                                        textVariant
                                                    }
                                                    value={review}
                                                    onChangeText={(text) =>
                                                        setReview(text)
                                                    }
                                                />

                                                <TouchableOpacity
                                                    disabled={
                                                        reviewSubmitted
                                                            ? true
                                                            : false
                                                    }
                                                    style={[
                                                        styles.confirmButton,
                                                        { width: "100%" },
                                                    ]}
                                                    onPress={() => {
                                                        if (reviewSubmitted) {
                                                            return;
                                                        }
                                                        setReviewSubmitted(true);
                                                        addDoc(
                                                            collection(
                                                                db,
                                                                "reviews"
                                                            ),
                                                            {
                                                                tutor: session
                                                                    .tutorProfile
                                                                    .id,
                                                                user: {
                                                                    picture:
                                                                        session
                                                                            .user
                                                                            .image,
                                                                    name: session
                                                                        .user.name,
                                                                    uid: user.uid,
                                                                },
                                                                rating: selectedRating,
                                                                review: review
                                                                    ? review
                                                                    : "",
                                                                created:
                                                                    new Date(),
                                                                sessionId:
                                                                    sessionId,
                                                                fromTutor: false,
                                                            }
                                                        )
                                                            .then(() => {
                                                                customAlert.success(
                                                                    "[Review Submitted] Thank you for leaving your feedback!"
                                                                );

                                                                fetch(`${hostname}/api/submit-review`, {
                                                                    method: "POST",
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        Authorization: `Bearer ${user.accessToken}`,
                                                                    },
                                                                    body: JSON.stringify({
                                                                        sessionId: session.user.id,
                                                                        tutorId: session.tutorProfile.id,
                                                                        userId: session.user.uid,
                                                                        reviewerName: name,
                                                                        review: review ? review : "",
                                                                        rating: selectedRating,
                                                                        email: session.user.email,
                                                                        fromTutor: false,
                                                                    }),
                                                                })
                                                                    .then(response => response.json())
                                                                    .then(emailData => {
                                                                        if (emailData.success) {
                                                                            console.log('Review email sent successfully.');
                                                                        } else {
                                                                            console.error('Error sending review email:', emailData.message);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        console.error('Error sending review email:', error);
                                                                    });

                                                                return;
                                                            })
                                                            .catch((error) => {
                                                                setReviewSubmitted(false);
                                                                customAlert.error(
                                                                    "[Error with Review] There was a network error... Please try again later."
                                                                );
                                                                return;
                                                            });
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            styles.confirmButtonText
                                                        }
                                                    >
                                                        Submit
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    )}
                                </>
                            )}
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }

    if (
        session.status === "in progress" ||
        session.status === "live" ||
        (session.status === "confirmed" &&
            new Date(session.date + " " + session.time) <= new Date())
    ) {
        return (
            <SafeAreaView style={styles.container}>
                <View onLayout={({ nativeEvent }) => {
                    const { height } = nativeEvent.layout;
                    setHeaderHeight(height);
                }}>
                    <MeetingHeader
                        startSession={startSession}
                        extendSession={extendSession}
                        status={status}
                        endAt={endAt}
                        user={isTutor ? session.user : session.tutorProfile}
                        endSession={endSession}
                        endCountDown={status === "live" ? (preliminaryCountdown !== null ? formatCountdown(preliminaryCountdown) : null) : endCountDown}
                        isTutor={isTutor}
                        name={
                            isTutor
                                ? session.user.name.split(" ")[0]
                                : session.tutorProfile.name.split(" ")[0] + "."
                        }
                        image={
                            isTutor
                                ? session.user.image
                                : session.tutorProfile.picture
                        }
                        description={session.note}
                        navigation={navigation}
                    />
                </View>

                <SessionExtensionConfirmation showModal={showExtendModal} setShowModal={setShowExtendModal} tutor={session.tutorProfile} session={session} customerId={customerId} />
                <SessionExtensionPrompt endCountDown={endCountDown} isTutor={isTutor} headerHeight={headerHeight} extendSession={extendSession} setShowExtendPrompt={setShowExtendPrompt} showExtendPrompt={showExtendPrompt} session={session} />


                <View style={styles.maxWidth}>
                    <ScrollView
                        contentContainerStyle={{ flexGrow: 1 }}
                        showsVerticalScrollIndicator={false}
                        style={{ flex: 1 }}
                    >
                        <View
                            nativeID="video-container"
                            style={{
                                flex: 1,
                                width: "100%",
                            }}
                        />
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }

    if (session.status === "pending") {
        return (
            <SafeAreaView style={styles.container}>
                <MeetingHeader
                    status={session.status}
                    isTutor={isTutor}
                    user={isTutor ? session.user : session.tutorProfile}
                    name={
                        isTutor ? (
                            <>
                                {session.user.name.split(" ").length > 1
                                    ? session.user.name.split(" ")[0] +
                                    " " +
                                    session.user.name.split(" ")[1][0] +
                                    "."
                                    : session.user.name.split(" ")[0]}
                            </>
                        ) : (
                            <>
                                {session.tutorProfile.name.split(" ").length > 1
                                    ? session.tutorProfile.name.split(" ")[0] +
                                    " " +
                                    session.tutorProfile.name.split(
                                        " "
                                    )[1][0] +
                                    "."
                                    : session.tutorProfile.name.split(" ")[0]}
                            </>
                        )
                    }
                    image={
                        isTutor
                            ? session.user.image
                            : session.tutorProfile.picture
                    }
                    description={session.note}
                    navigation={navigation}
                ></MeetingHeader>
                <View style={styles.maxWidth}>
                    <View
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Text style={styles.completedText}>
                            Meeting has not started yet.
                        </Text>
                    </View>
                </View>
            </SafeAreaView>
        );
    }
};

export default Meeting;