import { useColorsContext } from '@utils/styles';
import Svg, { Path } from 'react-native-svg';

const AboutMeIcon = ({
    size = 36,
    color,
}) => {
    const { text } = useColorsContext();
    return (
        <Svg width={size} height={size * (28 / 25)} viewBox="0 0 25 28" fill="none">
            <Path
                d="M25 7.91667V0.87963C25 0.37037 24.4907 0 23.9815 0H0.972222C0.462963 0 0 0.37037 0 0.87963V26.8519C0 27.4074 0.462963 27.7778 0.972222 27.7778H23.9815C24.4907 27.7778 25 27.4074 25 26.8981V8.7037C25 8.47222 25 8.19444 25 7.91667ZM23.1481 6.01852L18.8889 1.85185H23.1481V6.01852ZM1.85185 25.9259V1.85185H16.25L23.1481 8.61111V25.9259H1.85185ZM12.5 13.8889C15.1389 13.8889 17.3148 11.713 17.3148 9.07407C17.3148 6.43519 15.1389 4.25926 12.5 4.25926C9.86111 4.25926 7.68519 6.43519 7.68519 9.07407C7.68519 11.713 9.86111 13.8889 12.5 13.8889ZM12.5 6.11111C14.1204 6.11111 15.463 7.4537 15.463 9.07407C15.463 10.6944 14.1204 12.037 12.5 12.037C10.8796 12.037 9.53704 10.6944 9.53704 9.07407C9.53704 7.4537 10.8796 6.11111 12.5 6.11111ZM18.9815 17.1296C18.9815 17.6389 18.5648 18.0556 18.0556 18.0556H6.94444C6.43518 18.0556 6.01852 17.6389 6.01852 17.1296C6.01852 16.6204 6.43518 16.2037 6.94444 16.2037H18.0556C18.5648 16.2037 18.9815 16.6204 18.9815 17.1296ZM18.9815 21.7593C18.9815 22.2685 18.5648 22.6852 18.0556 22.6852H6.94444C6.43518 22.6852 6.01852 22.2685 6.01852 21.7593C6.01852 21.25 6.43518 20.8333 6.94444 20.8333H18.0556C18.5648 20.8333 18.9815 21.25 18.9815 21.7593Z"
                fill={color || text}
            />
        </Svg>

    )
}

export default AboutMeIcon
