// Imports
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

const Stack = createNativeStackNavigator();

// Import screens
import { EditProfileNew } from "@screens/loggedIn/Profile/EditProfileNew";
import TutorProfile from "@screens/loggedIn/Tutors/TutorProfile";

// Components
export const ProfileStack = ({ navigation, route, params }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="Profile"
        component={TutorProfile}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditProfile"
        component={EditProfileNew}
      />

    </Stack.Navigator>
  );
};
