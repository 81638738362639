import React, { useEffect, useState } from 'react'

import { useColorsContext, useStyles } from '../../utils/styles'
import { FontAwesome5, AntDesign } from '@expo/vector-icons'
import { Image, View, StyleSheet, Text } from 'react-native'
import { getAuth } from 'firebase/auth'
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu'
import { InstabookMenu } from '../InstabookMenu'
import { updateDoc, doc } from 'firebase/firestore'
import { Link } from '@react-navigation/native'

// File imports
import { useFirebaseContext } from '@contexts/firebaseContext'
import { useUserContext } from '@contexts/userContext'
import { useSessionContext } from '@contexts/sessionContext'
import { VerifyModal } from '../VerifyModal'
import { StripeAccountModal } from '../StripeAccountModal'
import { Tabs, Typography, useTheme, useMediaQuery } from '@mui/material'
import { LinkTab } from './LinkTab'
import HomeIcon from '@icons/HomeIcon'
import NotificationsIcon from '@icons/NotificationsIcon'
import ListingsIcon from '@icons/ListingsIcon'
import InboxIcon from '@icons/InboxIcon'
import SettingsIcon from '@icons/SettingsIcon'
import ProfileIcon from '@icons/ProfileIcon'
import { useDialog } from '@contexts/dialogContext'
import NotificationsMenu from './NotificationsMenu'
import { useNotificationsContext } from '@contexts/notificationsContext'
import NavigationOption from './NavigationOption'
import ProfileNavigation from './ProfileNavigation'
import TutredSwitch from '@components/TutredSwitch'
import TutredAvatar from '@components/TutredAvatar'

export const Header = ({ navigation, route }) => {
    // Firebase
    const { db } = useFirebaseContext()
    // Appearance
    const {
        toggleDarkMode,
        darkMode,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        primaryLight,
    } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const auth = getAuth()
    const { badgeCount } = useNotificationsContext()

    useEffect(() => {
        const handleShowOnboarding = () => {
            openOnboardingDialog()
        }

        window.addEventListener('showOnboarding', handleShowOnboarding)

        return () => {
            window.removeEventListener('showOnboarding', handleShowOnboarding)
        }
    }, [])

    const {
        isTutor,
        instaBook,
        userId,
        user,
        isOnboarded,
        name,
        instaBookLength,
        instaBookMinimum,
        extendedUser,
        signOut,
    } = useUserContext() // Get user from authentication, if no user then user = null and take user to login page
    const { pendingSessions } = useSessionContext()

    const [verifyModal, setVerifyModal] = useState(false)
    const [stripeAccountModal, setStripeAccountModal] = useState(false)

    const { openOnboardingDialog } = useDialog()

    // Stylesheet
    const styles = StyleSheet.create({
        header: {
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: !isSm ? 15 : 45,
            paddingVertical: 20,
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 100,
        },
        menuText: {
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
        },
        picker: {
            borderRadius: defaultStyles.radius,
            fontFamily: defaultStyles.boldFont,
            paddingLeft: 15,
            paddingRight: 15,
            color: text,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
        },
    })

    const [path, setPath] = useState('home')

    return (
        <View style={{ backgroundColor: primaryLight }}>
            <View style={styles.header}>
                {/* Logo left */}
                <Link
                    to={{
                        screen: isTutor ? 'Sessions' : 'Tutors',
                    }}
                >
                    <img
                        src={require('@assets/images/logo.svg')}
                        alt="logo"
                        height="40"
                        width="auto"
                    />
                </Link>

                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {/* Instabook toggle */}
                    {isTutor && isOnboarded && (
                        <InstabookMenu
                            instaBook={instaBook}
                            instaBookLength={instaBookLength}
                            instaBookMinimum={instaBookMinimum}
                            userId={userId}
                        ></InstabookMenu>
                    )}

                    {/* Welcome message */}
                    {user && (
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 15,
                                display: isTutor && !isMd ? 'none' : 'flex',
                            }}
                        >
                            <Typography
                                variant="h6"
                                fontFamily="Poppins_500Medium"
                                sx={{ mr: 0.5 }}
                            >
                                Welcome,
                            </Typography>
                            <Typography
                                variant="h6"
                                fontFamily="Poppins_600SemiBold"
                            >
                                {name.split(' ')[0]}
                            </Typography>
                        </View>
                    )}

                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 15,
                            display: isTutor && !isMd ? 'none' : 'flex',
                        }}
                    >
                        <NotificationsMenu />
                    </View>

                    {/* Profile pic with hamburger menu for navigation */}
                    {isSm && (
                        <Menu>
                            <MenuTrigger
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: `1px solid ${theme.palette.grey[500]}`,
                                    borderRadius: 16,
                                    height: 45,
                                    marginLeft: 15,
                                    paddingHorizontal: 15,
                                    backgroundColor: background,
                                }}
                            >
                                <FontAwesome5
                                    name="bars"
                                    size={16}
                                    color={text}
                                    style={{ marginRight: 15 }}
                                ></FontAwesome5>
                                <TutredAvatar
                                    user={extendedUser}
                                    clickable={false}
                                    size="xx-small"
                                />
                                {/* <Image
                                    style={{
                                        height: 30,
                                        width: 30,
                                        borderRadius: 100,
                                        border: `1px solid ${theme.palette.grey[500]}`,
                                    }}
                                    source={
                                        profile
                                            ? { uri: profile }
                                            : {
                                                  uri: 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                                              }
                                    }
                                /> */}
                            </MenuTrigger>

                            <MenuOptions
                                optionsContainerStyle={{
                                    backgroundColor: backgroundVariant,
                                    borderRadius: defaultStyles.radius,
                                    padding: 15,
                                    marginLeft: 0,
                                    marginTop: 60,
                                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                                    shadowOpacity: 0.8,
                                    shadowOffset: { width: 2, height: 2 },
                                    shadowRadius: 4,
                                }}
                                customStyles={{
                                    optionText: {
                                        fontSize: 16,
                                        fontFamily: defaultStyles.regularFont,
                                    },
                                }}
                            >
                                {/* Navigation options: Sessions, Messages, Profile */}
                                {isTutor && isOnboarded && (
                                    <NavigationOption
                                        name={
                                            pendingSessions &&
                                            pendingSessions.length > 0
                                                ? `Sessions (${pendingSessions.length})`
                                                : `Sessions`
                                        }
                                        icon="laptop"
                                        link={'/sessions'}
                                        menuNavigation={() => {
                                            navigation.navigate('Sessions')
                                        }}
                                    ></NavigationOption>
                                )}

                                {user && !isTutor && (
                                    <NavigationOption
                                        name={
                                            pendingSessions &&
                                            pendingSessions.length > 0
                                                ? `Sessions (${pendingSessions.length})`
                                                : `Sessions`
                                        }
                                        icon="laptop"
                                        link={'/sessions'}
                                        menuNavigation={() => {
                                            navigation.navigate('Sessions')
                                        }}
                                    ></NavigationOption>
                                )}
                                {!isTutor && user && (
                                    <NavigationOption
                                        name={'Find a Tutor'}
                                        icon="search1"
                                        link={'/tutors'}
                                        menuNavigation={() => {
                                            navigation.navigate('tutors')
                                        }}
                                    ></NavigationOption>
                                )}

                                {/* Listings */}
                                {isTutor && isOnboarded && (
                                    <NavigationOption
                                        name={'Student Listings'}
                                        icon="book"
                                        link={'/listings'}
                                        menuNavigation={() => {
                                            navigation.navigate('Listings')
                                        }}
                                    ></NavigationOption>
                                )}

                                {!isTutor && user && (
                                    <NavigationOption
                                        name={'My Listings'}
                                        icon="book"
                                        link={'/listings'}
                                        menuNavigation={() => {
                                            navigation.navigate('Listings')
                                        }}
                                    ></NavigationOption>
                                )}

                                {!isTutor && user && (
                                    <NavigationOption
                                        name={'Favourites'}
                                        icon="hearto"
                                        link={'/tutors/favourites'}
                                        menuNavigation={() => {
                                            navigation.navigate('Favourites')
                                        }}
                                    ></NavigationOption>
                                )}

                                {isTutor && isOnboarded && (
                                    <NavigationOption
                                        name={'Messaging'}
                                        icon="mail"
                                        link={'/chats'}
                                        menuNavigation={() => {
                                            navigation.navigate('Chats')
                                        }}
                                    ></NavigationOption>
                                )}

                                {user && !isTutor && (
                                    <NavigationOption
                                        name={'Messaging'}
                                        icon="mail"
                                        link={'/chats'}
                                        menuNavigation={() => {
                                            navigation.navigate('Chats')
                                        }}
                                    ></NavigationOption>
                                )}

                                {/* Student Navigation Menu */}
                                <>
                                    <NavigationOption
                                        name={'Settings'}
                                        icon="setting"
                                        link={'/settings'}
                                        menuNavigation={() => {
                                            navigation.navigate('Profile')
                                        }}
                                    ></NavigationOption>
                                </>

                                {/* Divider */}
                                <View
                                    style={{
                                        height: 1,
                                        width: '100%',
                                        backgroundColor: border,
                                        marginBottom: 10,
                                    }}
                                ></View>

                                {user && isOnboarded && isTutor && (
                                    <ProfileNavigation />
                                )}

                                {user && !isTutor && <ProfileNavigation />}
                                <MenuOption
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}
                                    onSelect={() => {}}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 25,
                                            height: 25,
                                            display: 'flex',
                                            margin: 5,
                                        }}
                                    >
                                        <TutredSwitch
                                            size="small"
                                            style={{ marginTop: !isMd && 15 }}
                                            onChange={(value) => {
                                                toggleDarkMode(value)
                                            }}
                                            checked={darkMode}
                                        />
                                    </View>
                                    <Text style={styles.menuText}>
                                        Dark mode
                                    </Text>
                                </MenuOption>

                                <MenuOption
                                    onSelect={async () => {
                                        updateDoc(doc(db, 'users', user.uid), {
                                            instaBook: false,
                                        })
                                        await signOut()
                                        navigation.navigate('LandingPage')
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 25,
                                            height: 25,
                                            display: 'flex',
                                            margin: 5,
                                        }}
                                    >
                                        <AntDesign
                                            size={19}
                                            name="logout"
                                            color={textVariant}
                                        ></AntDesign>
                                    </View>
                                    <Text style={styles.menuText}>Log out</Text>
                                </MenuOption>
                            </MenuOptions>
                        </Menu>
                    )}

                    <VerifyModal
                        verifyModal={verifyModal}
                        setVerifyModal={setVerifyModal}
                    ></VerifyModal>

                    <StripeAccountModal
                        display={stripeAccountModal}
                        setDisplay={setStripeAccountModal}
                    ></StripeAccountModal>
                </View>
            </View>

            <View>
                <Tabs
                    value={route?.name}
                    sx={{ gap: isSm ? 2 : 1 }}
                    textColor={'primary'}
                    variant="scrollable"
                >
                    <LinkTab
                        value="Sessions"
                        label="Home"
                        onClick={() => navigation.navigate('Sessions')}
                        icon={<HomeIcon size={isSm ? 24 : 20} />}
                    />
                    <LinkTab
                        value="Chats"
                        label="Inbox"
                        onClick={() => navigation.navigate('Chats')}
                        icon={<InboxIcon size={isSm ? 24 : 20} />}
                    />
                    <LinkTab
                        value="Notifications"
                        label="Notifications"
                        onClick={() => navigation.navigate('Notifications')}
                        icon={<NotificationsIcon size={isSm ? 24 : 20} />}
                        badgeContent={badgeCount}
                    />

                    {!isTutor && (
                        <LinkTab
                            value="Favourites"
                            label="Favourites"
                            onClick={() => navigation.navigate('Favourites')}
                            icon={
                                <AntDesign
                                    name="hearto"
                                    size={isSm ? 24 : 20}
                                    color={text}
                                />
                            }
                        />
                    )}
                    <LinkTab
                        value={isTutor ? 'Students' : 'Tutors'}
                        label={isTutor ? 'Student Listings' : 'Tutor Listings'}
                        onClick={() =>
                            navigation.navigate(isTutor ? 'Students' : 'Tutors')
                        }
                        icon={<ListingsIcon size={isSm ? 24 : 20} />}
                    />
                    <LinkTab
                        value="Settings"
                        label="Settings"
                        onClick={() => navigation.navigate('Settings')}
                        icon={<SettingsIcon size={isSm ? 24 : 20} />}
                    />
                    <LinkTab
                        value="Profile"
                        label="Profile"
                        onClick={() => navigation.navigate('Profile')}
                        icon={<ProfileIcon size={isSm ? 24 : 20} />}
                    />
                </Tabs>
            </View>
        </View>
    )
}
