// Imports
import { AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StudentListing } from '../../components/StudentListing';
import { TutorReview } from '../../components/TutorReview';
import { TutorSession } from '../../components/TutorSession';
import { useColorsContext, useStyles } from '../../utils/styles';

import { A } from '@expo/html-elements';
import * as Clipboard from 'expo-clipboard';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  getDocs,
  limit,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { useAlert } from 'react-alert';
import { useMediaQuery } from 'react-responsive';
import { Header as AuthHeader } from '../../components/AuthHeader';
import { Header } from '../../components/Header';

import Footer from '../../components/Footer';
// Import paper plane icon
import SendIcon from '@mui/icons-material/Send';
import FadeIn from 'react-native-fade-in-image';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { LoadingPage } from '../../components/LoadingPage';
import { ReportModal } from '../../components/ReportModal';
import { useFirebaseContext } from '../../contexts/firebaseContext';
import { useUserContext } from '../../contexts/userContext';


const hostname = process.env.HOSTNAME
const dev = process.env.mode === 'development'
const shareLink = dev ? 'https://tutred-staging.vercel.app' : 'https://tutred.com';

// Import linking

export const StudentProfile = ({ navigation, route }) => {
  // Firebase
  const { db } = useFirebaseContext();
  const auth = getAuth();
  const { isTutor, customerId, user, name, profile, favourites } = useUserContext(); // Get user from authentication, if no user then user = null and take user to login page
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  // Alert
  const customAlert = useAlert();

  // Responsive Breakpoints
  const isXxl = useMediaQuery({ query: '(min-width: 1395px)' });
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isSm = useMediaQuery({ query: '(min-width: 640px)' });

  // Appearance
  const {
    primary,
    primaryVariant,
    red,
    redVariant,
    background,
    backgroundVariant,
    text,
    textVariant,
    border,
    yellow,
    green,
    purple
  } = useColorsContext();
  const defaultStyles = useStyles();

  // States and variables

  const urlId = route.params.student;

  const [totalSessions, setTotalSessions] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [listings, setListings] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [hoursSinceOnline, setHoursSinceOnline] = useState(null);
  const [student, setStudent] = useState(null);
  const [studentId, setStudentId] = useState(null);

  // Random color for banner
  let randomColors = ['#0087ED', '#234EDB', '#FF3D51', '#FFB347', '#00AA54'];
  const [randomColor] = useState(
    randomColors[Math.floor(Math.random() * randomColors.length)]
  );

  // FOR TESTING
  const isVerified = false;

  // Stylesheet
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    menuText: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.regularFont
    },
    menuTextSmall: {
      color: text,
      fontSize: 14,
      fontFamily: defaultStyles.regularFont,
      marginBottom: 15,
    },
    maxWidth: {
      flex: 1,
      backgroundColor: background,
      // display: 'flex',
      // flexDirection: Platform.OS === 'web' ? 'row' : null,
      // flexDirection: 'row',
      paddingRight: 0, // Padding right is for the cover photo to stretch
      width: '100%',
      // maxWidth: Platform.OS === "web" ? 1350 : null,
      margin: Platform.OS === 'web' ? 'auto' : null,
    },

    mainSection: {
      flex: 1,
      position: 'relative',
    },
    profileImg: {
      marginBottom: 5,
      borderRadius: 100,
      width: 130,
      height: 130,
      opacity: 1,
      marginRight: 20,
    },
    title: {
      fontSize: 32,
      fontFamily: defaultStyles.titleFont,
      color: text,
    },
    online: {
      marginLeft: 15,
      fontSize: 14,
      color: "#00AA54",
      fontFamily: defaultStyles.boldFont,
    },
    banner: {
      shadowColor: '#171717',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 50,
      backgroundColor: backgroundVariant,
    },
    verified: {
      fontSize: 14,
      color: green,
      fontFamily: defaultStyles.boldFont,
    },
    description: {
      marginTop: 5,
      fontSize: 16,
      color: text,
      fontFamily: defaultStyles.regularFont,
      maxWidth: 925,
      // paddingHorizontal: 15,
    },
    circleButton: {
      borderRadius: defaultStyles.buttonRadius,
      height: 45,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      border: '1px solid ' + primary,
      backgroundColor: 'transparent',
      width: 245
    },
    button: {
      borderRadius: defaultStyles.buttonRadius,
      height: 45,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: primary,
      width: 245
    },
    disabledButton: {
      opacity: 0.5,
    },
    buttonText: {
      color: '#fff',
      fontSize: 19,
      fontFamily: defaultStyles.titleFont,
    },
    tutorInfo: {
      fontSize: 16,
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
    },
    tutorDescriptionTitles: {
      color: text,
      fontSize: 16,
      fontFamily: defaultStyles.titleFont,
    },
    showMore: {
      color: textVariant,
      fontFamily: defaultStyles.boldFont,
      fontSize: 16,
    },
    actionText: {
      color: textVariant,
      fontFamily: defaultStyles.regularFont,
      fontSize: 14,
      marginLeft: 5,
      textDecorationColor: textVariant,
      textDecorationLine: 'underline',
    }
  });


  // Get reviews & tutor
  useEffect(() => {
    const q = query(
      collection(db, 'users'),
      where('URL', '==', parseInt(urlId)),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let student = doc.data();
        let today = new Date();
        var hoursSinceOnline;
        if (student.lastOnline == null) {
          hoursSinceOnline = null;
        } else {
          let lastOnline = new Date(student.lastOnline.toDate());
          hoursSinceOnline = Math.floor(
            (today - lastOnline) / (1000 * 60 * 60)
          );
        }
        setStudentId(student.uid);
        setStudent(student);
        setHoursSinceOnline(hoursSinceOnline);
      });
    });

    return unsubscribe
  }, []);

  useEffect(() => {
    if (studentId) {
      setLoaded(true);
      // Snapshot listener for total tutor sessions
      onSnapshot(
        query(collection(db, 'sessions'), where('user.uid', '==', studentId)),
        where('status', '==', 'completed'),
        (querySnapshot) => {
          let sessions = [];
          querySnapshot.forEach((doc) => {
            let session = doc.data();
            if (session.status == 'completed' && session.lengthInMinutes > 0) {
              sessions.push(session);
            }
          });
          setSessions(sessions);
          setTotalSessions(sessions.length);
        }
      );


      // Snapshot listener for tutor listings
      onSnapshot(
        query(collection(db, 'listings'), where('user.uid', '==', studentId)),
        (querySnapshot) => {
          let listingsArr = [];
          querySnapshot.forEach((doc) => {
            let listing = doc.data();
            listingsArr.push(listing);
          });
          setListings(listingsArr);
        }
      );

      // Snapshot listener for tutor reviews
      const unsubscribe = onSnapshot(
        query(collection(db, 'reviews'), where('tutor', '==', studentId), where('fromTutor', '==', true)),
        (querySnapshot) => {
          let reviews = [];
          querySnapshot.forEach((doc) => {
            let review = doc.data();
            reviews.push(review);
          });
          setReviews(reviews);
        }
      );
      return unsubscribe;
    }
  }, [studentId])

  // Get rating
  useEffect(() => {
    // Get rating
    let rating = 0;
    reviews.map((review) => {
      rating += review.rating;
    });
    rating = rating / reviews.length || 0;
    setRating(rating.toFixed(1));
  }, [reviews]);

  if (!loaded) {
    return <LoadingPage></LoadingPage>;
  }
  // Return 404
  if (!student && loaded) {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.maxWidth}>
          <View
            style={[
              styles.mainSection,
              {
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            <Text style={styles.title}>404 Tutor not found</Text>
          </View>
        </View>
      </SafeAreaView>
    );
  }



  // Sort the ratings from highest rating to lowest
  reviews.sort((a, b) => {
    return b.rating - a.rating;
  });



  // Render
  return (
    <SafeAreaView style={styles.container}>
      {/* If viewing your own profile show banner */}

      {user && user.uid === student.uid ? (
        <View style={[styles.banner]}>
          <Text
            style={{
              color: text,
              fontFamily: defaultStyles.boldFont,
              fontSize: 16,
            }}
          >
            You are viewing your own profile
            {/* Public icon */}
            <FontAwesome5
              name='globe-americas'
              size={16}
              color={primaryVariant}
              style={{ marginLeft: 5 }}
            ></FontAwesome5>
          </Text>
        </View>
      ) : null}

      <View style={styles.maxWidth}>
        {/* Tutor info section  */}
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.mainSection}
        >

          {/* REPORT MODAL  */}
          <ReportModal
            modalVisible={reportModal}
            setModalVisible={setReportModal}
            tutor={student}
            navigation={navigation}
          />


          <View
            style={{
              display: 'flex',
              // LAYOUT FLEX
              flexDirection: isLg ? 'row' : 'column',
              width: '100%',
              paddingVertical: 30,
              paddingHorizontal: isMd ? 60 : 30,
            }}
          >
            <View
              style={{
                // Set width for all breakpoints
                borderRightColor: border,
                borderRightWidth: !isLg ? 0 : 1,
                maxWidth: isLg ? 375 : '100%',
                minWidth: isLg ? 300 : '100%'
              }}
            >
              <View
                style={{
                  flex: 1,
                  paddingTop: 0,
                  paddingBottom: !isSm ? 15 : 20,
                  paddingLeft: !isSm ? 15 : 0,
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: !isSm && '100%',
                  borderBottomColor: border,
                  borderBottomWidth: !isLg ? 1 : 0,
                }}
              >
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 30,
                  paddingRight: 20
                }}>


                  {/* Report and Favourite */}
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 15
                  }}>

                    {/* Report */}
                    <TouchableOpacity
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onPress={() => {
                        if (user && user.uid == student.uid) {
                          customAlert.error("[Error] You can't report yourself");
                        } else {
                          if (!user) {
                            customAlert.error("[Error] Please sign in to report a tutor");
                          }
                          setReportModal(true);
                        }
                      }}
                    >
                      {/* Icon */}
                      <FontAwesome
                        name='flag-o'
                        size={14}
                        color={textVariant}
                      ></FontAwesome>

                      <Text style={styles.actionText}>Report</Text>
                    </TouchableOpacity>


                  </View>

                </View>

                <FadeIn>
                  <Image
                    style={styles.profileImg}
                    source={{
                      uri: student.picture
                        ? student.picture
                        : 'https://firebasestorage.googleapis.com/v0/b/welearn-f94aa.appspot.com/o/no_pfp.png?alt=media&token=a3a980c4-088a-4f40-9598-fbfccef9c31e',
                    }}
                  />
                </FadeIn>
                {/* Name and online badge */}
                <View>
                  <View
                    style={{
                      // marginTop: 20,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={styles.title}>{student.name.split(' ').length > 1 ? `${student.name.split(' ')[0]} ${student.name.split(' ')[1][0]}.` : student.name}</Text>
                    {hoursSinceOnline !== null && hoursSinceOnline <= 1 ? (
                      <Text style={styles.online}>
                        <FontAwesome name='circle' size={12} color={"#00AA54"} />{' '}
                        Online
                      </Text>
                    ) : null}
                  </View>

                  {/* Total sessions */}
                  <View
                    style={{
                      marginTop: 10,
                      flexDirection: isMd ? 'row' : 'column',
                    }}
                  >
                    <Text
                      style={[
                        styles.tutorInfo,
                        { marginRight: 15, marginTop: isMd ? 0 : 5 },
                      ]}
                    >
                      <AntDesign
                        style={{ marginRight: 10 }}
                        name='laptop'
                        size={16}
                        color={textVariant}
                      />
                      {totalSessions} Completed Session(s)
                    </Text>
                  </View>


                  {/* Tutor info -Location, Rating, Sessions, Join date */}
                  <View
                    style={{
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >


                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {rating > 0 && (
                        <Text
                          style={[
                            styles.tutorInfo,
                            styles.tutorRating,
                            { marginTop: 5 },
                          ]}
                        >
                          <FontAwesome
                            style={{ marginRight: 5 }}
                            name='star'
                            size={14}
                            color={yellow}
                          />
                          {rating} ({reviews.length})
                        </Text>
                      )}
                    </View>

                    <View
                      style={{
                        marginTop: 15,
                        flexDirection: isSm ? 'row' : 'column',
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>


                        {user && isTutor && user.uid !== student.uid && (
                          <TouchableOpacity
                            disabled={
                              !user ||
                              user.uid == student.uid ||
                              !isTutor
                            }
                            onPress={() => {
                              // Check if the user has a chat with this tutor
                              // collection('chats').where('tutor', '==', tutor.uid).where('user', '==', user.uid).get()
                              const chatsRef = collection(db, 'chats');
                              const q = query(
                                chatsRef,
                                where('user.uid', '==', student.uid),
                                where('tutor.uid', '==', user.uid)
                              );
                              getDocs(q).then((querySnapshot) => {
                                if (querySnapshot.docs.length > 0) {
                                  // // Navigate to the chat
                                  var hoursSinceOnline;
                                  if (student.lastOnline) {
                                    hoursSinceOnline = Math.floor(
                                      (new Date() - student.lastOnline.toDate()) /
                                      1000 /
                                      60 /
                                      60
                                    );
                                  } else {
                                    hoursSinceOnline = null;
                                  }

                                  // Navigate to chat nested in chats navigator
                                  navigation.navigate('Chats', {
                                    screen: 'Chats',
                                    params: {
                                      chat: querySnapshot.docs[0].id,
                                      lo: hoursSinceOnline,
                                    },
                                  });
                                } else {
                                  // Create the chat in firestore
                                  const chatsRef = collection(db, 'chats');
                                  var hoursSinceOnline;

                                  if (student.lastOnline) {
                                    hoursSinceOnline = Math.floor(
                                      (new Date() - student.lastOnline.toDate()) /
                                      1000 /
                                      60 /
                                      60
                                    );
                                  } else {
                                    hoursSinceOnline = null;
                                  }

                                  // Add Doc
                                  addDoc(chatsRef, {
                                    tutor: {
                                      name: name,
                                      image: profile ? profile : null,
                                      uid: user.uid,
                                      id: user.uid,
                                    },
                                    user: {
                                      name: student.name,
                                      image: student.picture ? student.picture : null,
                                      uid: student.uid,
                                      id: student.uid,
                                    },
                                    created: new Date(),
                                    lastUpdated: new Date(),
                                    lastMessage: {},
                                  }).then((docRef) => {
                                    // Chat object for navigation

                                    // Navigate to the chat
                                    // navigation.navigate('Chats', {screen: 'Chat', params: {chat: chatObject, hoursSinceOnline: hoursSinceOnline}} )
                                    navigation.navigate('Chats', {
                                      screen: 'Chats',
                                      params: {
                                        chat: docRef.id,
                                        lo: hoursSinceOnline,
                                      },
                                    });
                                  });
                                }
                              });
                            }}
                            style={[
                              styles.circleButton,
                              {
                                marginTop: isSm ? 0 : 0,
                                marginRight: isSm ? 20 : 0
                              }
                            ]}
                          >
                            <Text style={[styles.buttonText, { color: primary, marginRight: 10 }]}>
                              Message
                            </Text>

                            <SendIcon
                              style={{
                                fontSize: 20, color: primary
                              }}
                            ></SendIcon>
                          </TouchableOpacity>
                        )}
                      </View>

                    </View>



                    {/* Share buttons */}
                    <View style={{ maxWidth: 245, marginTop: 15, flexDirection: 'row', justifyContent: 'center' }}>
                      <TouchableOpacity
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: 100,
                          borderColor: text,
                          borderWidth: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}

                        onPress={() => {
                          Clipboard.setString(
                            `${shareLink}/students/` +
                            urlId
                          );
                          customAlert.success('[Share Tutor] Copied tutor profile link to your clipboard.');
                        }}
                      >
                        <FontAwesome5
                          style={{ fontSize: 16, color: text }}
                          name='clipboard'
                        ></FontAwesome5>

                      </TouchableOpacity>

                      <A
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: 100,
                          borderColor: text,
                          borderWidth: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                        target='_blank'
                        href={`sms:&body=Check out this tutor on Tutred! ${shareLink}/students/` + urlId}
                      >
                        <AntDesign
                          style={{ fontSize: 16, color: text }}
                          name='message1'
                        ></AntDesign>
                      </A>


                      <A
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: 100,
                          borderColor: text,
                          borderWidth: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                        target='_blank'
                        href={`https://twitter.com/intent/tweet?text=Check out this tutor on Tutred! ${shareLink}/students/` + urlId}
                      >
                        <FontAwesome5
                          style={{ fontSize: 16, color: text }}
                          name='twitter'
                        ></FontAwesome5>
                      </A>



                      <A
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: 100,
                          borderColor: text,
                          borderWidth: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                        target='_blank'
                        href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}/students/` + urlId}
                      >
                        <FontAwesome5
                          style={{ fontSize: 16, color: text }}
                          name='facebook'
                        ></FontAwesome5>
                      </A>





                    </View>




                  </View>
                </View>
              </View>
            </View>

            {/* Right Section  */}

            <View
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
                padding: 30
              }}
            >

              {/* Student listings */}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    color: textVariant,
                    fontFamily: defaultStyles.boldFont,
                    fontSize: 16,
                  }}
                >
                  Student Listings
                </Text>{' '}
                <View>
                  {/* Map thru tutors skills  */}
                  {sessions.length < 1 ? (
                    <Text
                      style={{
                        color: text,
                        fontSize: 16,
                        fontFamily: defaultStyles.regularFont,
                      }}
                    >
                      No listings...
                    </Text>
                  ) : (
                    <ScrollView
                      style={{
                        maxHeight: 350,
                      }}
                    >
                      {listings.map((listing, index) => {
                        return (
                          <StudentListing
                            key={`listing-${index}`}
                            user={listing.user}
                            listing={listing}
                          ></StudentListing>
                        );
                      })}
                    </ScrollView>
                  )}
                </View>
              </View>

              {/* Past Sessions  */}
              <View
                style={{
                  display: 'flex',
                  flexDirection: !isMd ? 'column' : 'row',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    color: textVariant,
                    fontFamily: defaultStyles.boldFont,
                    fontSize: 16,
                  }}
                >
                  Past Sessions
                </Text>{' '}
                <View style={{ minWidth: '85%' }}>
                  {/* Map thru tutors skills  */}
                  {sessions.length < 1 ? (
                    <Text
                      style={{
                        color: text,
                        fontSize: 16,
                        fontFamily: defaultStyles.regularFont,
                      }}
                    >
                      No sessions yet...
                    </Text>
                  ) : (
                    <ScrollView
                      style={{
                        maxHeight: 350,
                      }}
                    >
                      {sessions.map((session, index) => {
                        return (
                          <TutorSession
                            key={`session-${index}`}
                            user={session.user}
                            session={session}
                          ></TutorSession>
                        );
                      })}
                    </ScrollView>
                  )}
                </View>
              </View>

              {/* Reviews  */}
              <View
                style={{
                  display: 'flex',
                  flexDirection: !isMd ? 'column' : 'row',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    color: textVariant,
                    fontFamily: defaultStyles.boldFont,
                    fontSize: 16,
                  }}
                >
                  Reviews
                </Text>
                <View
                  style={{
                    minWidth: '85%',
                  }}
                >
                  {reviews.length < 1 ? (
                    <Text
                      style={{
                        color: text,
                        fontSize: 16,
                        fontFamily: defaultStyles.regularFont,
                      }}
                    >
                      No reviews yet...
                    </Text>
                  ) : (
                    <ScrollView
                      style={{
                        maxHeight: 350,
                      }}
                    >
                      {reviews.map((review, index) => {
                        return (
                          <TutorReview
                            key={`review-${index}`}
                            user={review.user}
                            review={review}
                            tutor={true}
                          ></TutorReview>
                        );
                      })}
                    </ScrollView>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* Footer */}
          {isSm ? (
            <View style={{ marginTop: 100, width: '100%' }}>
              <Footer></Footer>{' '}
            </View>
          ) : null}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
