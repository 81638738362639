import React from 'react'
import { schoolLogos } from '@config/schools'
import {
    Box,
    Card,
    IconButton,
    SxProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import AntDesign from '@expo/vector-icons/AntDesign'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import UploadVerificationDialog from '@components/OnboardingDialog/OnboardingProgress/OnboardingSteps/Background/UploadVerificationDialog'
import { useState } from 'react'
import CompletionYearIcon from '@icons/CompletionYearIcon'
import { useColorsContext } from '@utils/styles'

interface DegreeCard {
    degree: {
        id: string
        year: string
        name: string
        school: string
        verificationImg?: string
        isVerified?: boolean
    }
    showVerificationOption?: boolean
    remove?: () => void
    sx?: SxProps
    size?: 'small' | 'medium' | 'large'
}

const DegreeCard: React.FC<DegreeCard> = ({
    degree,
    showVerificationOption,
    remove,
    sx,
    size = 'medium',
}) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const [showUploadVerificationDialog, setShowUploadVerificationDialog] =
        useState(false)
    const { darkMode } = useColorsContext()

    const getSizeStyles = () => {
        switch (size) {
            case 'small':
                return {
                    imgSize: 28,
                    marginRight: 8,
                    iconSize: 16,
                    typographyVariant: 'caption',
                }
            case 'large':
                return {
                    imgSize: isMd ? 44 : 28,
                    marginRight: isMd ? 16 : 8,
                    iconSize: isMd ? 24 : 18,
                    typographyVariant: isMd ? 'h6' : 'body2',
                }
            case 'medium':
            default:
                return {
                    imgSize: isMd ? 44 : 28,
                    marginRight: isMd ? 16 : 8,
                    iconSize: isMd ? 24 : 18,
                    typographyVariant: isMd ? 'h6' : 'body2',
                }
        }
    }

    const { imgSize, marginRight, iconSize, typographyVariant } =
        getSizeStyles()

    return (
        <Box
            sx={{
                borderRadius: 4,
                backgroundColor: darkMode ? '#262626' : '#FFF',
                border: '3px solid #A3A3A3',
                ...sx,
            }}
        >
            {showVerificationOption && (
                <UploadVerificationDialog
                    visible={showUploadVerificationDialog}
                    setVisible={setShowUploadVerificationDialog}
                    id={degree.id}
                />
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    position: 'relative',
                    p: size === 'small' ? 1 : { xs: 2, sm: 3 },
                    height: '100%',
                }}
            >
                {!!remove && (
                    <IconButton
                        onClick={remove}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <AntDesign
                            name="close"
                            size={iconSize}
                            color={theme.palette.grey[500]}
                        />
                    </IconButton>
                )}
                <img
                    src={schoolLogos[degree.school]}
                    style={{
                        width: imgSize,
                        height: imgSize,
                        marginRight: marginRight,
                        objectFit: 'contain',
                        marginTop: 8,
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', py: 1 }}>
                    <Typography
                        variant={typographyVariant}
                        fontFamily="Poppins_600SemiBold"
                        lineHeight={1.25}
                    >
                        {degree.school}
                    </Typography>
                    <Typography
                        variant={typographyVariant}
                        fontFamily="Poppins_500Medium"
                        lineHeight={1.25}
                        sx={{
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            display: isMd ? 'inline-flex' : 'inline',
                            alignItems: 'center',
                        }}
                    >
                        {degree.type} of {degree.name}
                        {isMd && (
                            <>
                                <span
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'baseline',
                                        marginLeft: 4,
                                        marginRight: 4,
                                    }}
                                >
                                    <CompletionYearIcon
                                        size={iconSize}
                                        color="#000"
                                    />
                                </span>
                                {degree.year}
                            </>
                        )}
                    </Typography>
                    {!isMd && (
                        <Typography
                            variant={typographyVariant}
                            fontFamily="Poppins_500Medium"
                            lineHeight={1}
                            sx={{
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                    marginLeft: 4,
                                    marginRight: 4,
                                }}
                            >
                                <CompletionYearIcon size={iconSize} />
                            </span>
                            {degree.year}
                        </Typography>
                    )}

                    {degree.isVerified ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MaterialIcons
                                name="verified"
                                size={iconSize}
                                color={theme.palette.primary.main}
                                style={{ marginRight: marginRight }}
                            />
                            <Typography
                                variant={typographyVariant}
                                fontFamily="Poppins_500Medium"
                            >
                                Verified
                            </Typography>
                        </Box>
                    ) : degree.verificationImg ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MaterialIcons
                                name="verified"
                                size={iconSize}
                                color={theme.palette.grey[500]}
                                style={{ marginRight: marginRight }}
                            />
                            <Typography
                                variant={typographyVariant}
                                fontFamily="Poppins_500Medium"
                            >
                                Awaiting verification
                            </Typography>
                        </Box>
                    ) : showVerificationOption ? (
                        <Typography
                            variant={typographyVariant}
                            fontFamily="Poppins_500Medium"
                            color={theme.palette.primary.main}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setShowUploadVerificationDialog(true)
                            }}
                        >
                            Verify Degree
                        </Typography>
                    ) : null}
                </Box>
            </Box>
        </Box>
    )
}

export default DegreeCard
