import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useColorsContext } from '@utils/styles';

const CompletionYearIcon = ({ size = 25, color }) => {
    const { text } = useColorsContext();

    return (
        <Svg width={size} height={size * 27 / 25} viewBox="0 0 25 27" fill="none">
            <Path
                d="M15.4531 2.53125C15.4531 2.06526 15.0754 1.6875 14.6094 1.6875H7.85938C7.85938 1.22151 7.48163 0.84375 7.01562 0.84375C6.54962 0.84375 6.17188 1.22151 6.17188 1.6875V1.73972C2.84218 2.15493 0.265625 4.99534 0.265625 8.4375V19.4062C0.265625 23.1342 3.28768 26.1562 7.01562 26.1562H17.9844C21.7123 26.1562 24.7344 23.1342 24.7344 19.4062V8.4375C24.7344 5.29225 22.5832 2.64948 19.6719 1.90015V1.6875C19.6719 1.22151 19.2941 0.84375 18.8281 0.84375C18.3621 0.84375 17.9844 1.22151 17.9844 1.6875V3.79688C17.9844 4.26288 18.3621 4.64062 18.8281 4.64062C19.2941 4.64062 19.6719 4.26288 19.6719 3.79688V3.66306C21.6381 4.35805 23.0469 6.23325 23.0469 8.4375V19.4062C23.0469 22.2022 20.7803 24.4688 17.9844 24.4688H7.01562C4.21969 24.4688 1.95312 22.2022 1.95312 19.4062V8.4375C1.95312 5.92903 3.77757 3.84669 6.17188 3.445V3.79688C6.17188 4.26288 6.54962 4.64062 7.01562 4.64062C7.48163 4.64062 7.85938 4.26288 7.85938 3.79688V3.375H14.6094C15.0754 3.375 15.4531 2.99724 15.4531 2.53125Z"
                fill={text}
            />
            <Path
                d="M18.1444 9.09212C18.4818 9.41351 18.4949 9.9476 18.1735 10.285L10.9413 17.8788C10.7786 18.0496 10.5518 18.1445 10.3159 18.1405C10.08 18.1364 9.85649 18.0338 9.69972 17.8574L6.80688 14.6029C6.49731 14.2547 6.52865 13.7214 6.87695 13.4118C7.22521 13.1022 7.75855 13.1336 8.06812 13.4819L10.3518 16.0509L16.9515 9.12123C17.2729 8.78377 17.807 8.77078 18.1444 9.09212Z"
                fill={text}
            />
        </Svg>
    );
};

export default CompletionYearIcon;
